import { ROW_TYPES } from "../components/order/detail";

export const createTableData = (order) => {
  const tableData = [];

  if (order.items) {
    order.items.forEach((item, index) => {
      tableData.push({
        key: `${item.itemId}`,
        type: ROW_TYPES.MENU,
        description: item.nameTh || item.nameEn,
        sequence: index + 1,
        quantity: item.amount,
        price: item.price,
        options: item.choices,
      });

      if (item.choices && item.choices.length > 0) {
        tableData.push({
          key: `${item.itemId}.optionLabel`,
          type: ROW_TYPES.OPTION_LABEL,
        });

        item.choices.forEach((choice) => {
          tableData.push({
            key: `${item.itemId}.options.${choice.choiceId}`,
            type: ROW_TYPES.OPTION,
            description: choice.nameTh || choice.nameEn,
            price: choice.price,
          });
        });
      }

      const comment = item.comment && item.comment.trim();
      if (comment) {
        tableData.push({
          key: `${item.itemId}.note`,
          type: ROW_TYPES.CUSTOMER_NOTE,
          description: comment,
        });
      }
    });
  }

  order.tableData = tableData;
};

export const adaptOrders = (responseData) => {
  // This function is responsible for
  // (1) Rename field "first" -> "1" / "second" -> "2" and so on
  // (2) For each order, create tableData which used for render table
  //      See render config in '../component/order/detail.js'
  // (3) Create mapping that map orderId to the tabNumber it currently belong to
  // (4) Initialize update count for each tab

  const currentTab = {}; // (3)

  ["first", "second", "third", "fourth"].forEach((field, index) => {
    // Start (1)
    const tabNumber = index + 1;
    const orders = responseData[field];

    responseData[tabNumber] = orders;
    delete responseData[field];
    // End (1)

    orders.forEach((order) => {
      createTableData(order); // (2)
      currentTab[order.orderId] = tabNumber; // (3)
    });
  });

  responseData.currentTab = currentTab; // (3)
  responseData.newUpdates = [0, 0, 0, 0, 0]; // (4)

  return responseData;
};

class MergeError extends Error {
  constructor(message) {
    super(message);
    this.name = "MergeError";
  }
}

export const mergeUpdate = (data, updateObj, currentTab, onConflict) => {
  const newData = { ...data, now: updateObj.now };
  newData.currentTab = { ...newData.currentTab };
  newData.newUpdates = [...newData.newUpdates];
  try {
    // #region [Tab 1]
    if (updateObj.first && updateObj.first.length > 0) {
      updateObj.first.forEach((order) => {
        newData.currentTab[order.orderId] = 1;
        createTableData(order);
      });

      newData[1] = [...newData[1], ...updateObj.first];

      if (1 != currentTab) {
        newData.newUpdates[0] += updateObj.first.length;
      }
    } else {
      newData[1] = [...newData[1]];
    }
    // #endregion

    // #region [Tab 2-5]
    ["second", "third", "fourth"].forEach((field, index) => {
      const tabNumber = index + 2;
      newData[tabNumber] = [...newData[tabNumber]];

      if (updateObj[field]) {
        updateObj[field].forEach((update) => {
          const id = update.orderId;
          const statusId = update.status.statusId;
          const oldTab = newData.currentTab[id];
          const orderIndex = newData[oldTab].findIndex(
            ({ orderId }) => orderId == id
          );
          const [order] = newData[oldTab].splice(orderIndex, 1);

          newData[tabNumber].push({ ...order, ...update, statusId });
          newData.currentTab[id] = tabNumber;
        });

        if (tabNumber != currentTab) {
          newData.newUpdates[tabNumber - 1] += updateObj[field].length;
        }
      }
    });
    // #endregion

    return {
      updated: true,
      data: newData,
    };
  } catch (error) {
    if (error.name === "MergeError") {
      if (onConflict) {
        onConflict(error);
      }

      return {
        updated: false,
        data,
      };
    } else {
      throw error;
    }
  }
};
