import React from 'react';
import styled from 'styled-components';
// import { Menu, Dropdown } from 'antd';
import { colors } from '../../../values/colors';
// import userIcon from '../../../assets/icons/user.svg';
// import downIcon from '../../../assets/icons/down.svg';
// import logoutIcon from '../../../assets/icons/logout.svg';

const Bar = styled.div`
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: ${props => props.height};
    background: ${colors.storeThemeColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: calc(${props => props.drawerWidth} + 2rem);
    padding-right: 2rem;
`

const Title = styled.div`
    width: ${props => props.logo ? '16rem' : '8.5rem'};
    font-family: 'Kanit';
    font-style: normal;
    font-weight: ${props => props.logo ? 'bold' : 'normal'};
    font-size: ${props => props.logo ? '1.25em' : '1em'};
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: ${colors.textColor1};
    padding: 0.5rem;
`;

// const FlexRow = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: ${props => props.space ? 'space-between' : 'flex-end'};
//     visibility: ${props => props.hidden ? 'hidden' : 'visible'};
// `;

// const DropdownMenu = styled(Menu)`
//     border-radius: 5px;
// `;

// const MenuItem = styled(Menu.Item)`
//     height: 2.6rem;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: 'flex-start';
//     font-size: 1rem;
//     line-height: 1.25rem;
//     font-family: 'Kanit';
// `;

// const MenuItemIcon = styled.img`
//     width: 1rem;
//     height: 1rem;
//     src: ${props => props.src};
//     margin-right: 1rem;
// `;

// const MenuList = () => {
//     return (
//         <DropdownMenu>
//             <MenuItem key="0">
//                 <MenuItemIcon src={logoutIcon}/>
//                 ออกจากระบบ
//             </MenuItem>
//         </DropdownMenu>
//     );
// };

class StoreHeader extends React.Component{
    render(){
        const { height, drawerWidth } = this.props;
        return (
            <Bar height={height} drawerWidth={drawerWidth}> 
                <Title logo>{'สาขาสุทธิสาร'}</Title>
                {/* <FlexRow>
                    <Dropdown overlay={<MenuList handleRedirect={this.handleRedirect}/>} trigger={['click']} placement="bottomRight">
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <img src={userIcon}/>
                            <img src={downIcon}/>
                        </a>
                    </Dropdown>
                </FlexRow> */}
            </Bar>
        );
    }
}

export default StoreHeader;