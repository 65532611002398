import styled from 'styled-components';
import { colors } from '../../../values/colors';

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    font-weight: 600;
    font-size: 1rem;
    color: ${colors.storeText};

    & > * {
        width: 100%;
    }
`;

export default Stack;