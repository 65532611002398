import React from "react";

const SVGIcon = ({ type, stroke, style, ...rest }) => {
  switch (type) {
    case "menu":
      return (
        <svg
          width="43"
          height="43"
          viewBox="0 0 43 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <path
            d="M5.375 21.5H37.625"
            stroke={stroke || "#4F4F4F"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.375 10.75H37.625"
            stroke={stroke || "#4F4F4F"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.375 32.25H37.625"
            stroke={stroke || "#4F4F4F"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "home":
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <path
            d="M1.875 5.625L7.5 1.25L13.125 5.625V12.5C13.125 12.8315 12.9933 13.1495 12.7589 13.3839C12.5245 13.6183 12.2065 13.75 11.875 13.75H3.125C2.79348 13.75 2.47554 13.6183 2.24112 13.3839C2.0067 13.1495 1.875 12.8315 1.875 12.5V5.625Z"
            stroke={stroke || "#25282B"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.625 13.75V7.5H9.375V13.75"
            stroke={stroke || "#25282B"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "myOrder":
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <g clip-path="url(#clip0)">
            <path
              d="M11.0355 17.8125H2.71445C1.21766 17.8125 0 16.5948 0 15.098V6.77695C0 5.28016 1.21766 4.0625 2.71445 4.0625H11.0355C12.5323 4.0625 13.75 5.28016 13.75 6.77695V15.098C13.75 16.5948 12.5323 17.8125 11.0355 17.8125ZM2.71445 6.5625C2.59617 6.5625 2.5 6.65867 2.5 6.77695V15.098C2.5 15.2163 2.59617 15.3125 2.71445 15.3125H11.0355C11.1538 15.3125 11.25 15.2163 11.25 15.098V6.77695C11.25 6.65867 11.1538 6.5625 11.0355 6.5625H2.71445Z"
              fill={stroke || "#663C46"}
            />
            <path
              d="M11.0355 35.9375H2.71445C1.21766 35.9375 0 34.7198 0 33.223V24.902C0 23.4052 1.21766 22.1875 2.71445 22.1875H11.0355C12.5323 22.1875 13.75 23.4052 13.75 24.902V33.223C13.75 34.7198 12.5323 35.9375 11.0355 35.9375ZM2.71445 24.6875C2.59617 24.6875 2.5 24.7837 2.5 24.902V33.223C2.5 33.3413 2.59617 33.4375 2.71445 33.4375H11.0355C11.1538 33.4375 11.25 33.3413 11.25 33.223V24.902C11.25 24.7837 11.1538 24.6875 11.0355 24.6875H2.71445Z"
              fill={stroke || "#663C46"}
            />
            <path
              d="M38.75 10.3125H18.75C18.0597 10.3125 17.5 9.75281 17.5 9.0625C17.5 8.37219 18.0597 7.8125 18.75 7.8125H38.75C39.4403 7.8125 40 8.37219 40 9.0625C40 9.75281 39.4403 10.3125 38.75 10.3125Z"
              fill={stroke || "#663C46"}
            />
            <path
              d="M31.875 15H18.75C18.0597 15 17.5 14.4403 17.5 13.75C17.5 13.0597 18.0597 12.5 18.75 12.5H31.875C32.5653 12.5 33.125 13.0597 33.125 13.75C33.125 14.4403 32.5653 15 31.875 15Z"
              fill={stroke || "#663C46"}
            />
            <path
              d="M38.75 28.125H18.75C18.0597 28.125 17.5 27.5653 17.5 26.875C17.5 26.1847 18.0597 25.625 18.75 25.625H38.75C39.4403 25.625 40 26.1847 40 26.875C40 27.5653 39.4403 28.125 38.75 28.125Z"
              fill={stroke || "#663C46"}
            />
            <path
              d="M31.875 33.125H18.75C18.0597 33.125 17.5 32.5653 17.5 31.875C17.5 31.1847 18.0597 30.625 18.75 30.625H31.875C32.5653 30.625 33.125 31.1847 33.125 31.875C33.125 32.5653 32.5653 33.125 31.875 33.125Z"
              fill={stroke || "#663C46"}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="40" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "user":
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <path
            d="M20.8337 21.875V19.7917C20.8337 18.6866 20.3947 17.6268 19.6133 16.8454C18.8319 16.064 17.7721 15.625 16.667 15.625H8.33366C7.22859 15.625 6.16878 16.064 5.38738 16.8454C4.60598 17.6268 4.16699 18.6866 4.16699 19.7917V21.875"
            stroke={stroke || "black"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.4997 11.4583C14.8009 11.4583 16.6663 9.59285 16.6663 7.29167C16.6663 4.99048 14.8009 3.125 12.4997 3.125C10.1985 3.125 8.33301 4.99048 8.33301 7.29167C8.33301 9.59285 10.1985 11.4583 12.4997 11.4583Z"
            stroke={stroke || "black"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "phone":
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <path
            d="M13.7504 10.575V12.45C13.7511 12.624 13.7154 12.7963 13.6457 12.9558C13.576 13.1153 13.4737 13.2585 13.3454 13.3761C13.2172 13.4938 13.0657 13.5834 12.9009 13.6392C12.736 13.6949 12.5612 13.7156 12.3879 13.7C10.4647 13.491 8.61726 12.8338 6.99414 11.7812C5.48403 10.8216 4.20372 9.54133 3.24414 8.03122C2.18787 6.40072 1.53054 4.54434 1.32539 2.61247C1.30977 2.43964 1.33031 2.26544 1.3857 2.10098C1.44109 1.93652 1.53012 1.7854 1.64711 1.65723C1.76411 1.52906 1.90651 1.42666 2.06525 1.35655C2.224 1.28643 2.3956 1.25013 2.56914 1.24997H4.44414C4.74745 1.24698 5.04151 1.35439 5.27149 1.55218C5.50147 1.74996 5.65168 2.02462 5.69414 2.32497C5.77327 2.92501 5.92004 3.51417 6.13164 4.08122C6.21573 4.30492 6.23392 4.54804 6.18408 4.78177C6.13423 5.0155 6.01842 5.23004 5.85039 5.39997L5.05664 6.19372C5.94636 7.75843 7.24192 9.054 8.80664 9.94372L9.60039 9.14997C9.77032 8.98193 9.98486 8.86612 10.2186 8.81628C10.4523 8.76643 10.6954 8.78463 10.9191 8.86872C11.4862 9.08031 12.0753 9.22708 12.6754 9.30622C12.979 9.34905 13.2563 9.50197 13.4545 9.7359C13.6527 9.96983 13.758 10.2685 13.7504 10.575Z"
            stroke={stroke || "#25282B"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "logout":
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <path
            d="M5.625 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V3.125C1.875 2.79348 2.0067 2.47554 2.24112 2.24112C2.47554 2.0067 2.79348 1.875 3.125 1.875H5.625"
            stroke={stroke || "#D30404"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 10.625L13.125 7.5L10 4.375"
            stroke={stroke || "#D30404"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.125 7.5H5.625"
            stroke={stroke || "#D30404"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "xIcon":
      return (
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <path
            d="M28.5 9.5L9.5 28.5"
            stroke={stroke || "#663C46"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 9.5L28.5 28.5"
            stroke={stroke || "#663C46"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "cart":
      return (
        <svg
          width="49"
          height="49"
          viewBox="0 0 49 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...style}
        >
          <path
            d="M18.3752 44.9163C19.5027 44.9163 20.4168 44.0023 20.4168 42.8747C20.4168 41.7471 19.5027 40.833 18.3752 40.833C17.2476 40.833 16.3335 41.7471 16.3335 42.8747C16.3335 44.0023 17.2476 44.9163 18.3752 44.9163Z"
            stroke={stroke || "#663C46"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.8332 44.9163C41.9608 44.9163 42.8748 44.0023 42.8748 42.8747C42.8748 41.7471 41.9608 40.833 40.8332 40.833C39.7056 40.833 38.7915 41.7471 38.7915 42.8747C38.7915 44.0023 39.7056 44.9163 40.8332 44.9163Z"
            stroke={stroke || "#663C46"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.0415 2.04199H10.2082L15.6798 29.3799C15.8665 30.3199 16.3779 31.1642 17.1244 31.7652C17.8709 32.3661 18.805 32.6854 19.7632 32.667H39.6082C40.5663 32.6854 41.5004 32.3661 42.2469 31.7652C42.9934 31.1642 43.5048 30.3199 43.6915 29.3799L46.9582 12.2503H12.2498"
            stroke={stroke || "#663C46"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default SVGIcon;
