// import Icon, { UploadOutlined } from "@ant-design/icons";
// import React, { Component } from "react";
// import { Upload, Button, Modal } from "antd";
// import { customer as api } from "../../../../services/api";
// import styled from "styled-components";
// import localName from "../../../../values/localStorageDict";

// const UploadBox = styled.div`
//   width: 18rem;
//   @media (max-width: 1220px) {
//     width: 13rem;
//   }
//   @media (max-width: 875px) {
//     width: 18rem;
//   }
// `;
// class ProfileUploader extends Component {
//   state = {
//     previewVisible: false,
//     previewImage: "",
//     fileList: [],
//     enableButton: false,
//     showPreviewIcon: false,
//     upload: this.props.upload,
//   };

//   componentWillReceiveProps(nextProps) {
// if (
//   this.props.imageProfile != nextProps.imageProfile &&
//   nextProps.imageProfile != "" &&
//   nextProps.imageProfile != "n"
// ) {
//       this.setState({
//         fileList: [
//           {
//             uid: -1,
//             status: "done",
//             url: nextProps.imageProfile,
//           },
//         ],
//       });
//     }
//     if (this.props.upload != nextProps.upload) {
//       this.setState({ upload: nextProps.upload });
//     }
//   }

//   handleCancel = () => this.setState({ previewVisible: false });
//   handlePreview = (file) => {
//     this.setState({
//       previewImage: file.thumbUrl,
//       previewVisible: true,
//     });
//   };

//   handleUpload = ({ fileList }) => {
//     this.setState({ fileList, enableButton: true, showPreviewIcon: true });
//     let formData = new FormData();
//     if (fileList[0] != undefined) {
//       formData.append("profile", fileList[0].originFileObj);
//     }
//     localStorage.setItem(localName.PROFILE_IMAGE, formData);
//     console.log(localStorage.getItem(localName.PROFILE_IMAGE));
//   };

//   handleSubmit = () => {
//     // event.preventDefault();
//     // if (this.state.upload) {
//     let formData = new FormData();
//     if (this.state.fileList.length > 0) {
//       if (this.state.fileList[0].uid != -1) {
//         if (this.state.fileList[0].originFileObj != undefined) {
//           formData.append("profile", this.state.fileList[0].originFileObj);
//           localStorage.setItem(localName.PROFILE_IMAGE, formData);
//           api.user.uploadProfileImg(formData, ({ data, status }) => {});
//         }
//       }
//     } else {
//       localStorage.setItem(localName.PROFILE_IMAGE, formData);
//       api.user.uploadProfileImg(formData, ({ data, status }) => {});
//     }
//   };
//   render() {
//     const { previewVisible, previewImage, fileList } = this.state;
//     const uploadButton = (
//       <UploadBox>
//         <UploadOutlined />
//         <div style={{ display: "inline-block" }} className="ant-upload-text">
//           Upload
//         </div>
//       </UploadBox>
//     );
//     return (
//       <div>
//         <Upload
//           disabled={!this.props.inputEnabled}
//           accept="image/png, image/jpeg"
//           listType="picture-card"
//           fileList={fileList}
//           onPreview={this.handlePreview}
//           onChange={this.handleUpload}
//           showUploadList={{ showPreviewIcon: this.state.showPreviewIcon }}
//           beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
//         >
//           {fileList.length < 1 && uploadButton}
//         </Upload>
//         {this.props.inputEnabled && fileList != [] ? (
//           <Button onClick={() => this.handleSubmit()}>บันทึกรูปโปรไฟล์</Button>
//         ) : null}
//         <Modal
//           visible={previewVisible}
//           footer={null}
//           onCancel={this.handleCancel}
//         >
//           <img alt="example" style={{ width: "100%" }} src={previewImage} />
//         </Modal>
//       </div>
//     );
//   }
// }
// export default ProfileUploader;
import React from "react";
import styled from "styled-components";
import { Modal, Upload } from "antd";
import { Text } from "../../components/sharedComponents";
import { LoadingOutlined } from "@ant-design/icons";
import { subDomain, redirectTo } from "../../../../services/redirect";
import deleteQRcode from "../../../../assets/image/deleteQRcode.svg";

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.padding || "0rem 0rem 0rem 0rem"};
`;

const SubTitleText = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
`;

const ModalBox = styled.div`
  display: flex;
  align-items: center;
  font-family: "Kanit";
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5em;
  justify-content: ${(props) => (props.space ? "space-between" : "center")};
  margin: ${(props) => (props.padding ? "2.5rem 0 1.5rem 0" : 0)};
  padding: 1rem 10% 1rem 10%;
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default class Uploader extends React.Component {
  state = {
    file: null,
    imageUrl: null,
    isImage: true,
    loading: false,
  };

  handleChange = (info, onUpload) => {
    if (info.file.type === "image/jpeg" || info.file.type === "image/png") {
      onUpload(info);
      getBase64(info.fileList[0].originFileObj, (imageUrl) =>
        this.setState({ file: info, isImage: true, imageUrl })
      );
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      this.props.value != nextProps.value &&
      nextProps.value != "" &&
      nextProps.value != "null"
    ) {
      this.setState({ imageUrl: nextProps.value });
    }
  }
  render() {
    const { file, loading, imageUrl } = this.state;
    const { onUpload, onDelete, value } = this.props;

    return (
      <>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            maxWidth="100%"
            height="100px"
            onMouseOver={(e) =>
              !this.props.isdisabled && (e.currentTarget.src = deleteQRcode)
            }
            onMouseOut={(e) =>
              !this.props.isdisabled && (e.currentTarget.src = imageUrl)
            }
            onClick={() => {
              if (!this.props.isdisabled) {
                onDelete();
                this.setState({ file: null, imageUrl: null });
              }
            }}
            style={{
              marginBottom: "1rem",
              cursor: this.props.isdisabled ? "auto" : "pointer",
            }}
          />
        ) : (
          <Upload
            name="image"
            listType="picture-card"
            accept="image/png, image/jpeg"
            showUploadList={false}
            beforeUpload={() => false}
            onChange={(info) => this.handleChange(info, onUpload)}
            disabled={this.props.isdisabled}
          >
            <FlexRow>
              <SubTitleText
                style={
                  this.props.isdisabled
                    ? {
                        fontWeight: "400",
                        color: "rgb(85.9%, 85.9%, 85.9%)",
                        width: "200px",
                        height: "100px",
                      }
                    : {
                        fontWeight: "400",
                        color: "#FFB800",
                        width: "200px",
                        height: "100px",
                      }
                }
              >
                {file ? (
                  <></>
                ) : loading ? (
                  <LoadingOutlined />
                ) : (
                  <FlexRow
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    กดเลือกรูปที่นี่
                  </FlexRow>
                )}
              </SubTitleText>
            </FlexRow>
          </Upload>
        )}
        {/* modal เมื่ออัพโหลดไฟล์อื่นที่ไม่ใช่รูป */}
        <Modal
          visible={!this.state.isImage}
          centered={true}
          closable={false}
          footer={[
            <ModalBox centered>
              <Text
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => {
                  this.setState({ isImage: true });
                }}
              >
                ปิด
              </Text>
            </ModalBox>,
          ]}
          width={"40rem"}
        >
          <ModalBox>
            <Text style={{ color: "black" }}>
              ภาพจะต้องเป็นไฟล์นามสกุล JPG/PNG เท่านั้น
            </Text>
          </ModalBox>
        </Modal>
      </>
    );
  }
}
