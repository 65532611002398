import React, { Component } from "react";
import { redirectTo, subDomain } from "../../services/redirect";
import { customer as api } from "../../services/api";
import localName from "../../values/localStorageDict";
import FormEditAddress from "./components/editaddress/formEditAddress";

class EditAddress extends Component {
  formRef = React.createRef();
  state = {
    signedIn:
      localStorage.getItem(localName.ACCESS_TOKEN) &&
      localStorage.getItem(localName.REFRESH_TOKEN),

    isDefault: false,
    isDisabledButton: false,
    startAt: localStorage.getItem(localName.START_AT),
    prevPath: "",
    addOrEditAddress: localStorage.getItem(localName.ADD_OR_EDIT_ADDR),
    enabledPin: false,
    isPinned: false,
    address: "",
    area: "",
    city: "",
    state: "",
    postal: "",
    latitude: 0,
    longitude: 0,
    mapPosition: { lat: null, lng: null },
    markerPosition: { lat: null, lng: null },
  };
  setAddress = this.setAddress.bind(this);
  setArea = this.setArea.bind(this);
  setCity = this.setCity.bind(this);
  setSubDistrict = this.setSubDistrict.bind(this);
  setPostal = this.setPostal.bind(this);
  setMapPosition = this.setMapPosition.bind(this);
  setMarkerPosition = this.setMarkerPosition.bind(this);

  setAddress(address) {
    this.setState({ address: address });
  }

  setArea(area) {
    this.setState({ area: area });
  }

  setCity(city) {
    this.setState({ city: city });
  }

  setSubDistrict(state) {
    this.setState({ state: state });
  }

  setPostal(postal) {
    this.setState({ postal: postal });
  }

  setMapPosition(mapPosition) {
    this.setState({ lat: mapPosition.lat, lng: mapPosition.lng });
  }

  setMarkerPosition(markerPosition) {
    this.setState({ lat: markerPosition.lat, lng: markerPosition.lng });
  }

  componentDidMount() {
    this.setFieldValue();
    localStorage.setItem(localName.PREVENT_TYPING_URL, false);
  }

  componentWillMount() {
    if (this.props.history.location.state !== undefined) {
      this.setState({
        enabledPin: true,
        isPinned: true,
        address: this.props.history.location.state.address,
        area: this.props.history.location.state.area,
        city: this.props.history.location.state.city,
        state: this.props.history.location.state.state,
        postal: this.props.history.location.state.postal,
        mapPosition: this.props.history.location.state.mapPosition,
        markerPosition: this.props.history.location.state.markerPosition,
      });
    }
  }

  handleDisabledButton = (isDisabledButton) => {
    this.setState({ isDisabledButton });
  };

  setFieldValue = () => {
    let addressWillBeEdited = localStorage.getItem(localName.ADDR_WILL_BE_EDIT);
    let address = JSON.parse(addressWillBeEdited);
    //ใช้ที่อยู่เดิมก่อน
    if (this.state.addOrEditAddress === "edit" && this.state.signedIn) {
      this.formRef.current.setFieldsValue({
        name: address.name,
        addressLine: address.addressLine,
        district: address.district,
        subDistrict: address.subDistrict,
        province: address.province,
        postalCode: address.postalCode,
        comment: address.comment,
      });
      const isDefault = address.isDefault;
      this.setState({
        isDefault,
        latitude: address.latitude,
        longitude: address.longitude,
        mapPosition: {
          lat: address.latitude,
          lng: address.longitude,
        },
      });
    }
    // overwrite ด้วยที่อยู่ปักหมุด
    if (this.props.location.state !== undefined) {
      const {
        address,
        district,
        subDistrict,
        province,
        postalCode,
        mapPosition,
      } = this.props.location.state;
      if (this.props.location.state.prevPath === `${subDomain}/pin`) {
        this.formRef.current.setFieldsValue({
          addressLine: address,
          district: district,
          subDistrict: subDistrict,
          province: province,
          postalCode: postalCode,
        });
        this.setState({
          latitude: mapPosition.lat,
          longitude: mapPosition.lng,
          mapPosition: {
            lat: mapPosition.lat,
            lng: mapPosition.lng,
          },
        });
      }
    }
    // field ไหนว่าง จะปรับค่าไม่ให้ undefined
    this.fillUndefinedField();
  };
  fillUndefinedField = () => {
    if (this.formRef.current.getFieldValue("district") === "")
      this.formRef.current.setFieldsValue({
        district: "-",
      });
    if (this.formRef.current.getFieldValue("subDistrict") === "")
      this.formRef.current.setFieldsValue({
        subDistrict: "-",
      });
    if (this.formRef.current.getFieldValue("province") === "")
      this.formRef.current.setFieldsValue({
        province: "-",
      });
  };
  handleChange = (e) => {
    const name = e.currentTarget.id;
    let val = e.currentTarget.value;
    const lastVal = val.slice(-1)[0];
    if (lastVal === "." || isNaN(parseInt(lastVal)))
      val = val.substring(0, val.length - 1);
    if (name === "postalCode") {
      if (isNaN(parseInt(val)))
        this.formRef.current.setFieldsValue({
          postalCode: "",
        });
      else
        this.formRef.current.setFieldsValue({
          postalCode: val.slice(0, 5),
        });
    }
  };

  handleOnRejected = (res) => {
    setTimeout(() => {
      alert("Something went wrong.");
      this.handleDisabledButton(false);
    }, 2500);
  };
  handleChecked = () => {
    const isDefault = !this.state.isDefault;
    this.setState({ isDefault });
  };
  onFinish = (val) => {
    const {
      name,
      addressLine,
      district,
      subDistrict,
      province,
      postalCode,
      comment,
    } = val; // 7 fieldValues
    const fieldValues = {
      name: name,
      addressLine: addressLine,
      district: district,
      subDistrict: subDistrict,
      province: province,
      postalCode: postalCode,
      comment: comment,
    };
    const {
      startAt,
      addOrEditAddress,
      isDefault,
      longitude,
      latitude,
    } = this.state;

    this.handleDisabledButton(true);
    if (this.state.signedIn) {
      if (
        (startAt === `${subDomain}/cart` || startAt === `${subDomain}`) &&
        addOrEditAddress === "add"
      ) {
        const addressForAdd = {
          ...fieldValues,
          latitude: latitude,
          longitude: longitude,
          isDefault: isDefault,
        };

        api.address.insert(
          addressForAdd,
          ({ data, status }) => {
            if (status === 200)
              this.props.history.replace(`${subDomain}/my-address`);
          },
          (response) => this.handleOnRejected(response)
        );
      } else if (
        (startAt === `${subDomain}/cart` || startAt === `${subDomain}`) &&
        addOrEditAddress === "edit"
      ) {
        let addressWillBeEdited = localStorage.getItem(
          localName.ADDR_WILL_BE_EDIT
        );
        addressWillBeEdited = JSON.parse(addressWillBeEdited);
        const addressForEdit = {
          ...fieldValues,
          latitude: latitude,
          longitude: longitude,
          isDefault: isDefault,
          addressId: addressWillBeEdited.addressId,
        };
        const addressForLocalStorage = {
          ...addressWillBeEdited,
          ...addressForEdit,
        };
        api.address.edit(
          addressForEdit,
          ({ status }) => {
            if (status === 200)
            localStorage.setItem(
              localName.ADDR_WILL_BE_EDIT,
              addressForLocalStorage
            );
            localStorage.setItem(
              localName.EDIT_ADDR,
              JSON.stringify(addressForEdit)
            );
            this.props.history.push(`${subDomain}/my-address`);
          },
          (response) => this.handleOnRejected(response)
        );
        localStorage.setItem(
          localName.EDIT_ADDR,
          JSON.stringify(addressForEdit)
        );
        // เช็คว่าที่อยู่เดิมถูกเลือกไว้หรือไม่ ถ้าใช่ ให้ส่งขึ้น CART_ADDR เพื่อไปแสดงในหน้า cart
        const addressWillBeEdit = JSON.parse(
          localStorage.getItem(localName.ADDR_WILL_BE_EDIT)
        );
        if (addressWillBeEdit.isSelectedByUser)
          localStorage.setItem(
            localName.CART_ADDR,
            JSON.stringify(addressForEdit)
          );
      }
    }
    // not logged in .
    else {
      const address = {
        ...fieldValues,
        latitude: latitude,
        longitude: longitude,
        isDefault: isDefault,
      };
      localStorage.setItem(localName.CART_ADDR, JSON.stringify(address));
      redirectTo("/cart");
    }
  };
  onFinishFailed = (errorInfo) => {

  };

  render() {
    const attributeSet = {
      // googlemap: GoogleMap,
      changeAddress: this.setAddress,
      changeArea: this.setArea,
      changeCity: this.setCity,
      changeState: this.setSubDistrict,
      changePostal: this.setPostal,
      changeMapPosition: this.setMapPosition,
      changeMarkerPosition: this.setMarkerPosition,
      onFinish: this.onFinish,
      onFinishFailed: this.onFinishFailed,
      onChange: this.handleChange,
      onChecked: this.handleChecked,
      enabledPin: this.state.enabledPin,
      isPinned: this.state.isPinned,
      signedIn: this.state.signedIn,
      isDefault: this.state.isDefault,
      isDisabledButton: this.state.isDisabledButton,
      formRef: this.formRef,
      address: this.state.address,
      area: this.state.area,
      city: this.state.city,
      state: this.state.state,
      postal: this.state.postal,
      mapPosition: this.state.mapPosition,
      markerPosition: this.state.markerPosition,
    };
    const { startAt, addOrEditAddress } = this.state;
    let pathItems = [];
    if (this.state.signedIn) {
      if (startAt === `${subDomain}/cart` && addOrEditAddress === "edit") {
        pathItems = [
          { name: "ตะกร้าสินค้า", path: `${subDomain}/cart` },
          {
            name: "เปลี่ยนแปลงที่อยู่",
            path: `${subDomain}/my-address`,
          },
        ];
        return (
          <FormEditAddress
            pathItems={pathItems}
            title="แก้ไขที่อยู่"
            attributeSet={attributeSet}
            history={this.props.history}
          />
        );
      } else if (
        startAt === `${subDomain}/cart` &&
        addOrEditAddress === "add"
      ) {
        pathItems = [
          {
            name: "ตะกร้าสินค้า",
            path: `${subDomain}/cart`,
          },
          {
            name: "เปลี่ยนแปลงที่อยู่",
            path: `${subDomain}/my-address`,
          },
        ];

        return (
          <FormEditAddress
            pathItems={pathItems}
            title="เพิ่มที่อยู่"
            attributeSet={attributeSet}
            history={this.props.history}
          />
        );
      } else if (startAt === `${subDomain}` && addOrEditAddress === "add") {
        pathItems = [
          {
            name: "ที่อยู่ของฉัน",
            path: `${subDomain}/my-address`,
          },
        ];

        return (
          <FormEditAddress
            pathItems={pathItems}
            title="เพิ่มที่อยู่"
            attributeSet={attributeSet}
            history={this.props.history}
          />
        );
      } else if (startAt === `${subDomain}` && addOrEditAddress === "edit") {
        pathItems = [
          {
            name: "ที่อยู่ของฉัน",
            path: `${subDomain}/my-address`,
          },
        ];
        return (
          <FormEditAddress
            pathItems={pathItems}
            title="แก้ไขที่อยู่"
            attributeSet={attributeSet}
            history={this.props.history}
          />
        );
      }

      return <p>Something went wrong.</p>; // localstorage พัง
    }
    // not logged in
    else {
      if (addOrEditAddress === "edit") {
        pathItems = [{ name: "ตะกร้าสินค้า", path: `${subDomain}/cart` }];
        return (
          <FormEditAddress
            pathItems={pathItems}
            title="เปลี่ยนแปลงที่อยู่"
            attributeSet={attributeSet}
            history={this.props.history}
          />
        );
      } else if (addOrEditAddress === "add") {
        pathItems = [
          {
            name: "ตะกร้าสินค้า",
            path: `${subDomain}/cart`,
          },
        ];
        return (
          <FormEditAddress
            pathItems={pathItems}
            title="เพิ่มที่อยู่"
            attributeSet={attributeSet}
            history={this.props.history}
          />
        );
      }
      return <p>Please Login</p>;
    }
  }
}
export default EditAddress;
