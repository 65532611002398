import React from "react";
import styled from "styled-components";
import { Form, Input, InputNumber } from "antd";
const FontText = styled(Form.Item)`
  color: #663c46;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.1px;
  width: 100%;
  /* border: 2px solid green; */
`;
const InputSize = styled(Input)`
  height: ${(props) => (props.boxsize === "moredetail" ? "7rem" : "2.2rem")};
  width: ${(props) => (props.inputsize === "single" ? "100%" : "100%")};
  @media (max-width: 1240px) {
    width: 12rem;
  }
  @media (max-width: 1075px) {
    width: 18rem;
    height: 2.2rem;
  }
  @media not screen and (min-device-width: 1224px) {
    width: 100%;
  }
`;
const InputNumberSize = styled(InputNumber)`
  height: ${(props) => (props.boxsize === "moredetail" ? "7rem" : "2.2rem")};
  width: ${(props) => (props.inputsize === "single" ? "100%" : "100%")};
  @media (max-width: 1240px) {
    width: 12rem;
  }
  @media (max-width: 1075px) {
    width: 18rem;
    height: 2.2rem;
  }
`;
const InputSizeButton = styled(Input.TextArea)`
  height: ${(props) => (props.boxsize === "moredetail" ? "10rem" : "2.2rem")};
  width: ${(props) => (props.inputsize === "single" ? "100%" : "100%")};
  @media (max-width: 1240px) {
    width: 12rem;
  }
  @media (max-width: 1075px) {
    width: 18rem;
    height: 2.2rem;
  }
  @media not screen and (min-device-width: 1224px) {
    width: 100%;
  }
`;
const InputForm = ({
  label,
  placeholder,
  boxsize,
  name,
  inputsize,
  isRequired,
  type,
  onChange,
  enabledPin,
  isPinned,
  defaultValue,
}) => {
  if (name === "postalCode") {
    return (
      <FontText //Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: isRequired,
            message: "จำเป็น",
          },
          { len: 5, message: "โปรดระบุรหัสไปรษณีย์ 5 หลัก" },
        ]}
      >
        <InputSize //Input
          disabled={!enabledPin}
          onChange={onChange}
          boxsize={boxsize}
          placeholder={placeholder}
          inputsize={inputsize}
          defaultValue={defaultValue}
        />
      </FontText>
    );
  } else if (name !== "comment") {
    return (
      <FontText // Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: "จำเป็น",
          },
          {
            max: 100,
            message: "ข้อความต้องมีความยาวไม่เกิน 100 ตัวอักษร",
          },
        ]}
      >
        <InputSize // Input
          autoFocus={name === "name" ? true : false}
          disabled={
            name === "district"
              ? !enabledPin || isPinned
              : name === "subDistrict"
              ? !enabledPin || isPinned
              : name === "province"
              ? !enabledPin || isPinned
              : !enabledPin
          }
          onChange={onChange}
          boxsize={boxsize}
          placeholder={placeholder}
          inputsize={inputsize}
          defaultValue={defaultValue}
        />
      </FontText>
    );
  }
  return (
    <FontText
      label={label}
      name={name}
      rules={[
        {
          required: isRequired,
        },
      ]}
    >
      <InputSizeButton
        disabled={!enabledPin}
        onChange={onChange}
        boxsize={boxsize}
        placeholder={placeholder}
        inputsize={inputsize}
        defaultValue={defaultValue}
      />
    </FontText>
  );
};

export default InputForm;
