import React from "react";
import styled from "styled-components";
import Modal from "../modal";
import Button from "../button";

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 1.5rem;

  & button {
    width: 160px;
  }
`;

const Container = styled.div`
  padding-top: 0.5rem;
`;

export default class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      isEditMode: false,
      value: undefined,
      resolve: undefined,
    };
  }

  // #region Instance Methods
  async getConfirmation(config) {
    // Setup promise and Show modal
    let modalResolve = undefined;
    const modalPromise = new Promise((resolve) => {
      modalResolve = resolve;
    });

    const { title, body, confirmText, cancelText } = config;

    this.setState({
      visible: true,
      title,
      body,
      resolve: modalResolve,
      confirmText,
      cancelText,
    });

    // Gather Result
    const confirmed = await modalPromise;

    // Clean promise and Close modal
    this.setState({
      visible: false,
      resolve: null,
    });

    // Return result
    return confirmed;
  }
  // #endregion

  render() {
    const {
      visible,
      title,
      body,
      resolve,
      confirmText,
      cancelText,
    } = this.state;

    return (
      <Modal {...this.props} title={title || "ยืนยัน"} visible={visible}>
        <Container>
          {body}
          <ButtonRow>
            <Button type="secondary" onClick={() => resolve && resolve(false)}>
              {cancelText || "ยกเลิก"}
            </Button>
            <Button type="primary" onClick={() => resolve && resolve(true)}>
              {confirmText || "ยืนยัน"}
            </Button>
          </ButtonRow>
        </Container>
      </Modal>
    );
  }
}
