import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../values/colors';

const StyledPagination = styled(AntdPagination)`
    & a {
        font-family: "Kanit";
    }

    & a[disabled] {
        color: #CCCCCC;
    }

    & > li.ant-pagination-item,
    & > li.ant-pagination-prev .ant-pagination-item-link,
    & > li.ant-pagination-next .ant-pagination-item-link {
        border: 1px solid transparent;
        border-radius: 5px;
    }

    & > li.ant-pagination-disabled,
    & > li.ant-pagination-disabled * {
        
    }

    & > li.ant-pagination-item:hover,
    & > li.ant-pagination-item:focus,
    & > li.ant-pagination-item {
        background-color: transparent;
        transition: all 0.4s ease;
    }

    & > li.ant-pagination-item:hover a,
    & > li.ant-pagination-item:focus a,
    & > li.ant-pagination-item a {
        color: ${colors.storeText};
    }

    & > li.ant-pagination-item.ant-pagination-item-active:hover,
    & > li.ant-pagination-item.ant-pagination-item-active:focus,
    & > li.ant-pagination-item.ant-pagination-item-active {
        background-color: ${colors.storeTextHightlighted};
    }

    & > li.ant-pagination-item.ant-pagination-item-active:hover a,
    & > li.ant-pagination-item.ant-pagination-item-active:focus a,
    & > li.ant-pagination-item.ant-pagination-item-active a {
        color: white;
    }
`;

const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
        return <a>{'ก่อนหน้า'}</a>;
    }
    if (type === 'next') {
        return <a>{'ถัดไป'}</a>;
    }
    return originalElement;
};

const Pagination = props => (
    <StyledPagination
        {...props}
        itemRender={itemRender}
    />
)

export default Pagination;