import React, {
  useCallback,
  useRef,
  useEffect,
  useContext,
  useState,
} from "react";
import styled from "styled-components";
import useModalState from "../../hooks/useModalState";
import { FlagIcon } from "../../components/icons";
import SubPageHeader from "./components/subpageHeader";
import Tabs from "./components/tabs";
import OrderCollapse from "./components/order/collapse";
import SelectDriverModal from "./components/modals/selectDriver";
import DriverFormModal from "./components/modals/driverForm";
import QRCodeModal from "./components/modals/qrCode";
import CancelOrderModal from "./components/modals/cancelOrder";
import { CenteredSpin } from "./components/spin";
import TabFace from "./components/order/tabFace";
import { range } from "./util/generators";
import { store } from "../../services/api";
import { ModalContext } from ".";
import { message } from "antd";

// const mockQRValue = JSON.stringify({
//   order_ids: ["5480123", "5480124"],
// });

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CustomTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  flex: 2;

  & .ant-tabs-content {
    height: 100%;
    flex: 2;
  }

  & .ant-tabs-tabpane {
    height: calc(100% - 68px);
    overflow-y: auto;
  }

  & div[role="tab"] {
    width: 25%;
  }
`;

const tabName = {
  "1": "รายการใหม่",
  "2": "ออเดอร์ปัจจุบัน",
  "3": "กำลังจัดส่ง",
  "4": "เสร็จสิ้น",
};

export default function Order({ poller, orders, tab, setTab }) {
  // TODO always call poller.current.poll();
  // after change status event is fired (update request is done)

  const { getConfirmation } = useContext(ModalContext);

  const setTabRef = useRef();
  setTabRef.current = setTab;
  useEffect(() => {
    // didMount
    setTab("1");
    return () => {
      // willUnMount
      setTabRef.current(undefined);
    };
  }, []);

  // useEffect(() => {
  //   // didMount
  //   setTab("1");
  //   return () => {
  //     // willUnMount
  //     setTab(undefined);
  //   };
  // }, []);

  const selectDriverModal = useModalState();
  const driverFormModal = useRef();
  const qrModal = useModalState();
  const cancelOrderModal = useModalState();
  const [orderIdState, setOrderId] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [token, setToken] = useState();

  const addDriver = useCallback(() => {
    selectDriverModal.close();
    driverFormModal.current
      .getData(undefined, true)
      .then(async ([data, confirmed]) => {
        if (confirmed) {
          try {
            var index = data.name.indexOf(" ");
            var firstName;
            var lastName;
            if (index === -1) {
              firstName = data.name;
              lastName = " ";
            } else {
              firstName = data.name.substr(0, index);
              lastName = data.name.substr(index + 1);
            }
            await store.driver
              .driverRegister({
                firstname: firstName,
                lastname: lastName,
                phone: data.phone,
                note: data.note,
                isTemp: data.isTemp,
              })
              .then(async (res) => {
                await store.order
                  .genTokenForDriver({
                    driverId: res.driverId,
                    orders: selectedItems,
                  })
                  .then((res) => {
                    setToken(res.accessToken);
                    selectDriverModal.close();
                    qrModal.open();
                  });
              });
          } catch (err) {
            message.error("ไม่สามารถเพิ่มคนขับได้ โปรดลองใหม่อีกครั้ง");
          }
        } else {
          selectDriverModal.open();
        }
      });
  }, [driverFormModal, qrModal, selectDriverModal]);

  const confirmPayment = useCallback(
    async ({ orderId }) => {
      const confirmed = await getConfirmation({
        title: "ยืนยันการชำระเงิน",
      });
      if (confirmed) {
        await store.order.setOrderStatus(orderId, 2);
        poller.current.poll();
      }
    },
    [getConfirmation, poller]
  );

  const confirmDelivered = useCallback(
    async ({ orderId }) => {
      const confirmed = await getConfirmation({
        title: "ยืนยันการส่งอาหาร",
      });
      if (confirmed) {
        await store.order.setOrderStatus(orderId, 4);
        poller.current.poll();
      }
    },
    [getConfirmation, poller]
  );

  const CancelOrder = useCallback(async (orderId, reason, pin) => {
    try {
      await store.order.cancelOrder({
        orderId: orderId,
        cancelReason: reason,
        pin: pin,
      });
      poller.current.poll();
      return "success";
    } catch (err) {
      return err;
    }
  });

  return (
    <Container>
      <SubPageHeader icon={FlagIcon} label={"รายการอาหารวันนี้"} />
      <CustomTabs onChange={setTab} activeKey={tab}>
        {range(1, 4).map((num) => (
          <Tabs.TabPane
            key={`${num}`}
            tab={
              <TabFace
                title={tabName[num]}
                badge={
                  orders.loading
                    ? 0
                    : orders.data.newUpdates
                    ? orders.data.newUpdates[num - 1]
                    : 0
                }
              />
            }
          >
            {orders.loading ? (
              <CenteredSpin />
            ) : (
              <OrderCollapse
                stage={num}
                orders={orders.data[num]}
                setOrderId={setOrderId}
                cancelOrder={cancelOrderModal.open}
                selectDriver={selectDriverModal.open}
                confirmPayment={confirmPayment}
                confirmDelivered={confirmDelivered}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                openQrCode={async (driverId) => {
                  //TODO: wait for api get token
                  try {
                    await store.driver.getDriverToken(driverId).then((res) => {
                      setToken(res.accessToken);
                      qrModal.open();
                    });
                  } catch (err) {
                    message.error("เกิดข้อผิดพลาด");
                  }
                }}
              />
            )}
          </Tabs.TabPane>
        ))}
      </CustomTabs>

      <SelectDriverModal
        visible={selectDriverModal.visible}
        onClose={selectDriverModal.close}
        addDriver={addDriver}
        showQR={async (driverId) => {
          //wrap driver id with list of order --> api
          try {
            await store.order
              .genTokenForDriver({
                driverId: driverId,
                orders: selectedItems,
              })
              .then((res) => {
                setToken(res.accessToken);
                setSelectedItems([]);
                selectDriverModal.close();
                qrModal.open();
              });
          } catch (err) {
            message.error("เกิดข้อผิดพลาด");
          }
        }}
        orders={orders.data}
        zIndex={1000}
      />
      <DriverFormModal ref={driverFormModal} zIndex={1001} />
      <QRCodeModal
        value={token}
        visible={qrModal.visible}
        onClose={qrModal.close}
        zIndex={1002}
      />
      <CancelOrderModal
        orderIdState={orderIdState}
        visible={cancelOrderModal.visible}
        onClose={cancelOrderModal.close}
        onSubmit={CancelOrder}
        zIndex={1000}
      />
    </Container>
  );
}
