import React from "react";
import styled from "styled-components";
import { Modal, Form, Input, Button, message } from "antd";
import { colors } from "../../../../values/colors";
import { redirectTo } from "../../../../services/redirect";
import { FontColorsOutlined } from "@ant-design/icons";
import FormItem from "../../../../components/login/formItem";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  paddingbottom: 0.1rem;
  color: "#663d45";
`;

class SetupPasswordSuccessModal extends React.Component {
  constructor(props) {
    super(props);
  }
  // handleCloseModal() {
  //   message.success("เข้าสู่ระบบสำเร็จ");
  // }
  render() {
    const { handleClose, visibleSetupSuccessModal, email } = this.props;
    return (
      <Modal
        footer={null}
        visible={visibleSetupSuccessModal}
        width={"24rem"}
        closable={false}
        bodyStyle={{ padding: 0 }}
        style={{ borderRadius: "5px" }}
        onCancel={handleClose}
      >
        <Column>
          <Form
            style={{
              lineHeight: 1.68,
              marginLeft: "1.26rem",
              fontFamily: "Kanit",
            }}
            layout={"vertical"}
            hideRequiredMark
          >
            <FormItem
              style={{
                marginTop: "1.26rem",
                marginBottom: "0.42rem",
                fontSize: "1.2rem",
                height: "2rem",
                padding: "0rem",
              }}
              label="บันทึกข้อมูลเรียบร้อย"
              name="line1"
            ></FormItem>

            <FormItem
              style={{
                marginTop: "0rem",
                padding: "0rem",
                marginRight: "1rem",
              }}
            >
              <Button
                onClick={() => {
                  redirectTo("/");
                }}
                style={{
                  marginTop: "22px",
                  width: "100%",
                  fontSize: "1.05rem",
                }}
                type="primary"
                htmlType="submit"
                aria-label="Close"
              >
                กลับไปยังหน้าแรก
              </Button>
            </FormItem>
          </Form>
        </Column>
      </Modal>
    );
  }
}

export default SetupPasswordSuccessModal;
