import React from "react";
import { Modal } from "antd";
import { colors } from "../../../values/colors";
import styled from "styled-components";

const ModalContainer = styled.div`
  height: 8rem;
  font-family: "Kanit";
  font-style: normal;
  font-size: 1em;
  line-height: 1.25rem;
`;

const Title = styled.div`
  margin-bottom: 0.5rem;
  height: 20%;
`;

const Content = styled.div`
  height: 50%;
`;

const Footer = styled.div`
  height: 30%;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.div`
  height: 100%;
  width: 30%;
  border-radius: 0.5rem;
  background: ${(props) => props.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textColor2};
  margin-right: ${(props) => (props.marginRight ? "0.5rem" : "0rem")};
`;

const Text = styled.div`
  color: ${(props) =>
    props.YellowColorText ? colors.color1 : colors.textColor1};
  font-size: ${(props) => (props.bigFont ? "1.3em" : "1em")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  padding: ${(props) => props.padding};
  padding-right: ${(props) => props.paddingRight};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "initial")};
`;

// ---props---
// title
// content
// onConfirm
// onCancel
// isVisible

class AlertModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Modal
        visible={this.props.isVisible}
        closable={false}
        footer={null}
        centered={true}
      >
        <ModalContainer>
          <Title>
            <Text YellowColorText bold bigFont>
              {this.props.title}
            </Text>
          </Title>
          <Content>
            <Text>{this.props.content}</Text>
          </Content>
          <Footer>
            <Button
              bgColor={colors.textColor3}
              marginRight
              onClick={this.props.onCancel}
            >
              ยกเลิก
            </Button>
            <Button bgColor={colors.color1} onClick={this.props.onConfirm}>
              ยืนยัน
            </Button>
          </Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

export default AlertModal;
