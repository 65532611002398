import React from "react";
import styled from "styled-components";
import { colors } from "../../values/colors";
import driver from "../../assets/image/driver.svg";
import { RightIcon, GreenCheckIcon } from "../../components/icons";
import GoogleMap from "google-map-react";
import { config } from "../../config";
import { MapMarker, calBound, MyMarker } from "./components/MapMarker";
import { subDomain } from "../../services/redirect";
import { driver as driverApi } from "../../services/api";
import { message, Button } from "antd";
import localName from "../../values/localStorageDict";
import QrReader from "react-qr-reader";
import { CenterSpin } from "../customers/components/sharedComponents";

const OrderGroupContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
`;

const OrderGoupFooter = styled.div`
  width: 100%;
  height: 4rem;
  position: fixed;
  bottom: 0;
  background: ${colors.footerColor};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const OrderGoupFooterContent = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  font-size: 1em;
  line-height: 1.25rem;
  color: ${colors.textColor2};
`;

const NoOrderGroup = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ShowMap = styled.div`
  height: 45%;
  background: gray;
  box-shadow: 0.05rem 0.05rem 0.7rem ${colors.textColor3};
`;
const OrderList = styled.div`
  height: 55%;
  overflow-y: auto;
`;

const OrderItem = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  box-shadow: 0.05rem 0.05rem 0.7rem ${colors.textColor3};
`;
//OrderItem   height: 5rem;

const Text = styled.div`
  color: ${(props) =>
    props.grayColor ? colors.textColor3 : colors.footerColor};
  font-size: ${(props) => (props.bigFont ? "1.5em" : "1em")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin: ${(props) => props.margin};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "initial")};
  word-wrap: break-word;
`;
const Span = styled.span`
  color: ${(props) =>
    props.grayColor ? colors.textColor3 : colors.footerColor};
  font-size: ${(props) => (props.bigFont ? "1.5em" : "1em")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin: ${(props) => props.margin};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "initial")};
  word-wrap: break-word;
`;

export default class OrderGroup extends React.Component {
  state = {
    center: {
      // lat: 59.95,
      // lng: 30.33,
      // lat: 13.7232384,
      // lng: 100.5420544,
      camera: false,
      result: "",
    },
    zoom: 11,
    mapRef: React.createRef(),
    orderList: [],
    loading: true,
  };

  // componentDidMount() {
  //   if (navigator.geolocation) {
  //     // alert(navigator.geolocation);
  //     navigator.geolocation.watchPosition(
  //       (position) => {
  //         console.log(position.coords.latitude, position.coords.longitude);
  //         if (
  //           position.coords.latitude !== this.state.center.lat ||
  //           position.coords.longitude !== this.state.center.lng
  //         ) {
  //           this.setState({
  //             center: {
  //               lat: position.coords.latitude,
  //               lng: position.coords.longitude,
  //             },
  //           });
  //           return false;
  //         }
  //       },
  //       (err) => {
  //         alert(err.message);
  //         console.log(err);
  //       },
  //       {
  //         enableHighAccuracy: true,
  //         maximumAge: 1000,
  //         timeout: 1000,
  //       }
  //     );
  //   } else {
  //     alert("Geolocation is not supported for this Browser/OS.");
  //   }
  // }

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );
    this.fetch();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (
          position.coords.latitude !== this.state.center.lat ||
          position.coords.longitude !== this.state.center.lng
        ) {
          this.setState({
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          return false;
        }
      },
      (err) => {
        console.log(err);
      },
      {
        enableHighAccuracy: true,
      }
    );
    return true;
  }

  async fetch() {
    try {
      if (!!localStorage.getItem(localName.DRIVER_ACCESS_TOKEN)) {
        await driverApi.deliveryOrder().then((data) => {
          this.props.setDeliveryGroupId(data.deliveryOrders.groupId);
          this.setState({
            orderList: data.deliveryOrders.uniqueOrders,
          });
        });
      }
    } catch (err) {
      this.props.setDeliveryGroupId("-");
      message.error("คุณไม่มีออเดอร์ที่ต้องจัดส่ง");
    } finally {
      this.setState({
        loading: false,
      });
    }
  }
  handleScanQR = () => {
    this.setState({
      camera: true,
      result: "",
    });
  };
  handleScan = (data) => {
    if (data) {
      this.setState({
        result: data,
        camera: false,
      });
    }
  };
  handleError = (err) => {
    this.setState({
      camera: false,
      result: "Error",
    });
  };
  render() {
    // const bound = calBound(this.state.mapRef.current);
    return this.state.loading ? (
      <CenterSpin height={"50rem"} />
    ) : (
      <React.Fragment>
        <OrderGroupContainer>
          {this.state.orderList.length == 0 ? (
            <React.Fragment>
              <NoOrderGroup>
                {this.state.camera ? (
                  <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: "300px", height: "300px" }}
                  />
                ) : (
                  <React.Fragment>
                    <img src={driver} />
                    <Text
                      textAlign={"center"}
                      margin={"2rem"}
                      bigFont
                      grayColor
                    >
                      กรุณาสแกน QR Code เพื่อรับงาน
                    </Text>
                  </React.Fragment>
                )}
                <Text textAlign={"center"} margin={"4rem"} bigFont grayColor>
                  {this.state.result == "Error" && (
                    <p style={{ color: "red" }}>ERROR</p>
                  )}
                  {this.state.result && this.state.result != "Error" && (
                    <a href={this.state.result}>CLICK TO TAKE ORDER</a>
                  )}
                </Text>
              </NoOrderGroup>
              <OrderGoupFooter>
                <OrderGoupFooterContent>
                  <Button
                    style={{ width: "12.5rem", height: "2.8rem" }}
                    onClick={() => this.handleScanQR()}
                  >
                    เปิดกล้องเพื่อสแกน
                  </Button>
                </OrderGoupFooterContent>
              </OrderGoupFooter>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ShowMap>
                <GoogleMap
                  ref={this.state.mapRef}
                  apiKey={config.googleMapKey}
                  center={this.state.center}
                  zoom={this.state.zoom}
                  // onGoogleApiLoaded={({ map, maps }) =>
                  //   calBound(map, maps, this.state.orderList)
                  // }
                >
                  {this.state.orderList.map((item, index) => {
                    if (item.statusId === "3") {
                      return (
                        <MapMarker
                          key={index}
                          lat={item.latitude}
                          lng={item.longitude}
                          text={index + 1}
                        />
                      );
                    }
                  })}
                  <MyMarker
                    lat={this.state.center.lat}
                    lng={this.state.center.lng}
                  />
                </GoogleMap>
              </ShowMap>
              <OrderList>
                {this.state.orderList.map((item, index) => {
                  return (
                    <OrderItem
                      key={index}
                      onClick={() => {
                        if (item.statusId === "3") {
                          this.props.history.push({
                            pathname: `${subDomain}/driver/order/${item.orderId}`,
                            state: { item },
                          });
                        }
                      }}
                      style={{
                        background:
                          item.statusId === "4" ? "#ebebeb" : undefined,
                      }}
                    >
                      <div
                        style={{
                          width: "10%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            height: "2rem",
                            width: "2rem",
                            backgroundColor: colors.color1,
                            borderRadius: "50%",
                            color: colors.textColor2,
                            paddingLeft: "0.8rem",
                            paddingTop: "0.4rem",
                          }}
                        >
                          {index + 1}
                        </div>
                      </div>
                      <div style={{ width: "85%", marginLeft: "0.5rem" }}>
                        <Text bold>{"จัดส่งที่ " + item.firstname}</Text>
                        <Text>{`${item.addressLine} ${item.subDistrict} ${item.district} ${item.province} ${item.postalCode}`}</Text>
                        <Text>
                          <Span bold>{"ข้อความถึงผู้ส่ง: "}</Span>
                          {item.comment || "-"}
                        </Text>
                      </div>
                      <div
                        style={{
                          width: "5%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {item.statusId === "3" && (
                          <RightIcon color={colors.footerColor} />
                        )}
                        {item.statusId === "4" && (
                          <GreenCheckIcon style={{ height: "1rem" }} />
                        )}
                      </div>
                    </OrderItem>
                  );
                })}
              </OrderList>
            </React.Fragment>
          )}
        </OrderGroupContainer>
      </React.Fragment>
    );
  }
}
