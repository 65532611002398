import grey1 from './1-grey.svg';
import grey2 from './2-grey.svg';
import grey3 from './3-grey.svg';
import grey4 from './4-grey.svg';
import color1 from './1-color.svg';
import color2 from './2-color.svg';
import color3 from './3-color.svg';
import lineIcon from './line.svg';
import phoneWhite from './phone-ringing-white.svg';
import phoneYellow from './phone-ringing-yellow.svg';
import phoneModal from './phone-ringing-modal.svg';
import betweenStep from './between-step.gif';
import betweenStepGrey from './between-step-grey.svg';

const greyIcons = ['', grey1, grey2, grey3, grey4];
const colorIcons = ['', color1, color2, color3];

const iconOrderSteps = {
  greyIcons,
  colorIcons,
  phoneWhite,
  phoneYellow,
  phoneModal,
  betweenStep,
  betweenStepGrey
};

export default iconOrderSteps;