import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../values/colors';

const SubPageHeader = ({ icon: Icon, label, afterLabel, tail, onIconClick }) => {
    return (
        <Container>
            <Head>
                <IconContainer onClick={onIconClick}>
                    <Icon color={colors.storeText} width={'1em'} height={'1em'} />
                </IconContainer>
                <span style={{marginRight:'12px'}}>{label}</span>
                {afterLabel}
            </Head>

            {tail}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 2.25rem;

    margin-bottom: 1em;
`;

const Head = styled.div`
    display: flex;
    align-items: center;

    font-weight: bold;
    color: ${colors.storeText};

    & > *:first-child {
        margin-right: 0.35em;
    }
`;

const IconContainer = styled.span`
    display: inline-flex;
    cursor: ${props => props.onClick ? 'pointer' : 'default'};
`;

const AfterLabel = styled.div`
    margin-left: 12px;
`;

export default SubPageHeader;