import React, { useEffect, useCallback } from 'react';
import useDataState from '../../hooks/useDataState';
import { Row, Col, DatePicker, Spin } from 'antd';
import styled from 'styled-components';
import { FolderIcon } from '../../components/icons';
import SubPageHeader from './components/subpageHeader';
import Metric from './components/metric';
import OrderCollapse from './components/report/orderCollapse';
import { colors } from '../../values/colors';
import moment from 'moment';
import { delay } from './util/promise';
import { createTableData } from './adapter/order';

const MOCK_DATA = {
    totalIncome: 3400,
    deliveryCost: 310,
    netIncome: 3090,
    orders: [
        {
            "nowTimestamp": "2020-05-25T12:59:47.951Z",
            "orderId": "129",
            "createTime": "25/05/20 12:59:19",
            "totalPrice": 159.5,
            "statusId": "7",
            "addressName": "758/60 ซอย รัตนาธิเบศร์ 36 โครงการเซล รัตนาธิเบศร์2 ตึก A ชั้น7 นนทบุรี รัตนาธิเบศร์ undefined 11000",
            "customer": {
                "customerId": "1",
                "username": "vathunyoo_uraisakun",
                "firstname": "vathunyoo",
                "lastanme": "uraisakun",
                "phone": "0812345678"
            },
            "status": {
                "statusId": "7",
                "nameCustomerTh": "ยกเลิกออเดอร์",
                "nameCustomerEn": "Cancel order",
                "nameRestaurantTh": "ยกเลิกออเดอร์",
                "nameRestaurantEn": "Cancel order"
            },
            "items": [
                {
                    "itemId": "125",
                    "productId": "2",
                    "nameTh": "ข้าวแกงกะหรี่ไข่เจียวฟูปู",
                    "nameEn": "curry rice omelet crab",
                    "price": 159.5,
                    "comment": null,
                    "amount": 1,
                    "choices": [
                        {
                            "choiceId": 2,
                            "nameTh": "เผ็ดกลาง",
                            "nameEn": "medium spicy",
                            "price": 0
                        }
                    ]
                }
            ]
        },
        {
            "nowTimestamp": "2020-05-25T12:59:47.951Z",
            "orderId": "128",
            "createTime": "25/05/20 12:58:38",
            "totalPrice": 315.5,
            "statusId": "7",
            "addressName": "758/60 ซอย รัตนาธิเบศร์ 36 โครงการเซล รัตนาธิเบศร์2 ตึก A ชั้น7 นนทบุรี รัตนาธิเบศร์ undefined 11000",
            "customer": {
                "customerId": "1",
                "username": "vathunyoo_uraisakun",
                "firstname": "vathunyoo",
                "lastanme": "uraisakun",
                "phone": "0812345678"
            },
            "status": {
                "statusId": "7",
                "nameCustomerTh": "ยกเลิกออเดอร์",
                "nameCustomerEn": "Cancel order",
                "nameRestaurantTh": "ยกเลิกออเดอร์",
                "nameRestaurantEn": "Cancel order"
            },
            "items": [
                {
                    "itemId": "124",
                    "productId": "7",
                    "nameTh": "บูตะด้ง",
                    "nameEn": "butadon",
                    "price": 300.5,
                    "comment": null,
                    "amount": 1,
                    "choices": [
                        {
                        "choiceId": 4,
                        "nameTh": "หมู",
                        "nameEn": "pork",
                        "price": 10
                        },
                        {
                        "choiceId": 8,
                        "nameTh": "ซอสพริก",
                        "nameEn": "chili sauce",
                        "price": 5
                        }
                    ]
                }
            ]
        }
    ]
};

const MOCK_API_LOAD_REPORT = async date => {
    await delay(1500);
    return MOCK_DATA;
};

const Highlighted = styled.span`
    color: ${colors.storeTextHightlighted};
    font-weight: 600;
`;

const MetricRow = styled(Row)`
    margin-bottom: 2rem;
`;

const MetricColumn = styled(Col)`
    width: 32%;
`;

const Title = styled.div`
    color: ${colors.storeText};
    font-size: 1.125rem;

    margin-bottom: 1em;
`;

const renderMoney = value => {
    value = parseFloat(value);
    if(isFinite(value)) {
        return `฿ ${value.toLocaleString()}`;
    } else {
        return '฿ 0';
    }
};

export default function Report(){
    const report = useDataState(undefined, moment());
    const loadReport = useCallback(date => {
        report.load(
            MOCK_API_LOAD_REPORT(date).then(data => {
                if(data.orders) {
                    data.orders.map(createTableData);
                }
                return data;
            }),
            date
        );
    }, [report]);

    const data = report.data || {};

    useEffect(() => {
        const today = moment();
        loadReport(today);
    }, []);

    return (
        <React.Fragment>
            <SubPageHeader
                icon={FolderIcon}
                label={'รายงาน'}
                afterLabel={
                    <DatePicker
                        allowClear={false}
                        defaultValue={report.dataKey}
                        format={'DD/MM/YYYY'}
                        onChange={loadReport}
                    />
                }
            />

            <Spin spinning={report.loading}>
                <Row type="flex" align="middle">
                    <Title>
                        {'รายงานประจำวันที่ '}
                        <Highlighted>
                            {report.dataKey.locale('th').format('DD MMMM YYYY')}
                        </Highlighted>
                    </Title>
                </Row>
                <MetricRow type="flex" justify="space-between">
                    <MetricColumn>
                        <Metric
                            title={'รายได้ทั้งหมด(ไม่รวมหัก)'}
                            value={renderMoney(data.totalIncome)}
                        />
                    </MetricColumn>
                    <MetricColumn>
                        <Metric
                            title={'ค่าส่งที่จ่ายคนส่งอาหารทั้งหมด'}
                            value={renderMoney(data.deliveryCost)}
                        />
                    </MetricColumn>
                    <MetricColumn>
                        <Metric
                            title={'รายได้สุทธิ'}
                            value={renderMoney(data.netIncome)}
                            highlighted
                        />
                    </MetricColumn>
                </MetricRow>
                <Title>{'รายการอาหารประจำวัน'}</Title>
                <OrderCollapse
                    orders={data.orders}
                />
            </Spin>
        </React.Fragment>
    );
};
