import React from "react";
import styled from "styled-components";
import { Divider, Input } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Checkboxes from "../../components/checkboxes";
import RadioButtons from "../../components/radio-buttons";
import {
  Text,
  ColumnHalfBox,
  ColumnHalfBoxContainer,
  ColumnFullBox,
  ColumnFullBoxContainer,
  CenterSpin,
} from "./components/sharedComponents";
import { BreadcrumbBar } from "./components/title";
import { Button } from "./components/sharedComponents";
import { customer as api } from "../../services/api";
import DefaultImage from "../../assets/image/cover/bg.svg";
import { subDomain } from "../../services/redirect";
import { colors } from "../../values/colors";
import { redirectTo } from "../../services/redirect";
import Modal from "./components/modal";
import localName from "../../values/localStorageDict";
import addToCart from "../../utils/addTOCart";
import MediaQuery from "react-responsive";
import { renderMoney } from "../../utils/money";

const OutBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding && "2.5rem 10% 2.5rem 10%"};
`;

const ImageBox = styled.div`
  width: 100%;
  padding-top: 90%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
`;

const AmountBox = styled.div`
  width: ${(props) => (props.mobile ? "auto" : "100%")};
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
  justify-content: ${(props) => (props.mobile ? "center" : "flex-end")};
`;

const EditAmountButton = styled.button`
  width: 2rem;
  height: 2rem;
  border: none;
  background-color: ${(props) => (props.disabled ? "#B8B8B8" : "#FFB800")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: white;
  border-radius: 5px;
  text-align: center;
  text-align: -webkit-center;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0;
`;

const AmountInput = styled.input`
  width: 3.5rem;
  height: 2.1rem;
  border-radius: 5px;
  margin: 0 0.5rem;
  text-align: center;
  font-family: Kanit;
  font-weight: 600;
  font-size: 1rem;
`;

const CommentInput = styled(Input.TextArea)`
  border: 1px solid ${colors.buttonColor};
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 0.5rem;

  font-family: Kanit;

  & .ant-input:hover {
    border-color: ${colors.buttonColor};
  }

  & .ant-input:focus,
  .ant-input-focused {
    border-color: ${colors.buttonColor};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${colors.buttonColor};
  }

  &::selection {
    border-color: ${colors.buttonColor};
    background: ${colors.buttonColor};
  }
`;

const TotalPriceBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ButtonAddToCart = styled(Button)`
  width: 100%;
  height: ${(props) => (props.mobile ? "3.6em" : "2.8rem")};
  margin-top: ${(props) => !props.mobile && "1rem"};
  position: ${(props) => props.mobile && "sticky"};
  bottom: ${(props) => props.mobile && "0 !important"};

  @media (max-width: 680px) {
    border-radius: 0;
  }
`;

const FloatMenu = styled.div`
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background-color: white;
`;

const MOCK_OPTION = [
  {
    nameTh: "ระดับความเผ็ด",
    isRequired: true,
    choices: [
      { nameTh: "เผ็ดน้อย", price: 0 },
      { nameTh: "เผ็ดกลาง", price: 0 },
      { nameTh: "เผ็ดมาก", price: 0 },
    ],
  },
  {
    nameTh: "เลือกวัตถุดิบเพิ่มเติม",
    isRequired: true,
    choices: [
      { nameTh: "ไข่แดงเค็ม", price: 25 },
      { nameTh: "ซอสแกงกะหรี่พิเศษ", price: 50 },
    ],
  },
];

class MenuDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      amount: 1,
      selectedOptions: null,
      pricePerUnit: null,
      visibleModal: false,
      requiredList: [],
      comment: "",
    };
    this.increaseAmount = this.increaseAmount.bind(this);
    this.decreaseAmount = this.decreaseAmount.bind(this);
    this.editAmount = this.editAmount.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.checkRequiredOption = this.checkRequiredOption.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
  }

  componentDidMount() {
    var productId;
    if (this.props.popup) {
      productId = this.props.productId;
    } else {
      productId = this.props.match.params.productId;
    }
    const cartIndex =
      this.props.location && this.props.location.state
        ? this.props.location.state.cartIndex
        : null;
    var cart, pricePerUnit;
    if (cartIndex === 0 || cartIndex) {
      const currentCart = localStorage.getItem(localName.CART);
      cart =
        currentCart && currentCart.length > 0 ? JSON.parse(currentCart) : null;
    }
    const isUpdateCart =
      (cartIndex === 0 || cartIndex) &&
      cart[cartIndex].productId === productId.toString();

    api.product.getProductDetail(productId, (status, product) => {
      if (status === 200) {
        const selectedOptions = [];
        const requiredList = [];
        pricePerUnit = parseFloat(product.productPrice);
        if (product.options && product.options.length) {
          for (var i = 0; i < product.options.length; i++) {
            const { optionId } = product.options[i];
            if (product.options[i].isRequired) {
              var choiceIndex;
              if (isUpdateCart) {
                const choiceId = [];
                for (var c = 0; c < cart[cartIndex].choices.length; c++) {
                  choiceId.push(cart[cartIndex].choices[c].choiceId);
                }
                for (var j = 0; j < product.options[i].choices.length; j++) {
                  if (
                    choiceId.includes(product.options[i].choices[j].choiceId)
                  ) {
                    choiceIndex = j;
                    pricePerUnit += parseFloat(
                      product.options[i].choices[j].price
                    );
                  }
                }
              } else {
                requiredList[requiredList.length] = i;
              }
              selectedOptions[i] = {
                optionId: optionId,
                selected: isUpdateCart ? choiceIndex : null,
              };
            } else {
              var selected = [];
              for (var j = 0; j < product.options[i].choices.length; j++) {
                if (isUpdateCart) {
                  for (var k = 0; k < cart[cartIndex].choices.length; k++) {
                    if (
                      product.options[i].choices[j].choiceId ===
                      cart[cartIndex].choices[k].choiceId
                    ) {
                      selected[j] = true;
                      pricePerUnit += parseFloat(
                        product.options[i].choices[j].price
                      );
                      break;
                    }
                  }
                  if (!selected[j]) {
                    selected[j] = false;
                  }
                } else {
                  selected[j] = false;
                }
              }
              selectedOptions[i] = {
                optionId: product.options[i].optionId,
                selected,
              };
            }
          }
        }
        this.setState({
          product,
          selectedOptions,
          pricePerUnit,
          requiredList,
          isUpdateCart: isUpdateCart,
          comment: isUpdateCart ? cart[cartIndex].comment : "",
          amount: isUpdateCart ? cart[cartIndex].amount : 1,
        });
      }
    });
  }

  increaseAmount() {
    this.setState({
      amount: this.state.amount + 1,
    });
  }

  decreaseAmount() {
    this.setState({
      amount: this.state.amount - 1,
    });
  }

  editAmount(e) {
    const { value } = e.target;
    if (Number.isInteger(Number(value)) && Number(value) > 0) {
      this.setState({
        amount: Number(value),
      });
    }
  }

  onRadioChange(optionIndex, checkedIndex) {
    var { selectedOptions } = this.state;
    var oldChoice = selectedOptions[optionIndex].selected;
    var pricePerUnit = this.state.pricePerUnit;
    if (oldChoice !== null) {
      pricePerUnit =
        pricePerUnit -
        parseFloat(
          this.state.product.options[optionIndex].choices[oldChoice].price
        );
    }
    selectedOptions[optionIndex].selected = checkedIndex;
    pricePerUnit =
      pricePerUnit +
      parseFloat(
        this.state.product.options[optionIndex].choices[checkedIndex].price
      );
    this.setState({
      selectedOptions,
      pricePerUnit,
    });
    this.checkRequiredOption(optionIndex);
  }

  onCheckboxChange(optionIndex, checkedIndex) {
    var selected = [];
    for (
      var i = 0;
      i < this.state.selectedOptions[optionIndex].selected.length;
      i++
    ) {
      if (i == checkedIndex) {
        selected[i] = !this.state.selectedOptions[optionIndex].selected[i];
      } else {
        selected[i] = this.state.selectedOptions[optionIndex].selected[i];
      }
    }
    var selectedOptions = this.state.selectedOptions;
    selectedOptions[optionIndex].selected = selected;
    var optionPrice = parseFloat(
      this.state.product.options[optionIndex].choices[checkedIndex].price
    );
    var pricePerUnit = selected[checkedIndex]
      ? this.state.pricePerUnit + optionPrice
      : this.state.pricePerUnit - optionPrice;
    this.setState({
      selectedOptions,
      pricePerUnit,
    });
  }

  onCommentChange(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  checkRequiredOption(optionIndex) {
    const { requiredList } = this.state;
    for (var i = 0; i < requiredList.length; i++) {
      if (requiredList[i] === optionIndex) {
        requiredList.splice(i, 1);
      }
    }
    this.setState({
      requiredList,
    });
  }

  addToCart() {
    const {
      product,
      selectedOptions,
      pricePerUnit,
      amount,
      comment,
      isUpdateCart,
    } = this.state;
    var choices = [];
    for (var i = 0; i < selectedOptions.length; i++) {
      if (product.options[i].isRequired) {
        choices[choices.length] = {
          ...product.options[i].choices[selectedOptions[i].selected],
          optionId: product.options[i].optionId,
        };
      } else {
        for (var j = 0; j < selectedOptions[i].selected.length; j++) {
          if (selectedOptions[i].selected[j]) {
            choices[choices.length] = {
              ...product.options[i].choices[j],
              optionId: product.options[i].optionId,
            };
          }
        }
      }
    }
    var cartItem = {
      productId: product.productId,
      nameTh: product.productNameTh,
      price: product.productPrice,
      pricePerUnit,
      amount,
      comment,
      choices,
    };

    const cartIndex =
      this.props.location && this.props.location.state
        ? this.props.location.state.cartIndex
        : null;
    const { popup } = this.props;
    addToCart(cartItem, isUpdateCart, cartIndex, popup);
    if (popup) {
      this.props.closePopup();
    }
    this.setState({ visibleModal: true });
  }

  render() {
    const { popup } = this.props;
    const {
      product,
      amount,
      selectedOptions,
      pricePerUnit,
      requiredList,
      isUpdateCart,
      comment,
    } = this.state;
    console.log(product);
    return !product || !selectedOptions ? (
      <CenterSpin />
    ) : (
      <React.Fragment>
        <MediaQuery minDeviceWidth={680}>
          <OutBox padding>
            {popup ? (
              <></>
            ) : (
              <BreadcrumbBar
                title={product.productNameTh}
                items={
                  isUpdateCart
                    ? [{ name: "ตะกร้าสินค้า", path: `${subDomain}/cart` }]
                    : []
                }
              />
            )}
            <ColumnHalfBoxContainer>
              <ColumnHalfBox left>
                <ImageBox src={product.image || DefaultImage} />
                <AmountBox>
                  <EditAmountButton
                    onClick={this.decreaseAmount}
                    disabled={amount <= 1}
                  >
                    <MinusOutlined style={{ color: "white" }} />
                  </EditAmountButton>
                  <AmountInput
                    readOnly
                    onChange={this.editAmount}
                    value={amount}
                    maxLength={3}
                  />
                  <EditAmountButton onClick={this.increaseAmount}>
                    <PlusOutlined style={{ color: "white" }} />
                  </EditAmountButton>
                </AmountBox>
                <Divider
                  style={{ border: "0.7px solid #DEDEDE", margin: "1rem 0" }}
                />
                <TotalPriceBox>
                  <Text> ราคารวมทั้งหมด </Text>
                  <Text price yellow>
                    {" "}
                    {pricePerUnit && amount
                      ? renderMoney(parseFloat(pricePerUnit) * amount)
                      : 0.0}{" "}
                    ฿{" "}
                  </Text>
                </TotalPriceBox>
                <ButtonAddToCart
                  onClick={this.addToCart}
                  disabled={requiredList.length !== 0}
                >
                  {`${
                    isUpdateCart
                      ? "อัปเดตสินค้าในตะกร้า"
                      : popup
                      ? "เลือกสินค้าชิ้นนี้"
                      : "เพิ่มสินค้าที่เลือกลงตะกร้า"
                  }`}
                </ButtonAddToCart>
                <Text
                  mini
                  style={{
                    visibility: `${
                      requiredList.length === 0 ? "hidden" : "visible"
                    }`,
                  }}
                >
                  **กรุณาระบุตัวเลือกก่อนเพิ่มสินค้าลงตะกร้า
                </Text>
              </ColumnHalfBox>
              <ColumnHalfBox style={{ paddingLeft: "2.5rem" }}>
                <Text title> {product.productNameTh} </Text>
                <Text price> {renderMoney(product.productPrice)} ฿ </Text>
                <Text style={{ padding: "1.25rem 0" }}>
                  {product.productDescriptionTh}
                </Text>
                {product.options ? (
                  product.options.map((option, optionIndex) => (
                    <div style={{ marginTop: "1rem" }}>
                      <Text option>
                        {option.nameTh}
                        {option.isRequired ? "*" : ""}
                      </Text>
                      {option.isRequired ? (
                        <RadioButtons
                          optionIndex={optionIndex}
                          list={option.choices}
                          onChange={this.onRadioChange}
                          checked={selectedOptions[optionIndex].selected}
                        />
                      ) : (
                        <Checkboxes
                          optionIndex={optionIndex}
                          list={option.choices}
                          onChange={this.onCheckboxChange}
                          checked={selectedOptions[optionIndex].selected}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
                <Text option style={{ paddingTop: "1rem" }}>
                  เพิ่มเติมถึงร้านค้า
                </Text>

                <CommentInput
                  rows={3}
                  value={comment}
                  onChange={this.onCommentChange}
                />
              </ColumnHalfBox>
            </ColumnHalfBoxContainer>
            {popup ? (
              <></>
            ) : (
              <Modal
                visible={this.state.visibleModal}
                text={
                  isUpdateCart
                    ? "อัปเดตสินค้าในตะกร้าเรียบร้อย"
                    : "เพิ่มสินค้าลงตะกร้าเรียบร้อย"
                }
                left={"กลับเมนูหลัก"}
                right={"ไปที่ตะกร้า"}
                onLeftClick={() => {
                  this.setState({ visibleModal: false });
                  redirectTo("/home");
                }}
                onRightClick={() => {
                  this.setState({ visibleModal: false });
                  redirectTo("/cart");
                }}
              />
            )}
          </OutBox>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={680}>
          <OutBox>
            <ColumnFullBoxContainer>
              <ColumnFullBox>
                <ImageBox src={product.image || DefaultImage} />
              </ColumnFullBox>
              <ColumnFullBox
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px 0px",
                  padding: "0 1em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Text title> {product.productNameTh} </Text>
                  <Text>{product.productDescriptionTh}</Text>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text price>{product.productPrice}</Text>
                  <Text price>&nbsp;฿</Text>
                </div> */}
              </ColumnFullBox>
              <ColumnFullBox>
                {product.options && product.options[0].choices.length != 0 ? (
                  product.options.map((option, optionIndex) => (
                    <div style={{ marginTop: "1rem", width: "100%" }}>
                      <Text option style={{ padding: "0 1em" }}>
                        {option.nameTh}
                        {option.isRequired ? "*" : ""}
                      </Text>
                      <Divider
                        style={{
                          border: "0.7px solid #DEDEDE",
                          margin: "1rem 0",
                        }}
                      />
                      <div style={{ padding: "0 1em" }}>
                        {option.isRequired ? (
                          <RadioButtons
                            optionIndex={optionIndex}
                            list={option.choices}
                            onChange={this.onRadioChange}
                            space={true}
                            checked={selectedOptions[optionIndex].selected}
                          />
                        ) : (
                          <Checkboxes
                            optionIndex={optionIndex}
                            list={option.choices}
                            onChange={this.onCheckboxChange}
                            checked={selectedOptions[optionIndex].selected}
                          />
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <Divider
                    style={{
                      border: "0.7px solid #DEDEDE",
                      margin: "1rem 0",
                    }}
                  />
                )}
              </ColumnFullBox>
              <ColumnFullBox style={{ padding: "0 1em" }}>
                <Text option>เพิ่มเติมถึงร้านค้า</Text>
              </ColumnFullBox>
              <Divider
                style={{ border: "0.7px solid #DEDEDE", margin: "1rem 0" }}
              />
              <ColumnFullBox style={{ padding: "0 1em", marginBottom: "1em" }}>
                <CommentInput
                  rows={3}
                  value={comment}
                  onChange={this.onCommentChange}
                />
              </ColumnFullBox>
              <FloatMenu>
                {requiredList.length !== 0 && (
                  <ColumnFullBox style={{ padding: "0 1em" }}>
                    <Text mini>**กรุณาระบุตัวเลือกก่อนเพิ่มสินค้าลงตะกร้า</Text>
                  </ColumnFullBox>
                )}
                <ColumnFullBox
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    border: "0.7px solid #DEDEDE",
                    alignItems: "center",
                  }}
                >
                  <TotalPriceBox
                    style={{
                      borderRight: "0.7px solid #DEDEDE",
                      padding: "0 1em",
                      margin: "1em 0",
                    }}
                  >
                    <Text>ราคารวมทั้งหมด</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginLeft: "1em",
                      }}
                    >
                      <Text priceM yellow>
                        {pricePerUnit && amount
                          ? renderMoney(
                              (parseFloat(pricePerUnit) * amount).toFixed(2)
                            )
                          : 0.0}
                      </Text>
                      <Text priceM yellow>
                        &nbsp;฿
                      </Text>
                    </div>
                  </TotalPriceBox>
                  <AmountBox
                    mobile
                    style={{
                      marginTop: "0px",
                      padding: "0 0.75em",
                    }}
                  >
                    <EditAmountButton
                      onClick={this.decreaseAmount}
                      disabled={amount <= 1}
                    >
                      <MinusOutlined style={{ color: "white" }} />
                    </EditAmountButton>
                    <AmountInput
                      readOnly
                      onChange={this.editAmount}
                      value={amount}
                      maxLength={3}
                    />
                    <EditAmountButton onClick={this.increaseAmount}>
                      <PlusOutlined style={{ color: "white" }} />
                    </EditAmountButton>
                  </AmountBox>
                </ColumnFullBox>
                <ColumnFullBox>
                  <ButtonAddToCart
                    onClick={this.addToCart}
                    disabled={requiredList.length !== 0}
                    mobile
                  >
                    {`${
                      isUpdateCart
                        ? "อัปเดตสินค้าในตะกร้า"
                        : popup
                        ? "เลือกสินค้าชิ้นนี้"
                        : "เพิ่มสินค้าที่เลือกลงตะกร้า"
                    }`}
                  </ButtonAddToCart>
                </ColumnFullBox>
              </FloatMenu>
            </ColumnFullBoxContainer>
            {popup ? (
              <></>
            ) : (
              <Modal
                visible={this.state.visibleModal}
                text={
                  isUpdateCart
                    ? "อัปเดตสินค้าในตะกร้าเรียบร้อย"
                    : "เพิ่มสินค้าลงตะกร้าเรียบร้อย"
                }
                left={"กลับเมนูหลัก"}
                right={"ไปที่ตะกร้า"}
                onLeftClick={() => {
                  this.setState({ visibleModal: false });
                  redirectTo("/home");
                }}
                onRightClick={() => {
                  this.setState({ visibleModal: false });
                  redirectTo("/cart");
                }}
              />
            )}
          </OutBox>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default MenuDetail;
