import React from 'react';
import styled from 'styled-components';
import { Row, Switch as AntdSwitch } from 'antd';

const AfterContainer = styled.div`
    margin-left: 0.5rem;
`;

const Switch = ({ after, value, ...props }) => (
    <Row type="flex" align="middle">
        <AntdSwitch {...props} checked={value} />
        <AfterContainer>
            {after}
        </AfterContainer>
    </Row>
);

export default Switch;