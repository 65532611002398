export const readFileAsDataURL = file => {
    let resolveResult = undefined;
    const resultPromise = new Promise(resolve => {
        resolveResult = resolve;
    });

    if(file) {
        const reader = new FileReader();

        reader.onload = () => {
        const result = reader.result;
        resolveResult(result);
        };

        reader.onerror = () => {
            resolveResult(null);
        }
        reader.onabort = reader.onerror;

        reader.readAsDataURL(file);
    } else {
        resolveResult(null);
    }

    return resultPromise;
};