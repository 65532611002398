import React from 'react';
import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';
import { colors } from '../../../../values/colors';

const Tabs = styled(AntdTabs)`
    font-family: Kanit;

    & .ant-tabs-nav-container div {
        width: 100%;
        // border-bottom: 0.4px solid ${colors.lineColor};
    }

    & div[role="tab"],
    & div[role="tab"]:hover,
    & div[role="tab"]:focus {
        color: ${colors.buttonColor};
        text-align: center;
        height: 50px;
        margin: 0px;
        width: 9.5rem;
        border-bottom: 3px solid ${colors.lineColor};
        padding-right : 1%;
        padding-left : 1%; 
        
    }

    & .ant-tabs-tab-active {
        font-weight: 600;
        border-radius: 5px 5px 0 0;
    }

    & .ant-tabs-ink-bar {
        background-color: ${colors.buttonColor};
        height: 3px;
        postition: absolute;
        bottom: 6px;
    }

    & .ant-tabs-tab-prev, .ant-tabs-tab-next {
        border-bottom: 0.4px solid ${colors.lineColor};
    }

    & .ant-tabs-tab-prev-icon-target, .ant-tabs-tab-next-icon-target {
        color: ${colors.iconColor};
        font-size: 1em;
    }

    @media not screen and (min-device-width: 1224px) {
        & div[role="tab"],
        & div[role="tab"]:hover,
        & div[role="tab"]:focus {
            color: ${colors.buttonColor};
            text-align: center;
            height: 3rem;
            line-height: 3rem;
            padding: 0;
            margin: 0px;
            width: 100%;
            font-size: 1.5rem; 
            border-bottom: 3px solid ${colors.lineColor};
        }

        & .ant-tabs-ink-bar {
            width: 100% !important
        }

        & .ant-tabs-bar{
            margin: 0 0 0.5rem 0;
        }
    }
`;

export default Tabs;