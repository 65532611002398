import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import { colors } from '../values/colors';
import { stringsTH } from '../values/strings';

const Bar = styled.div`
    height: ${props => props.height};
    background: ${colors.footerColor};
    display: flex;
    flex-direction: ${props => props.isDesktop ? 'row' : 'column'};
    align-items: ${props => props.isDesktop ? 'center' : 'space-between'};
    justify-content: ${props => props.isDesktop ? 'space-between' : 'center'};
    padding-left: 10%;
    padding-right: 10%;
    
    @media not screen and (min-device-width: 1224px){
        padding: 0;
        display: block;
        text-align: center;
    }
`
const Text = styled.div`
    color: ${colors.footerTextColor};
    font-family: 'IBM-Plex-Sans-Arabic';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    @media not screen and (min-device-width: 1224px){
        font-size: 14px;
        line-height: ${props => props.bold ? "1.5" : "2.5"};
        font-weight: ${props => props.bold ? "400" : "200"};
    }
`

const Footer = ({ footerHeight }) => {
    var isDesktop = useMediaQuery({ query: '(min-width: 960px)' });
    return <Bar isDesktop={isDesktop} height={footerHeight}>
        <Text> {stringsTH.copyRight} </Text>
        {!isDesktop && [
            <Text bold> {stringsTH.contact1} </Text>,
            <Text bold> {stringsTH.contact2} </Text>]}
        {isDesktop && <Text>{stringsTH.contact1 + " " +stringsTH.contact2 }</Text>}
    </Bar>
}

export default Footer;