import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import {
  Text,
  OutBox,
  Button,
  SpinIcon,
  CenterSpin,
  RowDivider,
} from "../sharedComponents";
import { renderMoney } from "../../../../utils/money";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.space ? "space-between" : "flex-start")};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableText = styled(Text)`
  font-size: ${(props) => (props.option ? "0.9em" : "1.2em")};
  font-weight: ${(props) => (props.header ? 500 : 400)};
  width: ${(props) => props.width};
  color: ${(props) => (props.header ? "#A5A5A5" : null)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 1rem;
`;

const TableTextMobile = styled(Text)`
  font-size: ${(props) => (props.option ? "0.9em" : "1em")};
  font-weight: ${(props) => (props.header ? 500 : 400)};
  width: ${(props) => props.width};
  color: ${(props) => (props.header ? "#A5A5A5" : null)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
`;

const CommentText = styled(Text)`
  font-size: 1.2em;
  font-weight: 300;
  color: #a5a5a5;
  display: block;
  padding-right: 1rem;
  word-wrap: break-all;
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
`;

const CommentTextMobile = styled(Text)`
  font-size: 1em;
  font-weight: 300;
  color: #a5a5a5;
  display: block;
  word-wrap: break-all;
  width: 32%;
  white-space: normal;
  overflow-wrap: break-word;
`;

const columnWidth = [4, "", 8, 6, 8];
const columnWidthMobile = [10, 40, 20, 10, 20];
const rowPadding = "0.4rem 1rem";

export const TableHeader = () => (
  <Row space style={{ padding: rowPadding }}>
    <Row>
      <TableText width={`${columnWidth[0]}rem`} header>
        ลำดับ
      </TableText>
      <TableText header>รายการออเดอร์</TableText>
    </Row>
    <Row>
      <TableText width={`${columnWidth[2]}rem`} header>
        ราคาต่อชิ้น
      </TableText>
      <TableText width={`${columnWidth[3]}rem`} header>
        จำนวน
      </TableText>
      <TableText width={`${columnWidth[4]}rem`} header>
        ราคา (บาท)
      </TableText>
    </Row>
  </Row>
);

export const TableRow = ({ number, item, shorten }) => {
  var optionText = "";
  if (shorten) {
    for (var i = 0; i < item.choices.length; i++) {
      optionText += item.choices[i].nameTh;
      if (i < item.choices.length - 1) {
        optionText += ", ";
      }
    }
  } else {
    for (var optionId of Object.keys(item.options)) {
      if (item.options[optionId].choices.length > 0) {
        optionText += item.options[optionId].nameTh + " - ";
      }
      for (var j = 0; j < item.options[optionId].choices.length; j++) {
        optionText += item.options[optionId].choices[j].nameTh + ", ";
      }
    }
  }
  return (
    <Column style={{ padding: rowPadding }}>
      <Row space>
        <Row>
          <TableText width={`${columnWidth[0]}rem`}>{number + 1}</TableText>
          <TableText>{item.nameTh}</TableText>
        </Row>
        <Row>
          <TableText width={`${columnWidth[2]}rem`}>
            {item.pricePerUnit}
          </TableText>
          <TableText width={`${columnWidth[3]}rem`}>
            {shorten ? item.amount : item.itemAmount}
          </TableText>
          <TableText width={`${columnWidth[4]}rem`}>
            {shorten
              ? item.pricePerUnit * item.amount
              : item.pricePerUnit * item.itemAmount}
          </TableText>
        </Row>
      </Row>
      {optionText.length > 0 ? (
        <Row style={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
          <TableText
            option
            style={{
              paddingLeft: `${columnWidth[0] + 1}rem`,
              width: "11rem",
              justifyContent: "flex-start",
            }}
          >
            ตัวเลือก:
          </TableText>
          <TableText
            option
            style={{
              paddingRight: `${
                columnWidth[2] + columnWidth[3] + columnWidth[4] - 1
              }rem`,
              justifyContent: "flex-start",
            }}
          >
            {optionText}
          </TableText>
        </Row>
      ) : (
        <></>
      )}
    </Column>
  );
};

const TableBodyRow = ({ name, price, amount }) => {
  return (
    <Row space>
      <Row>
        <TableText width={`${columnWidth[0] + 1}rem`} />
        <TableText>{`- ${name}`}</TableText>
      </Row>
      <Row>
        <TableText width={`${columnWidth[2]}rem`}>{""}</TableText>
        <TableText width={`${columnWidth[3]}rem`}>{""}</TableText>
        <TableText width={`${columnWidth[4]}rem`}>{""}</TableText>
      </Row>
    </Row>
  );
};

const TableBodyRowMobile = ({ name, price, amount }) => {
  return (
    <Row style={{ alignItems: "baseline" }}>
      <TableTextMobile width={`${columnWidthMobile[0] + 3}%`} />
      <TableTextMobile
        width={`${columnWidthMobile[1] - 8}%`}
        style={{ justifyContent: "flex-start" }}
      >{`- ${name}`}</TableTextMobile>
      <TableTextMobile width={`${columnWidthMobile[2]}%`}>{""}</TableTextMobile>
      <TableTextMobile width={`${columnWidthMobile[3]}%`}>{""}</TableTextMobile>
      <TableTextMobile width={`${columnWidthMobile[4]}%`}>{""}</TableTextMobile>
    </Row>
  );
};

export const TableBody = ({ number, item }) => {
  item.price = parseFloat(item.price);
  let fix = item.price % 2 > 0 && item.price % 2 < 1;
  return (
    <div>
      <MediaQuery minDeviceWidth={680}>
        <Column style={{ padding: rowPadding }}>
          <Row space>
            <Row>
              <TableText width={`${columnWidth[0]}rem`}>{number + 1}</TableText>
              <TableText>{item.nameTh}</TableText>
            </Row>
            <Row>
              <TableText width={`${columnWidth[2]}rem`}>
                {fix
                  ? renderMoney(item.pricePerUnit)
                  : renderMoney(item.pricePerUnit)}
              </TableText>
              <TableText width={`${columnWidth[3]}rem`}>
                {item.amount}
              </TableText>
              <TableText width={`${columnWidth[4]}rem`}>
                {fix
                  ? renderMoney((item.pricePerUnit * item.amount))
                  : renderMoney(item.pricePerUnit * item.amount)}
              </TableText>
            </Row>
          </Row>
          {item.choices.length > 0 ? (
            <Row>
              <TableText width={`${columnWidth[0]}rem`} />
              <TableText> ตัวเลือกอื่น: </TableText>
            </Row>
          ) : (
            <></>
          )}
          {item.choices.map((choice) => (
            <TableBodyRow name={choice.nameTh} />
          ))}
          {item.comment ? (
            <Row style={{ paddingTop: "1rem" }}>
              <TableText width={`${columnWidth[0]}rem`} />
              <CommentText>{`เพิ่มเติมจากลูกค้า: ${item.comment}`}</CommentText>
            </Row>
          ) : (
            <></>
          )}
        </Column>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={680}>
        <Column style={{ padding: rowPadding }}>
          <Row style={{ alignItems: "baseline" }}>
            <TableTextMobile
              width={`${columnWidthMobile[0]}%`}
              style={{ marginRight: "5%", justifyContent: "center" }}
            >
              {number + 1}
            </TableTextMobile>
            <TableTextMobile
              width={`${columnWidthMobile[1]}%`}
              style={{ marginRight: "5%", justifyContent: "flex-start" }}
            >
              {item.nameTh}
            </TableTextMobile>

            <TableTextMobile
              width={`${columnWidthMobile[2]}%`}
              style={{ marginRight: "5%", justifyContent: "center" }}
            >
              {fix
                ? renderMoney(item.pricePerUnit)
                : renderMoney(item.pricePerUnit)}
            </TableTextMobile>
            <TableTextMobile
              width={`${columnWidthMobile[3]}%`}
              style={{ marginRight: "5%", justifyContent: "center" }}
            >
              {item.amount}
            </TableTextMobile>
            <TableTextMobile
              width={`${columnWidthMobile[4]}%`}
              style={{ justifyContent: "center" }}
            >
              {fix
                ? renderMoney((item.pricePerUnit * item.amount))
                : renderMoney(item.pricePerUnit * item.amount)}
            </TableTextMobile>
          </Row>
          {item.choices.length > 0 ? (
            <Row>
              <TableTextMobile width={`${columnWidthMobile[0] + 2.9}%`} />
              <TableTextMobile header> ตัวเลือกอื่น: </TableTextMobile>
            </Row>
          ) : (
            <></>
          )}
          {item.choices.map((choice) => (
            <TableBodyRowMobile name={choice.nameTh} />
          ))}
          {item.comment ? (
            <Column>
              <Row>
                <TableTextMobile width={`${columnWidthMobile[0] + 2.9}%`} />
                <CommentTextMobile>{`เพิ่มเติมจากลูกค้า:`}</CommentTextMobile>
              </Row>
              <Row>
                <TableTextMobile width={`${columnWidthMobile[0] + 2.9}%`} />
                <CommentTextMobile>{`${item.comment}`}</CommentTextMobile>
              </Row>
            </Column>
          ) : (
            <></>
          )}
        </Column>
      </MediaQuery>
    </div>
  );
};

export const TableBodyEditable = ({ number, item }) => (
  <TableBody item={item} number={number} />
);
