import React from "react";
import styled from "styled-components";
import { Modal, Form, Input, Button, message } from "antd";
import { colors } from "../../values/colors";
import FormItem from "./formItem";
import { redirectTo } from "../../services/redirect";
import localName from "../../values/localStorageDict";

const Column = styled.div`
  display: flex;
  flex-direction: row;
  paddingbottom: 0.1rem;
`;

const Email = styled.text`
  font-family: Kanit;
  font-weight: 600;
  font-size: 1em;
  color: #fbb03b;
`;

const FormButton = styled.div`
  & .ant-btn {
    font-family: Kanit;
    font-size: 1.1em;
    line-height: 1.25rem;

    width: 100%;
    height: 3rem;
    border-color: transparent;
    border-radius: 0.5rem;
    padding: 0.75rem 0.75rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-btn-primary {
    color: ${colors.textColor2};
    background-color: ${colors.buttonColor};
  }
`;

const handleLogout = () => {
  localStorage.removeItem(localName.ACCESS_TOKEN);
  localStorage.removeItem(localName.REFRESH_TOKEN);
  localStorage.removeItem(localName.USERNAME);
  localStorage.removeItem(localName.PROFILE_IMAGE);
  let secondsToGo = 1;
  message.loading("ออกจากระบบ");
  const timer = setInterval(() => {
    secondsToGo -= 1;
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    window.location.reload();
  }, secondsToGo * 1000);
};

class AskToLogOutModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { visible, userName } = this.props;
    return (
      <Modal
        footer={null}
        visible={visible}
        width={"24rem"}
        closable={false}
        bodyStyle={{ padding: 0 }}
        style={{ borderRadius: "5px" }}
      >
        <Column>
          <Form
            style={{
              lineHeight: 1.68,
              marginLeft: "1.26rem",
              marginRight: "1.26rem",
              width: "100%",
            }}
            layout={"vertical"}
            hideRequiredMark
          >
            <FormItem
              style={{
                marginTop: "1.26rem",
                marginBottom: "0.42rem",
                fontSize: "1.6rem",
                height: "2rem",
                padding: "0rem",
              }}
              label="ออกจากระบบ?"
              name="line1"
            ></FormItem>
            <FormItem
              style={{
                margin: 0,
                fontSize: "16px",
                padding: "0rem",
                height: "1.5rem",
                width: "80%",
              }}
              label="ขณะนี้คุณเข้าสู่ระบบด้วยบัญชี"
            ></FormItem>
            <Email>{userName}</Email>

            <FormItem
              style={{
                margin: 0,
                fontSize: "16px",
                padding: "0rem",
                height: "1.5rem",
              }}
              label="กรุณาออกจากระบบเพื่อดำเนินการต่อ"
              name="line3"
            ></FormItem>

            <FormButton
              style={{
                display: "flex",
                marginTop: "0rem",
                padding: "0rem",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                onClick={() => redirectTo("/home")}
                style={{
                  marginTop: "22px",
                  marginBottom: "22px",
                  width: "7rem",
                  backgroundColor: "#A5A5A5",
                  color: "white",
                }}
                htmlType="submit"
                aria-label="Close"
              >
                ยกเลิก
              </Button>
              <Button
                onClick={() => handleLogout()}
                style={{
                  marginTop: "22px",
                  marginBottom: "22px",
                  width: "8.3rem",
                }}
                type="primary"
                htmlType="submit"
                aria-label="Close"
              >
                ออกจากระบบ
              </Button>
            </FormButton>
          </Form>
        </Column>
      </Modal>
    );
  }
}

export default AskToLogOutModal;
