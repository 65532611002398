import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../../../../values/colors";
import CheckBoxDefaultLocation from "./checkboxDefaultLocation";
import InputForm from "./inputForm";
import { Form } from "antd";
import { Button } from "../../../customers/components/sharedComponents";
import { Title } from "../../../customers/components/title";
import PinStatus from "../../../customers/components/editaddress/pinStatus";
import Map from "../../../../components/Map";
import localName from "../../../../values/localStorageDict";
import { redirectTo, subDomain } from "../../../../services/redirect";

const TitleBox = styled.div`
  padding: 0 10%;
  @media not screen and (min-device-width: 1224px) {
    padding: 0 5%;
  }
`;
const ButtonSaveContinue = styled(Button)`
  width: 100%;
  height: 2.8rem;
  background-color: ${(props) =>
    props.disabled
      ? "lightgrey"
      : props.yellow
      ? "#FFB800"
      : colors.buttonColor};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? "white" : props.yellow ? colors.buttonColor : "white"};
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
  font-weight: 500;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  margin-top: 2rem;
`;

const ButtonBack = styled(Button)`
  width: 7.5rem;
  height: 2.8rem;
  color: #ffb800;
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
  font-weight: 500;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  margin-top: 2.8rem;
  @media not screen and (min-device-width: 1224px) {
    display: none;
  }
`;

const PostalCodeBox = styled.div`
  margin-left: 2rem;
  @media (max-width: 77.5rem) {
    margin-left: 0rem;
  }
  @media (max-width: 67px) {
    margin-left: 0rem;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15%;
  margin-right: 10%;
  margin-bottom: 2.5rem;
  @media not screen and (min-device-width: 1224px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    flex-direction: column;
  }
`;

const FlexColumnInformation = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRowInformation = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 77.5rem) {
    flex-direction: column;
  }
  @media (max-width: 67rem) {
    padding-left: 4rem;
  }
  @media not screen and (min-device-width: 1224px) {
    padding-left: 0rem;
  }
`;

const InformationBox = styled.div`
  width: 25%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  justify-content: baseline;
  @media not screen and (min-device-width: 1224px) {
    width: 100%;
    margin-left: 0%;
  }
`;

const ColumnEdit = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  margin-top: 1rem;
  @media not screen and (min-device-width: 1224px) {
    margin-bottom: 0rem;
  }
`;

const MapColumn = styled.div`
  position: fix;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 40rem;
  @media not screen and (min-device-width: 1224px) {
    width: 100%;
    height: 100%;
  }
`;

const Mapblur = styled.div`
  width: 38%;
  height: 30rem;
  position: absolute;
  z-index: 100;
  cursor: no-drop;
  @media not screen and (min-device-width: 1224px) {
    width: 90%;
    height: 20rem;
  }
`;

class FormEditAddress extends Component {
  state = {
    signedIn:
      localStorage.getItem(localName.ACCESS_TOKEN) &&
      localStorage.getItem(localName.REFRESH_TOKEN),
  };
  componentDidMount() {
    localStorage.setItem(
      localName.PATH_ITEMS,
      JSON.stringify(this.props.pathItems)
    );
  }
  render() {
    const { pathItems, title, attributeSet, history } = this.props;
    let {
      // googlemap,
      changeAddress,
      changeArea,
      changeCity,
      changeState,
      changePostal,
      changeMapPosition,
      changeMarkerPosition,
      onFinish,
      onFinishFailed,
      onChange,
      signedIn,
      isDefault,
      onChecked,
      isDisabledButton,
      formRef,
      enabledPin,
      isPinned,
      address,
      area,
      city,
      state,
      postal,
      mapPosition,
      markerPosition,
      isDisabled,
    } = attributeSet;

    if (mapPosition.lat != null && mapPosition.lng != null) {
      mapPosition.lat = parseFloat(mapPosition.lat);
      mapPosition.lng = parseFloat(mapPosition.lng);
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (mapPosition.lat == null && mapPosition.lng == null) {
          mapPosition.lat = position.coords.latitude;
          mapPosition.lng = position.coords.longitude;
        }
      },
      (err) => {},
      {
        maximumAge: Infinity,
      }
    );

    return (
      <React.Fragment>
        {/* Title */}
        <TitleBox>
          <Title breadcrumbItems={pathItems} title={title}></Title>
        </TitleBox>
        <PinStatus
          enable={enabledPin}
          attributeSet={attributeSet}
          history={history}
        />
        <ColumnEdit>
          <FlexRow>
            <MapColumn>
              {!enabledPin && <Mapblur />}
              <Map
                center={mapPosition}
                height="30rem"
                width="100%"
                filterBlur={!enabledPin ? "opacity(20%)" : "none"}
                zoom={15}
                pinable={false}
                address={address}
                area={area}
                city={city}
                state={state}
                postal={postal}
                reset={false}
                changeAddress={changeAddress}
                changeArea={changeArea}
                changeCity={changeCity}
                changeState={changeState}
                changePostal={changePostal}
                changeMapPosition={changeMapPosition}
                changeMarkerPosition={changeMarkerPosition}
              />
              {!enabledPin && (
                <ButtonBack
                  white
                  border
                  onClick={() =>
                    this.state.signedIn
                      ? redirectTo("/my-address")
                      : redirectTo("/cart")
                  }
                >
                  กลับ
                </ButtonBack>
              )}
            </MapColumn>
            <InformationBox>
              <Form
                width="100%"
                ref={formRef}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                hideRequiredMark={true}
              >
                <FlexColumnInformation>
                  <FlexRowInformation>
                    <InputForm
                      enabledPin={enabledPin}
                      isPinned={isPinned}
                      type="name"
                      name="name"
                      label="ชื่อเรียกสถานที่"
                      placeholder="กรุณากรอกชื่อสถานที่ เช่น บ้าน, ที่ทำงาน"
                      isRequired={true}
                      boxsize=""
                      inputsize="single"
                      onChange={onChange}
                    />
                  </FlexRowInformation>
                  <FlexRowInformation>
                    <InputForm
                      enabledPin={enabledPin}
                      isPinned={isPinned}
                      inputsize="single"
                      name="addressLine"
                      boxsize=""
                      label="บ้านเลขที่/ซอย/ชื่อหมู่บ้าน/ชื่อโครงการ/ตึก/ชั้น"
                      placeholder="กรุณากรอกข้อมูล"
                      isRequired={true}
                      onChange={onChange}
                      defaultValue={address}
                    />
                  </FlexRowInformation>
                  <FlexRowInformation>
                    <InputForm
                      enabledPin={enabledPin}
                      isPinned={isPinned}
                      name="district"
                      boxsize=""
                      label="เขต"
                      placeholder="กรุณากรอกข้อมูล"
                      isRequired={true}
                      onChange={onChange}
                      defaultValue={area}
                    />
                    <div style={{ width: "5%" }}></div>
                    <InputForm
                      enabledPin={enabledPin}
                      isPinned={isPinned}
                      name="subDistrict"
                      boxsize=""
                      label="แขวง"
                      placeholder="กรุณากรอกข้อมูล"
                      isRequired={true}
                      onChange={onChange}
                      defaultValue={state}
                    />
                  </FlexRowInformation>
                  <FlexRowInformation>
                    <InputForm
                      enabledPin={enabledPin}
                      isPinned={isPinned}
                      name="province"
                      boxsize=""
                      label="จังหวัด"
                      placeholder="กรุณากรอกข้อมูล"
                      isRequired={true}
                      onChange={onChange}
                      defaultValue={city}
                    />
                    <div style={{ width: "5%" }}></div>
                    <InputForm
                      enabledPin={enabledPin}
                      isPinned={isPinned}
                      type="postalCode"
                      name="postalCode"
                      boxsize=""
                      label="รหัสไปรษณีย์"
                      placeholder="กรุณากรอกข้อมูล"
                      isRequired={true}
                      onChange={onChange}
                      defaultValue={postal}
                    />
                  </FlexRowInformation>
                  <FlexRowInformation>
                    <InputForm
                      enabledPin={enabledPin}
                      isPinned={isPinned}
                      inputsize="single"
                      name="comment"
                      boxsize="moredetail"
                      label="เพิ่มเติมถึงคนขับ"
                      placeholder="กรุณากรอกข้อมูล"
                      isRequired={false}
                    />
                  </FlexRowInformation>
                </FlexColumnInformation>
                {/* {signedIn ? (
                  <CheckBoxDefaultLocation
                    isDefault={isDefault}
                    onChecked={onChecked}
                    isDisabled={!enabledPin || isDisabledButton}
                  />
                ) : null} */}
                <ButtonSaveContinue disabled={!enabledPin || isDisabledButton}>
                  บันทึกและดำเนินการต่อ
                </ButtonSaveContinue>
              </Form>
            </InformationBox>
          </FlexRow>
        </ColumnEdit>
      </React.Fragment>
    );
  }
}

export default FormEditAddress;
