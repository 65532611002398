const toRgbValues = (colorCode) => {
  if (colorCode) {
    colorCode = colorCode.slice(1);
    if (colorCode.length === 6) {
      return colorCode
        .match(/.{1,2}/g)
        .map((x) => parseInt(x, 16))
        .join(",");
    } else if (colorCode.length === 3) {
      return Array.from(colorCode)
        .map((x) => parseInt(x, 16))
        .join(",");
    }
  }

  return null;
};

const storeTheme = {
  themeColor: "#FBB03B",
  primaryColor: "#663C46",
  primaryTextColor: "#663C46",
  danger: "#D30404",
};

export const colors = {
  color1: "#FBB03B",
  textColor1: "#000000",
  textColor2: "#FFFFFF",
  textColor3: "#A5A5A5",
  footerColor: "#663D45",
  footerTextColor: "#FFFFFF",
  lineColor: "#FFF1BD",
  buttonColor: "#663D45",
  inActiveButtonColor: "#F4F4F4",
  iconColor: "#FBB03B",
  inActiveTextColor: "#7D7D7D",
  facebookColor: "#3B5998",
  googleColor: "#FFFFFF",
  errorColor: "#D10000",
  activeManu: "#663C46",
  inActiveMenu: "#4F4F4F",
  // #region Store
  storeThemeColor: storeTheme.themeColor,
  storeThemeColorRGB: toRgbValues(storeTheme.themeColor),
  storeDrawerActiveBg: storeTheme.themeColor,
  storeDrawerInactiveBg: "#F7F7F7",
  storeDrawerDividerBg: "#E3E3E3",
  storeText: storeTheme.primaryTextColor,
  storeTextHightlighted: storeTheme.themeColor,
  storeTextHightlightedRGB: toRgbValues(storeTheme.themeColor),
  storeTextDanger: storeTheme.danger,
  storeTabActiveBg: storeTheme.themeColor,
  // #endregion
  //#region Driver
  lineDriverColor: "#DEDEDE",
  //#endregion
};
