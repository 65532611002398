import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { subDomain } from "../../services/redirect";
import styled from "styled-components";
import DriverHeader from "./components/header";
import OrderGroup from "./orderGroup";
import Order from "./order";
import OrderDetail from "./orderDetail";
import matchOrder from "./matchorder";

const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  padding-top: 4rem;
  overflow-y: auto;
  font-family: "Kanit";
  font-style: normal;
  font-size: 1em;
  line-height: 1.25rem;
`;

class Driver extends React.Component {
  state = {
    deliveryId: "-",
  };
  render() {
    const headerHeight = "4rem";

    return (
      <React.Fragment>
        <DriverHeader
          headerHeight={headerHeight}
          path={this.props.location.pathname}
          history={this.props.history}
          deliveryId={this.state.deliveryId}
        />
        <PageContainer>
          <Switch>
            <Route
              path={`${subDomain}/driver/ordergroup`}
              exact
              render={(props) => (
                <OrderGroup
                  {...props}
                  setDeliveryGroupId={(id) => {
                    this.setState({
                      deliveryId: id,
                    });
                  }}
                  history={this.props.history}
                />
              )}
            />
            <Route
              path={`${subDomain}/driver/order/:id`}
              exact
              render={(props) => (
                <Order
                  {...props}
                  setDeliveryGroupId={(id) => {
                    this.setState({
                      deliveryId: id,
                    });
                  }}
                  history={this.props.history}
                />
              )}
              // component={Order}
            />
            <Route
              path={`${subDomain}/driver/orderdetail/:id`}
              exact
              render={(props) => (
                <OrderDetail
                  {...props}
                  setDeliveryGroupId={(id) => {
                    this.setState({
                      deliveryId: id,
                    });
                  }}
                  history={this.props.history}
                />
              )}
              // component={OrderDetail}
            />
            <Route
              path={`${subDomain}/driver/matchorder`}
              exact
              component={matchOrder}
            />
            <Redirect to={`${subDomain}/driver/ordergroup`} />
          </Switch>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default Driver;
