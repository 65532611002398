export const stringsTH = {
    name: 'ไอแอมเยลโล่วคาเฟ่',
    copyRight: 'Copyright © 2020 I’m yellow cafe & restaurant.',
    contact1: 'Office: +66(2)-719-7738-9',
    contact2: ' Fax: +66(2)-719-7740'
};

export const stringsEN = {
    name: 'I\'m Yellow Cafe & Restaurant',
    copyRight: 'Copyright © 2020 I’m yellow cafe & restaurant.',
    contact1: 'Office: +66(2)-719-7738-9',
    contact2: ' Fax: +66(2)-719-7740'
};