import React from "react";
import { Row } from "antd";
import Modal from "../modal";
import styled from "styled-components";
import { colors } from "../../../../values/colors";
import { formatPhoneNumber } from "../../util/format";

const Container = styled.div`
  margin-top: 2em;

  & > div {
    padding: 4px 0px;
  }
`;

const FieldName = styled.span`
  color: ${colors.storeText};
  font-weight: 600;
`;

const FieldValue = styled.span`
  color: ${colors.storeText};
`;

export default class DriverInfoModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      data: undefined,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show(driverData) {
    this.setState({
      visible: true,
      data: driverData || {},
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
  }

  renderRow(field, value) {
    return (
      <Row type="flex" align="middle" justify="space-between">
        <FieldName>{field}</FieldName>
        <FieldValue>{value}</FieldValue>
      </Row>
    );
  }

  render() {
    const { visible, data = {} } = this.state;
    const {
      name,
      phone,
      // username,
      firstname,
      lastname,
      note,
    } = data;

    return (
      <Modal
        title={name}
        visible={visible}
        onCancel={this.hide}
        closable
        maskClosable
      >
        <Container>
          {this.renderRow("เบอร์โทรศัพท์", formatPhoneNumber(phone))}
          {this.renderRow("ชื่อผู้ใช้งาน", lastname == null ? `${firstname}` : `${firstname} ${lastname}`)}
          {this.renderRow("Note", note)}
        </Container>
      </Modal>
    );
  }
}
