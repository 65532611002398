import React from "react";
import moment from "moment";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { BreadcrumbBar } from "./components/title";
import {
  Divider,
  Modal,
  Dropdown,
  Button,
  Menu,
  DatePicker,
  Input,
  Form,
  message,
} from "antd";
import { Text, RowDivider } from "../customers/components/sharedComponents";
import { EditOutlined, DownOutlined } from "@ant-design/icons";
import { subDomain, redirectTo } from "../../services/redirect";
import Underscore from "underscore";
import { colors } from "../../values/colors";
import { Checkbox } from "../../components/checkboxes";
import { TableHeader } from "./components/order/orderItemTable";
import CartItem from "./components/cart/cartItem";
import { Redirect } from "react-router-dom";
import { customer as api } from "../../services/api.js";
import cartIcon from "../../assets/icons/title-icon/cart.svg";
import localName from "../../values/localStorageDict";
import { ConsoleView } from "react-device-detect";
import InputForm from "./components/editaddress/inputForm";
import { renderMoney } from "../../utils/money";

const CartRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10% 10% 10%;
  @media (max-width: 680px) {
    padding: 0 0 0 0;
  }
`;

const OutBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  @media (max-width: 680px) {
    padding: 3% 5% 0 5%;
  }
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding || "1rem 0 0 0"};
  @media (max-width: 680px) {
    padding: 5% 5% 0 5%;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.padding || "0 0 0 0"};

  ${(props) =>
    props.changeAddress &&
    `
  @media not screen and (min-device-width: 1224px){
    justify-content: flex-end;
    width: 100%;
  `}
`;

const SubTitleRow = styled.div`
  font-family: "Kanit";
  font-size: 1em;
  line-height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  @media (max-width: 680px) {
    padding: 0 5% 0 5%;
  }
  ${(props) =>
    props.topic &&
    `
    @media not screen and (min-device-width: 1224px){
      margin-left: -5%;
    }
  `}
`;

const SubTitleText = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
  word-wrap: break-word;
`;

const StyledDivider2 = styled(Divider)`
  border: 0.7px solid #dedede;
  margin-top: 0.5rem;
`;

const EditText = styled.div`
  font-family: "Kanit";
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
`;

const MenuText = styled(Text)`
  font-size: 0.9rem;
  font-weight: 500;
  justify-content: flex-end;
`;

const DetailText = styled.div`
  font-family: "Kanit";
  font-size: 1rem;
  font-weight: 400;
`;

const ButtonSubmit = styled.button`
  padding: 0.75rem 3rem 1rem 3rem;
  height: 2.8rem;
  border: none;
  background-color: ${(props) =>
    props.disabled ? "lightgrey" : colors.buttonColor};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: white;
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
  @media (max-width: 680px) {
    border-radius: 0;
    width: 100%;
  }
`;

const ButtonReturn = styled.button`
  align-items: center;
  padding: 0.75rem 3rem 1rem 3rem;
  height: 2.8rem;
  border: 1px solid #fbb03b;
  background-color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: #fbb03b;
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
`;

const AddressBox = styled.div`
  display: flex;
  align-items: start;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #f6f6f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
`;

const ModalBox = styled.div`
  display: flex;
  align-items: center;
  font-family: "Kanit";
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5em;
  justify-content: ${(props) => (props.space ? "space-between" : "center")};
  margin: ${(props) => (props.padding ? "2.5rem 0 1.5rem 0" : 0)};
  padding: 1rem 10% 1rem 10%;
`;

const FontText = styled(Form.Item)`
  color: #663c46;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const InputSize = styled(Input)`
  width: 19rem;
  @media (max-width: 1220px) {
    width: 14rem;
  }
  @media (max-width: 875px) {
    width: 19rem;
  }
`;

const currentCart = localStorage.getItem(localName.CART);
let cart = currentCart && currentCart.length > 0 ? JSON.parse(currentCart) : [];

// รวมเมนูที่ซ้ำกัน
for (let i = 0; i < cart.length; i++) {
  // สร้าง Object เพื่อนำมาเช็ค โดยใช้แค่ producId และ choices ในการเทียบว่าเป็นเมนูเดียวกันหรือไม่
  let tag_i = {
    productId: cart[i].productId,
    options: cart[i].choices,
    comment: cart[i].comment,
  };
  for (let j = i + 1; j < cart.length; j++) {
    let tag_j = {
      productId: cart[j].productId,
      options: cart[j].choices,
      comment: cart[j].comment,
    };
    //ถ้า Object ที่ใช้เทียบเหมือนกัน บวกจำนวนอันเดิมเข้าไปเท่ากับจำนวนอันใหม่ แล้วลบอันใหม่ทิ้ง
    if (Underscore.isEqual(tag_i, tag_j)) {
      cart[i].amount += cart[j].amount;
      cart = cart.slice(0, j).concat(cart.slice(j + 1, cart.length));
      j -= 1;
    }
  }
}
localStorage.setItem(localName.CART, JSON.stringify(cart));
// สถานะ login
let signedIn =
  localStorage.getItem(localName.ACCESS_TOKEN) &&
  localStorage.getItem(localName.REFRESH_TOKEN);

// ประกาศ object order สำหรับส่งไปยัง backend
let order = {};
// ประกาศ object paymentDetail สำหรับส่งไปยัง backend
let paymentDetail = {};

//สร้าง Object ของ Object Date โดยมีหน้าตาประมาณ {currentDate: Fri May 29 2020 23:59:14 GMT+0700 (เวลาอินโดจีน), selectDate: Fri May 29 2020 23:59:14 GMT+0700 (เวลาอินโดจีน)}}
let datetime = {};
datetime.delivery = {};
datetime.take = {};
datetime.delivery.currentDate = moment();
datetime.delivery.selectDate = moment();
datetime.take.currentDate = moment();
datetime.take.selectDate = moment();

// สร้าง array ของเวลาตั้งแต่เปิดร้านถึงปิดร้าน
let starthour = 12;
let endhour = 20;
let interval = [0, 30];
let deliveryTime = [];
let takeTime = [];
let allTime = [];
let closemoment = moment();
closemoment.hour(endhour);
closemoment.minute(0);
for (let i = starthour; i < endhour; i++) {
  interval.forEach((item) => {
    let timevalue = i + item / 100;
    let timemoment = moment();
    timemoment.hour(i);
    timemoment.minute(item);
    if (
      timemoment > moment() &&
      timemoment - moment() > 1800000 &&
      timemoment < closemoment
    )
      deliveryTime.push(timevalue.toFixed(2));
    allTime.push(timevalue.toFixed(2));
  });
}

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: {},
      deliveryTime: "",
      editMenu: false,
      editAddress: null,
      editCartIndex: null,
      editProductPath: null,
      wrongTimePick: { delivery: false, take: false },
      item: cart,
      selectedAdressIndex: [true, false],
      selectedTimeIndex: [true, false],
      takeTime: "",
      toPayment: false,
      deliveryCost: 0,
      lat: 0,
      long: 0,
      customerName: "-",
      // customerEmail: "-",
      customerPhone: localStorage.getItem(localName.PHONE_NUMBER),
      profilephone: "",
    };
    this.formRef = React.createRef();

    this.editAddress = this.editAddress.bind(this);
    this.handleAddressBoxChecked = this.handleAddressBoxChecked.bind(this);
    this.handleTimeClick = this.handleTimeClick.bind(this);
    this.handleTimeChecked = this.handleTimeChecked.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
    this.onDatepick = this.onDatepick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEditMenu = this.onEditMenu.bind(this);
    this.onOrder = this.onOrder.bind(this);
  }

  async componentDidMount() {
    takeTime = deliveryTime;
    localStorage.setItem(localName.PREVENT_TYPING_URL, false);
    let localAddress = localStorage.getItem(localName.CART_ADDR);
    if (signedIn) {
      api.user.getProfileData(
        ({ data }) => {
          this.formRef.current.setFieldsValue({
            phone:
              !!localStorage.getItem(localName.PHONE_NUMBER) &&
              localStorage.getItem(localName.PHONE_NUMBER) != "null"
                ? localStorage.getItem(localName.PHONE_NUMBER)
                : data.phone,
          });
          this.setState({
            customerName: data.firstname,
            // customerEmail: data.email,
            customerPhone:
              !!localStorage.getItem(localName.PHONE_NUMBER) &&
              localStorage.getItem(localName.PHONE_NUMBER) != "null"
                ? localStorage.getItem(localName.PHONE_NUMBER)
                : data.phone,
          });
        },
        () => {}
      );
      if (JSON.parse(localAddress) && !!!JSON.parse(localAddress).addressId) {
        api.address.insert(
          {
            ...JSON.parse(localAddress),
          },
          ({ data, status }) => {
            if (status === 200) {
              localStorage.setItem(localName.CART_ADDR, JSON.stringify(data));
              localAddress = localStorage.getItem(localName.CART_ADDR);
              this.setState({ addresses: data });
            }
          },
          (response) => {}
        );
      }
      let defaultAddress = {};
      await api.address
        .fetch(
          (data) => {
            defaultAddress = Underscore.isEmpty(data)
              ? []
              : data.filter((item) => item.isDefault && !item.isDeleted)[0];
          },
          (response) => {}
        )
        .then(() => {
          defaultAddress = defaultAddress === undefined ? [] : defaultAddress;
          let address =
            localAddress === null
              ? signedIn
                ? defaultAddress
                : {}
              : localAddress && localAddress.length > 0
              ? JSON.parse(localAddress)
              : {};
          address = address.isDeleted ? {} : address;
          this.setState({ addresses: address });
        });
    } else {
      let address =
        localAddress && localAddress.length > 0 ? JSON.parse(localAddress) : {};
      this.setState({ addresses: address });
    }
    await this.calculateDeliveryCost();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.addresses !== prevState.addresses) {
      this.calculateDeliveryCost();
    }
  }
  // คำนวนราคาจากเมนูทั้งหมด
  calculatePrice() {
    let Price = 0;
    for (let i = 0; i < this.state.item.length; i++) {
      Price += this.state.item[i].pricePerUnit * this.state.item[i].amount;
    }
    return Price;
  }
  // คำนวนราคาจากตำแหน่ง
  calculateDeliveryCost = () => {
    // คำนวณค่าส่งจากระยะทาง
    /*navigator.geolocation.getCurrentPosition(function(position) {
      console.log("Latitude is :", position.coords.latitude);
     // this.setState({lat:position.coords.latitude});
      console.log("Longitude is :", position.coords.longitude);
      //this.setState({long:position.coords.longitude});
    });*/

    let address = {
      latitude: parseFloat(this.state.addresses.latitude),
      longitude: parseFloat(this.state.addresses.longitude),
      branchId: 2,
    };
    api.address.getDeliveryCostByAddress(address, ({ data }) => {
      if (!!address.latitude || !!address.longitude) {
        this.setState({ deliveryCost: data.deliveryCost });
      }
    });
  };

  disabledDate(current) {
    // รับ moment ปัจจุบัน คืน moment ที่เลือกไม่ได้กลับไป (วันก่อนหน้า + วันเสาร์อาทิตย์)
    return current && current < moment().startOf("day");
  }

  editAddress(type) {
    this.setState({
      editAddress: type,
    });

    localStorage.setItem(localName.START_AT, `${subDomain}/cart`);
    localStorage.setItem(localName.PREVENT_TYPING_URL, true);
    if (type === "add") {
      localStorage.setItem(localName.ADD_OR_EDIT_ADDR, "add");
      redirectTo("/my-address");
    } else if (type === "edit" && !signedIn) {
      localStorage.setItem(localName.ADD_OR_EDIT_ADDR, "edit");
      redirectTo("/edit-address");
    } else if (type === "edit" && signedIn) {
      localStorage.setItem(localName.ADD_OR_EDIT_ADDR, null);
      redirectTo("/my-address");
    }
  }

  handleAddressBoxChecked(index) {
    this.setState({
      selectedAdressIndex: [index === 0, index === 1],
    });
  }

  handleTimeClick(e, type) {
    let wrongTime = false;
    //เปลี่ยนเวลา (ชม. นาที) ใน datetime.(Delivery/รับเองที่ร้าน).selectDate ให้ตรงกับที่เลือกไว้
    //type 0=Delivery 1=รับเองที่ร้าน
    //set state เพื่อให้เวลาที่แสดงตรงกับที่เลือกไว้
    if (type === 0) {
      const minute = (deliveryTime[e.key] % 1) * 100;
      const hour = Math.floor(deliveryTime[e.key]);
      datetime.delivery.selectDate.hour(hour);
      datetime.delivery.selectDate.minute(minute);
      if (datetime.delivery.selectDate - moment() < 1800000) wrongTime = true;
      this.setState({
        deliveryTime: deliveryTime[e.key] + "น.",
        wrongTimePick: {
          delivery: wrongTime,
          take: this.state.wrongTimePick.take,
        },
      });
    } else if (type === 1) {
      const minute = (takeTime[e.key] % 1) * 100;
      const hour = Math.floor(takeTime[e.key]);
      datetime.take.selectDate.hour(hour);
      datetime.take.selectDate.minute(minute);
      if (datetime.take.selectDate - moment() < 0) wrongTime = true;
      this.setState({
        takeTime: takeTime[e.key] + "น.",
        wrongTimePick: {
          delivery: this.state.wrongTimePick.delivery,
          take: wrongTime,
        },
      });
    }
  }
  handleTimeChecked(index) {
    this.setState({
      selectedTimeIndex: [index === 0, index === 1],
    });
  }

  onDatepick(date) {
    //เปลี่ยนเวลา (วัน เดือน ปี) ใน datetime.selectDate ให้ตรงกับที่เลือกไว้
    datetime.take.selectDate = date;
    //แก้ array takeTime ในกรณีที่วันที่เลือกคือวันนี้
    const isToday = date
      ? date.date() === moment().date() &&
        date.month() === moment().month() &&
        date.year() === moment().year()
      : null;
    takeTime = date ? (isToday ? deliveryTime : allTime) : [];
    this.setState({ takeTime: "" });
  }

  onDelete(index) {
    localStorage.setItem(localName.PHONE_NUMBER, this.state.customerPhone);
    const item = this.state.item;
    let newcart = item
      .slice(0, index)
      .concat(item.slice(index + 1, item.length));
    localStorage.setItem(localName.CART, JSON.stringify(newcart));
    localStorage.setItem(localName.PHONE_NUMBER, this.state.customerPhone);
    redirectTo("/cart");
  }

  onEditMenu(productId, index) {
    const path = `${subDomain}/menu/` + productId;
    this.props.history.push({
      pathname: path,
      state: { cartIndex: index },
    });
    // this.setState({
    //   editProductPath: `${subDomain}/menu/` + productId,
    //   editCartIndex: index,
    //   editMenu: true,
    // });
  }

  onSubmit(thisRef) {
    if (
      // this.state.customerPhone == "" ||
      this.formRef.current.getFieldValue().phone
    ) {
      api.user.editProfile(
        { phone: this.formRef.current.getFieldValue().phone },
        () => {
          thisRef.onOrder(this.state.item);
          localStorage.removeItem(localName.PHONE_NUMBER);
          this.setState({ toPayment: true });
        },
        (res) => {
          // console.log(res.message);
          message.error("บันทึกข้อมูลเบอร์โทรไม่สำเร็จ");
        }
      );
    } else {
      thisRef.onOrder(this.state.item);
      this.setState({ toPayment: true });
    }
  }

  onSubmitFailed(thisRef) {
    let element = document.getElementById("phone");
    element.focus();
  }

  onOrder(items) {
    let products = [];
    const time = this.state.selectedAdressIndex[0]
      ? datetime.delivery.selectDate
      : datetime.take.selectDate;
    paymentDetail.item = items;
    paymentDetail.address = this.state.selectedAdressIndex[0]
      ? this.state.addresses
      : {};
    paymentDetail.price = {
      item: this.calculatePrice(),
      delivery: this.state.deliveryCost,
    };
    paymentDetail.time = this.state.selectedAdressIndex[0]
      ? this.state.selectedTimeIndex[0]
        ? "จัดส่งทันที"
        : datetime.delivery.selectDate.hour().toString().padStart(2, "0") +
          ":" +
          datetime.delivery.selectDate.minute().toString().padStart(2, "0")
      : datetime.take.selectDate.hour().toString().padStart(2, "0") +
        ":" +
        datetime.take.selectDate.minute().toString().padStart(2, "0");
    items.forEach((item) => {
      let product = {};
      product.productId = parseInt(item.productId);
      product.amount = item.amount;
      product.comment = item.comment;
      //NOTE: old code choice: [1,2]
      // product.choices = [];
      // for (var j = 0; j < item.choices.length; j++) {
      //   product.choices.push(item.choices[j].choiceId);
      // }
      // products.push(product);
      product.options = {};
      for (var j = 0; j < item.choices.length; j++) {
        if (!!product.options[item.choices[j].optionId]) {
          product.options[item.choices[j].optionId].push(
            item.choices[j].choiceId
          );
        } else {
          product.options[item.choices[j].optionId] = [
            item.choices[j].choiceId,
          ];
        }
      }
      products.push(product);
    });
    order.shippingAddressId = this.state.addresses.addressId;
    order.branchId = 2;
    order.isPickup = this.state.selectedAdressIndex[1];
    order.pickupAppointTime =
      time.year() +
      "-" +
      time.month().toString().padStart(2, "0") +
      "-" +
      time.date().toString().padStart(2, "0") +
      " " +
      time.hour().toString().padStart(2, "0") +
      ":" +
      time.minute().toString().padStart(2, "0") +
      ":" +
      time.second().toString().padStart(2, "0");
    order.products = products;
    //api.order.createOrder(order)
    this.setState({ toPayment: true });
  }

  resetAddress() {
    localStorage.removeItem(localName.EDIT_ADDR);
    redirectTo("/cart");
  }

  render() {
    //localStorage.clear()
    let menu = this.state.item;
    //ดึง array เวลาจัดส่งมาสร้างเป็น Menu Component
    const renderCondition = this.state.addresses;
    const deliveryTimeMenu = (
      <Menu
        style={{ maxHeight: "15rem", overflowY: "scroll" }}
        onClick={(e) => this.handleTimeClick(e, 0)}
      >
        {deliveryTime.map((item, index) => (
          <Menu.Item key={String(index)}>
            <SubTitleText>{item + "น."}</SubTitleText>
          </Menu.Item>
        ))}
      </Menu>
    );
    const takeTimeMenu = (
      <Menu
        style={{ maxHeight: "15rem", overflowY: "scroll" }}
        onClick={(e) => this.handleTimeClick(e, 1)}
      >
        {takeTime.map((item, index) => (
          <Menu.Item key={String(index)}>
            <SubTitleText>{item + "น."}</SubTitleText>
          </Menu.Item>
        ))}
      </Menu>
    );
    const disableSubmit =
      !signedIn ||
      (this.state.selectedAdressIndex[0] &&
        (!signedIn || Underscore.isEmpty(this.state.addresses))) ||
      (this.state.selectedAdressIndex[0] &&
        this.state.selectedTimeIndex[1] &&
        this.state.deliveryTime === "") ||
      (this.state.selectedAdressIndex[1] && this.state.takeTime === "") ||
      this.state.wrongTimePick.take;
    return renderCondition ? (
      <CartRow>
        <OutBox>
          <MediaQuery minDeviceWidth={680}>
            <BreadcrumbBar title="ตะกร้าสินค้า" />
          </MediaQuery>
        </OutBox>
        <OutBox>
          <SubTitleRow
            topic={true}
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img src={cartIcon} style={{ marginRight: "1rem" }} />
            <SubTitleText
              style={{
                fontWeight: "600",
                fontSize: "28px",
                fontStyle: "normal",
              }}
            >
              ตะกร้าสินค้า
            </SubTitleText>
          </SubTitleRow>
        </OutBox>
        <OutBox>
          <SubTitleText
            option
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "1rem",
            }}
          >
            สรุปรายการสินค้า{" "}
          </SubTitleText>
        </OutBox>
        <MediaQuery minDeviceWidth={680}>
          <div style={{ width: "90%" }}>
            <TableHeader />
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={680}>
          <div style={{ width: "100%" }}>
            <RowDivider />
            <TableHeader />
          </div>
        </MediaQuery>
        <RowDivider />
        {menu.map((item, index) => (
          <>
            <CartItem
              item={item}
              number={index}
              editable={true}
              onDelete={() => this.onDelete(index)}
              onEditMenu={() => {
                localStorage.setItem(
                  localName.PHONE_NUMBER,
                  this.state.customerPhone
                );
                this.onEditMenu(item.productId, index);
              }}
            />
            <RowDivider />
          </>
        ))}

        {/* เมื่อกดแก้ไข Redirect ไป /menu/(productId) และส่ง state cartIndex แสดง index ของสินค้าที่จะแก้ไข*/}
        {this.state.editMenu ? (
          <Redirect
            to={{
              pathname: this.state.editProductPath,
              state: { cartIndex: this.state.editCartIndex },
            }}
          />
        ) : (
          <div />
        )}
        <FlexBox>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <SubTitleText
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "1rem",
              }}
              option
            >
              ข้อมูลผู้สั่ง
            </SubTitleText>
            {!signedIn && (
              <MenuText
                style={{
                  fontWeight: "400",
                  color: "red",
                  marginLeft: "10px",
                  alignSelf: "flex-start",
                }}
              >
                *** กรุณาล็อกอินเพื่อกดสั่งอาหาร
              </MenuText>
            )}
          </div>
          <FlexBox padding={".5rem 0 1rem 0"} />
          <SubTitleRow style={{ justifyContent: "initial" }}>
            <MediaQuery minDeviceWidth={680}>
              <SubTitleText option style={{ width: "25%" }}>
                <div>ชื่อ</div>
                <DetailText>{this.state.customerName}</DetailText>
              </SubTitleText>
              {/* <SubTitleText option style={{ width: "33%" }}>
              <div>อีเมล</div>
              <DetailText>{this.state.customerEmail}</DetailText>
            </SubTitleText> */}
              <SubTitleText option style={{ width: "33%" }}>
                <div>เบอร์โทรศัพท์</div>
                <Form
                  ref={this.formRef}
                  onFinish={() => this.onSubmit(this)}
                  onFinishFailed={() => this.onSubmitFailed(this)}
                >
                  <FontText
                    id="phone"
                    name="phone"
                    autofocus
                    validateFirst
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        this.setState({ customerPhone: e.target.value });
                      }
                    }}
                    normalize={(value, prevValue, prevValues) => {
                      let checkValue = /^[0-9]{0,10}$/.test(value);
                      if (checkValue) {
                        return value;
                      } else {
                        return prevValue;
                      }
                    }}
                    rules={[
                      {
                        required: true,
                        message:
                          "กรุณาใส่เบอร์โทรศัพท์เพื่อให้ร้านค้าหรือคนขับสามารถติดต่อได้",
                      },
                      {
                        type: "string",
                        min: 10,
                        max: 10,
                        message: "โปรดระบุเบอร์โทรศัพท์ที่ถูกต้อง",
                      },
                    ]}
                  >
                    <InputSize disabled={!signedIn} />
                  </FontText>
                </Form>
              </SubTitleText>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={680}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SubTitleText option style={{ width: "58%" }}>
                  <div>ชื่อ</div>
                  <DetailText>{this.state.customerName}</DetailText>
                </SubTitleText>
                {/* <SubTitleText option style={{ width: "33%" }}>
              <div>อีเมล</div>
              <DetailText>{this.state.customerEmail}</DetailText>
            </SubTitleText> */}
                <SubTitleText option style={{ width: "40%" }}>
                  <div>เบอร์โทรศัพท์</div>
                  <Form
                    ref={this.formRef}
                    onFinish={() => this.onSubmit(this)}
                    onFinishFailed={() => this.onSubmitFailed(this)}
                  >
                    <FontText
                      id="phone"
                      name="phone"
                      validateFirst
                      style={{ width: "20rem" }}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          this.setState({ customerPhone: e.target.value });
                        }
                      }}
                      normalize={(value, prevValue, prevValues) => {
                        let checkValue = /^[0-9]{0,10}$/.test(value);
                        if (checkValue) {
                          return value;
                        } else {
                          return prevValue;
                        }
                      }}
                      rules={[
                        {
                          required: true,
                          message:
                            "กรุณาใส่เบอร์โทรศัพท์เพื่อให้ร้านค้าหรือคนขับสามารถติดต่อได้",
                        },
                        {
                          type: "string",
                          min: 10,
                          max: 10,
                          message: "โปรดระบุเบอร์โทรศัพท์ที่ถูกต้อง",
                        },
                      ]}
                    >
                      <InputSize disabled={!signedIn} />
                    </FontText>
                  </Form>
                </SubTitleText>
              </div>
            </MediaQuery>
          </SubTitleRow>
          <FlexBox padding={".5rem 0 1rem 0"} />
        </FlexBox>

        <RowDivider />
        {/* ส่ง Delivery */}
        {/* เช็คว่ามีที่อยู่อยู่แล้วหรือไม่ ถ้าไม่มี ให้เพิ่มที่อยู่ใหม่ ถ้ามี เช็คล็อกอิน ถ้าล็อกอิน เช็คว่า localStorage ว่างหรือเปล่า ถ้าว่าง ดึงมาจาก backend ถ้าไม่ว่าง (แก้ไขที่อยู่) ดึงมาจาก localStorage */}
        {Underscore.isEmpty(this.state.addresses) ||
        this.state.addresses === null ? (
          <FlexBox>
            <SubTitleText
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "1rem",
              }}
              option
            >
              {" "}
              จัดส่งที่{" "}
            </SubTitleText>
            <FlexBox padding={".5rem 0 1rem 0"} />
            <AddressBox
              style={{ justifyContent: "space-between", paddingLeft: "4%" }}
            >
              <SubTitleRow style={{ alignItems: "center" }}>
                <Checkbox
                  checked={this.state.selectedAdressIndex[0]}
                  onClick={() => {
                    this.handleAddressBoxChecked(0);
                  }}
                />
                <SubTitleText option style={{ marginLeft: "1rem" }}>
                  เลือกสถานที่จัดส่ง
                </SubTitleText>
                <EditText
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#FF0000",
                    marginLeft: "1.5rem",
                  }}
                  onClick={() => {
                    localStorage.setItem(
                      localName.PHONE_NUMBER,
                      this.state.customerPhone
                    );
                    this.editAddress("add");
                  }}
                >
                  + เพิ่มที่อยู่ใหม่
                </EditText>
              </SubTitleRow>
            </AddressBox>
          </FlexBox>
        ) : // เมื่อกดเลือกให้ส่งแบบ Delivery
        this.state.selectedAdressIndex[0] ? (
          <FlexBox>
            <SubTitleText
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "1rem",
              }}
              option
            >
              {" "}
              จัดส่งที่{" "}
            </SubTitleText>
            <FlexBox padding={".5rem 0 1rem 0"} />
            <AddressBox
              style={{
                flexDirection: "column",
                padding: "0 0 0 0",
                wordWrap: "break-word",
              }}
            >
              <FlexRow
                style={{
                  display: "flex",
                  alignItems: "start",
                  padding: "1rem 1rem 0 4%",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <SubTitleRow>
                  <Checkbox
                    checked={this.state.selectedAdressIndex[0]}
                    onClick={() => {
                      this.handleAddressBoxChecked(0);
                    }}
                  />
                  <SubTitleText option style={{ marginLeft: "1rem" }}>
                    เลือกสถานที่จัดส่ง
                  </SubTitleText>
                </SubTitleRow>
                <FlexRow changeAddress>
                  <EditOutlined style={{ color: "#FFB800" }} />
                  <EditText
                    style={{
                      marginLeft: ".5rem",
                      fontWeight: "400",
                      color: "#FFB800",
                    }}
                    onClick={() => {
                      localStorage.setItem(
                        localName.PHONE_NUMBER,
                        this.state.customerPhone
                      );
                      this.editAddress("add");
                    }}
                  >
                    เปลี่ยนแปลงที่อยู่
                  </EditText>
                </FlexRow>
              </FlexRow>
              <StyledDivider2
                style={{ border: "#DEDEDE", margin: "1rem 0 0 0" }}
              />
              <FlexBox
                style={{ padding: "0 1% 0 4%", wordBreak: "break-word" }}
              >
                <FlexRow style={{ margin: "1rem 0 .35rem 0" }}>
                  <SubTitleText style={{ fontSize: "25px", fontWeight: "600" }}>
                    {this.state.addresses.name}
                  </SubTitleText>
                </FlexRow>
                <FlexRow style={{ marginBottom: ".25rem" }}>
                  <SubTitleText
                    style={{
                      fontSize: "20px",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {this.state.addresses.addressLine +
                      " " +
                      this.state.addresses.district +
                      " " +
                      this.state.addresses.subDistrict +
                      " " +
                      this.state.addresses.province +
                      " " +
                      this.state.addresses.postalCode}
                  </SubTitleText>
                </FlexRow>
                <FlexRow
                  style={{
                    marginBottom: "1rem",
                    width: "50%",
                    wordWrap: "break-word",
                  }}
                >
                  <SubTitleText
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      color: "#B57A08",
                      wordWrap: "break-word",
                    }}
                  >
                    {this.state.addresses.comment === null ||
                    this.state.addresses.comment === undefined
                      ? "ถึงผู้ส่ง: -"
                      : "ถึงผู้ส่ง: " + this.state.addresses.comment}
                  </SubTitleText>
                </FlexRow>
                {/* <FlexRow style={{ marginBottom: "1rem" }}>
                  <SubTitleText style={{ fontSize: "16px" }}>
                    เลือกเวลา
                  </SubTitleText>
                </FlexRow>
                <FlexRow
                  style={{ marginBottom: ".5rem", alignItems: "center" }}
                >
                  <div style={{ marginLeft: "1.5rem" }} />
                  <Checkbox
                    checked={this.state.selectedTimeIndex[0]}
                    onClick={() => {
                      this.handleTimeChecked(0);
                    }}
                  />
                  <SubTitleText style={{ marginLeft: "1rem" }}>
                    จัดส่งทันที
                  </SubTitleText>
                  <div style={{ marginLeft: "3rem" }} />
                  <Checkbox
                    checked={this.state.selectedTimeIndex[1]}
                    onClick={() => {
                      this.handleTimeChecked(1);
                    }}
                  />
                  <SubTitleText style={{ margin: "0 1rem 0 1rem" }}>
                    จัดส่งเวลา
                  </SubTitleText>
                  <Dropdown
                    disabled={this.state.selectedTimeIndex[0]}
                    overlay={deliveryTimeMenu}
                  >
                    <Button Primary>
                      <FlexRow style={{ alignItems: "center" }}>
                        <SubTitleText
                          style={{ marginRight: "1rem", width: "3rem" }}
                        >
                          {this.state.deliveryTime}
                        </SubTitleText>
                        <DownOutlined />
                      </FlexRow>
                    </Button>
                  </Dropdown>
                </FlexRow> 
                <FlexRow style={{ marginBottom: "1rem", alignItems: "center" }}>
                  <div style={{ marginLeft: "18rem" }} />
                  {this.state.wrongTimePick.delivery ? (
                    <MenuText
                      style={{
                        fontSzie: "14px",
                        fontWeight: "normal",
                        color: "#DF5151",
                      }}
                    >
                      กรุณาสั่งอาหารก่อนรอบจัดส่ง 30 นาที
                    </MenuText>
                  ) : (
                    <></>
                  )}
                </FlexRow>*/}
              </FlexBox>
            </AddressBox>
          </FlexBox>
        ) : (
          <FlexBox>
            <SubTitleText
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "1rem",
              }}
              option
            >
              {" "}
              จัดส่งที่{" "}
            </SubTitleText>
            <FlexBox padding={".5rem 0 1rem 0"} />
            <AddressBox
              style={{ justifyContent: "space-between", paddingLeft: "4%" }}
            >
              <SubTitleRow style={{ alignItems: "center" }}>
                <Checkbox
                  checked={this.state.selectedAdressIndex[0]}
                  onClick={() => {
                    this.handleAddressBoxChecked(0);
                  }}
                />
                <SubTitleText option style={{ marginLeft: "1rem" }}>
                  เลือกสถานที่จัดส่ง
                </SubTitleText>
              </SubTitleRow>
            </AddressBox>
          </FlexBox>
        )}
        <FlexBox padding=".5rem" />
        {/* รับเองที่ร้าน */}
        {/* {this.state.selectedAdressIndex[1] ? (
          <FlexBox>
            <AddressBox style={{ flexDirection: "column", padding: "0 0 0 0" }}>
              <FlexRow
                style={{
                  display: "flex",
                  alignItems: "start",
                  padding: "1rem 1rem 0 4%",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <SubTitleRow>
                  <Checkbox
                    checked={this.state.selectedAdressIndex[1]}
                    onClick={() => {
                      this.handleAddressBoxChecked(1);
                    }}
                  />
                  <SubTitleText option style={{ marginLeft: "1rem" }}>
                    รับเองที่ร้าน
                  </SubTitleText>
                </SubTitleRow>
              </FlexRow>
              <StyledDivider2
                style={{ border: "#DEDEDE", margin: "1rem 0 0 0" }}
              />
              <FlexRow style={{ padding: "0 0 0 4%" }}>
                <FlexBox>
                  <FlexRow style={{ marginBottom: "1rem" }}>
                    <SubTitleText>เลือกวันที่จะรับ</SubTitleText>
                  </FlexRow>
                  <DatePicker
                    allowClear={false}
                    inputReadOnly={true}
                    format={
                      datetime.take.selectDate == null
                        ? "DD/MM/YYYY"
                        : "DD/MM/YYYY".replace(
                            "YYYY",
                            Number(datetime.take.selectDate.format("YYYY")) +
                              543
                          )
                    }
                    defaultValue={datetime.take.selectDate}
                    disabledDate={this.disabledDate}
                    style={{ marginBottom: "1rem" }}
                    onChange={this.onDatepick}
                  />
                </FlexBox>
                <FlexBox style={{ marginLeft: "3rem" }}>
                  <FlexRow style={{ marginBottom: "1rem" }}>
                    <SubTitleText>เลือกรอบเวลาที่จะไปรับ</SubTitleText>
                  </FlexRow>
                  <Dropdown overlay={takeTimeMenu} width="80%">
                    <Button Primary>
                      <FlexRow
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <SubTitleText style={{ marginRight: "1rem" }}>
                          {this.state.takeTime}
                        </SubTitleText>
                        <DownOutlined />
                      </FlexRow>
                    </Button>
                  </Dropdown>
                </FlexBox>
              </FlexRow>
              <FlexRow style={{ marginBottom: "1rem", alignItems: "center" }}>
                <div style={{ marginLeft: "15rem" }} />
                {this.state.wrongTimePick.take ? (
                  <MenuText
                    style={{
                      fontSzie: "14px",
                      fontWeight: "normal",
                      color: "#DF5151",
                    }}
                  >
                    กรุณาเลือกวันเวลาให้ถูกต้อง
                  </MenuText>
                ) : (
                  <></>
                )}
              </FlexRow>
            </AddressBox>
          </FlexBox>
        ) : (
          <AddressBox style={{ paddingLeft: "4%" }}>
            <Checkbox
              checked={this.state.selectedAdressIndex[1]}
              onClick={() => {
                this.handleAddressBoxChecked(1);
              }}
            />
            <SubTitleText option style={{ marginLeft: "1rem" }}>
              รับเองที่ร้าน
            </SubTitleText>
          </AddressBox>
        )} */}
        <MediaQuery minDeviceWidth={680}>
          <FlexBox padding={"0 0 2rem 0"} />
          <SubTitleText
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "1rem",
            }}
            option
          >
            {" "}
            สรุปรายการเงิน{" "}
          </SubTitleText>
          <SubTitleRow style={{ paddingTop: "1rem" }}>
            <MenuText style={{ fontWeight: "400" }}>รวมรายการ</MenuText>
            <MenuText style={{ fontWeight: "400" }}>
              {renderMoney(this.calculatePrice()) + " ฿"}
            </MenuText>
          </SubTitleRow>
          <SubTitleRow>
            <MenuText style={{ fontWeight: "400" }}>ค่าส่ง</MenuText>
            <MenuText style={{ fontWeight: "400" }}>
              {renderMoney(this.state.deliveryCost) + " ฿"}
            </MenuText>
          </SubTitleRow>
          <StyledDivider2 />
          <SubTitleRow>
            <MenuText style={{ fontWeight: "500" }}>รวมทั้งหมด</MenuText>
            <MenuText style={{ fontSize: "2rem", fontWeight: "600" }}>
              {renderMoney(this.calculatePrice() + this.state.deliveryCost) +
                " ฿"}
            </MenuText>
          </SubTitleRow>
          <FlexBox padding={"4rem 0 0 0"} />
          <SubTitleRow>
            <div />
            <MenuText style={{ fontWeight: "400", color: "#FFB800" }}>
              คลิก "ชำระเงิน" เพื่อเข้าสู่ขั้นตอนการชำระเงิน
            </MenuText>
          </SubTitleRow>
          <FlexBox padding={"1rem 0 0 0"} />

          {/* ปุ่มกดสั่งอาหาร */}
          <SubTitleRow style={{ alignItems: "center" }}>
            <ButtonReturn
              onClick={() => {
                localStorage.removeItem(localName.PHONE_NUMBER);
                redirectTo("/home");
              }}
            >
              กลับหน้าหลัก
            </ButtonReturn>
            {/* กดได้เมื่อ 1.เลือกสั่ง delivery 2.ล็อกอินแล้ว+เลือกสถานที่จัดส่งแล้ว*/}
            <ButtonSubmit
              onClick={() => {
                this.formRef.current.submit();
                // this.onSubmit();
                localStorage.removeItem(localName.PHONE_NUMBER);
              }}
              disabled={disableSubmit}
            >
              ชำระเงิน
            </ButtonSubmit>
          </SubTitleRow>

          {/* ข้อความเมื่อสั่งไม่ได้ */}
          {signedIn &&
          Underscore.isEmpty(this.state.addresses) &&
          this.state.selectedAdressIndex[0] ? (
            <SubTitleRow>
              <div />
              <MenuText style={{ fontWeight: "400", color: "red" }}>
                *** กรุณาเพิ่มที่อยู่เพื่อกดสั่งอาหาร
              </MenuText>
            </SubTitleRow>
          ) : (this.state.selectedAdressIndex[0] &&
              this.state.selectedTimeIndex[1] &&
              this.state.deliveryTime === "") ||
            (this.state.selectedAdressIndex[1] &&
              this.state.takeTime === "") ? (
            <SubTitleRow>
              <div />
              <MenuText style={{ fontWeight: "400", color: "red" }}>
                *** กรุณาระบุเวลารับส่งอาหาร
              </MenuText>
            </SubTitleRow>
          ) : (
            <></>
          )}
        </MediaQuery>
        <MediaQuery maxDeviceWidth={680}>
          <div
            style={{
              position: "sticky",
              bottom: "0",
              backgroundColor: "white",
            }}
          >
            <RowDivider />
            <FlexBox
              style={{
                marginBottom: "-3%",
              }}
            />
            {/* <SubTitleText
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "1rem",
              }}
              option
            >
              {" "}
              สรุปรายการเงิน{" "}
            </SubTitleText> */}
            <SubTitleRow>
              <MenuText style={{ fontWeight: "400" }}>รวมรายการ</MenuText>
              <MenuText style={{ fontWeight: "400" }}>
                {renderMoney(this.calculatePrice()) + " ฿"}
              </MenuText>
            </SubTitleRow>
            <SubTitleRow>
              <MenuText style={{ fontWeight: "400" }}>ค่าส่ง</MenuText>
              <MenuText style={{ fontWeight: "400" }}>
                {renderMoney(this.state.deliveryCost) + " ฿"}
              </MenuText>
            </SubTitleRow>
            <StyledDivider2 style={{ marginBottom: "0%" }} />
            <SubTitleRow
              style={{ alignItems: "baseline", marginBottom: "-3%" }}
            >
              <MenuText style={{ fontWeight: "500", color: "#4F4F4F" }}>
                ราคาสุทธิ
              </MenuText>
              <MenuText
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  color: "#FFB800",
                }}
              >
                {renderMoney(this.calculatePrice() + this.state.deliveryCost) +
                  " บาท"}
              </MenuText>
            </SubTitleRow>
            <FlexBox />

            {/* <ButtonReturn onClick={() => redirectTo("/home")}>
                กลับหน้าหลัก
              </ButtonReturn> */}
            {/* กดได้เมื่อ 1.เลือกสั่ง delivery 2.ล็อกอินแล้ว+เลือกสถานที่จัดส่งแล้ว*/}
            {/* ปุ่มกดสั่งอาหาร */}
            {/* ข้อความเมื่อสั่งไม่ได้ */}
            {signedIn &&
            Underscore.isEmpty(this.state.addresses) &&
            this.state.selectedAdressIndex[0] ? (
              <SubTitleRow>
                <div />
                <MenuText style={{ fontWeight: "400", color: "red" }}>
                  *** กรุณาเพิ่มที่อยู่เพื่อกดสั่งอาหาร
                </MenuText>
              </SubTitleRow>
            ) : (this.state.selectedAdressIndex[0] &&
                this.state.selectedTimeIndex[1] &&
                this.state.deliveryTime === "") ||
              (this.state.selectedAdressIndex[1] &&
                this.state.takeTime === "") ? (
              <SubTitleRow>
                <div />
                <MenuText style={{ fontWeight: "400", color: "red" }}>
                  *** กรุณาระบุเวลารับส่งอาหาร
                </MenuText>
              </SubTitleRow>
            ) : (
              <></>
            )}
            <ButtonSubmit
              onClick={() => {
                this.formRef.current.submit();
                // this.onSubmit();
                localStorage.removeItem(localName.PHONE_NUMBER);
              }}
              disabled={disableSubmit}
              style={{ position: "sticky", bottom: "0" }}
            >
              ชำระเงิน
            </ButtonSubmit>
          </div>
        </MediaQuery>

        {this.state.toPayment ? (
          <Redirect
            to={{
              pathname: `${subDomain}/cart/payment`,
              state: { order: order, paymentDetail: paymentDetail },
            }}
          />
        ) : null}

        {/* แสดง modal เมื่อตะกร้าว่าง */}
        <Modal
          visible={Underscore.isEmpty(this.state.item)}
          centered={true}
          closable={false}
          footer={[
            <ModalBox centered>
              <Text
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => {
                  this.setState({ visibleModal: false });
                  redirectTo(`/home`);
                }}
              >
                ปิด
              </Text>
            </ModalBox>,
          ]}
          width={"30rem"}
        >
          <ModalBox>
            <Text style={{ color: "black" }}>
              ไม่มีสินค้าในตะกร้า กรุณาเพิ่มสินค้า
            </Text>
          </ModalBox>
        </Modal>
      </CartRow>
    ) : (
      <></>
    );
  }
}

export default Cart;
