import React from 'react';
import styled from 'styled-components';
import Button from '../../pages/stores/components/button';
import NotFoundImage from '../../assets/image/notFoundImage.svg';
import { redirectTo } from '../../services/redirect';

const Page = styled.div`
    font-family: Kanit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
`
const BigText = styled.text`
    margin-bottom: 1.75rem;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 1.75rem;
    text-align: center;
    letter-spacing: 0.1px;
    color: #333333;
`

const Text1 = styled.text`
    font-style: normal;
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 1.75rem;
    text-align: center;
    letter-spacing: 0.1px;
    color: #333333;
`
const Text2 = styled.text`
    margin-top: 1.75rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    letter-spacing: 0.1px;

    color: #919191;
`

const HomeButton = styled.div`
    margin-top: 2rem;
`

export default function notFoundPage() {
    return (
        <Page>
            <Content>
                <BigText>
                    อุ๊ปส์!
                </BigText>
                <img src={NotFoundImage} />
                <Text1>
                    ขออภัยไม่พบหน้าที่คุณต้องการ
                </Text1>
                <Text2>
                    กรุณาตรวจสอบลิ้งค์ที่คุณเข้ามา หรือ กลับไปยังหน้าแรก
                </Text2>
                <HomeButton>
                    <Button type='alt2' onClick={() => redirectTo('/home')} style={{ color: 'white', height: '3rem', width: '22.3rem' }} >
                        <text>กลับไปยังหน้าแรก</text>
                    </Button>
                </HomeButton>
            </Content>
        </Page>
    );
}

