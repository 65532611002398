import React from "react";
import styled from "styled-components";
import { Breadcrumb, Divider } from "antd";
import { colors } from "../../../values/colors";
import { Text } from "./sharedComponents";
import { subDomain } from "../../../services/redirect";
import MediaQuery from "react-responsive";

const OutBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
`;

const OutBoxMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: space-between;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  @media not screen and (min-device-width: 1224px) {
    margin-top: 0rem;
    flex-wrap: wrap;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const BreadcrumbItem = styled(Breadcrumb.Item)`
  font-family: "Kanit";
  font-size: 1em;
  line-height: 1.5em;
  color: ${(props) =>
    props.clickable ? colors.buttonColor : colors.textColor1};
`;

const StyledDivider = styled(Divider)`
  border: 0.7px solid ${colors.buttonColor};
  background: ${colors.buttonColor};
  margin-top: 0.5rem;
  height: 1px;
`;

const BreadcrumbModify = styled(Breadcrumb)`
  @media not screen and (min-device-width: 1224px) {
    display: none;
  }
`;

const OptionDiv = styled.div`
  @media not screen and (min-device-width: 1224px) {
    justify-content: flex-end;
    width: 100%;
    display: flex;
  }
`;

export const BreadcrumbBar = ({ items, title }) => {
  if (!items || !items.length) items = [];
  return (
    <BreadcrumbModify separator=">">
      <BreadcrumbItem href={`${subDomain}/home`}> หน้าหลัก </BreadcrumbItem>
      {items.map((item) => (
        <BreadcrumbItem href={item.path ? item.path : null}>
          {item.name}
        </BreadcrumbItem>
      ))}
      <BreadcrumbItem> {title} </BreadcrumbItem>
    </BreadcrumbModify>
  );
};

export const TitleBar = (props) => {
  return (
    <>
      <TitleRow>
        <Row>
          <Text title>
            {props.img ? (
              <span style={{ paddingRight: "1rem " }}>
                <img src={props.img} />
              </span>
            ) : (
              <></>
            )}
            {props.title}
          </Text>
        </Row>
        <OptionDiv> {props.option} </OptionDiv>
      </TitleRow>
    </>
  );
};

export const Title = ({ breadcrumbItems, title, option, img }) => {
  return (
    <div>
      <MediaQuery minDeviceWidth={680}>
        <OutBox>
          <BreadcrumbBar items={breadcrumbItems} title={title} />
          <TitleBar title={title} option={option} img={img} />
        </OutBox>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={680}>
        <OutBox>
          <TitleBar title={title} option={option} img={img} />
        </OutBox>
      </MediaQuery>
    </div>
  );
};

export const TitleWithOutBreadCrumb = ({ title, option, img }) => {
  return (
    <OutBoxMobile>
      <TitleBar title={title} option={option} img={img} />
    </OutBoxMobile>
  );
};
