import React from "react";
import { subDomain } from "../../../../services/redirect";
import { Title, TitleWithOutBreadCrumb } from "../../components/title";
export const MyAddressTitle = ({ startAt, pathLocalStorage, titleIcon }) => {
  return (
    <div style={{ padding: "0 10%" }}>
      <Title
        breadcrumbItems={
          startAt === `${subDomain}/cart`
            ? [
                {
                  name: "ตะกร้าสินค้า",
                  path: `${subDomain}/cart`,
                },
              ]
            : []
        }
        title={
          startAt === `${subDomain}/cart`
            ? "เปลี่ยนแปลงที่อยู่"
            : "ที่อยู่ของฉัน"
        }
        option={
          <div
            style={{
              position: "relative",
              top: "1rem",
              cursor: "pointer",
              color: "#FBB03B",
              fontFamily: "Kanit",
            }}
            onClick={() =>
              pathLocalStorage(
                "add",
                startAt === `${subDomain}/cart`
                  ? `${subDomain}/cart`
                  : `${subDomain}`
              )
            }
          >
            + เพิ่มที่อยู่
          </div>
        }
        img={titleIcon}
      />
    </div>
  );
};
export const MyAddressTitleWithOutBreadCrumb = ({
  startAt,
  pathLocalStorage,
  titleIcon,
}) => {
  return (
    <div style={{ padding: "0 10%", paddingTop: "2em" }}>
      <TitleWithOutBreadCrumb
        title={
          startAt === `${subDomain}/cart`
            ? "เปลี่ยนแปลงที่อยู่"
            : "ที่อยู่ของฉัน"
        }
        option={
          <div
            style={{
              position: "relative",
              top: "1rem",
              cursor: "pointer",
              color: "#FBB03B",
              fontFamily: "Kanit",
              fontSize: "16px",
            }}
            onClick={() =>
              pathLocalStorage(
                "add",
                startAt === `${subDomain}/cart`
                  ? `${subDomain}/cart`
                  : `${subDomain}`
              )
            }
          >
            + เพิ่มที่อยู่
          </div>
        }
        img={titleIcon}
      />
    </div>
  );
};
export default MyAddressTitle;
