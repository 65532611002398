import React from 'react';
import { store } from '../../../../services/api';

export default class OrderStatusPoller extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleFetched = this.handleFetched.bind(this);
        this.locked = false;

        this.poll = this.poll.bind(this);
    }

    startTimer() {
        this.stopTimer();
        const { interval } = this.props;
        this.intervalId = setInterval(this.fetch.bind(this), interval);
    }

    stopTimer() {
        clearInterval(this.intervalId);
    }

    componentDidMount() {
        if(!this.props.blocked) {
            this.startTimer();
        }
    }

    componentWillUnmount() {
        this.locked = true; // prevent request sent before unmount to take effect
        this.stopTimer();
    }

    componentDidUpdate(prevProps) {
        const { blocked: wasBlcoked } = prevProps;
        const { blocked: isBlocked } = this.props;

        if(Boolean(wasBlcoked) === Boolean(isBlocked)) {
            return;
        } else if(isBlocked) {
            this.block();
        } else {
            this.unblock();
        }
    }

    fetch() {
        const { onFailed, lastUpdateTime } = this.props;
        store.order.getAllUpdates(lastUpdateTime)
            .then(this.handleFetched)
            .catch(onFailed);
    }

    handleFetched(result) {
        const { onFetched } = this.props;
        if(!this.locked && onFetched) {
            onFetched(result);
        }
    }

    block() {
        this.locked = true;
        this.stopTimer();
    }

    unblock() {
        this.locked = false;
        this.fetch();
        this.startTimer();
    }

    poll() {
        this.stopTimer();
        this.fetch();
        this.startTimer();
    }

    render() {
        return null;
    }
}