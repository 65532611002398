import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form } from "antd";
import ResetForm from "../customers/components/resetPassword/resetForm";
import { customer as api } from "../../services/api";
import localName from "../../values/localStorageDict";
import SetupPasswordSuccessModal from "../customers/components/setupPassword/setupPasswordSuccessModal";

const OutsideBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 0 3% 0;
  @media not screen and (min-device-width: 1224px) {
    padding: 10% 0 10% 0;
  }
`;

const ContentBox = styled.div`
  padding: 2% 0 2% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  @media not screen and (min-device-width: 1224px) {
    width: 90%;
    padding: 5% 0 5% 0;
  }
`;

const ResetPassword = ({ match }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    localStorage.setItem(localName.ACCESS_TOKEN, match.params.tokenId);
  }, []);

  const onFinish = (val) => {
    const { password } = val;
    const payload = {
      password: password,
    };
    api.user.confirmForgotPassword(payload, (data) => {
      setVisibleModal(true);
    });
  };
  const onFinishFailed = (failed) => {
    // console.log("failed", failed);
  };

  return (
    <OutsideBox>
      <ContentBox>
        <ResetForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
        <SetupPasswordSuccessModal visibleSetupSuccessModal={visibleModal} />
      </ContentBox>
    </OutsideBox>
  );
};

export default ResetPassword;
