import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import ListSelectionContext from "./context";
import { colors } from "../../../../values/colors";

const Item = styled.div`
    user-select: none;
    margin-bottom: 16px;
    padding: 0.5em 1em;
    background-color: ${props => props.active ? colors.storeText : '#fff'};
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);

    font-size: 1rem;
    color: ${props => props.active ? '#fff' : colors.storeText};

    transition: background-color 0.4s ease, color 0.25s ease;
`;

/**
 * @typedef {Function} eventCallback
 * @param {Event} event
 * @returns {void}
 */

/**
 * @typedef {object} Props
 * @property {string} id
 * @property {number} index
 * @property {eventCallback} onClick
 *
 * @extends {React.Component<Props>}
 */
class DNDListItem extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { id, onClick } = this.props;

        if(this.context && this.context.select) {
            this.context.select(id);
        }

        if(onClick) {
            onClick(e);
        }
    }

    get active() {
        return Boolean(
            this.props.id && (
                (this.context && this.context.selectedId) === this.props.id
            )
        );
    }

    render() {
        const { id, index, children, style } = this.props;

        return (
            <Draggable key={id} draggableId={id} index={index} isDragDisabled={this.context.disabled}>
                {(provided, snapshot) => (
                    <Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        style={{
                            ...style,
                            ...provided.draggableProps.style,
                            // If DND is disabled, and onSelect is passed into DNDList
                            // show pointer cursor
                            ...this.context.disabled && !!this.context.select && {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={this.handleClick}
                        active={this.active}
                    >
                        {children}
                    </Item>
                )}
            </Draggable>
        );
    }
};

DNDListItem.contextType = ListSelectionContext;

export default DNDListItem;