import React from "react";
import { Table as AntdTable, Row } from "antd";
import styled from "styled-components";
import Button from "../button";
import { colors } from "../../../../values/colors";
import { ReceiptIcon, PrintIcon } from "../../../../components/icons";
import Link from "../link";
import { renderMoney } from "../../../../utils/money";

// #region Stying
const Table = styled(AntdTable)`
  word-break: break-word;
  & .ant-table-thead > tr > th {
    color: ${colors.storeText};
    font-size: 1rem;
    font-weight: 600;
  }

  & .ant-table-tbody > tr > td {
    color: ${colors.storeText};
    font-size: 0.875rem;
    border-bottom: none;
    padding: 0px 16px;
    height: 20px;
  }

  & .ant-table-tbody > tr:last-child > td,
  & .ant-table-tbody > tr.row-spanned > td {
    padding-bottom: 16px;
    height: auto;
  }

  & .ant-table-tbody > tr.entry > td {
    padding-top: 16px;
  }

  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: white;
  }

  & td {
    vertical-align: top;
  }

  & .ant-table-footer {
    padding: 0px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceSum = styled.div`
  display: flex;
  width: 26%;
  padding: 16px;

  font-weight: 600;

  & .label {
    font-size: 1.125rem;
    color: ${colors.storeText};
  }

  & .value {
    font-size: 1.125rem;
    margin-left: 5rem;
    color: ${colors.storeTextHightlighted};
  }
`;

const PaymentStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  color: ${colors.storeText};
`;

const ButtonStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    width: 100%;
  }

  & > button:not(:first-child) {
    margin-top: 0.75rem;
  }
`;

const Note = styled.span`
  color: #a5a5a5;
  word-break: break-word;
`;

const AfterIcon = styled.span`
  margin-left: 0.5rem;
`;
// #endregion

export const ROW_TYPES = Object.freeze({
  MENU: "menu",
  OPTION_LABEL: "opt_label",
  OPTION: "opt",
  CUSTOMER_NOTE: "cust_note",
});

export default class OrderDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      {
        width: "8%",
        key: "sequence",
        title: "ลำดับ",
        align: "right",
        render: (_, row) => (row.type === ROW_TYPES.MENU ? row.sequence : null),
      },
      {
        width: "20%",
        key: "description",
        dataIndex: "description",
        title: "รายการออเดอร์",
        render: (value, row) => {
          switch (row.type) {
            case ROW_TYPES.MENU:
            case ROW_TYPES.OPTION:
              return value;
            case ROW_TYPES.OPTION_LABEL:
              return "ตัวเลือกอื่น:";
            case ROW_TYPES.CUSTOMER_NOTE:
              return {
                children: <Note>{`เพิ่มเติมจากลูกค้า : ${value}`}</Note>,
                props: {
                  colSpan: 3,
                },
              };
            default:
              return null;
          }
        },
      },
      {
        width: "11%",
        key: "price",
        dataIndex: "price",
        align: "right",
        title: "ราคาต่อชิ้น",
        render: (value, row) => {
          switch (row.type) {
            case ROW_TYPES.MENU:
              for (var i = 0; i < row.options.length; i++) {
                value += row.options[i].price;
              }
              return renderMoney(value);
            case ROW_TYPES.OPTION:
            case ROW_TYPES.OPTION_LABEL:
            case ROW_TYPES.CUSTOMER_NOTE:
              return {
                children: undefined,
                props: {
                  colSpan: 0,
                },
              };
            default:
              return null;
          }
        },
      },
      {
        width: "9%",
        key: "quantity",
        dataIndex: "quantity",
        align: "right",
        title: "จำนวน",
        render: (value, row) => {
          if (row.type === ROW_TYPES.CUSTOMER_NOTE) {
            return {
              children: undefined,
              props: {
                colSpan: 0,
              },
            };
          }
          return value;
        },
      },
      {
        width: "11%",
        key: "price",
        dataIndex: "price",
        align: "right",
        title: "ราคา(บาท)",
        render: (value, row) => {
          switch (row.type) {
            case ROW_TYPES.MENU:
              for (var i = 0; i < row.options.length; i++) {
                value += row.options[i].price;
              }
              return renderMoney(value * row.quantity);
            case ROW_TYPES.OPTION:
            case ROW_TYPES.OPTION_LABEL:
            case ROW_TYPES.CUSTOMER_NOTE:
              return {
                children: undefined,
                props: {
                  colSpan: 0,
                },
              };
            default:
              return null;
          }
        },
      },
      {
        width: "21%",
        key: "destination",
        title: "สถานที่จัดส่ง",
        className: "row-spanned",
        render: this.renderDestination.bind(this),
      },
      {
        width: "26%",
        key: "controls",
        title: "",
        className: "row-spanned",
        render: this.renderControls.bind(this),
      },
    ];
  }


  renderDestination(value, row, index) {
    const { order } = this.props;

    if (index === 0) {
      return {
        children:
          order.comment == null
            ? order.addressName
            : [
                order.addressName,
                <br></br>,
                <br></br>,
                <Note>{`ถึงผู้ส่ง : ${order.comment}`}</Note>,
              ],
        props: {
          rowSpan: order.tableData.length,
        },
      };
    }

    return {
      children: undefined,
      props: {
        rowSpan: 0,
      },
    };
  }

  renderControls(value, row, index) {
    if (index === 0) {
      const {
        stage,
        setOrderId,
        cancelOrder,
        order,
        confirmPayment,
        confirmDelivered,
        openQrCode,
      } = this.props;

      return {
        children: (
          <ButtonStack>
            {stage === 1 && (
              <Button type="primary" onClick={() => confirmPayment(order)}>
                {"ยืนยันการจ่ายเงิน"}
              </Button>
            )}
            {stage === 2 && (
              <Button type="primary">
                <PrintIcon color={"white"} width={"1rem"} height={"1rem"} />
                <AfterIcon>{"พิมพ์รายการอาหาร"}</AfterIcon>
              </Button>
            )}
            {(stage === 1 || stage === 2) && (
              <Button
                type="secondary"
                onClick={() => {
                  setOrderId(order.orderId);
                  cancelOrder();
                }}
              >
                {"ยกเลิกออเดอร์"}
              </Button>
            )}
            {stage === 3 && (
              <Button type="alt1" onClick={() => confirmDelivered(order)}>
                {"ยืนยันการส่งอาหาร"}
              </Button>
            )}
            {stage === 3 && (
              <Button
                type="highlight"
                onClick={() => openQrCode(order.driver[0].driverid)}
              >
                {"เปิดQR code"}
              </Button>
            )}
            {/* {stage === 3 && (
              <Button type="primary">{"กลับไปเลือกคนส่งใหม่"}</Button>
            )} */}
          </ButtonStack>
        ),
        props: {
          rowSpan: order.tableData.length,
        },
      };
    }

    return {
      children: undefined,
      props: {
        rowSpan: 0,
      },
    };
  }
  render() {
    const { order, showImage } = this.props;
    return (
      <Table
        pagination={false}
        columns={this.columns}
        dataSource={order.tableData}
        rowClassName={(row) => {
          return row.type === ROW_TYPES.MENU ||
            row.type === ROW_TYPES.CUSTOMER_NOTE
            ? "entry"
            : undefined;
        }}
        footer={() => (
          <Footer>
            <PaymentStatus>
              {!!order.slipImage && (
                <React.Fragment>
                  <ReceiptIcon
                    color={colors.storeText}
                    width={"1.25em"}
                    height={"1.25em"}
                  />
                  <AfterIcon>
                    {"หลักฐานการชำระเงิน : "}
                    <Link onClick={() => showImage(order.slipImage)}>
                      {"คลิกเพื่อดูรูปหลักฐาน"}
                    </Link>
                  </AfterIcon>
                </React.Fragment>
              )}
            </PaymentStatus>
            <PriceSum style={{ marginLeft: "18.5rem", width: "20%" }}>
              <span className="label">{"ค่าส่ง"}</span>
              <span
                style={{ marginLeft: "2rem" }}
                className="value"
              >{`${renderMoney(order.deliveryCost)} บาท`}</span>
            </PriceSum>
            <PriceSum>
              <span className="label">{"ราคาทั้งหมด:"}</span>
              <span className="value">{`${renderMoney(
                (
                  parseFloat(order.totalPrice) + parseFloat(order.deliveryCost)
                ).toFixed(2)
              )} บาท`}</span>
            </PriceSum>
          </Footer>
        )}
      />
    );
  }
}
