import localName from "../values/localStorageDict";

function isSameItem(item1, item2) {
  if (
    item1.productId !== item2.productId ||
    item1.pricePerUnit !== item2.pricePerUnit ||
    item1.comment !== item2.comment
  ) {
    return false;
  }
  if (item1.choices.length !== item2.choices.length) {
    return false;
  }
  for (var i = 0; i < item1.choices.length; i++) {
    if (item1.choices[i].choiceId !== item2.choices[i].choiceId) {
      return false;
    }
  }
  if (item1.comment !== item2.comment) {
    return false;
  }
  return true;
}

const addToCart = (cartItem, isUpdateCart, cartIndex, isPopup) => {
  var currentCart;
  if (isPopup) {
    currentCart = localStorage.getItem(localName.RESERVE_CART);
  } else {
    currentCart = localStorage.getItem(localName.CART);
  }
  var cart =
    currentCart && currentCart.length > 0 ? JSON.parse(currentCart) : [];
  var itemAdded = false;
  for (var i = 0; i < cart.length; i++) {
    if (isSameItem(cart[i], cartItem)) {
      if (!isUpdateCart) {
        cart[i].amount += cartItem.amount;
        itemAdded = true;
      }
      break;
    }
  }
  if (!itemAdded) {
    if (isUpdateCart) {
      cart[cartIndex] = cartItem;
    } else {
      cart[cart.length] = cartItem;
    }
  }
  if (isPopup) {
    localStorage.setItem(localName.RESERVE_CART, JSON.stringify(cart));
  } else {
    localStorage.setItem(localName.CART, JSON.stringify(cart));
  }
  // console.log(cart);
};

export default addToCart;
