import React from "react";
import { Input, Empty, message } from "antd";
import styled from "styled-components";
import Modal from "../modal";
import Button from "../button";
import DashBox from "../dashBox";
import DNDList from "../dndList";
import OptionFace from "../menu/optionFace";
import { store } from "../../../../services/api";

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 1.5rem;

  & button {
    width: 6em;
  }
`;

const Container = styled.div`
  padding-top: 1rem;
`;

const OptionsContainer = styled.div`
  margin-top: 1rem;
`;

const OptionsList = styled(DNDList)`
  max-height: 190px;
  overflow-y: auto;
`;

const StyledDashBox = styled(DashBox)`
  padding: 8px;
  margin-top: 0.5rem;
`;

export default class OptionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      options: undefined,
      selectedOptions: undefined,
      selectedOptionIds: undefined,
      resolve: undefined,
      search: undefined,
    };

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.fetch = this.fetch.bind(this);

    this.addOption = this.addOption.bind(this);
    /* TODO remove mock variable below */
    this.MOCK_OPTION_ID_SEQ = 0;
  }

  fetch() {
    store.product.fetchOption().then((data) => {
      this.setState({
        options: [...data],
      });
    });
  }

  componentDidMount() {
    this.fetch();
  }

  async addOption() {
    const { optionModalRef } = this.props;
    const [option, confirmed] = await optionModalRef.current.getOption();

    if (confirmed) {
      /* TODO CALL API and UPDATE LIST */
      let choices = [];
      for (let i = 0; i < option.choices.length; i++) {
        choices.push(option.choices[i].choiceId);
      }
      try {
        store.product
          .addOption({
            nameTh: option.nameTh,
            nameEn: option.nameEn,
            isRequired: !!option.isRequired ? option.isRequired : false,
            choices: choices,
          })
          .then(() => {
            this.fetch();
            message.success("ดำเนินการสำเร็จ");
          });
      } catch (err) {
        message.error("เพิ่มตัวเลือกเพิ่มเติมไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      }
      /* TODO remove mock code below */
      const updatedOptions = this.state.options ? [...this.state.options] : [];
      updatedOptions.push({ ...option, optionId: ++this.MOCK_OPTION_ID_SEQ });
      this.setState({ options: updatedOptions });
    }
  }

  async editOption(index) {
    const initialValues = this.state.options[index];

    const { optionModalRef } = this.props;
    const [newValues, confirmed] = await optionModalRef.current.getOption(
      initialValues
    );

    if (confirmed) {
      /* TODO CALL API and UPDATE LIST */
      let choices = [];
      for (let i = 0; i < newValues.choices.length; i++) {
        choices.push(newValues.choices[i].choiceId);
      }
      try {
        store.product
          .editOption({
            optionId: newValues.optionId,
            nameTh: newValues.nameTh,
            nameEn: newValues.nameEn,
            isRequired: !!newValues.isRequired ? newValues.isRequired : false,
            choices: choices,
          })
          .then(() => {
            this.fetch();
            if (choices.length === 0) {
              this.handleSelect(index, false);
            } else {
              if (
                Boolean(this.state.selectedOptionIds) &&
                this.state.selectedOptionIds.includes(newValues.optionId)
              ) {
                const allItem = this.state.selectedOptions.filter(
                  ({ optionId: id }) => id !== newValues.optionId
                );
                const item = this.state.selectedOptions.filter(
                  (i) => i.optionId === newValues.optionId
                );
                this.setState({
                  selectedOptions: [
                    ...allItem,
                    {
                      ...item[0],
                      choices: newValues.choices,
                    },
                  ],
                });
              }
            }
            message.success("ดำเนินการสำเร็จ");
          });
      } catch (err) {
        message.error("แก้ไขตัวเลือกเพิ่มเติมไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      }
    }
  }

  async deleteOption(id) {
    /* TODO CALL API and UPDATE LIST */
    const { confirmModalRef } = this.props;
    const confirmed = await confirmModalRef.current.getConfirmation({
      title: "ยืนยันการลบตัวเลือกเพิ่มเติม",
    });

    if (confirmed) {
      //TODO: call api delete then fetch
      try {
        await store.product.deleteOption(id).then((data) => {
          var ids = [];
          var options = [];
          for (let i = 0; i < this.state.selectedOptionIds.length; i++) {
            if (this.state.selectedOptionIds[i] != id) {
              ids.push(this.state.selectedOptionIds[i]);
              options.push(this.state.selectedOptions[i]);
            }
          }
          this.setState({ selectedOptionIds: ids, selectedOptions: options });
          this.fetch();
          message.success("ดำเนินการสำเร็จ");
          let secondsToGo = 1;
          const timer = setInterval(() => {
            secondsToGo -= 1;
          }, 1000);
          setTimeout(() => {
            clearInterval(timer);
          }, secondsToGo * 1000);
        });
      } catch (err) {
        message.error("ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
      }
    }
  }

  // #region Instance Methods
  async getOptions(selectedOptions) {
    // Setup promise and Show modal
    let modalResolve = undefined;
    const modalPromise = new Promise((resolve) => {
      modalResolve = resolve;
    });

    this.setState({
      visible: true,
      resolve: modalResolve,
      selectedOptions,
      selectedOptionIds:
        selectedOptions && selectedOptions.map(({ optionId }) => optionId),
    });

    // Wait Confirmation
    const confirmed = await modalPromise;

    // Clean promise and Close modal
    this.setState({
      visible: false,
      resolve: null,
    });

    // Return result
    if (confirmed) {
      return [this.state.selectedOptions, confirmed];
    } else {
      return [selectedOptions, confirmed];
    }
  }
  // #endregion

  // #region Event Handlers
  handleConfirm() {
    if (this.state.resolve) {
      this.state.resolve(true);
    }
  }

  handleCancel() {
    if (this.state.resolve) {
      this.state.resolve(false);
    }
    this.setState({
      search: undefined,
    });
  }

  handleSelect(index, selected) {
    const option = this.state.options[index];
    const optionId = option.optionId;
    if (selected) {
      this.setState({
        selectedOptions: [...(this.state.selectedOptions || []), option],
        selectedOptionIds: [...(this.state.selectedOptionIds || []), optionId],
      });
    } else {
      this.setState({
        selectedOptions: this.state.selectedOptions.filter(
          ({ optionId: id }) => id !== optionId
        ),
        selectedOptionIds: this.state.selectedOptionIds.filter(
          (id) => id !== optionId
        ),
      });
    }
  }

  handleSearchChange(e) {
    this.setState({ search: e.target.value.trimLeft() });
  }
  // #endregion

  match(option, search) {
    if (!search) {
      return true;
    }

    return Boolean(
      (option.nameTh && option.nameTh.includes(search)) ||
        (option.nameEn && option.nameEn.includes(search)) ||
        (option.choices &&
          option.choices.find(
            ({ nameTh, nameEn }) =>
              (nameTh && nameTh.includes(search)) ||
              (nameEn && nameEn.includes(search))
          ))
    );
  }

  render() {
    const { visible, options, selectedOptionIds, search } = this.state;

    const { optionModalRef, ...props } = this.props;

    let matchCount = 0;

    return (
      <Modal
        {...props}
        title={"ตัวเลือกเพิ่มเติมของเมนู"}
        visible={visible}
        width={480}
      >
        <Container>
          <Input.Search
            value={search}
            onChange={this.handleSearchChange}
            placeholder="ค้นหาตัวเลือกเพิ่มเติม"
          />

          <OptionsContainer>
            {options && options.length > 0 && (
              <OptionsList disableDND /* Disabled by design */>
                {options.map((option, index) => {
                  const subText =
                    (option.choices &&
                      option.choices.map(({ nameTh }) => nameTh).join("/")) ||
                    "-";

                  const matched = this.match(option, search);

                  if (matched) {
                    matchCount += 1;
                  }

                  return (
                    <OptionsList.Item
                      key={option.optionId}
                      id={option.optionId.toString()}
                      index={index}
                      style={matched ? undefined : { display: "none" }}
                    >
                      <OptionFace
                        mainText={option.nameTh}
                        subText={subText}
                        onEdit={() => this.editOption(index)}
                        onDelete={() => this.deleteOption(option.optionId)}
                        onSelect={(selected) =>
                          this.handleSelect(index, selected)
                        }
                        selected={
                          Boolean(selectedOptionIds) &&
                          selectedOptionIds.includes(option.optionId)
                        }
                        checkboxDisable={option.choices.length == 0}
                      />
                    </OptionsList.Item>
                  );
                })}

                {matchCount === 0 && <Empty description="ไม่พบตัวเลือก" />}
              </OptionsList>
            )}
            <StyledDashBox onClick={this.addOption}>
              {"+ เพิ่มตัวเลือกใหม่"}
            </StyledDashBox>
          </OptionsContainer>

          <ButtonRow>
            <Button type="secondary" onClick={this.handleCancel}>
              {"ยกเลิก"}
            </Button>
            <Button type="primary" onClick={this.handleConfirm}>
              {"บันทึก"}
            </Button>
          </ButtonRow>
        </Container>
      </Modal>
    );
  }
}
