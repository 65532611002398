import React from 'react';
import Modal from '../modal';

export default class ImageModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            src: undefined
        };
    }

    // #region Instance Methods
    showImage(imageUrl) {
        this.setState({
            visible: true,
            src: imageUrl
        });
    }
    // #endregion

    render() {
        const {
            visible,
            src
        } = this.state;

        return (
            <Modal
                id="image-modal"
                visible={visible}
                maskClosable={true}
                onCancel={() => this.setState({ visible: false })}
                width={'fit-content'}
                centered
                bodyStyle={{
                    padding: '0px'
                }}
            >
                <img src={src} style={{ maxHeight: '80vh', maxWidth: '80vw' }} />
            </Modal>
        );
    }
};
