import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../../values/colors";
import { Modal, Form, Input, Button, message } from "antd";
import playlivery from "../../assets/image/playlivery.png";
import { authentication } from "../../services/api";
import localName from "../../values/localStorageDict";
import { useHistory } from "react-router-dom";
import { subDomain } from "../../services/redirect";

const LineTop = styled.div`
  /* Rectangle 44 */

  position: absolute;
  width: 2px;
  height: 40%;
  left: 48px;
  top: 22px;

  /* Gray 1 */

  background: #333333;
`;

const PlaySideBar = styled.div`
  /* PLAYTORIUM */

  position: absolute;
  display: flex;

  top: 55%;

  letter-spacing: 0.5em;
  margin-left: -25px;
  color: #696969;

  transform: rotate(-90deg);
`;

const LineDown = styled.div`
  /* Rectangle 44 */

  position: absolute;
  width: 2px;
  height: 35%;
  left: 48px;
  top: 72%;

  /* Gray 1 */

  background: #333333;
`;

const Box = styled.div`
  position: absolute;
  width: 383px;
  height: 100px;
  left: 40%;
  top: 20%;

  background: #ffffff;
  border-radius: 5px;
`;

const Restaurant = styled.div`
  /* Restaurant */

  position: absolute;
  width: 103px;
  height: 15px;
  left: 60%;
  top: 5%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 102%;
  /* or 15px */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  color: #4c4c4c;
`;

const BoxFieldset = styled.div`
  position: absolute;
  width: 383px;
  height: 24rem;
  left: 40%;
  top: 35%;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const IncorrectDiv = styled.div`
  font-family: Kanit;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  color: ${colors.errorColor};
  display: flex;
  justify-content: center;
  padding-top: 1rem;
`;

const validateMessages = {
  required: "กรุณากรอกข้อมูล!",
};

export const SideBar = (props) => {
  return (
    <React.Fragment>
      <LineTop></LineTop>
      <PlaySideBar>PLAYTORIUM</PlaySideBar>
      <LineDown></LineDown>
    </React.Fragment>
  );
};
export class LogInFrame extends Component {
  constructor(props) {
    super(props);
    this.loginRef = React.createRef();
    this.state = {
      loginPassword: "",
      loginUsername: "",
      incorrect: false,
    };
  }
  handleResetFields = () => {
    if (this.loginRef.current) {
      this.loginRef.current.resetFields();
      this.setState({
        loginUsername: "",
        loginPassword: "",
      });
    }
  };
  onLoginUsernameChange = (e) => {
    this.setState({
      loginUsername: e.target.value,
      incorrect: false,
    });
  };
  onLoginPasswordChange = (e) => {
    this.setState({
      loginPassword: e.target.value,
      incorrect: false,
    });
  };
  handleLoginStore = async () => {
    const payload = {
      username: this.state.loginUsername.toLowerCase(),
      password: this.state.loginPassword,
    };
    await authentication.signInStore(
      payload,
      (data) => {
        localStorage.setItem(localName.STORE_ACCESS_TOKEN, data.accessToken);
        localStorage.setItem(localName.STORE_REFRESH_TOKEN, data.refreshToken);
        this.confirmLogin();
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          // message.error(response.data.message);
        }
      }
    );
  };
  confirmLogin = () => {
    message.success("เข้าสู่ระบบสำเร็จ", 1);
    let secondsToGo = 1.5;
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      this.handleResetFields();
      this.props.history.push(`${subDomain}/store/order`);
    }, secondsToGo * 1000);
  };
  render() {
    return (
      <React.Fragment>
        <Box>
          <Restaurant>Restaurant</Restaurant>
          <img
            style={{
              padding: "20px 20px 20px 80px",
            }}
            src={playlivery}
            alt="Logo"
          />
        </Box>
        <BoxFieldset
          style={{
            padding: "15px 10px 10px 10px",
            fontFamily: "Kanit",
            fontWeight: "600",
            fontSize: "24px",
          }}
        >
          เข้าสู่ระบบ
          <div
            style={{
              color: "#D10000",
              fontSize: "1rem",
              fontWeight: "400",
              textAlign: "center",
              height: "2rem",
            }}
          >
            {this.state.incorrect && (
              <div>ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง</div>
            )}
          </div>
          <Form
            onFinish={this.handleLoginStore}
            colon={false}
            ref={this.loginRef}
            hideRequiredMark
            style={{
              fontWeight: "500px",
              fontSize: "16px",
            }}
          >
            <Form.Item
              name="username"
              label="ชื่อผู้ใช้"
              colon="true"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกข้อมูล!",
                },
              ]}
            >
              <Input
                style={{
                  height: "55px",
                  width: "353px",
                  borderRadius: "5px",
                  border: "1px solid #D1D1D1",
                  fontFamily: "Kanit",
                  fontWeight: "500",
                }}
                value={this.state.loginUsername}
                onChange={this.onLoginUsernameChange}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="รหัสผ่าน"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกข้อมูล!",
                },
              ]}
            >
              <Input.Password
                style={{
                  height: "55px",
                  width: "353px",
                  borderRadius: "5px",
                  border: "1px solid #D1D1D1",
                  fontFamily: "Kanit",
                  alignItems: "center",
                }}
                value={this.state.loginPassword}
                onChange={this.onLoginPasswordChange}
              />
            </Form.Item>
            <Button
              style={{
                backgroundColor: "#333333",
                color: "#FFFFFF",
                width: "353px",
                height: "55px",
                borderRadius: "5px",
                fontSize: "16px",
              }}
              htmlType="submit"
            >
              เข้าสู่ระบบ
            </Button>
          </Form>
        </BoxFieldset>
      </React.Fragment>
    );
  }
}

export default function LogIn() {
  const history = useHistory();
  return (
    <React.Fragment>
      <section>
        <SideBar />
        <LogInFrame history={history} />
      </section>
    </React.Fragment>
  );
}
