import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';
import { colors } from '../../../values/colors';

const Tabs = styled(AntdTabs)`
    font-size: 18px;

    & .ant-tabs-nav-container div {
        width: 100%;
    }

    & div[role="tab"],
    & div[role="tab"]:hover,
    & div[role="tab"]:focus {
        color: ${colors.storeText};
        text-align: center;
        height: 50px;
        margin: 0px;
        border-bottom: 3px solid ${colors.storeTabActiveBg};
    }

    & .ant-tabs-tab-active {
        font-weight: 600;
        border-radius: 5px 5px 0 0;
        background-color: ${colors.storeDrawerActiveBg};
    }

    & .ant-tabs-ink-bar {
        background-color: ${colors.storeText};
        height: 3px;
    }
`;

export default Tabs;