import React, { Component, useEffect } from "react";
import { Title } from "../customers/components/title";
import { redirectTo, subDomain } from "../../services/redirect";
import { Button } from "../customers/components/sharedComponents";
import GoogleMap from "../../components/googleMap";
import styled from "styled-components";
import Map from "../../components/Map";
import titleIcon from "../../assets/icons/title-icon/my-address.svg";
import { Redirect } from "react-router-dom";
import localName from "../../values/localStorageDict";

const TitleBox = styled.div`
  padding: 0 10%;
  @media not screen and (min-device-width: 1224px) {
    padding: 0 5%;
  }
`;

const MapBoundary = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 2rem;
  font-family: Kanit;
  height: 40.8rem;
  @media not screen and (min-device-width: 1224px) {
    height: 22rem;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const ButtonRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  margin-right: 10%;
  @media not screen and (min-device-width: 1224px) {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }
`;

const BackButtonComponent = styled.div`
  width: 50%;

  @media not screen and (min-device-width: 1224px) {
    display: none;
  }
`;

const ActionButtonComponent = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media not screen and (min-device-width: 1224px) {
    width: 100%;
    flex-direction: column;
  }
`;

const ActionButton = styled(Button)`
  margin: 3rem 0.5rem 3rem 0.5rem;
  min-height: 2.8rem;
  width: ${(props) => (props.width ? props.width : "25%")};
  @media not screen and (min-device-width: 1224px) {
    width: 100%;
    margin: 0.5rem 0 0.5rem 0;
  }
`;

const Pin = ({ ...props }) => {
  const title = "ปักหมุด";
  const [address, setAddress] = React.useState("");
  const [area, setArea] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [postal, setPostal] = React.useState("");
  const [enabledPin, setPin] = React.useState(true);
  const [reset, setReset] = React.useState(false);
  const [isMyLocation, setIsMyLocation] = React.useState(false);
  const [zoom, setZoom] = React.useState(15);
  const [isNewAddress, setIsNewAddress] = React.useState(false);
  const [markerPosition, setMarkerPosition] = React.useState(
    props.history.location.state
  );
  const [mapPosition, setMapPosition] = React.useState(
    props.history.location.state
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (
          props.history.location.state.lat === position.coords.latitude &&
          props.history.location.state.lng === position.coords.longitude
        ) {
          setIsNewAddress(true);
          setIsMyLocation(true);
        } else {
          setIsNewAddress(false);
        }
      },
      (err) => {
        console.log(err);
      },
      {
        enableHighAccuracy: true,
      }
    );
  }, []);

  let pathItems = [];
  const startAt = localStorage.getItem(localName.START_AT);
  const addOrEditAddress = localStorage.getItem(localName.ADD_OR_EDIT_ADDR);
  if (startAt === `${subDomain}/cart` && addOrEditAddress === "edit") {
    pathItems = [
      { name: "ตะกร้าสินค้า", path: `${subDomain}/cart` },
      {
        name: "เปลี่ยนแปลงที่อยู่",
        path: `${subDomain}/my-address`,
      },
      {
        name: "แก้ไขที่อยู่",
        path: `${subDomain}/edit-address`,
      },
    ];
  } else if (startAt === `${subDomain}/cart` && addOrEditAddress === "add") {
    pathItems = [
      { name: "ตะกร้าสินค้า", path: `${subDomain}/cart` },
      {
        name: "เปลี่ยนแปลงที่อยู่",
        path: `${subDomain}/my-address`,
      },
      {
        name: "เพิ่มที่อยู่",
        path: `${subDomain}/edit-address`,
      },
    ];
  } else if (startAt === `${subDomain}` && addOrEditAddress === "add") {
    pathItems = [
      {
        name: "ที่อยู่ของฉัน",
        path: `${subDomain}/my-address`,
      },
      {
        name: "เพิ่มที่อยู่",
        path: `${subDomain}/edit-address`,
      },
    ];
  } else if (startAt === `${subDomain}` && addOrEditAddress === "edit") {
    pathItems = [
      {
        name: "ที่อยู่ของฉัน",
        path: `${subDomain}/my-address`,
      },
      {
        name: "แก้ไขที่อยู่",
        path: `${subDomain}/edit-address`,
      },
    ];
  }

  return (
    <React.Fragment>
      <TitleBox>
        <Title breadcrumbItems={pathItems} title={title} img={titleIcon} />
      </TitleBox>
      {/* <GoogleMap /> */}
      <MapBoundary>
        <Map
          center={mapPosition}
          height="37.8rem"
          width="100%"
          zoom={zoom}
          pinable={true}
          address={address}
          area={area}
          city={city}
          state={state}
          postal={postal}
          reset={reset}
          isMyLocation={isMyLocation}
          setIsMyLocation={setIsMyLocation}
          changeAddress={setAddress}
          changeArea={setArea}
          changeCity={setCity}
          changeState={setState}
          changePostal={setPostal}
          changeMapPosition={setMapPosition}
          changeMarkerPosition={setMarkerPosition}
        />
      </MapBoundary>
      <ButtonRow>
        <BackButtonComponent>
          <Button
            border
            white
            style={{
              margin: "3rem 0 3rem 0",
              left: "10%",
              height: "2.8rem",
              minWidth: "5rem",
              width: "20%",
              color: "#FFB800",
            }}
            onClick={() => redirectTo("/edit-address")}
          >
            กลับ
          </Button>
        </BackButtonComponent>
        <ActionButtonComponent>
          <ActionButton
            disabled={
              // props.history.location.state == mapPosition ? true : false
              isMyLocation
            }
            width="25%"
            onClick={() => {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  setMapPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                  setIsMyLocation(true);
                },
                (err) => {
                  console.log(err);
                },
                {
                  enableHighAccuracy: true,
                }
              );
            }}
          >
            ตำแหน่งของฉัน
          </ActionButton>
          {!isNewAddress && (
            <ActionButton
              disabled={
                props.history.location.state.lat === mapPosition.lat &&
                props.history.location.state.lng === mapPosition.lng
                  ? true
                  : false
              }
              width="25%"
              onClick={() => {
                setMapPosition(props.history.location.state);
                setReset(!reset);
                setIsMyLocation(false);
              }}
            >
              รีเซ็ตที่อยู่
            </ActionButton>
          )}
          <ActionButton
            width="45%"
            // onClick={() =>
            //   props.history.push(`${subDomain}/edit-address`, {
            //     address,
            //     area,
            //     city,
            //     state,
            //     postal,
            //     mapPosition,
            //     markerPosition,
            //     enabledPin,
            //   })
            // }
            onClick={() =>
              props.history.push(`${subDomain}/edit-address`, {
                address,
                district: area,
                province: city,
                subDistrict: state,
                postalCode: postal,
                mapPosition,
                markerPosition,
                enabledPin,
                prevPath: props.location.pathname,
              })
            }
          >
            บันทึกและดำเนินการต่อ
          </ActionButton>
        </ActionButtonComponent>
      </ButtonRow>
    </React.Fragment>
  );
};

export default Pin;
