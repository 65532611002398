import React, { useState, useCallback, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Empty, message } from "antd";
import styled from "styled-components";
import SubPageHeader from "./components/subpageHeader";
import DNDList from "./components/dndList";
import Button from "./components/button";
import { ListIcon, DragIcon } from "../../components/icons";
import { colors } from "../../values/colors";
import ItemFace from "./components/menu/itemFace";
import { subDomain } from "../../services/redirect";
import CategoryModal from "./components/modals/category";
import ConfirmModal from "./components/modals/confirmModal";
import { store } from "../../services/api";

const mockCategories = [
  {
    id: "5050",
    name: "ของทานเล่น",
    menus: [
      { id: "1", name: "เฟรนช์ฟราย" },
      { id: "2", name: "คาราอาเกะ" },
      { id: "3", name: "โคร็อกเกะ" },
      { id: "4", name: "กุ้งเทมปุระ" },
      { id: "5", name: "ไข่ออนเซ็น" },
      { id: "6", name: "ไข่ตุ๋นทรงเครื่อง" },
      { id: "7", name: "ไข่หวาน" },
      { id: "8", name: "ซุปมิโสะ" },
      { id: "9", name: "สึคุเนะ" },
    ],
  },
  {
    id: "5051",
    name: "แกงกะหรี่",
    menus: [
      { id: "1", name: "แกงกะหรี่หมูทอดทงคัตสึ" },
      { id: "2", name: "แกงกะหรี่หมู" },
      { id: "3", name: "แกงกะหรี่ไก่ทอด" },
      { id: "4", name: "แกงกะหรี่กุ้งเทมปุระ" },
      { id: "5", name: "แกงกะหรี่ไข่ข้น" },
    ],
  },
  {
    id: "5052",
    name: "ดงบุริ",
    menus: [
      { id: "1", name: "ข้าวหน้าเนื้อ" },
      { id: "2", name: "ข้าวหน้าไก่ทอด" },
      { id: "3", name: "ข้าวหน้าเทมปุระ" },
      { id: "4", name: "ข้าวหน้าเต้าหู้ผัดเผ็ด" },
    ],
  },
  {
    id: "5053",
    name: "ของหวาน",
    menus: [
      { id: "1", name: "นามะงาชิ" },
      { id: "2", name: "ไดฟุกุ" },
      { id: "3", name: "ดังโกะ" },
      { id: "4", name: "โดรายากิ" },
      { id: "5", name: "ไทยากิ" },
      { id: "6", name: "ซุปโมจิถั่วแดง" },
    ],
  },
  {
    id: "5054",
    name: "เครื่องดื่ม",
    menus: [
      { id: "1", name: "ชาเขียว" },
      { id: "2", name: "ชาเขียวมัทฉะ" },
      { id: "3", name: "ชาอู่หลง" },
      { id: "4", name: "ชาข้าวคั่ว" },
      { id: "5", name: "โคล่า" },
      { id: "6", name: "น้ำแร่" },
    ],
  },
  { id: "5055", name: "เมนูพิเศษฤดูร้อน" },
  { id: "5056", name: "ภาชนะเพิ่มเติม", menus: [] },
  { id: "5057", name: "บริการเพิ่มเติม" },
  { id: "5058", name: "สิทธิพิเศษ" },
];

const Flexbox = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.column ? "column" : "row")};
    justify-content: space-between;
    ${(props) => (props.fullHeight ? "height: 93%;" : "")}
    ${(props) => (props.fillSpace ? "flex: 2;" : "")}
    ${(props) => (props.fillSpace ? "overflow-y: hidden;" : "")}
`;

const Column = styled.div`
  width: ${(props) => props.width};
`;

const ListTitle = styled.span`
  padding-left: 8px;

  font-size: 1.25rem;
  font-weight: 600;
  color: ${colors.storeText};
`;

const CategoryList = styled(DNDList)`
  height: 100%;
  padding-right: 16px;
  overflow-y: scroll;
`;

const MenuList = styled(DNDList)`
  height: 100%;
  overflow-y: auto;
`;

const Center = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;

  & > button {
    width: 10em;
  }

  & > button:not(:first-child) {
    margin-left: 12px;
  }
`;

const DNDHint = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin: 1rem 0px 0.6em;

  font-size: 0.875rem;
  color: #a5a5a5;

  & > span {
    margin-left: 0.5em;
  }
`;

const Footer = styled.div`
  height: 5%;
  width: 100%;
  margin-top: 1rem;
`;

export default function Menu() {
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    store.product.getProductByCategory().then((data) => {
      setCategories(data);
    });
    return () => {
      setCategories([]);
    };
  }, []);

  const onCategoryOrderChange = useCallback(
    (result, reorder) => {
      const newCategoryList = reorder(categories);
      // store.product.updatePositionCategoty({
      //   prevBeforeCategoryId:
      //     result.source.index - 1 < 0
      //       ? "0"
      //       : categories[result.source.index - 1].categoryId,
      //   nextBeforeCategoryId:
      //     categories.length == result.source.index + 1
      //       ? "0"
      //       : categories[result.source.index + 1].categoryId,
      //   prevAfterCategoryId:
      //     result.destination.index - 1 < 0
      //       ? "0"
      //       : newCategoryList[result.destination.index - 1].categoryId,
      //   nextAfterCategoryId:
      //     newCategoryList.length == result.destination.index + 1
      //       ? "0"
      //       : newCategoryList[result.destination.index + 1].categoryId,
      //   currentCategoryId: result.draggableId,
      // });
      setCategories(newCategoryList);
    },
    [categories, setCategories]
  );

  const selectedCategoryObj = categories.find(
    ({ categoryId }) => categoryId === selectedCategory
  );
  const menus = selectedCategoryObj && selectedCategoryObj.products;

  const onMenuOrderChange = useCallback((reorder) => {
    const reorderedMenus = reorder(menus);
    setCategories(
      categories.map((cat) => {
        if (cat.categoryId === selectedCategory) {
          return {
            ...cat,
            menus: reorderedMenus,
          };
        } else {
          return cat;
        }
      })
    );
  });

  const fetch = () =>
    store.product.getProductByCategory().then((data) => {
      setCategories(data);
    });

  const categoryIsSelected = selectedCategory !== undefined;
  const hasMenu = Boolean(menus && menus.length > 0);

  const firstColWidth = "37%";
  const secondColWidth = "60%";

  const categoryModal = useRef(null);
  const confirmModal = useRef(null);

  const addCategory = async () => {
    const [text, confirmed] = await categoryModal.current.getName();
    if (confirmed) {
      try {
        await store.product
          .addCategory({
            nameTh: text.name,
            descriptionTh: text.description,
          })
          .then(() => {
            message.success("เพิ่มหมวดหมู่สำเร็จ");
            fetch();
          });
      } catch (err) {
        message.error("เพิ่มหมวดหมู่ไม่สำเร็จ โปรดลองใหม่อีกครั้ง");
      }
      /* CALL_ADD_API(text) */
    }
  };

  const editCategory = async (id, oldName, oldDescription) => {
    const [text, confirmed] = await categoryModal.current.getName(
      oldName,
      oldDescription
    );
    if (confirmed) {
      try {
        await store.product
          .editCategory({
            categoryId: id,
            nameTh: text.name,
            descriptionTh: text.description,
          })
          .then(() => {
            message.success("แก้ไขหมวดหมู่สำเร็จ");
            fetch();
          });
      } catch (err) {
        message.error("แก้ไขหมวดหมู่ไม่สำเร็จ โปรดลองใหม่อีกครั้ง");
      }
      /* CALL_EDIT_API(id, text) */
    }
  };

  const deleteCategory = async (id) => {
    const confirmed = await confirmModal.current.getConfirmation({
      title: "ยืนยันการลบหมวดหมู่",
    });
    if (confirmed) {
      try {
        await store.product.deleteCategory(id).then(() => {
          message.success("ลบหมวดหมู่สำเร็จ");
          fetch();
        });
      } catch (err) {
        message.error("ลบหมวดหมู่ไม่สำเร็จ โปรดลองใหม่อีกครั้ง");
      }
    }
  };

  const deleteProduct = async (id) => {
    const confirmed = await confirmModal.current.getConfirmation({
      title: "ยืนยันการลบเมนูอาหาร",
    });
    if (confirmed) {
      try {
        await store.product.deleteProduct(id).then(() => {
          message.success("ลบเมนูอาหารสำเร็จ");
          fetch();
        });
      } catch (err) {
        message.error("ลบเมนูอาหารไม่สำเร็จ โปรดลองใหม่อีกครั้ง");
      }
    }
  };

  const history = useHistory();

  const openMenuForm = (id) => {
    if (!id) {
      history.push(`${subDomain}/store/menu/add`);
    } else {
      history.push(`${subDomain}/store/menu/edit/${id}`);
    }
  };

  const addMenu = () => openMenuForm();

  return (
    <React.Fragment>
      <Flexbox column fullHeight>
        <SubPageHeader
          icon={ListIcon}
          label={"จัดการเมนูอาหาร"}
          tail={
            <ButtonRow>
              <Button type="primary" onClick={addCategory}>
                {"เพิ่มหมวดหมู่อาหาร"}
              </Button>
              <Button type="primary" onClick={addMenu}>
                {"เพิ่มเมนูอาหาร"}
              </Button>
            </ButtonRow>
          }
        />
        <Flexbox>
          <Column width={firstColWidth}>
            <ListTitle>{"หมวดหมู่อาหาร"}</ListTitle>
          </Column>
          <Column width={secondColWidth}>
            <ListTitle>{"เมนูอาหาร"}</ListTitle>
          </Column>
        </Flexbox>
        <DNDHint>
          <DragIcon size={"1em"} />
          <span>{"จัดเรียงลำดับโดยการกดค้างและลาก"}</span>
        </DNDHint>
        <Flexbox fillSpace>
          <Column width={firstColWidth}>
            <CategoryList
              disableDND /* TODO enable when API is ready */
              alwaysShowScroll
              selectedId={selectedCategory}
              onSelect={setSelectedCategory}
              onOrderChange={onCategoryOrderChange}
            >
              {categories.map(
                (
                  {
                    categoryId,
                    categoryNameTh,
                    products,
                    categoryDescriptionTh,
                  },
                  index
                ) => (
                  <CategoryList.Item
                    key={categoryId}
                    id={categoryId}
                    index={index}
                  >
                    <ItemFace
                      name={categoryNameTh}
                      badge={products ? products.length : 0}
                      active={categoryId === selectedCategory}
                      onEdit={() => {
                        editCategory(
                          categoryId,
                          categoryNameTh,
                          categoryDescriptionTh
                        );
                      }}
                      onDelete={() => {
                        deleteCategory(categoryId);
                      }}
                    />
                  </CategoryList.Item>
                )
              )}
            </CategoryList>
          </Column>
          <Column width={secondColWidth}>
            {categoryIsSelected ? (
              hasMenu ? (
                <MenuList
                  disableDND /* TODO enable when API is ready */
                  onOrderChange={onMenuOrderChange}
                >
                  {menus.map(({ productId, productNameTh }, index) => (
                    <MenuList.Item key={productId} id={productId} index={index}>
                      <ItemFace
                        name={productNameTh}
                        onEdit={() => openMenuForm(productId)}
                        onDelete={() => deleteProduct(productId)}
                      />
                    </MenuList.Item>
                  ))}
                </MenuList>
              ) : (
                <Center>
                  <Empty description="ไม่มีเมนูในหมวดหมู่นี้" />
                </Center>
              )
            ) : null}
          </Column>
        </Flexbox>
      </Flexbox>
      {/* <Footer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="secondary"
            style={{ marginLeft: "12px" }}
            // onClick={}
          >
            {"ยกเลิก"}
          </Button>
          {`ปุ่มที่รอapi ยังไม่มีอะไร จะกดเล่นแก้เซ็งก็ได้นะ-->`}
          <Button
            type="primary"
            style={{ marginLeft: "12px" }}
            // onClick={}
          >
            {"บันทึกการเปลี่ยนแปลง"}
          </Button>
        </div>
      </Footer> */}

      <CategoryModal ref={categoryModal} />
      <ConfirmModal ref={confirmModal} />
    </React.Fragment>
  );
}
