import React from "react";
import { Form } from "react-final-form";
import { Input, Checkbox, Row, Col, message } from "antd";
import styled from "styled-components";
import Modal from "../modal";
import Button from "../button";
import DashBox from "../dashBox";
import DNDList from "../dndList";
import Field from "../field";
import ItemFace from "../menu/itemFace";
import { thaiLanguageRule, englishLanguageRule } from "../../util/form/rules";
import { validateRequiredInput } from "../../util/form/validators";
import { colors } from "../../../../values/colors";
import IconButton from "../iconButton";
import { EditIcon, DeleteIcon } from "../../../../components/icons";
import { store } from "../../../../services/api";

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 1.5rem;

  & button {
    width: 6em;
  }
`;

const Container = styled.div`
  padding-top: 1rem;
`;

const HalfCol = styled(Col)`
  width: 48%;
`;

const ChoicesContainer = styled.div`
  margin-top: 1rem;
`;

const ChoicesList = styled(DNDList)`
  max-height: 190px;
  overflow-y: auto;
`;

const StyledDashBox = styled(DashBox)`
  padding: 8px;
  margin-top: 0.5rem;
`;

const AllChoiceList = styled.div`
  max-height: 12.5rem;
  overflow-y: auto;
  padding-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

const ChoiceItem = styled.div`
  width: 47%;
  height: 3rem;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: ${colors.footerColor};
`;

export default class OptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      isEditMode: false,
      InitialFormValues: undefined,
      choices: undefined,
      resolve: undefined,
      allChoices: [],
      search: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleReorderChoices = this.handleReorderChoices.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.initialValuesEqual = this.initialValuesEqual.bind(this);
    this.fetch = this.fetch.bind(this);

    this.addChoice = this.addChoice.bind(this);
    this.selectChoice = this.selectChoice.bind(this);
    this.deleteSelectChoice = this.deleteSelectChoice.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  fetch() {
    store.product.fetchChoice().then((data) => {
      this.setState({
        allChoices: data,
      });
    });
  }

  componentDidMount() {
    this.fetch();
  }

  handleSearchChange(e) {
    this.setState({ search: e.target.value.trimLeft() });
  }

  async addChoice() {
    const { choiceModalRef } = this.props;
    const [choice, confirmed] = await choiceModalRef.current.getChoice();

    if (confirmed) {
      store.product
        .addChoice({
          nameTh: choice.nameTh,
          nameEn: choice.nameEn,
          price:
            choice.priceEffect === 1
              ? choice.increaseValue
              : choice.priceEffect === 2
              ? `-${choice.decreaseValue}`
              : "0.00",
        })
        .then((data) => {
          this.fetch();
          this.selectChoice(data.data);
        })
        .catch((err) => {
          message.error("เพิ่มช้อยส์ไม่สำเร็จ");
        });
    }
  }

  async editChoice(id) {
    // const initialValues = this.state.choices[index];
    const initialValues = {};
    // store.product.fetchChoiceById(id)
    store.product.fetchChoiceById(id).then(async (data) => {
      let choiceData = {};
      if (Math.sign(data.price) === 1) {
        choiceData = {
          ...data,
          priceEffect: 1,
          increaseValue: Math.abs(data.price),
        };
      } else if (Math.sign(data.price) === -1) {
        choiceData = {
          ...data,
          priceEffect: 2,
          decreaseValue: Math.abs(data.price),
        };
      } else {
        choiceData = {
          ...data,
          priceEffect: 3,
        };
      }
      const { choiceModalRef } = this.props;
      const [newValues, confirmed] = await choiceModalRef.current.getChoice(
        choiceData
      );

      if (confirmed) {
        let data = {
          choiceId: newValues.choiceId,
          nameTh: newValues.nameTh,
          nameEn: newValues.nameEn,
          price:
            newValues.priceEffect === 1
              ? newValues.increaseValue
              : newValues.priceEffect === 2
              ? `-${newValues.decreaseValue}`
              : "0.00",
        };
        //TODO: api edit choice and fetch
        store.product.editChoice(data).then((res) => {
          this.fetch();
          this.setState({
            choices: this.state.choices.map((choice) => {
              return choice.choiceId === res.data.choiceId ? newValues : choice;
            }),
          });
        });
      }
    });
  }

  async deleteChoice(id) {
    const { confirmModalRef } = this.props;
    const confirmed = await confirmModalRef.current.getConfirmation({
      title: "ยืนยันการลบช้อยส์",
    });

    if (confirmed) {
      //TODO: call api delete then fetch
      try {
        await store.product.deleteChoice(id).then((data) => {
          this.fetch();
          this.setState({
            choices: !!this.state.choices
              ? this.state.choices.filter((eachChoice) => {
                  return id != eachChoice.choiceId;
                })
              : [],
          });
          message.success("ดำเนินการสำเร็จ");
        });
      } catch (err) {
        message.error("ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
      }
    }
  }

  selectChoice(item) {
    const updatedChoices = this.state.choices ? [...this.state.choices] : [];
    let isSelect = false;
    for (let i = 0; i < updatedChoices.length; i++) {
      if (updatedChoices[i].choiceId === item.choiceId) {
        isSelect = true;
      }
    }
    if (isSelect) {
      //delete from list
      this.setState({
        choices: updatedChoices.filter(
          (select) => select.choiceId != item.choiceId
        ),
      });
    } else {
      updatedChoices.push(item);
      this.setState({ choices: updatedChoices });
    }
  }

  deleteSelectChoice(index) {
    this.setState({
      choices: this.state.choices.filter((_, i) => {
        return i !== index;
      }),
    });
  }

  isSelected(item) {
    if (this.state.choices) {
      for (let i = 0; i < this.state.choices.length; i++) {
        if (this.state.choices[i].choiceId === item.choiceId) {
          return true;
        }
      }
    }
    return false;
  }

  // #region Instance Methods
  async getOption(initialValues) {
    // Setup promise and Show modal
    let modalResolve = undefined;
    const modalPromise = new Promise((resolve) => {
      modalResolve = resolve;
    });

    const isEditMode = Boolean(initialValues);

    initialValues = initialValues || {};
    const { choices, ...InitialFormValues } = initialValues;

    this.setState({
      visible: true,
      resolve: modalResolve,
      isEditMode,
      InitialFormValues,
      choices,
    });

    // Gather Result
    const [newValues, confirmed] = await modalPromise;

    // Clean promise and Close modal
    this.setState({
      visible: false,
      resolve: null,
    });

    // Return result
    return [newValues, confirmed];
  }
  // #endregion

  // #region Event Handlers
  handleSubmit(formValues) {
    if (this.state.resolve) {
      this.state.resolve([
        {
          ...formValues,
          choices: this.state.choices || [],
        },
        true,
      ]);
    }
  }

  handleCancel() {
    if (this.state.resolve) {
      this.state.resolve([null, false]);
    }
    this.setState({
      search: "",
    });
  }

  handleReorderChoices(result, reorder) {
    this.setState({ choices: reorder(this.state.choices) });
  }
  // #endregion

  initialValuesEqual(initialA, initialB) {
    return initialA === initialB;
  }

  render() {
    const {
      visible,
      isEditMode,
      InitialFormValues,
      choices,
      search,
    } = this.state;

    const { choiceModalRef, ...props } = this.props;

    return (
      <Modal
        {...props}
        title={isEditMode ? "แก้ไขตัวเลือก" : "เพิ่มตัวเลือก"}
        visible={visible}
        width={480}
      >
        <Form
          initialValues={InitialFormValues}
          initialValuesEqual={this.initialValuesEqual}
          onSubmit={this.handleSubmit}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <Container>
                  <Row type="flex" justify="space-between">
                    <HalfCol>
                      <Field
                        name="nameTh"
                        component={Input}
                        label="ชื่อตัวเลือก TH"
                        placeholder="ชื่อตัวเลือก TH"
                        rules={[thaiLanguageRule]}
                        validate={validateRequiredInput}
                      />
                    </HalfCol>
                    <HalfCol>
                      <Field
                        name="nameEn"
                        component={Input}
                        label="ชื่อตัวเลือก EN"
                        placeholder="ชื่อตัวเลือก EN"
                        rules={[englishLanguageRule]}
                        validate={validateRequiredInput}
                      />
                    </HalfCol>
                  </Row>
                  <Field
                    name="isRequired"
                    component={Checkbox}
                    fieldType="checkbox"
                    noError
                    noMinHeight
                  >
                    {"จำเป็นต้องเลือกหรือไม่?"}
                  </Field>
                  {/* <Field
                    name="isMultiple"
                    component={Checkbox}
                    fieldType="checkbox"
                    noError
                    noMinHeight
                  >
                    {"เลือกได้หลายคำตอบหรือไม่?"}
                  </Field> */}

                  <ChoicesContainer>
                    <div style={{ height: "190px" }}>
                      {choices && choices.length > 0 && (
                        <ChoicesList
                          // disableDND /* TODO enable when API is ready */
                          onOrderChange={this.handleReorderChoices}
                        >
                          {choices.map((choice, index) => (
                            <ChoicesList.Item
                              key={choice.choiceId}
                              id={`${choice.choiceId}`}
                              index={index}
                            >
                              <ItemFace
                                name={choice.nameTh}
                                onDelete={() => this.deleteSelectChoice(index)}
                              />
                            </ChoicesList.Item>
                          ))}
                        </ChoicesList>
                      )}
                    </div>
                    <StyledDashBox onClick={this.addChoice}>
                      {"+ เพิ่มช้อยส์ใหม่"}
                    </StyledDashBox>
                  </ChoicesContainer>
                  <Input.Search
                    value={search}
                    onChange={this.handleSearchChange}
                    placeholder="ค้นหาช้อยส์"
                    style={{
                      marginTop: "0.5rem",
                    }}
                  />
                  <div style={{ height: "12.5rem" }}>
                    <AllChoiceList>
                      {this.state.allChoices &&
                        this.state.allChoices
                          .filter((item) => {
                            return item.nameTh.includes(search.trim());
                          })
                          .map((item, index) => {
                            return (
                              <ChoiceItem key={index}>
                                <Checkbox
                                  style={{ marginRight: "0.5rem" }}
                                  onChange={() => this.selectChoice(item)}
                                  checked={this.isSelected(item)}
                                />
                                <div>
                                  <div
                                    style={{
                                      fontSize: "0.9rem",
                                      lineHeight: "1.1rem",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      width: "7rem",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item.nameTh}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "0.7rem",
                                      lineHeight: "0.7rem",
                                      color: `${colors.textColor3}`,
                                    }}
                                  >
                                    {/* TODO : increase or decrease */}
                                    {item.price}
                                  </div>
                                </div>
                                <IconButton
                                  icon={EditIcon}
                                  size={"1em"}
                                  onClick={() => {
                                    this.editChoice(item.choiceId);
                                  }}
                                  // isOnDarkBg={active}
                                />
                                <IconButton
                                  icon={DeleteIcon}
                                  size={"1em"}
                                  onClick={() =>
                                    this.deleteChoice(item.choiceId)
                                  }
                                  // isOnDarkBg={active}
                                />
                              </ChoiceItem>
                            );
                          })}
                    </AllChoiceList>
                  </div>

                  <ButtonRow>
                    <Button type="secondary" onClick={this.handleCancel}>
                      {"ยกเลิก"}
                    </Button>
                    <Button type="primary" buttonType="submit">
                      {"บันทึก"}
                    </Button>
                  </ButtonRow>
                </Container>
              </form>
            );
          }}
        </Form>
      </Modal>
    );
  }
}
