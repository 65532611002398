export const formatPhoneNumber = number => {
    if(!number) {
        return null;
    }

    const tokens = [
        number.slice(0, -7),
        number.slice(-7, -4),
        number.slice(-4)
    ].filter(x => !!x);

    return tokens.join('-');
};