import React, { useState, useEffect } from "react";
import Modal from "../modal";
import Stack from "../stack";
import styled from "styled-components";
import Button from "../button";
import { Input, AutoComplete } from "antd";
import { store } from "../../../../services/api";

const StyledStack = styled(Stack)`
  margin-top: 0.5rem;

  & .or-label {
    text-align: center;
    margin: 1.1rem 0;
  }

  & button {
    margin: 0.25rem 0;
  }

  & button.cancel-button {
    margin-top: 2rem;
  }
`;

const SelectDriverModal = ({
  visible,
  onClose,
  addDriver,
  showQR,
  orders,
  ...props
}) => {
  const [value, setValue] = useState(undefined);
  const [driverList, setDriverList] = useState([]);
  const [options, setOptions] = useState(() => {
    let driver = [];
    driverList.map((item, index) => {
      driver.push(
        item.lastname == null
          ? { label: item.firstname, value: item.driverId }
          : {
              label: item.firstname + " " + item.lastname,
              value: item.driverId,
            }
      );
    });
    return driver;
  });

  useEffect(() => {
    store.driver.getAllDriverAvaliable().then((data) => {
      setDriverList(data);
      let driverTmp = [];
      data &&
        data.map((item, index) => {
          driverTmp.push(
            item.lastname == null
              ? { label: item.firstname, value: item.driverId }
              : {
                  label: item.firstname + " " + item.lastname,
                  value: item.driverId,
                }
          );
        });
      setOptions(driverTmp);
    });

  }, [orders]);

  const onInputSearch = (value) => {
    value = value.trim().toLowerCase();
    let driver = [];
    driverList &&
      driverList
        .filter((item) =>
          (item.phone + "," + item.firstname + " " + item.lastname)
            .toLowerCase()
            .includes(value)
        )
        .map((item, index) => {
          driver.push(
            item.lastname == null
              ? { label: item.firstname, value: item.driverId }
              : {
                  label: item.firstname + " " + item.lastname,
                  value: item.driverId,
                }
          );
        });
    setOptions(driver);
  };
  const onInputChange = (value) => setValue(value);

  return (
    <Modal {...props} title="เลือกคนส่งอาหาร" visible={visible}>
      <StyledStack>
        <AutoComplete
          autoFocus
          placeholder="ค้นหา เช่น 0826790028,นายแดง"
          value={value}
          onChange={onInputChange}
          options={options}
          onSelect={(value) => {
            showQR(value);
            setValue("");
          }}
          onSearch={onInputSearch}
        >
          <Input.Search />
        </AutoComplete>
        <div className="or-label">หรือ</div>
        <Button
          type="primary"
          onClick={() => {
            addDriver();
            setValue("");
          }}
        >
          {"เพิ่มคนส่งอาหารใหม่"}
        </Button>
        <Button
          type="secondary"
          className="cancel-button"
          onClick={() => {
            onClose();
            setValue("");
          }}
        >
          {"ยกเลิก"}
        </Button>
      </StyledStack>
    </Modal>
  );
};

export default SelectDriverModal;
