/**
 * Rule is a function that determine if the change of input should be applied
 * (designed to be used with Field component (/pages/store/component/Field.js))
 *
 * Signature:
 *      function(nextValue: any, oldValue: any) => shouldBeApplied: boolean
 */

// #region Rule Generator/Template: func(...params: [any]) => Rule
// These function should not be exported
const combineRules = (...rules) => (nextValue, prevValue) => {
  for (const rule of rules) {
    const passRule = rule(nextValue, prevValue);
    if (!passRule) {
      return false;
    }
  }

  return true;
};

const createStringRegexRule = (regex) => (value) => {
  if (typeof value === "string") {
    return regex.test(value);
  }

  return true;
};

const maxLengthRule = (length) => (value) => {
  return typeof value !== "string" || value.length <= length;
};
// #endregion

export const integerRule = createStringRegexRule(/^([1-9][0-9]*|0)?$/);
export const realNumberRule = createStringRegexRule(
  /^(([1-9][0-9]*|0)(\.[0-9]*)?)?$/
);
export const priceRule = createStringRegexRule(
  /^(([1-9][0-9]*|0)(\.[0-9]{0,2})?)?$/
);
export const englishLanguageRule = createStringRegexRule(/^[ -~]*$/);
export const thaiLanguageRule = createStringRegexRule(/^[ก-๙+ -@+[-`+{-~]*$/);
export const digitsRule = createStringRegexRule(/^\d*$/);
export const phoneNumberRule = combineRules(digitsRule, maxLengthRule(10));
export const pinRule = combineRules(digitsRule, maxLengthRule(4));
