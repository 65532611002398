import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "antd";
import SetupForm from "../customers/components/setupPassword/setupForm";
import { customer as api } from "../../services/api";
import localName from "../../values/localStorageDict";
import SetupPasswordSuccessModal from "../customers/components/setupPassword/setupPasswordSuccessModal";
import { render } from "@testing-library/react";
import AskToLogOutModal from "../../components/login/askToLogOutModal";

const OutsideBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 0 3% 0;
  @media not screen and (min-device-width: 1224px) {
    padding: 10% 0 10% 0;
  }
`;

const ContentBox = styled.div`
  padding: 2% 0 2% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  @media not screen and (min-device-width: 1224px) {
    width: 90%;
    padding: 5% 0 5% 0;
  }
`;

const SetupPassword = ({ match }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [userName, setUsername] = useState("");
  const [form] = Form.useForm();

  const signedIn =
    localStorage.getItem(localName.ACCESS_TOKEN) &&
    localStorage.getItem(localName.REFRESH_TOKEN);

  if (signedIn) {
    api.user.getProfileData(({ data, status }) => {
      // localStorage.setItem(localName.PROFILE_DATA, JSON.stringify(data));
      setUsername(data.firstname + " " + data.lastname);
    });
  }

  const onFinish = (val) => {
    const { password, firstName, lastName } = val;
    const payload = {
      token: match.params.tokenId, // ตรงนี้อาจจะต้องตัด % ออก
      password: password,
      firstname: firstName,
      lastname: lastName,
    };
    const displayName = `${firstName} ${lastName}`;
    localStorage.setItem(localName.USERNAME, displayName); // เปลี่ยนชื่อตรง header
    api.user.confirmPassword(payload, (data, status) => {});
    setVisibleModal(true);
  };

  const onFinishFailed = (failed) => {
    // console.log("failed", failed);
  };

  return (
    <OutsideBox>
      <ContentBox>
        <SetupForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
        <SetupPasswordSuccessModal visibleSetupSuccessModal={visibleModal} />
        <AskToLogOutModal visible={signedIn} userName={userName} />
      </ContentBox>
    </OutsideBox>
  );
};

export default SetupPassword;
