import React, { useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer";
import MyAddress from "../../pages/customers/myAddress";
import Profile from "../../pages/customers/profile";
import Contact from "../../pages/customers/contact";
import ChangePassword from "../../pages/customers/changePassword";
import Login from "../../components/login/login";
import Register from "../../pages/customers/register";
import MyOrder from "../../pages/customers/myOrder";
import Cart from "../../pages/customers/cart";
import MenuDetail from "../../pages/customers/menuDetail";
import Reservation from "../../pages/customers/reservation";
import { subDomain } from "../../services/redirect";
import EditAddress from "../../pages/customers/editAddress";
import SetupPassword from "../../pages/customers/setupPassword";
import Pin from "../../pages/customers/pin";
import CustomerSlidingMenu from "./components/menu/customerSlidingMenu";
import CustomerSlidingRecommended from "./components/menu/customerSlidingRecommended";
import Cover from "../../components/header/cover";
import Payment from "./payment";
// import Banner from "./components/menu/banner";
import SimpleMap from "../../components/googleMap";
import ResetPassword from "./resetPassword";
import NotFoundPage from "../../components/errorMessage/notFoundPage";
import { customer as api, checked } from "../../services/api";
import ErrorPage from "../../components/errorMessage/errorPage";
import MediaQuery from "react-responsive";

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10%;

  @media not screen and (min-device-width: 1224px) {
    margin: 0 15px;
    position: relative;
  }
`;

const CustomerMainPage = () => {
  const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-device-width: 680px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const recommendSize = useMediaQuery({ query: "(min-device-width: 1500px)" })
    ? 8
    : isDesktop
    ? 6
    : isMobile
    ? 8
    : 4;
  return (
    <React.Fragment>
      {isDesktop && <Cover />}
      <MainBox>
        {/* <Banner /> */}
        <CustomerSlidingRecommended
          recommendSize={recommendSize}
          isDesktop={isDesktop}
          isMobile={isMobile}
        />
        <CustomerSlidingMenu
          isDesktop={isDesktop}
          isMobile={isMobile}
          isPortrait={isPortrait}
        />
      </MainBox>
    </React.Fragment>
  );
};

const CustomerPage = () => {
  const headerHeight = "4rem";
  const footerHeight = "5.2rem";
  const path = useLocation().pathname;
  const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-device-width: 680px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [error, setError] = React.useState(false);
  const [isUpdateProfile, setIsUpdateProfile] = React.useState(false);

  // catch error when backend fail
  useEffect(() => {
    checked.Checkedstatus().catch((err) => {
      setError(true);
    });
  }, []);

  if (error) {
    return (
      <Switch>
        <Route path={path} exact component={ErrorPage} />
      </Switch>
    );
  } else {
    return (
      <React.Fragment>
        <Header
          headerHeight={headerHeight}
          isUpdateProfile={isUpdateProfile}
          setIsUpdateProfile={setIsUpdateProfile}
        />
        <div
          style={{
            zIndex: "0",
            height: `calc(100vh - ${headerHeight})`,
            overflowY: "auto",
            position: "relative",
          }}
        >
          <div
            style={{
              minHeight: `calc(100vh - ${headerHeight} - ${footerHeight})`,
              
            }}
          >
            <Switch>
              <Route
                exact
                path={`${subDomain}/home`}
                component={CustomerMainPage}
              />
              <Route
                exact
                path={`${subDomain}/profile`}
                // component={Profile}
                render={(props) => (
                  <Profile
                    {...props}
                    isUpdateProfile={isUpdateProfile}
                    setIsUpdateProfile={setIsUpdateProfile}
                  />
                )}
              />
              <Route
                exact
                path={`${subDomain}/my-address/`}
                component={MyAddress}
              />
              <Route
                exact
                path={`${subDomain}/cart/payment`}
                component={Payment}
              />
              <Route exact path={`${subDomain}/contact`} component={Contact} />
              <Route exact path={`${subDomain}/my-order`}
                render={
                  (props) => <MyOrder {...props} isDesktop={isDesktop} isMobile={isMobile} isPortrait={isPortrait} />
                } />
              <Route exact path={`${subDomain}/cart`} component={Cart} />
              <Route
                exact
                path={`${subDomain}/edit-address/`}
                component={EditAddress}
              />
              <Route
                exact
                path={`${subDomain}/setup-password/:tokenId`}
                component={SetupPassword}
              />
              <Route
                exact
                path={`${subDomain}/reset-password/:tokenId`}
                component={ResetPassword}
              />
              <Route
                exact
                path={`${subDomain}/change-password/`}
                component={ChangePassword}
              />
              <Route
                exact
                path={`${subDomain}/menu/:productId`}
                component={MenuDetail}
              />

              {/* <Route
              exact
              path={`${subDomain}/make-a-reservation`}
              component={Reservation}
            /> */}

              <Route
                exact
                path={`${subDomain}/register`}
                component={Register}
              />
              <Route exact path={`${subDomain}/pin`} component={Pin} />
              <Route
                exact
                path={`${subDomain}/login/:accessToken/:refreshToken/:username`}
                component={Login}
              />
              <Route
                exact
                path={`${subDomain}/logout`}
                component={() => <Redirect to={`${subDomain}/`} />}
              />

              <Route
                exact
                path={`${subDomain}/`}
                component={() => <Redirect to={`${subDomain}/home`} />}
              />

              {/* for test */}
              <Route
                exact
                path={`${subDomain}/test-google-map`}
                component={SimpleMap}
              />
              {/* for test */}

              <Route path={`${subDomain}/`} component={NotFoundPage} />
            </Switch>
          </div>
          <MediaQuery minDeviceWidth={680}>
            <Footer footerHeight={footerHeight} />
          </MediaQuery>
          <MediaQuery maxDeviceWidth={680}>
            {path == `${subDomain}/home` ? (
              <Footer footerHeight={footerHeight} />
            ) : null}
          </MediaQuery>
        </div>
      </React.Fragment>
    );
  }
};

export default CustomerPage;
