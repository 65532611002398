import React from "react";
import { Title } from "../../../customers/components/title";
import titleIcon from "../../../../assets/icons/title-icon/profile.svg";
import EditProfile from "../../../../assets/icons/editProfileMobile.svg";
import { EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

const HoverDiv = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  color: #b57a08;
  width: 7.5rem;

  @media (max-width: 680px) {
    width: 3.5rem;
  }
`;
const ProfileTitle = ({ pathItems, option, onEditProfile }) => {
  return (
    <div style={{ padding: "0 10%" }}>
      <MediaQuery minDeviceWidth={680}>
        <Title
          breadcrumbItems={pathItems}
          title="โปรไฟล์ของฉัน"
          option={
            option === "not-edit" ? (
              <HoverDiv onClick={onEditProfile}>
                <div>
                  <EditOutlined />
                </div>
                <p>แก้ไขข้อมูลติดต่อ</p>
              </HoverDiv>
            ) : null
          }
          img={titleIcon}
        />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={680}>
        <Title
          breadcrumbItems={pathItems}
          title="โปรไฟล์ของฉัน"
          option={
            option === "not-edit" ? (
              <HoverDiv onClick={onEditProfile}>
                <img src={EditProfile} style={{ padding: "0 0 0 40%" }} />
              </HoverDiv>
            ) : null
          }
          img={titleIcon}
        />
      </MediaQuery>
    </div>
  );
};

export default ProfileTitle;
