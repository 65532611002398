import React from "react";
import styled from "styled-components";
import { Checkbox } from "../../../../components/checkboxes";
const FlexRowCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1075px) {
    padding-top: 1rem;
    padding-left: 3rem;
  }
  @media not screen and (min-device-width: 1224px) {
    padding-left: 0rem;
  }
`;
const FontCheckBox = styled.p`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-left: 0.5em;
  color: #000000;
  @media (max-width: 1240px) {
    font-size: 15.5px;
  }
`;
const CheckBoxDefaultLocation = ({ isDefault, onChecked, isDisabled }) => {
  return (
    <FlexRowCheckbox>
      <Checkbox
        checked={isDefault}
        onClick={onChecked}
        disabled={isDisabled}
      ></Checkbox>
      <FontCheckBox>ที่อยู่ตั้งต้นในการรับสินค้า</FontCheckBox>
    </FlexRowCheckbox>
  );
};

export default CheckBoxDefaultLocation;
