import styled from 'styled-components';
import { colors } from '../values/colors';

export const OutBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem 0;
`;

export const Label = styled.div`
    margin: 0.5rem 0;
    display: flex;
`;

export const Span = styled.span`
    margin-left: 1rem;
    font-family: Kanit;
    font-weight: ${props => props.price ? '600' : 400};
    font-size: 18px;
    line-height: 20px;
    color: ${props => props.price ? colors.buttonColor : colors.textColor1};
`;

export const SpanBox = styled.div`
    display: flex;
    flex-direction: row;
`;
