import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import {
  Text,
  OutBox,
  Button,
  SpinIcon,
  CenterSpin,
  RowDivider,
} from "../sharedComponents";
import { renderMoney } from "../../../../utils/money";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.space ? "space-between" : "flex-start")};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableText = styled(Text)`
  font-size: ${(props) => (props.option ? "0.9em" : "1.2em")};
  font-weight: ${(props) => (props.header ? 500 : 400)};
  width: ${(props) => props.width};
  color: ${(props) => (props.header ? "#A5A5A5" : null)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 1rem;
`;

const TableTextMobile = styled(Text)`
  font-size: ${(props) => (props.option ? "0.9em" : "0.872em")};
  font-weight: ${(props) => (props.header ? 500 : 400)};
  width: ${(props) => props.width};
  color: ${(props) => (props.header ? "#A5A5A5" : null)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: start;
  box-sizing: border-box;
  word-break: break-all;
  @media only screen and (max-device-width: 680px) {
    font-size: ${(props) => (props.header ? "0.85em" : "1em")};
  }
`;

const CommentText = styled(Text)`
  font-size: 1.2em;
  font-weight: 300;
  color: #a5a5a5;
  display: block;
  padding-right: 1rem;
  word-wrap: break-all;
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
`;
const columnWidth = [4, "", 8, 6, 8];
const columnWidthMobile = [12.5, 37.5, 18.5, 13, 18.5];
const rowPadding = "0.4rem 1rem";

export const TableHeader = () => (
  <div>
    <MediaQuery minDeviceWidth={680}>
      <Row space style={{ padding: rowPadding }}>
        <Row>
          <TableText width={`${columnWidth[0]}rem`} header>
            ลำดับ
          </TableText>
          <TableText header>รายการออเดอร์</TableText>
        </Row>
        <Row>
          <TableText width={`${columnWidth[2]}rem`} header>
            ราคาต่อชิ้น
          </TableText>
          <TableText width={`${columnWidth[3]}rem`} header>
            จำนวน
          </TableText>
          <TableText width={`${columnWidth[4]}rem`} header>
            ราคา (บาท)
          </TableText>
        </Row>
      </Row>
    </MediaQuery>
    <MediaQuery maxDeviceWidth={680}>
      <Row style={{ padding: "0.4rem 1rem 0.4rem", alignItems: "flex-start" }}>
        <TableTextMobile
          width={`${columnWidthMobile[0]}%`}
          header
          style={{ paddingRight: "2.5%" }}
        >
          ลำดับ
        </TableTextMobile>
        <TableTextMobile
          width={`${columnWidthMobile[1]}%`}
          header
          style={{ paddingRight: "2.5%", justifyContent: "flex-start" }}
        >
          รายการออเดอร์
        </TableTextMobile>
        <TableTextMobile
          width={`${columnWidthMobile[2]}%`}
          header
          style={{ paddingRight: "2.5%" }}
        >
          ราคา/ชิ้น
        </TableTextMobile>
        <TableTextMobile
          width={`${columnWidthMobile[3]}%`}
          header
          style={{ paddingRight: "2.5%" }}
        >
          จำนวน
        </TableTextMobile>
        <TableTextMobile
          style={{ justifyContent: "center" }}
          width={`${columnWidthMobile[4]}%`}
          style={{ paddingRight: "2.5%" }}
          header
        >
          ราคา(฿)
        </TableTextMobile>
      </Row>
    </MediaQuery>
  </div>
);

export const TableRow = ({ number, item, shorten }) => {
  var optionText = "";
  if (shorten) {
    for (var i = 0; i < item.choices.length; i++) {
      optionText += item.choices[i].nameTh;
      if (i < item.choices.length - 1) {
        optionText += ", ";
      }
    }
  } else {
    for (var optionId of Object.keys(item.options)) {
      if (item.options[optionId].choices.length > 0) {
        optionText += item.options[optionId].nameTh + " - ";
      }
      for (var j = 0; j < item.options[optionId].choices.length; j++) {
        optionText += item.options[optionId].choices[j].nameTh + ", ";
      }
    }
  }
  return (
    <Column style={{ padding: rowPadding }}>
      <Row space>
        <Row>
          <TableText width={`${columnWidth[0]}rem`}>{number + 1}</TableText>
          <TableText>{item.nameTh}</TableText>
        </Row>
        <Row>
          <TableText width={`${columnWidth[2]}rem`}>
            {renderMoney(item.pricePerUnit)}
          </TableText>
          <TableText width={`${columnWidth[3]}rem`}>
            {shorten ? item.amount : item.itemAmount}
          </TableText>
          <TableText width={`${columnWidth[4]}rem`}>
            {shorten
              ? renderMoney(item.pricePerUnit * item.amount)
              : renderMoney(item.pricePerUnit * item.itemAmount)}
          </TableText>
        </Row>
      </Row>
      {optionText.length > 0 ? (
        <Row style={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
          <TableText
            option
            style={{
              paddingLeft: `${columnWidth[0] + 1}rem`,
              width: "11rem",
              justifyContent: "flex-start",
            }}
          >
            ตัวเลือก:
          </TableText>
          <TableText
            option
            style={{
              paddingRight: `${
                columnWidth[2] + columnWidth[3] + columnWidth[4] - 1
              }rem`,
              justifyContent: "flex-start",
            }}
          >
            {optionText}
          </TableText>
        </Row>
      ) : (
        <></>
      )}
    </Column>
  );
};

const TableBodyRow = ({ name, price, amount }) => {
  return (
    <Row space>
      <MediaQuery minDeviceWidth={680}>
        <Row>
          <TableText width={`${columnWidth[0] + 1}rem`} />
          <TableText>{`- ${name}`}</TableText>
        </Row>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={680}>
        <Row style={{ width: "100%" }}>
          <TableTextMobile width={`${columnWidthMobile[0]}%`} />
          <TableTextMobile>{`- ${name}`}</TableTextMobile>
        </Row>
      </MediaQuery>

      {/* <Row>
            <TableText width={`${columnWidth[2]}rem`}>
                {100}
            </TableText>
            <TableText width={`${columnWidth[3]}rem`}>
                {amount}
            </TableText>
            <TableText width={`${columnWidth[4]}rem`}>
                {amount*price}
            </TableText>
        </Row> */}
    </Row>
  );
};

export const TableBody = ({ number, item }) => {
  return (
    <Column style={{ padding: rowPadding }}>
      <MediaQuery minDeviceWidth={680}>
        <Row space>
          <Row>
            <TableText width={`${columnWidth[0]}rem`}>{number + 1}</TableText>
            <TableText>{item.nameTh}</TableText>
          </Row>
          <Row>
            <TableText width={`${columnWidth[2]}rem`}>
              {renderMoney(item.pricePerUnit)}
            </TableText>
            <TableText width={`${columnWidth[3]}rem`}>{item.amount}</TableText>
            <TableText width={`${columnWidth[4]}rem`}>
              {renderMoney(item.pricePerUnit * item.amount)}
            </TableText>
          </Row>
        </Row>
        {item.choices.length > 0 ? (
          <Row>
            <TableText width={`${columnWidth[0]}rem`} />
            <TableText> ตัวเลือกอื่น: </TableText>
          </Row>
        ) : (
          <></>
        )}
        {item.choices.length > 0 ? (
          item.choices.map((choice) => (
            <TableBodyRow
              name={choice.nameTh}
              price={choice.price}
              amount={item.amount}
            />
          ))
        ) : (
          <></>
        )}
        {item.comment ? (
          <Row style={{ paddingTop: "1rem" }}>
            <TableText width={`${columnWidth[0]}rem`} />
            <CommentText>{`เพิ่มเติมจากลูกค้า: ${item.comment}`}</CommentText>
          </Row>
        ) : (
          <></>
        )}
        {/* <Row space>
            <Row style={{paddingTop: '1rem'}}>
                <TableText width={`${columnWidth[0]}rem`}/>
                <TableText style={{ fontWeight: 500 }}> 
                    ราคารวม 
                </TableText>
            </Row>
            <TableText 
                width={`${columnWidth[4]}rem`} 
                style={{ fontWeight: 600 }}
            >
                {item.pricePerUnit * item.amount}
            </TableText>
        </Row> */}
      </MediaQuery>
      <MediaQuery maxDeviceWidth={680}>
        <Row space style={{ width: "100%", justifyContent: "flex-start" }}>
          <Row
            style={{
              width: "100%",
              alignSelf: "flex-start",
            }}
          >
            <TableTextMobile
              width={`${columnWidthMobile[0] * 2}%`}
              style={{ paddingRight: "5%" }}
            >
              {number + 1}
            </TableTextMobile>
            <TableTextMobile
              width={`${columnWidthMobile[1] * 2}%`}
              style={{ paddingRight: "5%" , justifyContent: "start"}}
            >
              {item.nameTh}
            </TableTextMobile>
          </Row>
          <Row
            style={{
              width: "100%",
              alignSelf: "flex-start",
            }}
          >
            <TableTextMobile
              width={`${columnWidthMobile[2] * 2}%`}
              style={{ paddingRight: "5%" }}
            >
              {renderMoney(item.pricePerUnit)}
            </TableTextMobile>
            <TableTextMobile
              width={`${columnWidthMobile[3] * 2}%`}
              style={{ paddingRight: "5%" }}
            >
              {item.amount}
            </TableTextMobile>
            <TableTextMobile
              width={`${columnWidthMobile[4] * 2}%`}
              style={{ padding: "0 3%" }}
            >
              {renderMoney(item.pricePerUnit * item.amount)}
            </TableTextMobile>
          </Row>
        </Row>
        {item.choices.length > 0 ? (
          <Row>
            <TableTextMobile width={`${columnWidthMobile[0]}%`} />
            <TableTextMobile> ตัวเลือกอื่น: </TableTextMobile>
          </Row>
        ) : (
          <></>
        )}
        {item.choices.length > 0 ? (
          item.choices.map((choice) => (
            <TableBodyRow
              name={choice.nameTh}
              price={choice.price}
              amount={item.amount}
            />
          ))
        ) : (
          <></>
        )}
        {item.comment ? (
          <Row style={{ paddingTop: "1rem" }}>
            <TableTextMobile width={`${columnWidthMobile[0]}%`} />
            <CommentText>{`เพิ่มเติมจากลูกค้า: ${item.comment}`}</CommentText>
          </Row>
        ) : (
          <></>
        )}
        {/* <Row space>
            <Row style={{paddingTop: '1rem'}}>
                <TableText width={`${columnWidth[0]}rem`}/>
                <TableText style={{ fontWeight: 500 }}> 
                    ราคารวม 
                </TableText>
            </Row>
            <TableText 
                width={`${columnWidth[4]}rem`} 
                style={{ fontWeight: 600 }}
            >
                {item.pricePerUnit * item.amount}
            </TableText>
        </Row> */}
      </MediaQuery>
    </Column>
  );
};

export const TableBodyEditable = ({ number, item }) => (
  // <Row>
  <TableBody item={item} number={number} />
  // <Column width={`${columnWidth[4]}rem`}>
  // <Text>แก้ไข</Text>
  // <Text>ลบออก</Text>
  // </Column>
  // </Row>
);
