import React from "react";
import styled from "styled-components";
import { colors } from "../../../values/colors";
import { Spin, Divider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const OutBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  @media not screen and (min-device-width: 768px) {
    padding: 0 15px;
  }
`;

export const Text = styled.div`
  font-family: "Kanit";
  @media only screen and (min-width: 768px) {
    font-size: ${(props) =>
      props.price
        ? "2.25em"
        : props.title
        ? "1.75em"
        : props.subtitle
        ? "1.25em"
        : props.option
        ? "1.4em"
        : props.mini
        ? "1em"
        : props.size
        ? props.size
        : "1.2em"};
    font-weight: ${(props) =>
      props.price || props.title || props.option
        ? 600
        : props.subtitle
        ? 500
        : props.weight
        ? props.weight
        : 400};
  }
  @media only screen and (max-width: 768px) {
    font-size: ${(props) =>
      props.size
        ? props.size
        : props.price
        ? "1.80em"
        : props.priceM
        ? "1.50em"
        : props.title
        ? "1.80em"
        : props.subtitle
        ? "1.25em"
        : props.option
        ? "1.4em"
        : props.mini
        ? "1em"
        : "1.2em"};
    font-weight: ${(props) =>
      props.price || props.title || props.option || props.priceM
        ? 600
        : props.subtitle
        ? 500
        : props.weight
        ? props.weight
        : 400};
  }

  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    font-size: ${(props) =>
      props.size
        ? props.size
        : props.price
        ? "1.80em"
        : props.priceM
        ? "1.50em"
        : props.title
        ? "1.80em"
        : props.subtitle
        ? "1.0em"
        : props.option
        ? "1.4em"
        : props.mini
        ? "1em"
        : "1.2em"};
    font-weight: ${(props) =>
      props.price || props.title || props.option || props.priceM
        ? 600
        : props.subtitle
        ? 500
        : props.weight
        ? props.weight
        : 400};
  }

  line-height: ${(props) => (props.height ? props.height : "1.5em")};
  color: ${(props) =>
    props.yellow ? "#FFB800" : props.color ? props.color : colors.buttonColor};
`;

export const ColumnHalfBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.5rem 0;
`;

export const ColumnFullBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0;
`;
export const ColumnFullBox = styled.div`
  width: 100%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.left ? "flex-end" : "flex-start")};
  justify-content: "center";
`;
export const ColumnHalfBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.left ? "flex-end" : "flex-start")};
  justify-content: "flex-start";
  padding-left: ${(props) => (props.left ? 0 : "1rem")};
  padding-right: ${(props) => (props.left ? "1rem" : 0)};
`;

export const Button = styled.button`
  border: ${(props) => (props.border ? "1px solid #fbb03b" : "none")};
  background-color: ${(props) =>
    props.disabled
      ? "lightgrey"
      : props.yellow
      ? "#FFB800"
      : props.grey
      ? "#9C9C9C"
      : props.white
      ? "#FFFFFF"
      : colors.buttonColor};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? "white" : props.yellow ? colors.buttonColor : "white"};
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
  font-weight: 500;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
`;

export const SpinIcon = ({ size }) => (
  <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: size ? size : 24,
          color: colors.buttonColor,
        }}
        spin
      />
    }
  />
);

export const CenterSpin = ({ padding, height, size }) => (
  <div
    style={{
      width: "100%",
      height: `${height ? height : "100%"}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: `${padding ? padding : padding === 0 ? 0 : "12rem 0"}`,
    }}
  >
    <SpinIcon size={size ? size : 100} />
  </div>
);

export const RowDivider = styled(Divider)`
  margin: 0;
  border: 0.5px solid #e3e3e3;
`;

export const OptionButton = styled.button`
  width: ${(props) =>
    props.full ? "271px" : props.width ? props.width : "8rem"};
  height: ${(props) =>
    props.full ? "2.8rem" : props.height ? props.height : "2.5rem"};
  border: ${(props) => (props.typeA ? "none" : `1px solid ${colors.color1}`)};
  background-color: ${(props) => (props.typeA ? colors.buttonColor : "white")};
  cursor: pointer;
  color: ${(props) => (props.typeA ? "white" : `${colors.color1}`)};
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1em;
  font-weight: ${(props) => (props.full ? 600 : 400)};
  margin-left: ${(props) => (props.full ? 0 : "1rem")};

  @media not screen and (min-device-width: 1224px) {
    margin-right: ${(props) => (props.typeA ? "0.5rem" : "0")};
    width: fit-content%;
    
  }

  @media (min-width: 671px) and (max-width: 720px) {
    height: 100%;
  }
`;
