import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Text, RowDivider } from "../sharedComponents";
import { TableBody } from "../cart/cartItemTable";
import { Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const EditText = styled.div`
  font-family: "Kanit";
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
`;

const CartItem = (props) => {
  var { item, number, onDelete, onEditMenu, editable } = props;
  return (
    <div>
      <MediaQuery minDeviceWidth={680}>
        <FlexRow>
          <div style={{ width: "90%" }}>
            <TableBody item={item} number={number} />
          </div>
          {editable ? (
            <div>
              <EditText onClick={onEditMenu} style={{ color: "#B57A08" }}>
                <EditOutlined /> แก้ไข
              </EditText>
              <Popconfirm
                title="คุณต้องการลบเมนูนี้ใช่หรือไม่?"
                placement="bottomLeft"
                onConfirm={onDelete}
                okText="ใช่"
                cancelText="ไม่ใช่"
              >
                <EditText style={{ color: "#FF0000" }}>
                  <DeleteOutlined /> ลบออก
                </EditText>
              </Popconfirm>
            </div>
          ) : (
            <></>
          )}
        </FlexRow>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={680}>
        <FlexRow>
          <div style={{ width: "100%" }}>
            <TableBody item={item} number={number} />
          </div>
        </FlexRow>
        {editable ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "3%",
            }}
          >
            <div style={{ width: "15%" }}></div>
            <EditText
              onClick={onEditMenu}
              style={{ color: "#B57A08", marginRight: "1.25rem" }}
            >
              <EditOutlined /> แก้ไข
            </EditText>
            <Popconfirm
              title="คุณต้องการลบเมนูนี้ใช่หรือไม่?"
              placement="bottomLeft"
              onConfirm={onDelete}
              okText="ใช่"
              cancelText="ไม่ใช่"
            >
              <EditText style={{ color: "#FF0000" }}>
                <DeleteOutlined /> ลบออก
              </EditText>
            </Popconfirm>
          </div>
        ) : (
          <></>
        )}
      </MediaQuery>
    </div>
  );
};

export default CartItem;
