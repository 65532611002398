import React, { useState, useCallback, useContext } from "react";
import { Form, Empty, Checkbox } from "antd";
import Collapse from "../collapse";
import OrderDetail from "./detail";
import styled from "styled-components";
import { colors } from "../../../../values/colors";
import Link from "../link";
import Status from "../status";
import { ReceiptIcon, DriverIcon } from "../../../../components/icons";
import Button from "../button";
import { formatPhoneNumber } from "../../util/format";
import moment from "moment";
import { ModalContext } from "../..";
import { renderMoney } from "../../../../utils/money";

const Header = styled.div`
  display: flex;
  font-size: 1.125rem;
  color: ${colors.storeText};

  & > * {
    margin: 0px 0.25rem;
  }
`;

const Title = styled.span`
  font-weight: 600;
`;

const Highlighted = styled.span`
  color: ${colors.storeTextHightlighted};
`;

const Column = styled.div`
  width: ${(props) => props.width};
  word-wrap: break-word;
  box-sizing: border-box;
`;

const DeliverButtonContainer = styled.div`
  padding: 0.5rem;

  & button {
    padding: 0.5em 2em;
    margin-bottom: 0.5rem;
  }
`;

const OrderCollapse = React.memo(
  ({
    stage,
    selectDriver,
    cancelOrder,
    confirmPayment,
    confirmDelivered,
    setOrderId,
    selectedItems,
    setSelectedItems,
    openQrCode,
    ...props
  }) => {
    const { showImage } = useContext(ModalContext);

    const isSelected = useCallback((item) => selectedItems.includes(item), [
      selectedItems,
    ]);

    const handleCheckboxChange = useCallback(
      (item, checked) => {
        if (checked) {
          setSelectedItems([...selectedItems, item]);
        } else {
          setSelectedItems(selectedItems.filter((_item) => _item !== item));
        }
      },
      [selectedItems, setSelectedItems]
    );

    const hasSelectedItems = selectedItems.length > 0;

    const orders = props.orders;

    if (!orders || orders.length === 0) {
      return (
        <Empty
          style={{ marginTop: "12px" }}
          description="ไม่มีออเดอร์ที่อยู่ในสถานะนี้"
        />
      );
    }

    return (
      <React.Fragment>
        {stage === 2 && (
          <DeliverButtonContainer>
            <Form.Item
              validateStatus={hasSelectedItems ? undefined : "error"}
              help={
                hasSelectedItems
                  ? undefined
                  : "กรุณาเลือกออเดอร์ก่อนทำการจัดส่ง"
              }
            >
              <Button
                type={"primary"}
                onClick={selectDriver}
                disabled={!hasSelectedItems}
              >
                {"ยืนยันจัดส่งออเดอร์ที่เลือก"}
              </Button>
            </Form.Item>
          </DeliverButtonContainer>
        )}
        <Collapse expandIconPosition={"right"}>
          {orders &&
            orders.map((order) => {
              let {
                orderId,
                customer,
                totalPrice,
                addressName,
                status: { statusId, time: serverTime },
                slipImage,
                driver,
              } = order;

              customer = customer || {};

              const delivered = parseInt(statusId) === 4;

              const time = serverTime;

              const header = (
                <Header>
                  {stage === 2 && (
                    <Column
                      width={"28px"}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Checkbox
                        checked={isSelected(orderId)}
                        onChange={(e) => {
                          handleCheckboxChange(orderId, e.target.checked);
                        }}
                      />
                    </Column>
                  )}
                  <Column width={"20%"}>
                    <Title>
                      {"รายการเลขที่ "}
                      <Highlighted>{orderId.padStart(7, "0")}</Highlighted>
                    </Title>
                  </Column>
                  <Column width={"20%"}>
                    <Title>
                      {`คุณ${/^[a-zA-Z]/.test(customer.firstname) ? " " : ""}${
                        customer.firstname
                      }`}
                    </Title>
                  </Column>
                  <Column width={"15%"}>
                    {formatPhoneNumber(customer.phone)}
                  </Column>
                  {stage === 1 && (
                    <Column width={"30%"}>
                      {!!slipImage && (
                        <span>
                          <ReceiptIcon
                            color={colors.storeText}
                            width={"18px"}
                            height={"18px"}
                          />{" "}
                          <Link onClick={() => showImage(slipImage)}>
                            คลิกเพื่อดูรูปหลักฐาน
                          </Link>
                        </span>
                      )}
                    </Column>
                  )}
                  {stage === 2 && (
                    <Column width={"calc(40% - 28px)"}>
                      <Title>สถานที่จัดส่ง : </Title>
                      {addressName}
                    </Column>
                  )}
                  {stage === 3 && (
                    <Column width={"20%"}>
                      <DriverIcon
                        color={colors.storeText}
                        width={"1em"}
                        height={"1em"}
                      />
                      <Title>
                        {" "}
                        {driver[0].firstname + " " + driver[0].lastname}{" "}
                      </Title>
                      <div>{formatPhoneNumber(driver[0].phone)}</div>
                    </Column>
                  )}
                  {stage === 3 && (
                    <Column width={"20%"}>
                      <Title>{"เวลาจัดส่ง : "}</Title>
                      {time}
                    </Column>
                  )}
                  {stage === 4 && (
                    <Column width={"15%"}>
                      {delivered
                        ? `จำนวนเงิน : ${renderMoney(totalPrice)}฿`
                        : ""}
                    </Column>
                  )}
                  {stage === 4 && (
                    <Column width={"15%"}>
                      {delivered ? `เสร็จสิ้น : ${time}` : ""}
                    </Column>
                  )}
                  {stage === 4 && (
                    <Column width={"10%"}>
                      <Status status={delivered ? "success" : "error"}>
                        {delivered ? "เสร็จสิ้น" : "ยกเลิกออเดอร์"}
                      </Status>
                    </Column>
                  )}
                </Header>
              );

              return (
                <Collapse.Panel header={header} key={orderId}>
                  <OrderDetail
                    order={order}
                    stage={stage}
                    setOrderId={setOrderId}
                    cancelOrder={cancelOrder}
                    confirmPayment={confirmPayment}
                    confirmDelivered={confirmDelivered}
                    showImage={showImage}
                    openQrCode={openQrCode}
                  />
                </Collapse.Panel>
              );
            })}
        </Collapse>
      </React.Fragment>
    );
  }
);

export default OrderCollapse;
