import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { colors } from "../../../values/colors";

const Password = styled(Input.Password)`
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  color: transparent;
  caret-color: transparent;
`;

const Slot = React.forwardRef(
  ({ name, value, onChange, onkeypress, ...props }, ref) => (
    <div {...props}>
      <Password
        ref={ref}
        name={name}
        value={null}
        onChange={onChange}
        onKeyDown={onkeypress}
        visibilityToggle={false}
        maxLength={1}
      />
    </div>
  )
);

const ResponsiveSlot = styled(Slot)`
  position: relative;
  width: 20%;

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &::after {
    position: absolute;
    top: 40%;
    left: 40%;
    content: "";
    display: block;
    padding: 10%;
    background-color: ${({ value }) =>
      value !== "_" ? colors.storeText : "transparent"};
    border-radius: 50%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default class PinInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: ["_", "_", "_", "_"],
    }; // '_' mean empty
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.slots = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];

    this.focusTarget = undefined;
    this.fallbackTarget = undefined;
  }

  static getDerivedStateFromProps(props, state) {
    if ("value" in props) {
      state.values = Array.from(props.value || "____");
    }

    return state;
  }

  componentDidUpdate() {
    if (this.focusTarget || this.focusTarget === 0) {
      const target = this.slots[this.focusTarget];
      if (target) {
        target.current.focus();
      } else {
        const fallbackTarget = this.slots[this.fallbackTarget];
        fallbackTarget.current.blur();
      }
      this.focusTarget = undefined;
      this.fallbackTarget = undefined;
    }
  }

  handleInputChange(e) {
    const index = parseInt(e.target.name);
    const value = e.target.value[0]; // take only one character

    // Check if it's a digit
    if (!isFinite(parseInt(value))) {
      return;
    }

    this.focusTarget = index + 1;
    this.fallbackTarget = index;

    this.setState((prevState) => {
      const values = [...prevState.values];
      values[index] = value;

      if (this.props.onChange) {
        this.props.onChange(values.join(""));
      }

      return { values };
    });
  }

  handleKeyPress(e) {
    if (e.keyCode === 8) {
      const index = parseInt(e.target.name);

      this.focusTarget = index - 1 < 0 ? 0 : index - 1;
      this.fallbackTarget = index - 2 < 0 ? 0 : index - 2;

      this.setState((prevState) => {
        const values = [...prevState.values];
        values[index] = "_";

        if (this.props.onChange) {
          this.props.onChange(values.join(""));
        }

        return { values };
      });
    }
  }

  render() {
    const { className } = this.props;
    const { values } = this.state;
    return (
      <Container className={className}>
        {[0, 1, 2, 3].map((index) => (
          <ResponsiveSlot
            key={index}
            name={`${index}`}
            onChange={this.handleInputChange}
            onkeypress={this.handleKeyPress}
            value={values[index]}
            ref={this.slots[index]}
          />
        ))}
      </Container>
    );
  }
}
