import React from "react";
import styled from "styled-components";
import { colors } from "../../values/colors";
import { RightIcon, SendIcon, PhoneIcon } from "../../components/icons";
import AlertModal from "./components/AlertModal";
import { subDomain } from "../../services/redirect";
import { driver as driverApi } from "../../services/api";
import { message } from "antd";
import GoogleMap from "google-map-react";
import { MapMarker, calBound, MyMarker } from "./components/MapMarker";
import { config } from "../../config";
import { CenterSpin } from "../customers/components/sharedComponents";

const OrderContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 8rem;
`;

const OrderInformation = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
`;

const OrderMoreDetail = styled.div`
  width: 100%;
  height: 2.5rem;
  background: ${colors.footerColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OrderInformationFooter = styled.div`
  width: 100%;
  height: 8rem;
  position: fixed;
  bottom: 0;
  background: ${colors.textColor2};
  z-index: 1;
  display: flex;
  flow-direction: row;
  justify-content: space-around;
`;

const FinishFooter = styled.div`
  width: 100%;
  height: 3rem;
  position: fixed;
  bottom: 0;
  background: ${(props) =>
    props.finish ? colors.textColor3 : colors.footerColor};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowMap = styled.div`
  height: 100%;
  background: gray;
  padding-bottom: 8rem;
`;

const Text = styled.div`
  color: ${(props) =>
    props.WhiteColorText ? colors.textColor2 : colors.footerColor};
  font-size: ${(props) => (props.bigFont ? "1.3em" : "1em")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  padding: ${(props) => props.padding};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "initial")};
  word-wrap: break-word;
`;

const Button = styled.a`
  height: 3rem;
  width: 40%;
  border-radius: 0.5rem;
  background: ${(props) => props.bgColor};
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textColor2};
`;

class Order extends React.Component {
  state = {
    center: {
      // lat: 59.95,
      // lng: 30.33,
      // lat: 13.7232384,
      // lng: 100.5420544,
      camera: false,
      result: "No result",
    },
    zoom: 11,
    mapRef: React.createRef(),
    isShowConfirmModal: false,
    orderDetail: {},
    loading: true,
  };

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );
    this.props.setDeliveryGroupId(this.props.match.params.id);
    this.fetch();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (
          position.coords.latitude !== this.state.center.lat ||
          position.coords.longitude !== this.state.center.lng
        ) {
          this.setState({
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          return false;
        }
      },
      (err) => {
        console.log(err);
      },
      {
        enableHighAccuracy: true,
      }
    );
    return true;
  }

  async fetch() {
    try {
      await driverApi
        .deliveryOrderDetail(this.props.match.params.id)
        .then((data) => {
          this.setState({
            orderDetail: data,
          });
        });
    } catch (err) {
      message.error("เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง");
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  OpenConfirmModal = () => {
    this.setState({
      isShowConfirmModal: true,
    });
  };

  CloseConfirmModal = () => {
    this.setState({
      isShowConfirmModal: false,
    });
  };
  render() {
    // const item = this.props.history.location.state.item;
    const orderDetail = this.state.orderDetail;
    const branchPhone =
      orderDetail.branch && orderDetail.branch.phone.split(",")[0];
    return this.state.loading ? (
      <CenterSpin height={"50rem"} />
    ) : (
      <React.Fragment>
        <OrderContainer>
          <OrderInformation>
            <div
              style={{
                height: "100%",
                width: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SendIcon color={"red"} />
            </div>
            <div
              style={{
                width: "90%",
                padding: "0.5rem",
                maxHeight: "100%",
                overflowY: "auto",
              }}
            >
              <Text bold>{"จัดส่งที่ " + orderDetail.firstName}</Text>
              <Text>{`${orderDetail.addressLine} ${orderDetail.subDistrict} ${orderDetail.district} ${orderDetail.province} ${orderDetail.postalCode}`}</Text>
            </div>
          </OrderInformation>
          <OrderMoreDetail
            onClick={() => {
              this.props.history.push({
                pathname: `${subDomain}/driver/orderdetail/${this.props.match.params.id}`,
                // state: { item },
              });
            }}
          >
            <Text padding={"0.5rem"} WhiteColorText>
              รายละเอียดการจัดส่ง
            </Text>
            <div
              style={{
                marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RightIcon color={colors.textColor2} />
            </div>
          </OrderMoreDetail>
          <ShowMap>
            <a
              href={`https://www.google.com/maps/dir/${this.state.center.lat},${this.state.center.lng}/${orderDetail.addressLine}+${orderDetail.subDistrict}+${orderDetail.district}+${orderDetail.province}+${orderDetail.postalCode}/@${orderDetail.latitude},${orderDetail.longitude}`}
            >
              แสดงเส้นทางใน Google Map
            </a>
            <GoogleMap
              ref={this.state.mapRef}
              apiKey={config.googleMapKey}
              center={this.state.center}
              zoom={this.state.zoom}
            >
              <MapMarker
                lat={orderDetail.latitude}
                lng={orderDetail.longitude}
                // text={index + 1}
              />
              <MyMarker
                lat={this.state.center.lat}
                lng={this.state.center.lng}
              />
            </GoogleMap>
          </ShowMap>
          <OrderInformationFooter>
            <Button
              bgColor={colors.footerColor}
              href={`tel:${orderDetail.customerPhone}`}
            >
              <div style={{ marginRight: "0.7rem", paddingTop: "0.3rem" }}>
                <PhoneIcon color={colors.textColor2} />
              </div>
              <Text WhiteColorText>โทรหาลูกค้า</Text>
            </Button>
            <Button bgColor={colors.color1} href={`tel:${branchPhone}`}>
              <div style={{ marginRight: "0.7rem", paddingTop: "0.3rem" }}>
                <PhoneIcon color={colors.textColor2} />
              </div>
              <Text WhiteColorText>ติดต่อร้านค้า</Text>
            </Button>
            <FinishFooter onClick={this.OpenConfirmModal}>
              <Text bold WhiteColorText bigFont>
                ส่งอาหารเรียบร้อยแล้ว
              </Text>
            </FinishFooter>
          </OrderInformationFooter>
        </OrderContainer>
        <AlertModal
          title={"ยืนยันการส่งอาหาร"}
          content={"กรุณากดยืนยันเพื่อยืนยันการส่งอาหาร"}
          onConfirm={() => {
            try {
              driverApi
                .changeOrderStatus({
                  orderId: this.props.match.params.id,
                })
                .then(() => {
                  message.success("ส่งอาหารเรียบร้อยแล้ว");
                  this.props.history.goBack();
                });
              this.CloseConfirmModal();
            } catch (err) {
              message.error("ดำเนินการไม่สำเร็จ โปรดลองใหม่อีกครั้ง");
            }
          }}
          onCancel={this.CloseConfirmModal}
          isVisible={this.state.isShowConfirmModal}
        />
      </React.Fragment>
    );
  }
}

export default Order;
