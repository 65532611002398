import localName from "../values/localStorageDict";
import { message } from "antd";
import { redirectTo } from "../services/redirect";

const handleLogout = () => {
  localStorage.removeItem(localName.ACCESS_TOKEN);
  localStorage.removeItem(localName.REFRESH_TOKEN);
  localStorage.removeItem(localName.USERNAME);
  localStorage.removeItem(localName.PROFILE_IMAGE);
  let secondsToGo = 1;
  message.loading("ออกจากระบบ");
  const timer = setInterval(() => {
    secondsToGo -= 1;
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    redirectTo("/home");
  }, secondsToGo * 1000);
};

export default handleLogout;

export const handleStoreLogout = () => {
  localStorage.removeItem(localName.STORE_ACCESS_TOKEN);
  localStorage.removeItem(localName.STORE_REFRESH_TOKEN);
  let secondsToGo = 1;
  message.loading("ออกจากระบบ");
  const timer = setInterval(() => {
    secondsToGo -= 1;
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    redirectTo("/storelogin");
  }, secondsToGo * 1000);
};

export const handleDriverLogout = () => {
  localStorage.removeItem(localName.DRIVER_ACCESS_TOKEN);
  let secondsToGo = 1;
  message.loading("ออกจากระบบ");
  const timer = setInterval(() => {
    secondsToGo -= 1;
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    redirectTo("/driver");
  }, secondsToGo * 1000);
};
