import React from 'react'
import { Row } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../../values/colors';

const Badge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 0.3em;
    border-radius: 50%;
    width: 2.2em !important;
    height: 2.2em;

    font-size: 75%;
    font-weight: normal;
    color: white;
    background-color: ${colors.storeText};
`;

const TabFace = React.memo(({ title, badge }) => (
    <Row type="flex" align="middle" justify="center">
        <span>{title}</span>
        {!!badge && <Badge>{badge}</Badge>}
    </Row>
));

export default TabFace;
