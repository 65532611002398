import React from "react";
import styled from "styled-components";
import Modal from "../modal";
import Button from "../button";
import { Input } from "antd";
import { Form } from "react-final-form";
import Field from "../field";
import { validateRequiredInput } from "../../util/form/validators";
import TextArea from "../textArea";

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;

  & button {
    width: 160px;
  }
`;
// padding-top: 1.5rem;

const Container = styled.div`
  padding-top: 0.5rem;
`;

export default class CategoryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      isEditMode: false,
      initialValues: undefined,
      resolve: undefined,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.initialValuesEqual = this.initialValuesEqual.bind(this);
  }

  // #region Instance Methods
  async getName(oldName, descriptionTh) {
    // Setup promise and Show modal
    let modalResolve = undefined;
    const modalPromise = new Promise((resolve) => {
      modalResolve = resolve;
    });

    this.setState({
      visible: true,
      resolve: modalResolve,
      initialValues: { name: oldName, description: descriptionTh },
      isEditMode: Boolean(oldName),
    });

    // Gather Result
    const [values, confirmed] = await modalPromise;

    // Clean promise and Close modal
    this.setState({
      visible: false,
      resolve: null,
    });

    // Return result
    return [values && values, confirmed];
  }
  // #endregion

  // #region Event Handlers
  handleSubmit(form) {
    if (this.state.resolve) {
      this.state.resolve([form, true]);
    }
  }

  handleCancel() {
    if (this.state.resolve) {
      this.state.resolve([null, false]);
    }
  }
  // #endregion

  initialValuesEqual(a, b) {
    return a === b;
  }

  render() {
    const { visible, isEditMode, initialValues } = this.state;

    return (
      <Modal
        title={
          isEditMode ? "แก้ไขชื่อหมวดหมู่" : "กรุณาใส่ชื่อหมวดหมู่ที่ต้องการ"
        }
        visible={visible}
        destroyOnClose
      >
        <Container>
          <Form
            initialValues={initialValues}
            initialValuesEqual={this.initialValuesEqual}
            onSubmit={this.handleSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  id="category-name-input"
                  name="name"
                  component={Input}
                  validate={validateRequiredInput}
                  placeholder="ใส่ชื่อหมวดหมู่"
                  maxLength={50}
                />
                <Field
                  id="category-description-input"
                  name="description"
                  component={TextArea}
                  validate={validateRequiredInput}
                  placeholder="คำอธิบาย"
                  maxLength={100}
                />
                <ButtonRow>
                  <Button type="secondary" onClick={this.handleCancel}>
                    {"ยกเลิก"}
                  </Button>
                  <Button type="primary" buttonType="submit">
                    {isEditMode ? "บันทึก" : "ยืนยัน"}
                  </Button>
                </ButtonRow>
              </form>
            )}
          </Form>
        </Container>
      </Modal>
    );
  }
}
