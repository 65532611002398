import React, { useState } from "react";
import Modal from "../modal";
import Stack from "../stack";
import styled from "styled-components";
import Button from "../button";
import PinInput from "../pinInput";
import TextArea from "../textArea";
import { message } from "antd";

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  font-size: 1.125rem;

  &.pin {
    margin-top: 1rem;
  }
`;

const StyledPinInput = styled(PinInput)`
  margin: 0.5rem 0 1.5rem;
`;

const StyledStack = styled(Stack)`
  margin-top: 0.5rem;

  & button {
    width: 6.25rem;
  }
`;

const CancelOrderModal = ({
  visible,
  onClose,
  onSubmit,
  orderIdState,
  ...props
}) => {
  const [pin, setPin] = useState();
  const [reason, setReason] = useState();

  const isPinFilled = Boolean(pin) && !pin.includes("_");
  const isResonFilled = !!reason && reason !== "";

  const submit = () => {
    onSubmit(orderIdState, reason, pin).then((res) => {
      if (res === "success") {
        message.success("ดำเนินการสำเร็จ");
        setReason(undefined);
        setPin(undefined);
        onClose();
      } else {
        console.log(res);
        message.error("PIN ไม่ถูกต้องกรุณาตรวจสอบใหม่อีกครั้ง");
        setPin(undefined)
      }
    });
  };

  return (
    <Modal
      {...props}
      title="กรุณาใส่เหตุผลที่ยกเลิกออเดอร์"
      visible={visible}
      destroyOnClose
    >
      <StyledStack>
        <TextArea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          autoSize={{ minRows: 3, maxRows: 3 }}
          placeholder="ใส่เหตุผลที่ยกเลิกออเดอร์"
        />
        <Label className="pin">PIN</Label>
        <StyledPinInput value={pin} onChange={setPin} />
        <ButtonRow>
          <Button type="secondary" onClick={() => { onClose(); setPin(undefined); setReason(undefined); }}>
            {"ยกเลิก"}
          </Button>
          <Button
            type="primary"
            onClick={submit}
            disabled={!isPinFilled || !isResonFilled}
          >
            {"ยืนยัน"}
          </Button>
        </ButtonRow>
      </StyledStack>
    </Modal>
  );
};

export default CancelOrderModal;
