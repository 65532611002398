import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import coverBg from "../../assets/image/cover/bg.svg";
import coverBgMobile from "../../assets/image/cover/bg-mobile.svg";
import coverIm from "../../assets/image/cover/im.svg";
import coverYellow from "../../assets/image/cover/yellow.svg";
import coverOpenEveryday from "../../assets/image/cover/open-everyday.svg";
import fullCover from "../../assets/image/cover/full-cover.svg";

const CoverDiv = styled.div`
  height: 15.75rem;
  background-image: url(${coverBg});
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10% 0 10%;
`;

const CoverDivMobile = styled.div`
  height: 15rem;
  background-image: url(${coverBgMobile});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding-top: 1rem;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoSpace = styled.div`
  padding-left: ${(props) => (props.mobile ? "6rem" : "13rem")};
`;

const Cover = () => {
  const desktopMinWidth = 950;
  return (
    <div>
      <MediaQuery minDeviceWidth={desktopMinWidth}>
        {/* <CoverDiv>
          <FlexRow>
            <LogoSpace />
            <img src={coverIm} />
            <img src={coverYellow} />
          </FlexRow>
          <img src={coverOpenEveryday} />
        </CoverDiv> */}
        <img 
          src={fullCover} 
          style={{
            width: '100%',
            objectFit: 'cover'
          }}
        />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={desktopMinWidth}>
        <CoverDivMobile></CoverDivMobile>
      </MediaQuery>
    </div>
  );
};

export default Cover;
