import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../values/colors';

const getBgColor = props => {
    const { ghost, theme } = props;

    if (ghost) {
        return 'rgba(255, 255, 255, 0)';
    } else {
        switch (theme) {
            case 'primary':
                return colors.storeText;
            case 'secondary':
                return '#A5A5A5'
            case 'highlight':
                return colors.storeTextHightlighted;
            case 'alt1':
                return '#F2F2F2';
            case 'alt2':
                return '#333333';
        }
    }

    return 'inherit';
}

const getFontColor = props => {
    const { ghost, theme } = props;

    if (ghost) {
        switch (theme) {
            case 'primary':
                return colors.storeText;
            case 'secondary':
                return '#A5A5A5'
            case 'highlight':
                return colors.storeTextHightlighted;
        }
    } else {
        switch (theme) {
            case 'primary':
            case 'secondary':
                return 'white'
            case 'highlight':
            case 'alt1':
                return colors.storeText;
        }
    }

    return 'inherit';
}

const StyledButton = styled.button`
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    border: none;
    border-radius: 5px;
    padding: 0.25em 1em;
    background-color: ${getBgColor};
    opacity: 1;
    box-shadow: ${({ ghost, disabled }) => ghost || disabled
        ? '0px 0 0px 0px rgba(0,0,0,0.2)'
        : '0px 0 8px 4px rgba(0,0,0,0.2)'
    };

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
    font-weight: ${props => props.ghost ? 'normal' : 600};
    color: ${getFontColor};

    &:focus {
        outline: 0;
    }

    &[disabled] {
        opacity: 0.6;
    }

    &:hover, &:focus {
        box-shadow: ${({ ghost, disabled }) => ghost || disabled
        ? '0px 0 0px 0px rgba(0,0,0,0.2)'
        : `0px 0 8px 4px rgba(${colors.storeThemeColorRGB},0.4)`
    };
    }

    transition: all 0.4s ease, transform 0.2s ease;
`;

const Button = ({ type, buttonType = 'button', ...props }) => (
    <StyledButton {...props} theme={type} type={buttonType} />
)

export default Button;