import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../values/colors';

const Box = styled.div`
    font-weight: 600;
    color: ${colors.storeText};

    width: 3em;
    text-align: center;
    border-radius: 5px;
    background-color: ${colors.storeTextHightlighted};
`;

// Mimic Antd's Badge API
export default function Badge({ count, ...props }) {
    return (
        <Box {...props}>{count}</Box>
    );
};
