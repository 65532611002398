import React from 'react';
import styled from 'styled-components';

class Register extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            
        };

    }

    render(){
        return <div> Register </div>
    }
}

export default Register;