import React from "react";
import { message } from "antd";
import { CenterSpin } from "../../pages/customers/components/sharedComponents";
import { subDomain } from "../../services/redirect";
import { Redirect } from "react-router-dom";
import localName from "../../values/localStorageDict";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: "/",
    };
  }

  componentDidMount() {
    const redirect = localStorage.getItem(localName.LAST_PAGE) || subDomain;
    const { accessToken, refreshToken, username } = this.props.match.params;
    if (accessToken && refreshToken && username) {
      localStorage.setItem(localName.ACCESS_TOKEN, accessToken);
      localStorage.setItem(localName.REFRESH_TOKEN, refreshToken);
      localStorage.setItem(localName.USERNAME, username);
      localStorage.setItem(localName.PROFILE_IMAGE, localName.PROFILE_IMAGE);
      this.setState({
        loading: false,
        redirect: redirect,
      });
      message.success("เข้าสู่ระบบสำเร็จ");
      let secondsToGo = 1;
      const timer = setInterval(() => {
        secondsToGo -= 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        window.location.reload(false);
      }, secondsToGo * 1000);
    } else {
      message.error("การเข้าสู่ระบบไม่สำเร็จ");
      let secondsToGo = 1;
      const timer = setInterval(() => {
        secondsToGo -= 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        this.setState({
          loading: false,
          redirect: redirect,
        });
      }, secondsToGo * 1000);
    }
  }

  render() {
    //return <CenterSpin height={'50rem'}/>;
    return this.state.loading ? (
      <CenterSpin height={"50rem"} />
    ) : (
      <Redirect to={this.state.redirect} />
    );
  }
}

export default Login;
