import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useContext,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "react-final-form";
import { Row, Col, Input, Checkbox, message } from "antd";
import styled from "styled-components";
import SubPageHeader from "./components/subpageHeader";
import ImageDropZone from "./components/dropzone";
import Field from "./components/field";
import Switch from "./components/switch";
import DashBox from "./components/dashBox";
import Button from "./components/button";
import TextArea from "./components/textArea";
import { LeftArrowIcon } from "../../components/icons";
import OptionsModal from "./components/modals/optionsModal";
import OptionModal from "./components/modals/optionModal";
import ChoiceModal from "./components/modals/choiceModal";
import DNDList from "./components/dndList";
import OptionFace from "./components/menu/optionFace";
import {
  priceRule,
  thaiLanguageRule,
  englishLanguageRule,
} from "./util/form/rules";
import {
  validateRequiredInput,
  validateRequiredCheckboxGroup,
} from "./util/form/validators";
import { store, customer } from "../../services/api";
import ConfirmModal from "./components/modals/confirmModal";
import { subDomain } from "../../services/redirect";

const mockCategories = [
  { categoryId: "1", categoryNameTh: "แกงกะหรี่" },
  {
    categoryId: "2",
    categoryNameTh: "ดงบุริ",
  },
  {
    categoryId: "3",
    categoryNameTh: "ของหวาน",
  },
  {
    categoryId: "4",
    categoryNameTh: "เครื่องดื่ม",
  },
];

const OneThirdCol = styled(Col)`
  width: 32%;
`;

const ButtonRow = styled.div`
  margin-top: 3rem;

  display: flex;
  justify-content: space-between;

  & button {
    width: 7em;
  }
`;

const OptionsList = styled(DNDList)`
  max-height: 190px;
  overflow-y: auto;
`;

export default function MenuForm() {
  const [options, setOptions] = useState(undefined);
  const [allCategory, setAllCategory] = useState([]);

  const onSubmit = useCallback(
    async (form) => {
      const confirmed = await confirmModalRef.current.getConfirmation({
        title: !isInEditMode
          ? "ต้องการบันทึกข้อมูล?"
          : "ต้องการบันทึกการเปลี่ยนแปลง?",
      });
      if (confirmed) {
        const selectOptions = options
          ? options.map((item) => item.optionId)
          : [];
        const payload = {
          nameTh: form.product_name_th,
          nameEn: form.product_name_en,
          descriptionTh: form.detail_th || "",
          descriptionEn: form.detail_en || "",
          price: form.price,
          isRecommended: form.recommended || false,
          isEnabled: form.enabled || false,
          categories: form.categories,
          options: selectOptions,
        };
        const image = {
          product: form.image,
        };

        if (!isInEditMode) {
          store.product
            .addProduct(payload, (data) => {
              if (form.image != undefined) {
                var formData = new FormData();
                formData.append("product", image.product);
                formData.append("productId", data.product.productId);
                formData.append("type", "A");
                store.product.uploadProductImage(
                  formData,
                  (data) => {
                    message.success("ดำเนินการสำเร็จ");
                    history.push(`${subDomain}/store/menu`);
                  },
                  (response) => {
                    if (
                      response.message == "Request failed with status code 500"
                    ) {
                      message.error(
                        "เพิ่มเมนูใหม่ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
                      );
                    }
                  }
                );
              } else {
                message.success("ดำเนินการสำเร็จ");
                history.push(`${subDomain}/store/menu`);
              }
            })
            .then((response) => {})
            .catch((err) => {
              message.error("เพิ่มเมนูใหม่ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            });
        } else {
          payload.productId = id;
          console.log(image.product);
          store.product
            .editProduct(payload, (data) => {
              var formData = new FormData();
              formData.append(
                "product",
                image.product == undefined ? "" : image.product
              );
              formData.append("productId", payload.productId);
              formData.append("type", image.product == undefined ? "D" : "E");
              for (var pair of formData.entries()) {
                console.log(pair[0] + ", " + pair[1]);
              }
              if (typeof image.product != "string") {
                store.product.uploadProductImage(
                  formData,
                  (data) => {
                    message.success("ดำเนินการสำเร็จ");
                    history.push(`${subDomain}/store/menu`);
                  },
                  (response) => {
                    if (
                      response.message == "Request failed with status code 500"
                    ) {
                      message.error("อัปโหลดรูปไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
                    }
                  }
                );
              } else {
                message.success("ดำเนินการสำเร็จ");
                history.push(`${subDomain}/store/menu`);
              }
            })
            .then((response) => {})
            .catch((err) => {
              message.error("แก้ไขเมนูไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            });
        }
      }
    },
    [options]
  );

  const [initialValues, setInitialValues] = useState(undefined);

  const { id } = useParams();
  const isInEditMode = Boolean(id);

  const history = useHistory();
  const goBack = () => history.goBack();

  const optionsModalRef = useRef();
  const optionModalRef = useRef();
  const choiceModalRef = useRef();
  const confirmModalRef = useRef();

  useEffect(() => {
    var check = []; //check if options are deleted or not
    store.product.fetchOption().then((data) => {
      for (let i = 0; i < data.length; i++) {
        check.push(data[i].optionId);
      }

      if (isInEditMode) {
        store.product.getProductById(id).then((data) => {
          const category = [];
          for (let i = 0; i < data.categories.length; i++) {
            category.push(data.categories[i].categoryId);
          }
          setInitialValues({
            product_name_th: data.nameTh,
            product_name_en: data.nameEn,
            detail_th: data.descriptionTh,
            detail_en: data.descriptionEn,
            price: data.price,
            categories: category,
            recommended: data.isRecommended,
            enabled: data.isEnabled,
            image: data.imageUrl[0],
          });
          var options = [];
          for (let i = 0; i < data.options.length; i++) {
            if (check.includes(data.options[i].optionId)) {
              options.push(data.options[i]);
            }
          }
          setOptions(options);
        });
      }
    });
    store.product.fetchCategory().then((data) => {
      setAllCategory(data);
    });
  }, []);

  const getOptions = useCallback(() => {
    optionsModalRef.current.getOptions(options).then((res) => {
      const [data, confirmed] = res;
      if (confirmed) {
        setOptions(data);
      }
    });
  }, [optionsModalRef, setOptions, options]);

  const onOptionOrderChange = useCallback(
    (item, reorder) => {
      setOptions(reorder(options));
    },
    [options, setOptions]
  );

  return (
    <React.Fragment>
      <SubPageHeader
        icon={LeftArrowIcon}
        onIconClick={goBack}
        label={isInEditMode ? "แก้ไขเมนู" : "เพิ่มเมนูใหม่"}
      />

      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Row type="flex" justify="space-between">
                <OneThirdCol>
                  <Field
                    name="image"
                    component={ImageDropZone}
                    onChange={ImageDropZone.onChange}
                    label="รูปเมนู"
                    placeholder="คลิกหรือลากแล้ววางเพื่อเพิ่มรูป"
                    remark="ขนาดแนะนำ: 800*800 px ขึ้นไป"
                  />
                </OneThirdCol>

                <OneThirdCol>
                  <Field
                    name="product_name_th"
                    validate={validateRequiredInput}
                    rules={[thaiLanguageRule]}
                    component={Input}
                    label="ชื่อเมนู TH"
                    placeholder="ชื่อเมนู TH"
                    maxLength={100}
                  />
                </OneThirdCol>

                <OneThirdCol>
                  <Field
                    name="product_name_en"
                    validate={validateRequiredInput}
                    rules={[englishLanguageRule]}
                    component={Input}
                    label="ชื่อเมนู EN"
                    placeholder="ชื่อเมนู EN"
                    maxLength={100}
                  />
                </OneThirdCol>
              </Row>

              <Row type="flex" justify="space-between">
                <OneThirdCol>
                  <Field
                    name="detail_th"
                    component={TextArea}
                    rules={[thaiLanguageRule]}
                    label="รายละเอียด TH"
                    placeholder="รายละเอียด TH"
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    maxLength={100}
                  />
                </OneThirdCol>

                <OneThirdCol>
                  <Field
                    name="detail_en"
                    component={TextArea}
                    rules={[englishLanguageRule]}
                    label="รายละเอียด EN"
                    placeholder="รายละเอียด EN"
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    maxLength={100}
                  />
                </OneThirdCol>

                <OneThirdCol>
                  <Field
                    name="price"
                    rules={[priceRule]}
                    validate={validateRequiredInput}
                    component={Input}
                    label="ราคา"
                    placeholder="ราคา"
                  />
                </OneThirdCol>
              </Row>

              <Field.Layout label="เลือกตัวเลือกเพิ่มเติม">
                <OptionsList
                  // disableDND /* TODO enable when API is ready */
                  onOrderChange={onOptionOrderChange}
                >
                  {options &&
                    options.map((option, index) => (
                      <OptionsList.Item
                        key={option.optionId}
                        id={option.optionId.toString()}
                        index={index}
                      >
                        <OptionFace
                          mainText={option.nameTh}
                          subText={
                            (option.choices &&
                              option.choices
                                .map(({ nameTh }) => nameTh)
                                .join("/")) ||
                            "-"
                          }
                          onDelete={() => {
                            setOptions(options.filter((i) => i !== option));
                          }}
                          showEdit={false}
                          showCheckbox={false}
                        />
                      </OptionsList.Item>
                    ))}
                </OptionsList>
                <DashBox onClick={getOptions}>
                  {"+ เพิ่มตัวเลือกเพิ่มเติมของเมนู"}
                </DashBox>
              </Field.Layout>

              <Field
                name="categories"
                component={Checkbox.Group}
                validate={validateRequiredCheckboxGroup}
                label="เลือกหมวดหมู่ให้เมนู"
                subLabel="สามารถเลือกได้หลายหมวดหมู่"
                style={{ width: "100%" }}
              >
                <Row type="flex">
                  {allCategory &&
                    allCategory.map((item, index) => (
                      <Col span={4} key={index}>
                        <Checkbox value={item.categoryId}>
                          {item.nameTh}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </Field>

              <Field
                name="recommended"
                component={Switch}
                after={"เมนูแนะนำ?"}
                noError
              />
              <Field
                name="enabled"
                component={Switch}
                after={"เปิดขาย?"}
                noError
              />

              <ButtonRow>
                <Button type="secondary" onClick={goBack}>
                  ยกเลิก
                </Button>
                <Button type="primary" buttonType="submit">
                  ยืนยัน
                </Button>
              </ButtonRow>
            </form>
          );
        }}
      </Form>

      <OptionsModal
        ref={optionsModalRef}
        optionModalRef={optionModalRef}
        zIndex={1000}
        confirmModalRef={confirmModalRef}
      />
      <OptionModal
        ref={optionModalRef}
        choiceModalRef={choiceModalRef}
        confirmModalRef={confirmModalRef}
        zIndex={1001}
        destroyOnClose
      />
      <ChoiceModal ref={choiceModalRef} zIndex={1002} destroyOnClose />
      <ConfirmModal ref={confirmModalRef} zIndex={1003} />
    </React.Fragment>
  );
}
