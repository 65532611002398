import React from 'react';
import styled from 'styled-components';
import { Pagination as AntdPagination } from 'antd';

export function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <a>ก่อนหน้า</a>;
    }
    if (type === 'next') {
      return <a>ถัดไป</a>;
    }
    return originalElement;
  }

export const Pagination = styled(AntdPagination)`

    & .ant-pagination-item{
        border-color: transparent;
        font-family: Kanit;
    }

    & .ant-pagination-item a {
        display: block;
        padding: 0 6px;
        color: #333333;
        font-family: Kanit;
    }

    & .ant-pagination-item-active a {
        color: #fff;
    }

    & .ant-pagination-item-active {
        font-weight: 500;
        background: #E5A374;
        border-color: transparent;
    }

    & .ant-pagination-prev,
    & .ant-pagination-next, 
    & .ant-pagination-jump-prev,
    & .ant-pagination-jump-next{
        font-family: Kanit;
    }

`;
