import React from "react";
import styled from "styled-components";
import { Modal, Form, Input, Button, message } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { colors } from "../../values/colors";
import FormItem from "./formItem";
import FacebookIcon from "../../assets/icons/fb.svg";
import GoogleIcon from "../../assets/icons/google.svg";
import { authentication, customer } from "../../services/api";

import localName from "../../values/localStorageDict";
import SendToEmailModal from "./sendToEmailModal";
import ForgotPasswordModal from "./forgotPasswordModal";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const checkText = styled.div`
  position: absolute;
  left: 4.44%;
  right: 3.92%;
  top: 53.87%;
  bottom: 39.68%;

  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: 0.1px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const TwinTabs = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 0;
  border-radius: ${(props) => (props.left ? "5px 0 0 0" : "0 5px 0 0")};
  cursor: pointer;

  font-family: Kanit;
  font-size: 1em;
  line-height: 1.5em;

  font-weight: ${(props) => (props.active ? 600 : 400)};
  background-color: ${(props) =>
    props.active ? colors.textColor2 : colors.inActiveButtonColor};
  color: ${(props) =>
    props.active ? colors.buttonColor : colors.inActiveTextColor};
  border-bottom: ${(props) => (props.active ? "none" : "1px solid #DEDEDE")};
`;

const OrBox = styled.div`
  font-family: Kanit;
  font-size: 0.8em;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
`;
const IncorrectDiv = styled.div`
  font-family: Kanit;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  color: ${colors.errorColor};
  display: flex;
  justify-content: center;
  padding-top: 1rem;
`;

const ConfirmLogin = styled.div`
  font-family: Kanit;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.2em;
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.loginRef = React.createRef();
    this.registerRef = React.createRef();
    this.state = {
      loginEmail: "",
      loginPassword: "",
      registerEmail: "",
      text: "",
      text2: "",
      incorrect: false,
      alreadyExistEmail: false,
    };
    this.onTabChange = this.onTabChange.bind(this);
    this.onLoginEmailChange = this.onLoginEmailChange.bind(this);
    this.onLoginPasswordChange = this.onLoginPasswordChange.bind(this);
    this.onRegisterEmailChange = this.onRegisterEmailChange.bind(this);
    this.handleLoginWithEmail = this.handleLoginWithEmail.bind(this);
    this.handleLoginWithFacebook = this.handleLoginWithFacebook.bind(this);
    this.handleLoginWithGoogle = this.handleLoginWithGoogle.bind(this);
    this.confirmLogin = this.confirmLogin.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleResetFields = this.handleResetFields.bind(this);
  }
  handleResetFields(loginTab) {
    if (loginTab === "loginTab") {
      if (this.loginRef.current) {
        this.loginRef.current.resetFields();
        this.setState({
          loginEmail: "",
          loginPassword: "",
        });
      }
    }
    if (loginTab === "registerTab") {
      if (this.registerRef.current) {
        this.registerRef.current.resetFields();
        this.setState({
          registerEmail: "",
          text: "",
          text2: "",
        });
      }
    }
  }
  onTabChange(isLoginTab) {
    this.props.setLoginTab(isLoginTab);
    if (isLoginTab) {
      this.handleResetFields("registerTab");
    }
    if (!isLoginTab) {
      this.handleResetFields("loginTab");
    }
    this.setState({
      incorrect: false,
    });
  }

  onLoginEmailChange(e) {
    this.setState({
      loginEmail: e.target.value,
      incorrect: false,
    });
  }

  onLoginPasswordChange(e) {
    this.setState({
      loginPassword: e.target.value,
      incorrect: false,
    });
  }

  onRegisterEmailChange(e) {
    if (
      this.state.text !== "" &&
      this.state.text2 !== "" &&
      this.state.alreadyExistEmail === true
    ) {
      this.setState({
        text: "",
        text2: "",
        alreadyExistEmail: false,
      });
    }
    this.setState({
      registerEmail: e.target.value,
      incorrect: false,
    });
  }

  handleLoginWithEmail() {
    const payload = {
      email: this.state.loginEmail.toLowerCase(),
      password: this.state.loginPassword,
    };
    authentication.signInWithEmail(
      payload,
      ({ data }) => {
        localStorage.setItem(localName.ACCESS_TOKEN, data.accessToken);
        localStorage.setItem(localName.REFRESH_TOKEN, data.refreshToken);
        localStorage.setItem(localName.USERNAME, data.username);
        localStorage.setItem(localName.PROFILE_IMAGE, data.profileImage);
        this.confirmLogin();
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
        }
      }
    );
  }

  handleLoginWithGoogle() {
    localStorage.setItem(localName.LAST_PAGE, window.location.pathname);
    authentication.signInWithGoogle();
  }

  handleLoginWithFacebook() {
    localStorage.setItem(localName.LAST_PAGE, window.location.pathname);
    authentication.signInWithFacebook();
  }

  confirmLogin() {
    this.props.handleClose();
    message.success("เข้าสู่ระบบสำเร็จ");
    let secondsToGo = 1;
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      window.location.reload(false);
    }, secondsToGo * 1000);
  }

  handleEmailRegister = () => {
    const payload = {
      email: this.state.registerEmail.toLowerCase(),
    };
    this.props.setEmail(this.state.registerEmail.toLowerCase());
    customer.user.emailRegister(
      payload,
      ({ data }) => {
        this.props.onCloseSendToEmailTab();
        console.log("สมัครสำเร็จ");
      },
      ({ response }) => {
        this.setState({
          text: "อีเมลนี้มีอยู่ในระบบแล้ว หากลืมรหัสผ่าน ",
          text2: "กดที่นี่",
          alreadyExistEmail: true,
        });
        console.log("res", response);
        console.log("มีอีเมลแล้ว");
      }
    );
  };

  handleEmailRegisterFailed = (res) => {
    this.setState({
      text: "",
      text2: "",
    });
    console.log("failed", res);
  };

  handleForgotPassword() {
    this.props.onCloseForgotPasswordTab();
    this.handleResetFields("loginTab");
    this.handleResetFields("registerTab");
  }

  handleCloseLoginModal = () => {
    this.props.handleClose();
    this.setState({
      incorrect: false,
    });
  };

  render() {
    const {
      loginEmail,
      loginPassword,
      incorrect,
      text,
      text2,
      alreadyExistEmail,
    } = this.state;
    const {
      visible,
      handleClose,
      isLoginTab,
      visibleSendToEmailModal,
      visibleForgotPasswordModal,
      onCloseSendToEmailTab,
      onCloseForgotPasswordTab,
      backToLoginTab,
      registerEmail,
      setEmail,
    } = this.props;
    return (
      <Modal
        title={null}
        footer={null}
        visible={visible}
        width={"24rem"}
        closable={false}
        bodyStyle={{ padding: 0 }}
        style={{ borderRadius: "5px" }}
        onCancel={this.handleCloseLoginModal}
        destroyOnClose
      >
        <Row>
          <TwinTabs
            left
            active={isLoginTab}
            onClick={() => this.onTabChange(true)}
          >
            เข้าสู่ระบบ
          </TwinTabs>
          <TwinTabs
            active={!isLoginTab}
            onClick={() => this.onTabChange(false)}
          >
            สมัครสมาชิก
          </TwinTabs>
        </Row>
        {incorrect ? (
          <IncorrectDiv> อีเมลหรือรหัสผ่านไม่ถูกต้อง </IncorrectDiv>
        ) : (
          <></>
        )}
        {isLoginTab ? (
          <Column>
            <Form
              onFinish={this.handleEmailLogin}
              style={{ lineHeight: 1.2 }}
              layout={"vertical"}
              hideRequiredMark
              ref={this.loginRef}
            >
              <FormItem
                label="อีเมล"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกข้อมูล!",
                    type: "email",
                  },
                ]}
              >
                <Input value={loginEmail} onChange={this.onLoginEmailChange} />
              </FormItem>
              <FormItem
                label="รหัสผ่าน"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกข้อมูล!",
                  },
                ]}
              >
                <Input.Password
                  value={loginPassword}
                  onChange={this.onLoginPasswordChange}
                />
              </FormItem>
              <FormItem style={{ marginBottom: "0.75rem", lineHeight: "0rem" }}>
                <Button
                  style={{ marginBottom: "0rem", lineHeight: "0rem" }}
                  type="primary"
                  htmlType="submit"
                  onClick={this.handleLoginWithEmail}
                >
                  เข้าสู่ระบบ
                </Button>
              </FormItem>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <TwinTabs
                  style={{
                    marginBottom: "0rem",
                    lineHeight: "0rem",
                    width: "5rem",
                    fontWeight: "450",
                  }}
                  active={isLoginTab}
                  onClick={this.handleForgotPassword}
                >
                  ลืมรหัสผ่าน?
                </TwinTabs>
              </div>
              <Row>
                <OrBox>หรือ</OrBox>
              </Row>
              <FormItem>
                <Button
                  style={{
                    height: "2.9rem",
                    backgroundColor: `${colors.facebookColor}`,
                    color: `${colors.textColor2}`,
                    fontWeight: 300,
                    fontFamily: "Kanit",
                    alignItems: "center",
                    padding: "0",
                  }}
                  onClick={this.handleLoginWithFacebook}
                >
                  <img
                    src={FacebookIcon}
                    style={{
                      paddingRight: "0.2rem",
                      height: "2rem ",
                      width: "1.57rem",
                    }}
                  />
                  Login with Facebook
                </Button>
              </FormItem>
              <FormItem>
                <Button
                  style={{
                    height: "2.9rem",
                    backgroundColor: `${colors.googleColor}`,
                    color: "#282828",
                    fontWeight: 400,
                    fontFamily: "Kanit",
                    alignItems: "center",
                  }}
                  onClick={this.handleLoginWithGoogle}
                >
                  <img src={GoogleIcon} style={{ paddingRight: "0.5rem" }} />
                  Login with Google
                </Button>
              </FormItem>
            </Form>
          </Column>
        ) : (
          <Column>
            <Form
              onFinish={this.handleEmailRegister}
              onFinishFailed={this.handleEmailRegisterFailed}
              style={{ lineHeight: 1.2 }}
              layout={"vertical"}
              hideRequiredMark
              ref={this.registerRef}
            >
              <FormItem
                label="อีเมล"
                name="regEmail"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกข้อมูล!",
                    type: "email",
                  },
                ]}
              >
                <Input
                  value={registerEmail}
                  onChange={this.onRegisterEmailChange}
                />
              </FormItem>
              <checkText style={{ color: "red" }}>{this.state.text}</checkText>
              <checkText
                style={{ color: "#FBB03B", cursor: "pointer" }}
                onClick={this.handleForgotPassword}
              >
                {this.state.text2}
              </checkText>
              <FormItem style={{ marginTop: "1rem" }}>
                <Button type="primary" htmlType="submit">
                  สมัครสมาชิก
                </Button>
              </FormItem>
            </Form>
          </Column>
        )}
      </Modal>
    );
  }
}

export default LoginModal;
