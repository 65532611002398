import React from "react";
import styled from "styled-components";
import { OutBox, Label, Span, SpanBox } from "./choice-components";
import { colors } from "../values/colors";

const Icon = styled.div`
  width: 13px;
  height: 13px;
  background: ${colors.buttonColor};
  border-radius: 3px;
  ${(props /*disable ให้checkboxกดไม่ได้ ในหน้าก่อนปักหมุด*/) =>
    props.disabled &&
    `
    width: 30px;
    height: 18px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.05);
    `}
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 0.8px solid #dcdcdc;
  background: #FFFFFF
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events:${(props) => (props.disabled ? "none" : "auto")};
  ${(props) => props.disabled && `background: #C2C2C2`}
  
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const CheckboxContainer = styled.div`
  display: inline;
  vertical-align: middle;
`;

export const Checkbox = ({ index, checked, onClick, disabled, ...props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox
      disabled={disabled}
      checked={checked}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(index);
      }}
    >
      <Icon disabled={disabled} />
    </StyledCheckbox>
  </CheckboxContainer>
);

class Checkboxes extends React.Component {
  constructor(props) {
    super(props);
    this.toggleChecked = this.toggleChecked.bind(this);
  }

  toggleChecked(index) {
    var { onChange, optionIndex } = this.props;
    onChange(optionIndex, index);
  }

  render() {
    const { list, checked } = this.props;
    return (
      <OutBox>
        {list.map((choice, index) => (
          <Label>
            <Checkbox
              index={index}
              checked={checked[index]}
              onClick={this.toggleChecked}
              key={index}
            />
            <SpanBox>
              <Span> {choice.nameTh} </Span>
              {Number(choice.price) !== 0 ? (
                <Span price>
                  {" "}
                  {choice.price > 0 ? "+" : choice.price < 0 ? "" : ""}
                  {choice.price} ฿{" "}
                </Span>
              ) : (
                <div />
              )}
            </SpanBox>
          </Label>
        ))}
      </OutBox>
    );
  }
}

export default Checkboxes;
