import React from "react";
import { Form } from "react-final-form";
import { Input, Radio, Row } from "antd";
import styled from "styled-components";
import Modal from "../modal";
import Button from "../button";
import Switch from "../switch";
import Field from "../field";
import { priceRule } from "../../util/form/rules";
import { thaiLanguageRule, englishLanguageRule } from "../../util/form/rules";
import {
  validateRequiredInput,
  validateNotZeroInput,
  validateRequiredRadioGroup,
} from "../../util/form/validators";

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 1.5rem;

  & button {
    width: 6em;
  }
`;

const Container = styled.div`
  padding-top: 1rem;
`;

const PRICE_EFFECT = Object.freeze({
  INCREASE: 1,
  DECREASE: 2,
  NONE: 3,
});

let generatedId = 0;

export default class ChoiceModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      isEditMode: false,
      initialValues: undefined,
      resolve: undefined,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.initialValuesEqual = this.initialValuesEqual.bind(this);
    this.validateInputOfRadio = this.validateInputOfRadio.bind(this);
  }

  // #region Instance Methods
  async getChoice(initialValues) {
    // Setup promise and Show modal
    let modalResolve = undefined;
    const modalPromise = new Promise((resolve) => {
      modalResolve = resolve;
    });

    const isEditMode = Boolean(initialValues);
    this.setState({
      visible: true,
      resolve: modalResolve,
      isEditMode,
      initialValues: initialValues || {},
    });

    // Gather Result
    const [values, confirmed] = await modalPromise;
    if (confirmed && !isEditMode) {
      // will be used as DNDList.item's id
      values.generatedId = `@gen(${++generatedId})`;
    }

    // Clean promise and Close modal
    this.setState({
      visible: false,
      resolve: null,
    });

    // Return result
    return [values, confirmed];
  }
  // #endregion

  // #region Event Handlers
  handleSubmit(form) {
    if (this.state.resolve) {
      this.state.resolve([form, true]);
    }
  }

  handleCancel() {
    if (this.state.resolve) {
      this.state.resolve([null, false]);
    }
  }
  // #endregion

  initialValuesEqual(initialA, initialB) {
    return initialA === initialB;
  }

  validateInputOfRadio(typeOfRow, value, allValues) {
    if (allValues && allValues.priceEffect === typeOfRow) {
      return validateNotZeroInput(value);
    }
    return undefined;
  }

  render() {
    const { visible, isEditMode, initialValues } = this.state;

    const props = this.props;

    return (
      <Modal
        {...props}
        title={isEditMode ? "แก้ไขช้อยส์" : "เพิ่มช้อยส์ใหม่"}
        visible={visible}
        destroyOnclose
      >
        <Form
          initialValues={initialValues}
          initialValuesEqual={this.initialValuesEqual}
          onSubmit={this.handleSubmit}
        >
          {(props) => {
            const getField = (field) => {
              const fieldState = props.form.getFieldState(field);
              return fieldState && fieldState.value;
            };
            const priceEffect = getField("priceEffect");

            return (
              <form onSubmit={props.handleSubmit}>
                <Container>
                  <Field
                    name="nameTh"
                    component={Input}
                    label="ชื่อช้อยส์ TH"
                    placeholder="ชื่อช้อยส์ TH"
                    rules={[thaiLanguageRule]}
                    validate={validateRequiredInput}
                  />
                  <Field
                    name="nameEn"
                    component={Input}
                    label="ชื่อช้อยส์ EN"
                    placeholder="ชื่อช้อยส์ EN"
                    rules={[englishLanguageRule]}
                    validate={validateRequiredInput}
                  />
                  <Field
                    name="priceEffect"
                    component={Radio.Group}
                    label="ช้อยส์นี้มีผลต่อราคาอย่างไร?"
                    smallLabel
                    onChange={() => {
                      props.form.batch(() => {
                        props.form.change("increaseValue", undefined);
                        props.form.change("decreaseValue", undefined);
                      });
                    }}
                    validate={validateRequiredRadioGroup}
                  >
                    <Row align="middle" style={{ marginBottom: "0.4em" }}>
                      <Radio value={PRICE_EFFECT.INCREASE}>{"ราคาเพิ่ม"}</Radio>
                      <Field
                        name="increaseValue"
                        rules={[priceRule]}
                        component={Input}
                        noError
                        validateBorder={priceEffect === PRICE_EFFECT.INCREASE}
                        noMinHeight
                        style={{
                          width: "4em",
                          height: "1.6em",
                          marginRight: "0.5em",
                        }}
                        disabled={priceEffect !== PRICE_EFFECT.INCREASE}
                        validate={(value, allValues) => {
                          return this.validateInputOfRadio(
                            PRICE_EFFECT.INCREASE,
                            value,
                            allValues
                          );
                        }}
                      />
                      <span>{"บาท"}</span>
                    </Row>
                    <Row align="middle" style={{ marginBottom: "0.4em" }}>
                      <Radio value={PRICE_EFFECT.DECREASE}>{"ราคาลดลง"}</Radio>
                      <Field
                        name="decreaseValue"
                        rules={[priceRule]}
                        component={Input}
                        noError
                        validateBorder={priceEffect === PRICE_EFFECT.DECREASE}
                        noMinHeight
                        style={{
                          width: "4em",
                          height: "1.6em",
                          marginRight: "0.5em",
                        }}
                        disabled={priceEffect !== PRICE_EFFECT.DECREASE}
                        validate={(value, allValues) => {
                          return this.validateInputOfRadio(
                            PRICE_EFFECT.DECREASE,
                            value,
                            allValues
                          );
                        }}
                      />
                      <span>{"บาท"}</span>
                    </Row>
                    <Row align="middle" style={{ marginBottom: "0.4em" }}>
                      <Radio value={PRICE_EFFECT.NONE}>
                        {"ไม่มีผลต่อราคา"}
                      </Radio>
                    </Row>
                  </Field>
                  {/* <Field
                    name="outOfStock"
                    component={Switch}
                    after="ของหมดสต็อก?"
                    noError
                  /> */}
                  <ButtonRow>
                    <Button type="secondary" onClick={this.handleCancel}>
                      {"ยกเลิก"}
                    </Button>
                    <Button type="primary" buttonType="submit">
                      {"บันทึก"}
                    </Button>
                  </ButtonRow>
                </Container>
              </form>
            );
          }}
        </Form>
      </Modal>
    );
  }
}
