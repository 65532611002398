import React, { useRef, useEffect, useCallback, useState } from "react";
import useDataState from "../../hooks/useDataState";
import styled from "styled-components";
import { UserIcon } from "../../components/icons";
import SubPageHeader from "./components/subpageHeader";
import Button from "./components/button";
import DriverFormModal from "./components/modals/driverForm";
import DriverInfoModal from "./components/modals/driverInfo";
import DriverTable from "./components/tables/driver";
import { delay } from "./util/promise";
import { message } from "antd";
import { store } from "../../services/api";
import ConfirmModal from "./components/modals/confirmModal";

const PAGE_SIZE = 10;

const MOCK_ROW = (
  driverId,
  name,
  username,
  phone,
  password,
  note,
  isFulltime
) => ({
  driverId,
  name,
  username,
  phone,
  password,
  note,
  isFulltime,
});

const MOCK_DATA = [
  MOCK_ROW(
    1,
    "ธนพล เพิ่มพูน",
    "thanos",
    "0818328583",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    true
  ),
  MOCK_ROW(
    2,
    "ไพฑูรย์ สุขสม",
    "python",
    "0812348821",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    true
  ),
  MOCK_ROW(
    3,
    "ชดช้อย ชวนชม",
    "chod.choi",
    "0812381257",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    true
  ),
  MOCK_ROW(
    4,
    "อภิรมย์ ปฐมกาล",
    "api.rom",
    "0812382198",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    true
  ),
  MOCK_ROW(
    5,
    "นรากร อมรฤทธิ์",
    "rng",
    "0813842312",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    true
  ),
  MOCK_ROW(
    6,
    "พงษ์สิทธิ์ มิตรสมาน",
    "pongsit",
    "0855642246",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    true
  ),
  MOCK_ROW(
    7,
    "มุ่งหมาย สายธาร",
    "moongmai",
    "0812580932",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    false
  ),
  MOCK_ROW(
    8,
    "อภิบาล งานดี",
    "apibaan",
    "0812402012",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    false
  ),
  MOCK_ROW(
    9,
    "ทวี วงศ์พิทักษ์",
    "tweee",
    "0822124987",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    false
  ),
  MOCK_ROW(
    10,
    "วรลักษณ์ สุขศรี",
    "wora.wora.wora",
    "0961235942",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    false
  ),
  MOCK_ROW(
    11,
    "นรธรรม จำปี",
    "goody",
    "0941239843",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    false
  ),
  MOCK_ROW(
    12,
    "มากมี อนันตกาล",
    "maak.mee",
    "0921459987",
    "xxxxxxx",
    "วินมอเตอร์ไซค์",
    false
  ),
];

const AddButton = styled(Button)`
  width: 10em;
`;

export default function Driver() {
  const drivers = useDataState();
  const [allDriver, setAllDriver] = useState();

  const pageSlice = async (page, pageSize) => {
    // await delay(2000);
    return {
      data: allDriver.slice((page - 1) * pageSize, page * pageSize),
      totalCount: allDriver.length,
    };
  };

  const loadPage = useCallback(
    (page) => {
      drivers.load(pageSlice(page, PAGE_SIZE), page);
    },
    [drivers]
  );

  const driverFormModal = useRef();
  const driverInfoModal = useRef();
  const confirmModal = useRef();

  const addDriver = useCallback(() => {
    driverFormModal.current.getData().then(async ([data, confirmed]) => {
      if (confirmed) {
        /* TODO call ADD API and update UI */
        try {
          var index = data.name.indexOf(" ");
          let firstName = "";
          let lastName = "";
          if (index === -1) {
            firstName = data.name;
            lastName = " ";
          } else {
            firstName = data.name.substr(0, index);
            lastName = data.name.substr(index + 1);
          }
          await store.driver
            .driverRegister({
              firstname: firstName,
              lastname: lastName,
              phone: data.phone,
              note: data.note,
              isTemp: data.isTemp,
            })
            .then(() => {
              message.success("เพิ่มคนส่งอาหารสำเร็จ");
              store.driver.getAllDriver().then((data) => {
                setAllDriver(data);
              });
            });
        } catch (err) {
          message.error("ไม่สามารถเพิ่มคนขับได้ โปรดลองใหม่อีกครั้ง");
        }
      }
    });
  }, [driverFormModal]);

  const editDriver = useCallback(
    (initialValues) => {
      driverFormModal.current
        .getData(initialValues)
        .then(([data, confirmed]) => {
          if (confirmed) {
            /* TODO call UPDATE API and update UI */
            try {
              var index = data.name.indexOf(" ");
              var firstName;
              var lastName;
              if (index === -1) {
                firstName = data.name;
                lastName = " ";
              } else {
                firstName = data.name.substr(0, index);
                lastName = data.name.substr(index + 1);
              }
              store.driver
                .editDriver({
                  driverId: data.driverId,
                  firstName: firstName,
                  lastName: lastName,
                  phone: data.phone,
                  note: data.note,
                  is_temp: data.isTemp,
                })
                .then(() => {
                  message.success("แก้ไขข้อมูลคนส่งอาหารสำเร็จ");
                  store.driver.getAllDriver().then((data) => {
                    setAllDriver(data);
                  });
                });
            } catch (err) {
              message.error("ไม่สามารถแก้ไขข้อมูลคนขับได้ โปรดลองใหม่อีกครั้ง");
            }
          }
        });
    },
    [driverFormModal]
  );

  const deleteDriver = useCallback(async ({ driverId: id }) => {
    /* TODO call DELETE API and update UI */
    const confirmed = await confirmModal.current.getConfirmation({
      title: "ยืนยันการลบคนส่งอาหาร",
    });

    if (confirmed) {
      try {
        store.driver.deleteDriver(id).then(() => {
          message.success("ลบข้อมูลคนส่งอาหารสำเร็จ");
          store.driver.getAllDriver().then((data) => {
            setAllDriver(data);
          });
        });
      } catch (err) {
        message.error("ไม่สามารถลบข้อมูลคนขับได้ โปรดลองใหม่อีกครั้ง");
      }
    }
  }, []);

  useEffect(() => {
    try {
      store.driver.getAllDriver().then((data) => {
        setAllDriver(data);
      });
    } catch (err) {
      message.error("เกิดข้อผิดพลาดในการดึงข้อมูล");
    }
  }, []);

  useEffect(() => {
    if (!!allDriver) {
      loadPage(1);
    }
  }, [allDriver]);

  return (
    <React.Fragment>
      <SubPageHeader
        icon={UserIcon}
        label={"คนส่งอาหาร"}
        tail={
          <AddButton type="primary" onClick={addDriver}>
            {"เพิ่มคนส่งอาหาร"}
          </AddButton>
        }
      />

      <DriverTable
        dataSource={drivers.data && drivers.data.data}
        loading={drivers.loading}
        onEdit={editDriver}
        onDelete={deleteDriver}
        onClickName={driverInfoModal.current && driverInfoModal.current.show}
        pagination={{
          onChange: loadPage,
          total: drivers.data && drivers.data.totalCount,
          pageSize: PAGE_SIZE,
          current: drivers.dataKey,
        }}
      />

      <DriverFormModal ref={driverFormModal} />

      <DriverInfoModal ref={driverInfoModal} />
      <ConfirmModal ref={confirmModal} />
    </React.Fragment>
  );
}
