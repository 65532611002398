import React from 'react';
import GoogleMapReact from 'google-map-react';
import { config } from '../config';
import { MyMarker } from "../pages/drivers/components/MapMarker";
import styled from "styled-components";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;
const SearchBoundary = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 73rem;
`;

class SimpleMap extends React.Component {
  // static defaultProps = {
  //   center: {
  //     lat: 59.95,
  //     lng: 30.33
  //   },
  //   zoom: 11
  // };
  constructor(props) {
    super(props);

    this.state = {
      mapsApiLoaded: false,
      mapInstance: null,
      mapsapi: null,
      center: {
        lat: 59.95,
        lng: 30.33
      },
      zoom: 11
    };

    this.apiLoaded = this.apiLoaded.bind(this);
  }

  apiLoaded = (map, maps) => {
    this.setState({
      mapsApiLoaded: true,
      mapInstance: map,
      mapsapi: maps,
    });
  }

  render() {
    console.log(this.state)
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${config.googleMapKey}`, libraries: ['places', 'drawing'] }}
          defaultCenter={this.state.center}
          defaultZoom={this.state.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            this.apiLoaded(map, maps);
          }}
        >
          <MyMarker lat={this.state.center.lat}
            lng={this.state.center.lng} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;

