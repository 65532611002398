import axios from "axios";
import {
  createAuthCycle,
  createStoreAuthCycle,
  createDriverAuthCycle,
} from "./authCycle";
import { config } from "../config";
import localName from "../values/localStorageDict";
import Password from "antd/lib/input/Password";

const baseURL = config.apiUrl;

const commonApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const authorizedApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",

    Authorization: `Bearer ${localStorage.getItem(localName.ACCESS_TOKEN)}`,
  },
});

const authorizedDriverApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",

    Authorization: `Bearer ${localStorage.getItem(
      localName.DRIVER_ACCESS_TOKEN
    )}`,
  },
});

const storeApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem(
      localName.STORE_ACCESS_TOKEN
    )}`,
  },
});

createAuthCycle(authorizedApi);
createStoreAuthCycle(storeApi);
createDriverAuthCycle(authorizedDriverApi);

export const checked = {
  Checkedstatus: () =>
    commonApi.get(`/status`).catch((err) => {
      throw err;
    }),
};

export const authentication = {
  signInWithEmail: (payload, callback, onRejected) =>
    commonApi
      .post("/auth/login/email", payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  signInWithGoogle: () => (window.location = `${baseURL}/auth/login/google`),
  signInWithFacebook: () =>
    (window.location = `${baseURL}/auth/login/facebook`),
  signUp: (payload, callback, onRejected) =>
    commonApi
      .post("/sign-up", payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  signUpWithImage: (formData) =>
    commonApi.post("/sign-up-with-image", formData, {
      "Content-Type": "multipart/form-data",
    }),
  getNewToken: (refreshToken) =>
    commonApi
      .get("/auth/login/getAccessByRefresh", {
        headers: {
          "Content-Type": "application/json",
          Authorization: refreshToken,
        },
      })
      .then(({ data }) => data),
  signInStore: (payload, callback, onRejected) =>
    commonApi
      .post("/restaurant/branch/signIn", payload)
      .then(({ data }) => callback(data))
      .catch(({ response }) => onRejected(response)),
};

export const customer = {
  product: {
    getRecommended: (page, pageSize, callback) =>
      commonApi
        .get(
          `/customer/product/getRecommendProduct?page=${page}&pageSize=${pageSize}`
        )
        .then(({ data, status }) => callback(status, data)),
    getProductList: (pageSize, callback) =>
      commonApi
        .get(`/customer/product/getAllCategory?pageSize=${pageSize}`)
        .then(({ data, status }) => callback(status, data)),
    getProductListPage: (categoryId, page, pageSize, callback) =>
      commonApi
        .get(
          `/customer/product/getProductByCategory?categoryId=${categoryId}&page=${page}&pageSize=${pageSize}`
        )
        .then(({ data, status }) => callback(status, data)),
    getProductDetail: (productId, callback) =>
      commonApi
        .get(`/customer/product/getProductDetail?productId=${productId}`)
        .then(({ data, status }) => callback(status, data)),
    searchProductInCategory: (
      search,
      categoryId,
      language,
      page,
      pageSize,
      callback
    ) =>
      commonApi
        .get(
          `/customer/product/searchProductInCategory?search=${search}&categoryId=${categoryId}&language=${language}&page=${page}&pageSize=${pageSize}`
        )
        .then(({ data, status }) => callback(status, data)),
  },

  order: {
    createOrder: (order, callback) =>
      authorizedApi
        .post("/customer/order/createOrder", order)
        .then(({ data }) => callback(data))
        .catch(({ response }) => {
          throw response;
        }),
    getActiveOrder: (callback, onRejected) =>
      authorizedApi
        .get("/customer/order/getActiveOrder")
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    getOrderHistory: (page, callback, onRejected) =>
      authorizedApi
        .get(`/customer/order/getOrderHistory?page=${page}`)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    changeOrderStatus: (orderId, nextStatusId) =>
      authorizedApi.post("/customer/order/changeOrderStatus", {
        orderId,
        nextStatusId,
      }),
    getChangedActiveOrder: (lastUpdate, callback) =>
      authorizedApi
        .get(
          `/customer/order/getChangedActiveOrder?currentTimestamp=${lastUpdate}`
        )
        .then(({ data }) => callback(data)),

    uploadOrderSlip: (slip, callback) =>
      authorizedApi
        .post("/customer/order/uploadOrderSlip", slip)
        .then((data, status) => callback(data, status))
        .catch(({ response }) => console.log("catch", response)),
    genQr: (amount, callback) =>
      authorizedApi
        .post("/customer/order/genQr", amount)
        .then((data) => callback(data.data, data.status))
        .catch(({ response }) => console.log("catch", response)),
  },
  address: {
    fetch: (callback, onRejected) =>
      authorizedApi
        .get(`/customer/address/fetch`)
        .then(({ data, status }) => callback(data, status))
        .catch(({ response }) => onRejected({ response })),
    insert: (address, callback, onRejected) =>
      authorizedApi
        .post(`/customer/address/insert`, address)
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => onRejected({ response })),
    delete: (addressId, callback, onRejected) =>
      authorizedApi
        .delete(`/customer/address/delete?addressId=` + String(addressId))
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => onRejected({ response })),
    edit: (address, callback, onRejected) =>
      authorizedApi
        .patch(`/customer/address/edit`, address)
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => onRejected({ response })),
    setDefault: (addressId, onRejected) =>
      authorizedApi
        .patch(`/customer/address/setDefault`, addressId)
        .then(({ data }) => console.log("SETDEFAULT SUCCESSFUL", data))
        .catch(({ response }) => onRejected({ response })),
    getDeliveryCostByAddress: (address, callback) =>
      authorizedApi
        .get(
          `/customer/address/getDeliveryCostByAddress?latitude=${address.latitude}&longitude=${address.longitude}&branchId=${address.branchId}`
        )
        .then(({ data }) => callback({ data })),
  },
  user: {
    emailRegister: (email, callback, onRejected) =>
      authorizedApi
        .post("/customer/user/emailRegister", email)
        .then(({ data }) => callback(data))
        .catch(({ response }) => {
          onRejected({ response });
        }),
    getProfileData: (callback, onRejected) =>
      authorizedApi
        .get("/customer/user/getProfile")
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => onRejected({ response })),
    uploadProfileImg: (img, callback) =>
      authorizedApi
        .post("/customer/user/uploadProfileImage", img)
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => {
          console.log("REJECTED", response);
        }),
    editProfile: (payload, callback, onRejected) =>
      authorizedApi
        .patch("/customer/user/editProfile", payload)
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => onRejected({ response })),
    editPassword: (payload, callback, onRejected) =>
      authorizedApi
        .patch("/customer/user/editPassword", payload)
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => onRejected({ response })),
    confirmPassword: (payload, callback) =>
      authorizedApi
        .post("/customer/user/confirmPassword", payload)
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => {
          console.log("REJECTED");
          console.log(response);
        }),
    confirmForgotPassword: (payload, callback) =>
      authorizedApi
        .post("/customer/user/confirmForgotPassword ", payload)
        .then(({ data, status }) => callback({ data, status }))
        .catch(({ response }) => {
          console.log("REJECTED");
          console.log(response);
        }),
    forgotPassword: (payload, callback, onRejected) =>
      authorizedApi
        .post(`/customer/user/forgotPassword`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected({ response })),
  },
  store: {
    getBranchInformation: (id) =>
      commonApi
        .get(`/restaurant/branch/getBranchInformation?branchId=${id}`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
  },
};

export const store = {
  order: {
    getAllStage: () =>
      storeApi
        .get(`/restaurant/order/getOrderAllStage`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    getAllUpdates: (lastUpdateTime) =>
      storeApi
        .get(
          `/restaurant/order/getChangedOrderAllStage?currentTimestamp=${encodeURIComponent(
            lastUpdateTime.replace("/", "-")
          )}`
        )
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    setOrderStatus: (orderId, statusId) =>
      storeApi
        .post("/restaurant/order/changeOrderStatus", {
          orderId,
          nextStatusId: statusId,
        })
        .catch((err) => {
          throw err;
        }),
    cancelOrder: (data) =>
      storeApi.post(`/restaurant/order/canceledOrder`, data).catch((err) => {
        throw err;
      }),
    genTokenForDriver: (data) =>
      storeApi
        .post(`/restaurant/order/genTokenForDriver`, data)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
  },
  product: {
    getProductByCategory: () =>
      storeApi
        .get(`/restaurant/product/fetchProductByCategory`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    fetchCategory: () =>
      storeApi
        .get(`/restaurant/category/fetch`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    addCategory: (data) =>
      storeApi.post(`/restaurant/category/add`, data).catch((err) => {
        throw err;
      }),
    editCategory: (data) =>
      storeApi.patch(`/restaurant/category/edit`, data).catch((err) => {
        throw err;
      }),
    deleteCategory: (categoryId) =>
      storeApi
        .delete(`/restaurant/category/delete?categoryId=` + String(categoryId))
        .catch((err) => {
          throw err;
        }),
    updatePositionCategoty: (data) =>
      storeApi
        .patch(`/restaurant/category/updatePosition`, data)
        .catch((err) => {
          throw err;
        }),
    fetchOption: () =>
      storeApi
        .get(`/restaurant/option/fetch`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    addOption: (data) =>
      storeApi.post(`/restaurant/option/add`, data).catch((err) => {
        throw err;
      }),
    editOption: (data) =>
      storeApi
        .patch(`/restaurant/option/edit`, data)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    deleteOption: (id) =>
      storeApi
        .delete(`/restaurant/option/delete?optionId=${id}`)
        .catch((err) => {
          throw err;
        }),
    fetchChoice: () =>
      storeApi
        .get(`/restaurant/choice/fetch`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    fetchChoiceById: (id) =>
      storeApi
        .get(`/restaurant/choice/getChoiceById?choiceId=${id}`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    addChoice: (data) =>
      storeApi.post(`/restaurant/choice/add`, data).catch((err) => {
        throw err;
      }),
    editChoice: (data) =>
      storeApi.patch(`/restaurant/choice/edit`, data).catch((err) => {
        throw err;
      }),
    deleteChoice: (id) =>
      storeApi
        .delete(`/restaurant/choice/delete?choiceId=${id}`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    getProductById: (id) =>
      storeApi
        .get(`/restaurant/product/getProductById?productId=${id}`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    addProduct: (payload, callback) =>
      storeApi
        .post(`/restaurant/product/add`, payload)
        .then(({ data }) => callback(data))
        .catch((err) => {
          throw err;
        }),
    editProduct: (payload, callback) =>
      storeApi
        .patch(`/restaurant/product/edit`, payload)
        .then(({ data }) => callback(data))
        .catch((err) => {
          throw err;
        }),
    deleteProduct: (id) =>
      storeApi
        .delete(`/restaurant/product/delete?productId=${id}`)
        .catch((err) => {
          throw err;
        }),
    uploadProductImage: (payload, callback, onRejected) => {
      storeApi
        .post(`/restaurant/product/uploadProductImage`, payload)
        .then(({ data }) => callback(data))
        .catch((response) => onRejected(response));
    },
  },
  driver: {
    getAllDriver: () =>
      commonApi
        .get(`/driver/user/getAllDriver`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    getAllDriverAvaliable: () =>
      commonApi
        .get(`/driver/user/getAllDriverAvailable`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    driverRegister: (data) =>
      commonApi
        .post(`/driver/user/driverRegister`, data)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    editDriver: (data) =>
      commonApi
        .patch(`/driver/user/updateDriverProfile`, data)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
    deleteDriver: (id) =>
      commonApi.get(`/driver/user/deleteDriver?driverId=${id}`).catch((err) => {
        throw err;
      }),
    getDriverToken: (id) =>
      storeApi
        .get(`/restaurant/order/getDriverToken?driverId=${id}`)
        .then(({ data }) => data)
        .catch((err) => {
          throw err;
        }),
  },
};

export const driver = {
  matchOrderWithDriver: () =>
    authorizedDriverApi
      .post(`/restaurant/order/matchOrderWithDriver`)
      .then(({ data }) => data)
      .catch((err) => {
        throw err;
      }),
  deliveryOrder: () =>
    authorizedDriverApi
      .get(`/driver/user/getAllDeliveryOrder`)
      .then(({ data }) => data)
      .catch((err) => {
        throw err;
      }),
  deliveryOrderDetail: (id) =>
    authorizedDriverApi
      .get(`/driver/user/getOrderDetail?orderId=${id}`)
      .then(({ data }) => data)
      .catch((err) => {
        throw err;
      }),
  changeOrderStatus: (data) =>
    authorizedDriverApi
      .post(`driver/user/changeOrderStatus`, data)
      .catch((err) => {
        throw err;
      }),
};
