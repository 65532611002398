import styled from 'styled-components';

const DashBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4px 8px;
    background-color: transparent;
    border: 1px dashed #A5A5A5;
    border-radius: 5px;

    font-size: 0.875rem;
    color: #A5A5A5;

    user-select: none;
    cursor: pointer;
    outline: 0;
`;

export default DashBox;