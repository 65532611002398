import React from "react";
import styled from "styled-components";
import { colors } from "../../../values/colors";
import { LeftArrowIcon } from "../../../components/icons";
import { subDomain } from "../../../services/redirect";

const TopBar = styled.div`
  width: 100%;
  height: ${(props) => props.headerHeight};
  background: ${colors.storeThemeColor};
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const HeaderContent = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  font-size: 1em;
  line-height: 1.25rem;
  color: ${colors.textColor1};
`;

const GoBack = styled.div`
  position: absolute;
  left: 0;
  margin-left: 1rem;
`;

export default class DriverHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TopBar headerHeight={this.props.headerHeight}>
          {/* {this.props.path == `${subDomain}/driver/order` && (
            <GoBack
              onClick={() => this.props.history.push(`${subDomain}/driver/ordergroup`)}
            >
              <LeftArrowIcon />
            </GoBack>
          )} */}
          {this.props.path !== `${subDomain}/driver/ordergroup` && (
            <GoBack onClick={() => this.props.history.goBack()}>
              <LeftArrowIcon />
            </GoBack>
          )}
          <HeaderContent>
            {this.props.path === `${subDomain}/driver/orderdetail/:id`
              ? "รายละเอียดการจัดส่ง"
              : `ออเดอร์หมายเลข: ${this.props.deliveryId}`}
          </HeaderContent>
        </TopBar>
      </React.Fragment>
    );
  }
}
