import React, { useCallback, useEffect } from "react";
import { Form } from "react-final-form";
import { Spin, Row, Col, Input } from "antd";
import styled from "styled-components";
import SubPageHeader from "./components/subpageHeader";
import { SettingsIcon } from "../../components/icons";
import useDataState from "../../hooks/useDataState";
import Button from "./components/button";
import { delay } from "./util/promise";
import Field from "./components/field";
import { colors } from "../../values/colors";
import { priceRule, integerRule } from "./util/form/rules";
import { validateRequiredInput } from "./util/form/validators";

const MOCK_FETCH_API = async () => {
  await delay(1500);
  return {
    price: 10,
    distance: 2,
  };
};

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${colors.storeText};

  margin-bottom: 1em;
`;

const FieldJunction = styled.span`
  margin-bottom: 1.5rem;
  line-height: 32px;
  font-weight: 600;
  padding: 0px 1em;
  color: ${colors.storeText};
`;

const Remark = styled.div`
  margin-top: 0.4rem;
  font-size: 0.875rem;
  color: red;
`;

const ButtonRow = styled.div`
  margin-top: 1.5rem;
`;

const valid = (value) => {
  return value || value === 0 ? undefined : "กรุณากรอกข้อมูล";
};

export default function Settings() {
  const onSubmit = useCallback((form) => {
    /* TODO CALL UPDATE API */
  }, []);

  const initialValues = useDataState(undefined);

  useEffect(() => {
    initialValues.load(MOCK_FETCH_API());
  }, []);

  return (
    <React.Fragment>
      <SubPageHeader icon={SettingsIcon} label={"การตั้งค่า"} />
      <Spin spinning={initialValues.loading}>
        <Form onSubmit={onSubmit} initialValues={initialValues.data}>
          {({ handleSubmit, form }) => {
            const getFieldValue = (field) => {
              const fieldState = form.getFieldState(field);
              return fieldState && fieldState.value;
            };

            const distance = getFieldValue("distance") || 0;

            return (
              <form onSubmit={handleSubmit}>
                <Title>{"ค่าส่งอาหาร"}</Title>

                <Row type="flex" align="bottom" styled={{ marginBottom: 0 }}>
                  <Col width={100}>
                    <Field
                      name="price"
                      rules={[priceRule]}
                      validate={validateRequiredInput}
                      component={Input}
                      label={"ราคา(บาท)"}
                      setMarginBottomZero
                    />
                  </Col>
                  <FieldJunction>{"ต่อ"}</FieldJunction>
                  <Col width={100}>
                    <Field
                      name="distance"
                      rules={[integerRule]}
                      validate={validateRequiredInput}
                      component={Input}
                      label={"ระยะทาง(กิโลเมตร)"}
                      setMarginBottomZero
                    />
                  </Col>
                </Row>

                <Remark>
                  {`*หากระยะทางไม่ถึง ${distance} กิโลเมตร จะถูกนับเป็น ${distance} กิโลเมตร`}
                </Remark>

                <ButtonRow>
                  <Button type="primary" buttonType="submit">
                    {"บันทึก"}
                  </Button>
                </ButtonRow>
              </form>
            );
          }}
        </Form>
      </Spin>
    </React.Fragment>
  );
}
