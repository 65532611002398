import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "antd";

const searchOptions = {
  componentRestrictions: { country: ["th"] },
  // types: ['administrative_area_level_5']
};

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.address != nextProps.address ||
      this.props.area != nextProps.area ||
      this.props.state != nextProps.state ||
      this.props.city != nextProps.city ||
      this.props.postal != nextProps.postal
    ) {
      var add =
        nextProps.address == undefined
          ? ""
          : nextProps.address == null
          ? ""
          : nextProps.address;
      var ar =
        nextProps.area == undefined
          ? ""
          : nextProps.area == null
          ? ""
          : nextProps.area;
      var sd =
        nextProps.state == undefined
          ? ""
          : nextProps.state == null
          ? ""
          : nextProps.state;
      var c =
        nextProps.city == undefined
          ? ""
          : nextProps.city == null
          ? ""
          : nextProps.city;
      var p =
        nextProps.postal == undefined
          ? ""
          : nextProps.postal == null
          ? ""
          : nextProps.postal;
      this.setState({
        address: add + ar + " " + sd + " " + c + " " + p,
      });
    }
  }

  handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const addressArray = results[0].address_components,
      address = this.props.getAddress(addressArray),
      city = this.props.getCity(addressArray),
      area = this.props.getArea(addressArray),
      state = this.props.getState(addressArray),
      postal = this.props.getPostal(addressArray),
      latValue = results[0].geometry.location.lat(),
      lngValue = results[0].geometry.location.lng();
    const mapPosition = { lat: latValue, lng: lngValue };
    this.props.setAddress(address);
    this.props.setArea(area);
    this.props.setCity(city);
    this.props.setSubDistrict(state);
    this.props.setPostal(postal);
    this.props.setMapPosition(mapPosition);
    this.props.setMarkerPosition(mapPosition);
    this.props.handleChange();
  };

  setAddressAuto(v) {
    this.setState({ address: v });
  }

  render() {
    return (
      <div>
        <PlacesAutocomplete
          searchOptions={searchOptions}
          value={this.state.address}
          onChange={(v) => this.setAddressAuto(v)}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                style={{
                  width: "100%",
                  height: "2.5rem",
                  paddingLeft: "1rem",
                }}
                {...getInputProps({
                  placeholder: "กรุณากรอกที่อยู่",
                })}
              />

              <div>
                {loading ? <div>...กำลังค้นหา</div> : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#A5A5A5" : "#fff",
                  };

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

export default AutoComplete;
