import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../values/colors';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 1rem 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
`;

const Title = styled.span`
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.storeText};
`;
const Value = styled.span`
    font-size: 3rem;
    line-height: 3rem;
    color: ${props => props.highlighted
        ? colors.storeTextHightlighted
        : '#BBB'
    };
`;

const Metric = React.memo(({
    title,
    value,
    highlighted
}) => (
    <Container>
        <Title>
            {title}
        </Title>
        <Value highlighted={highlighted}>
            {value}
        </Value>
    </Container>
));

export default Metric;