import { message } from "antd";
import { authentication } from "./api";
import localName from "../values/localStorageDict";
import handleLogout, {
  handleStoreLogout,
  handleDriverLogout,
} from "../utils/handleLogout";

const getToken = () => {
  return `Bearer ${localStorage.getItem(localName.ACCESS_TOKEN)}`;
};

const getRefreshToken = () => {
  return `Bearer ${localStorage.getItem(localName.REFRESH_TOKEN)}`;
};

const applyToken = (token) => {
  localStorage.setItem(localName.ACCESS_TOKEN, token);
};

/**
 * Create Promise of access token and store it
 * If this function invoke while promise is not resolved
 * the stored promised will be returned
 *
 * when promise is resolved, the promise is clear
 *
 * @typedef {string} AccessToken
 * @returns {Promise<AccessToken>}
 */
const refreshToken = (() => {
  let refreshSession = null;
  return () => {
    if (!refreshSession) {
      const session = new Promise((resolve, reject) => {
        authentication
          .getNewToken(getRefreshToken())
          .then((data) => {
            const { accessToken } = data;
            applyToken(accessToken);
            if (refreshSession === session) {
              refreshSession = null;
            }
            resolve(accessToken);
          })
          .catch((error) => {
            const status = error.response.status;
            if (status === 401) {
              handleLogout();
              message.error("กรุณาเข้าสู่ระบบอีกครั้ง");
            } else {
              message.error("เกิดข้อผิดพลาด กรุณาลองใหม่");
            }

            reject(error);
          });
      });

      refreshSession = session;
    }

    return refreshSession;
  };
})();

export const createAuthCycle = (client) => {
  // Attach token to all request created by this client
  client.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = getToken();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // When request failed with Unauthorized (401)
  // Try to get new token and resend request
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // If status is not 401, bypass error
      console.log(error);
      if (!error.response || error.response.status !== 401) {
        return Promise.reject(error);
      }

      const config = error.response.config;

      // Token maybe already changed (when 401 of another request occured) when 401 occured
      // So we check the change first
      const storedToken = getToken();
      const tokenHasBeenChanged =
        storedToken !== config.headers["Authorization"];
      // If changed, Set the changed token and resend
      if (tokenHasBeenChanged) {
        config.headers["Authorization"] = storedToken;
        return client.request(config);
      }

      // Refresh token and resend
      // Note that refresh function must handle multiple calls at a time
      return refreshToken()
        .then((token) => {
          config.headers["Authorization"] = token;
          return client.request(config);
        })
        .catch((authError) => {
          return Promise.reject(error);
        });
    }
  );
};

//store
const getStoreToken = () => {
  return `Bearer ${localStorage.getItem(localName.STORE_ACCESS_TOKEN)}`;
};

const getStoreRefreshToken = () => {
  return `Bearer ${localStorage.getItem(localName.STORE_REFRESH_TOKEN)}`;
};

const applyStoreToken = (token) => {
  localStorage.setItem(localName.STORE_ACCESS_TOKEN, token);
};

const refreshStoreToken = (() => {
  let refreshSession = null;
  return () => {
    if (!refreshSession) {
      const session = new Promise((resolve, reject) => {
        authentication
          .getNewToken(getStoreRefreshToken())
          .then((data) => {
            const { accessToken } = data;
            applyStoreToken(accessToken);
            if (refreshSession === session) {
              refreshSession = null;
            }
            resolve(accessToken);
          })
          .catch((error) => {
            const status = error.response.status;
            if (status === 401) {
              handleStoreLogout();
              message.error("กรุณาเข้าสู่ระบบอีกครั้ง");
            } else {
              message.error("เกิดข้อผิดพลาด กรุณาลองใหม่");
            }

            reject(error);
          });
      });

      refreshSession = session;
    }

    return refreshSession;
  };
})();

export const createStoreAuthCycle = (client) => {
  client.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = getStoreToken();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);
      if (!error.response || error.response.status !== 401) {
        return Promise.reject(error);
      }

      const config = error.response.config;

      const storedToken = getStoreToken();
      const tokenHasBeenChanged =
        storedToken !== config.headers["Authorization"];
      if (tokenHasBeenChanged) {
        config.headers["Authorization"] = storedToken;
        return client.request(config);
      }

      return refreshStoreToken()
        .then((token) => {
          config.headers["Authorization"] = token;
          return client.request(config);
        })
        .catch((authError) => {
          return Promise.reject(error);
        });
    }
  );
};

//driver
const getDriverToken = () => {
  return `Bearer ${localStorage.getItem(localName.DRIVER_ACCESS_TOKEN)}`;
};

export const createDriverAuthCycle = (client) => {
  client.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = getDriverToken();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // client.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     handleDriverLogout();
  //     return Promise.reject(error);
  //   }
  // );
};
