import styled from 'styled-components';
import { colors } from '../../../values/colors';

const Badge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 0.3em;
    border-radius: 50%;
    width: 2.2em !important;
    height: 2.2em;

    font-size: 75%;
    line-height: 1em;
    font-weight: normal;
    color: white;
    background-color: ${colors.storeText};
`;

export default Badge;
