import React, { useCallback, useEffect } from "react";
import useDataState from "../../hooks/useDataState";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Row, Input, Col, Spin } from "antd";
import styled from "styled-components";
import { HomeIcon } from "../../components/icons";
import SubPageHeader from "./components/subpageHeader";
import Field from "./components/field";
import Button from "./components/button";
import TextArea from "./components/textArea";
import { colors } from "../../values/colors";
import { delay } from "./util/promise";
import { phoneNumberRule, pinRule } from "./util/form/rules";
import {
  validateRequiredInput,
  validateEmail,
  validatePin,
} from "./util/form/validators";

const MOCK_FETCH_API = async () => {
  await delay(1500);
  return {
    name: "ไอแอมเยลโล่คาเฟ่",
    address: "449 ถนนสุทธิสาร แขวงดินแดง เขตดินแดง กรุงเทพมหานคร 10400",
    email: "im_yellow@outlook.com",
    pin: "5412",
    phones: ["0629812526"],
    facebook: `I'm Yellow Cafe & Restaurant`,
    instagram: "im.yellowcafe",
    twitter: "@yellowcafe",
  };
};

const FieldColumn = styled(Col)`
  width: 32%;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  & button:not(:first-child) {
    margin-left: 12px;
  }
`;

const MOCK_MAP = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;

  background-color: #e9e9e9;
`;

const AddPhone = styled.span`
  color: ${colors.storeTextHightlighted};
  cursor: pointer;
`;

const renderPhones = ({ fields, label, deleteField }) => (
  <Field.Layout label={label}>
    {fields.map((name, index) => (
      <React.Fragment>
        <Field
          key={index}
          name={name}
          rules={[phoneNumberRule]}
          validate={validateRequiredInput}
          component={Input}
          style={{ marginBottom: "13px" }}
          noError={index !== 0}
          deletableField={index !== 0}
          handleDeletablaField={() => {
            deleteField(index);
          }}
        />
      </React.Fragment>
    ))}
  </Field.Layout>
);

export default function Profile() {
  const onSubmit = useCallback((form) => {
    /* TODO CALL UPDATE API */
  }, []);

  const initialValues = useDataState(undefined);

  useEffect(() => {
    initialValues.load(MOCK_FETCH_API());
  }, []);

  return (
    <React.Fragment>
      <SubPageHeader icon={HomeIcon} label={"โปรไฟล์ร้าน"} />
      <Spin spinning={initialValues.loading}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues.data}
          mutators={arrayMutators}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row type="flex" justify="space-between">
                <FieldColumn>
                  <Field
                    name="name"
                    validate={validateRequiredInput}
                    component={Input}
                    label={"ชื่อร้าน"}
                  />
                  <Field
                    name="address"
                    validate={validateRequiredInput}
                    component={TextArea}
                    label={"ที่อยู่"}
                    autoSize={{ minRows: 3, maxRows: 3 }}
                  />
                  <Field
                    name="location"
                    component={MOCK_MAP}
                    label={"ปักหมุดร้านอาหาร"}
                  />
                </FieldColumn>
                <FieldColumn>
                  <Field
                    name="pin"
                    rules={[pinRule]}
                    // validate={validateRequiredInput}
                    validate={validatePin}
                    component={Input.Password}
                    label={"PIN"}
                  />
                  <Field
                    name="email"
                    validate={validateEmail}
                    component={Input}
                    label={"Email"}
                    type="email"
                  />
                  <FieldArray name="phones">
                    {({ fields }) =>
                      renderPhones({
                        fields,
                        label: (
                          <Row type="flex" justify="space-between">
                            <span>{"เบอร์โทรศัพท์ร้าน"}</span>
                            <AddPhone onClick={() => fields.push("")}>
                              {"+ เพิ่มเบอร์โทรศัพท์"}
                            </AddPhone>
                          </Row>
                        ),
                        deleteField: (index) => {
                          fields.remove(index);
                        },
                      })
                    }
                  </FieldArray>
                </FieldColumn>
                <FieldColumn>
                  <Field
                    name="facebook"
                    validate={validateRequiredInput}
                    component={Input}
                    label={"Facebook"}
                  />
                  <Field
                    name="instagram"
                    validate={validateRequiredInput}
                    component={Input}
                    label={"Instagram"}
                  />
                  <Field
                    name="twitter"
                    validate={validateRequiredInput}
                    component={Input}
                    label={"Twitter"}
                  />
                </FieldColumn>
              </Row>

              <ButtonRow>
                <Button type="highlight">{"แก้ไขรหัสผ่าน"}</Button>
                <Button type="primary" buttonType="submit">
                  {"บันทึกการเปลี่ยนแปลง"}
                </Button>
              </ButtonRow>
            </form>
          )}
        </Form>
      </Spin>
    </React.Fragment>
  );
}
