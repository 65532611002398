import React from 'react';
import styled from 'styled-components';
import errorImage from '../../assets/image/errorImage.svg';
import ReloadIcon from '../../assets/icons/reload.svg';
import Button from '../../pages/stores/components/button';

const Page = styled.div`
    font-family: Kanit;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height:100vh;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
`

const Text1 = styled.text`
    margin-top: 2rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 1.75rem;
    text-align: center;
    letter-spacing: 0.1px;
    color: #333333;
`
const Text2 = styled.text`
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    letter-spacing: 0.1px;

    color: #919191;
`

const ReloadButton = styled.div`
    margin-top: 2rem;
`
const inButton = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.25rem;
    letter-spacing: 0.1px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export default function errorPage () {
    return (
        <Page>
            <Content>
                <img src={errorImage} />
                <Text1>
                    อุ๊บส์! ขณะนี้เรากำลังปรับปรุงเว็บไซต์
                </Text1>
                <div style={{ marginTop: '1.5rem', display: 'flex',flexDirection: 'column' }}>
                    <Text2>
                        เรากำลังทำการปรับปรุงแก้ไขเพื่อเว็บไซต์ที่ดีขึ้น
                </Text2>
                    <Text2>
                        กรุณาอดใจรออีกสักนิด หรือ ลองใหม่อีกครั้งค่ะ
                </Text2>
                </div>
                <ReloadButton>
                    <Button type='alt2' onClick={() => window.location.reload()} style={{ color: 'white', height: '3rem', width: '22.3rem' }} >
                        <inButton>
                            <img src={ReloadIcon} style={{ marginRight: '0.5rem' }} />
                            <text>ลองใหม่อีกครั้ง</text>
                        </inButton>
                    </Button>
                </ReloadButton>
            </Content>
        </Page>
    );
}

