import React from "react";
import styled from "styled-components";
import { Divider, Modal, message, Radio } from "antd";
import { customer } from "../../services/api";
import { BreadcrumbBar } from "./components/title";
import {
  Text,
  RowDivider,
  CenterSpin,
} from "../customers/components/sharedComponents";
import { TableHeader } from "./components/order/orderItemTable";
import Uploader from "./components/payment/uploader";
import { colors } from "../../values/colors";
import { redirectTo, subDomain } from "../../services/redirect";
import cartIcon from "../../assets/icons/cart.svg";
import downloadQRcode from "../../assets/icons/downloadQRcode.svg";
import downloadQRcodeYellow from "../../assets/icons/downloadQRCodeYellow.svg";
import alreadyPaid from "../../assets/image/alreadyPaid.svg";
import CartItem from "./components/cart/cartItem";
import Underscore from "underscore";
import localName from "../../values/localStorageDict";
import QRCode from "qrcode.react";
import MediaQuery from "react-responsive";
import { renderMoney } from "../../utils/money";

const ButtonSubmit = styled.button`
  align-items: center;
  width: 30%;
  height: 2.8rem;
  border: none;
  background-color: ${(props) =>
    props.disabled ? "lightgrey" : colors.buttonColor};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: white;
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2rem;
  @media only screen and (min-width: 768px) {
    font-size: 1.2rem;
  }
  margin-top: 1rem;

  @media only screen and (max-width: 680px) {
    align-items: center;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
    font-size: 1.25rem;
    padding: 1.25rem;
  }
`;

const ButtonReturn = styled.button`
  align-items: center;
  width: 30%;
  height: 2.8rem;
  border: 1px solid #fbb03b;
  background-color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: #fbb03b;
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2rem;
  @media only screen and (min-width: 768px) {
    font-size: 1.2rem;
  }

  margin-top: 1rem;
`;

const PaymentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10% 10% 10%;

  @media only screen and (max-width: 680px) {
    padding: 0 0 1rem 0;
  }
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding || "0rem 0rem 0rem 0rem"};
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.padding || "0rem 0rem 0rem 0rem"};
`;

const ModalBox = styled.div`
  display: flex;
  align-items: center;
  font-family: "Kanit";
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5em;
  justify-content: ${(props) => (props.space ? "space-between" : "center")};
  margin: ${(props) => (props.padding ? "2.5rem 0 1.5rem 0" : 0)};
  padding: 1rem 10% 1rem 10%;
  @media not screen and (min-device-width: 1224px) {
    padding: 0;
  }
`;

const OutBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
`;

const StyledDividerThin = styled(Divider)`
  border: 0.7px solid #dedede;
  margin-top: 0.5rem;
`;

const SubTitleRow = styled.div`
  font-family: "Kanit";
  font-size: 1em;
  line-height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
`;

const SubTitleText = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
  word-break: break-word;

  @media only screen and (max-width: 680px) {
    margin: 0 1rem 0 1rem;
  }
`;

const ButtonQRDownload = styled.button`
  align-items: center;
  width: 100%;
  height: 2.8rem;
  border: 1px solid #fbb03b;
  background-color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: #fbb03b;
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1rem;
`;

const SummaryText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  word-break: break-word;
`;

const SummaryPriceText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;
  word-break: break-word;
`;

const SummaryBar = styled.div`
  overflow: hidden;
  position: sticky;
  background-color: #fff;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dedede;
`;

const MethodBox = styled(FlexBox)`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 1rem 0 1rem;
`;

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: null,
      file: null,
      order: {},
      detail: {},
      disableSubmit: true,
      submitModal: false,
      qrString: "",
      accountName: "",
      bankName: "",
      accountId: "",
      data: {},
      customerName: "-",
      customerPhone: "",
    };
    this.contentRef = React.createRef();
    this.mobileQRRefCallback = (e) =>
      this.setState({ mobileQRSize: !!e ? e.clientWidth : undefined });
  }
  scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  async componentDidMount() {
    this.scroll(this.contentRef);
    const { order, paymentDetail } = this.props.location.state;
    await this.setState({
      order: { ...order, deliveryCost: paymentDetail.price.delivery },
      detail: paymentDetail,
    });
    let amount = {
      amount: paymentDetail.price.item + paymentDetail.price.delivery,
    };
    customer.order
      .genQr(amount, (data, status) => {
        let { qrString, accountName, bankName, accountId } = data;
        this.setState({ qrString, accountName, bankName, accountId });
      })
      .catch((err) => {
        message.error("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      });
    customer.user.getProfileData(
      ({ data }) => {
        this.setState({
          customerName: data.firstname,
          customerPhone: `${data.phone.substring(0, 3)}-${data.phone.substring(
            3,
            6
          )}-${data.phone.substring(6, 10)}`,
        });
      },
      () => {}
    );
  }

  onSubmit = () => {
    try {
      this.setState({ disableSubmit: true });
      customer.order.createOrder(this.state.order, (data) => {
        this.setState({ orderId: data.orderId });
        var formData = new FormData();
        formData.append("slip", this.state.file.fileList[0].originFileObj);
        formData.append("orderId", data.orderId);
        customer.order.uploadOrderSlip(formData, ({ data, status }) => {
          this.setState({ submitModal: true });
        });
      });
    } catch (err) {
      message.error("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
    }
  };

  onUpload = (file) => {
    this.setState({ disableSubmit: false, file });
  };

  onDelete = () => {
    this.setState({ disableSubmit: true });
  };

  downloadQR = () => {
    // const canvas = document.getElementById("qrCode");
    // const pngUrl = canvas
    //   .toDataURL("image/png")
    //   .replace("image/png", "image/octet-stream");
    // let downloadLink = document.createElement("a");
    // downloadLink.href = pngUrl;
    // downloadLink.download = "QRCodePayment.png";
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);

    let tmpImage = new Image();
    tmpImage.src = document.getElementById("qrCode").toDataURL("image/png");
    var image = tmpImage;

    var downloadLink = document.createElement("a");

    downloadLink.setAttribute("href", image.src);
    downloadLink.setAttribute("download", "qrCode.png");
    downloadLink.click();
  };

  render() {
    const { detail } = this.state;
    const menu = detail.item;
    const pathItem = [{ name: "ตะกร้าสินค้า", path: `${subDomain}/cart` }];
    return (
      <>
        <PaymentBox ref={this.contentRef}>
          {Underscore.isEmpty(detail.item) ? (
            <CenterSpin />
          ) : (
            <>
              <MediaQuery minDeviceWidth={680}>
                <OutBox>
                  <BreadcrumbBar title="ชำระเงิน" items={pathItem} />
                </OutBox>
              </MediaQuery>
              <OutBox>
                <SubTitleRow
                  style={{ justifyContent: "flex-start", alignItems: "center" }}
                >
                  <MediaQuery minDeviceWidth={680}>
                    <img src={cartIcon} style={{ marginRight: "1rem" }} />
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={680}>
                    <img
                      src={cartIcon}
                      style={{ marginRight: "1rem", marginLeft: "1rem" }}
                    />
                  </MediaQuery>
                  <SubTitleText
                    style={{
                      fontWeight: "600",
                      fontSize: "2rem",
                      fontStyle: "normal",
                      margin: 0,
                    }}
                  >
                    ชำระเงิน
                  </SubTitleText>
                </SubTitleRow>
              </OutBox>
              <OutBox>
                <SubTitleText
                  option
                  style={{
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <FlexRow>สรุปรายการสินค้า</FlexRow>
                </SubTitleText>
              </OutBox>
              <MediaQuery minDeviceWidth={680}>
                <div style={{ width: "90%" }}>
                  <TableHeader />
                </div>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={680}>
                <div style={{ width: "100%" }}>
                  <RowDivider />
                  <TableHeader />
                </div>
              </MediaQuery>
              <RowDivider />
              {menu.map((item, index) => (
                <>
                  <CartItem item={item} number={index} editable={false} />
                  <RowDivider />
                </>
              ))}

              <MediaQuery minDeviceWidth={680}>
                <FlexRow>
                  <FlexBox
                    style={{
                      width: "50%",
                      borderRight: "1.5px solid #DEDEDE",
                      borderBottom: "1.5px solid #DEDEDE",
                    }}
                  >
                    <FlexRow style={{ margin: "1rem 0 .35rem 0" }}>
                      <SubTitleText
                        style={{ fontSize: "1.5rem", fontWeight: "600" }}
                      >
                        จัดส่งที่
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow>
                      <SubTitleText
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "#B57A08",
                        }}
                      >
                        คุณ {this.state.customerName}
                      </SubTitleText>
                      <SubTitleText
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "#B57A08",
                          marginLeft: "1rem",
                        }}
                      >
                        ({this.state.customerPhone})
                      </SubTitleText>
                    </FlexRow>
                    {Underscore.isEmpty(detail.address) ? (
                      <>
                        <FlexRow style={{ margin: "1rem 0 .35rem 0" }}>
                          <SubTitleText
                            style={{ fontSize: "1.1rem", fontWeight: "600" }}
                          >
                            -
                          </SubTitleText>
                        </FlexRow>
                        <FlexRow style={{ marginBottom: "1rem" }}>
                          <SubTitleText
                            style={{
                              fontSize: "1.1rem",
                              fontWeight: "normal",
                              color: "#B57A08",
                            }}
                          >
                            ถึงผู้ส่ง: -
                          </SubTitleText>
                        </FlexRow>
                        <FlexRow style={{ marginBottom: "1rem" }}>
                          <SubTitleText
                            style={{ fontSize: "1.1rem", fontWeight: "600" }}
                          >
                            {"เวลาจัดส่ง:"}
                          </SubTitleText>
                          <SubTitleText
                            style={{
                              fontSize: "1.1rem",
                              fontWeight: "normal",
                              color: "black",
                              marginLeft: ".35rem",
                            }}
                          >
                            {detail.time}
                          </SubTitleText>
                        </FlexRow>
                      </>
                    ) : (
                      <>
                        <FlexRow style={{ margin: ".5rem 0 .35rem 0" }}>
                          <SubTitleText
                            style={{
                              fontSize: "1.25rem",
                              fontWeight: "600",
                              overflowWrap: "break-word",
                              width: "90%",
                            }}
                          >
                            {detail.address.name}
                          </SubTitleText>
                        </FlexRow>
                        <FlexRow style={{ marginBottom: ".25rem" }}>
                          <SubTitleText
                            style={{
                              fontSize: "1rem",
                              fontWeight: "normal",
                              color: "black",
                            }}
                          >
                            {detail.address.addressLine +
                              " " +
                              detail.address.district +
                              " " +
                              detail.address.subDistrict +
                              " " +
                              detail.address.province +
                              " " +
                              detail.address.postalCode}
                          </SubTitleText>
                        </FlexRow>
                        <FlexRow style={{ marginBottom: "1rem" }}>
                          <SubTitleText
                            style={{
                              fontSize: "1rem",
                              fontWeight: "normal",
                              color: "#B57A08",
                              width: "90%",
                              overflowWrap: "break-word",
                            }}
                          >
                            {detail.address.comment === null
                              ? "ถึงผู้ส่ง: -"
                              : "ถึงผู้ส่ง: " + detail.address.comment}
                          </SubTitleText>
                        </FlexRow>
                        <FlexRow style={{ marginBottom: "1rem" }}>
                          <SubTitleText
                            style={{ fontSize: "1rem", fontWeight: "600" }}
                          >
                            {"เวลาจัดส่ง:"}
                          </SubTitleText>
                          <SubTitleText
                            style={{
                              fontSize: "1rem",
                              fontWeight: "normal",
                              color: "black",
                              marginLeft: ".35rem",
                            }}
                          >
                            {detail.time}
                          </SubTitleText>
                        </FlexRow>
                      </>
                    )}
                  </FlexBox>
                  <FlexBox
                    style={{
                      paddingLeft: "1rem",
                      width: "50%",
                      borderBottom: "1.5px solid #DEDEDE",
                    }}
                  >
                    <FlexRow style={{ margin: "1rem 0 1rem 0" }}>
                      <SubTitleText
                        style={{ fontSize: "1.5rem", fontWeight: "600" }}
                      >
                        สรุปรายการเงิน
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow style={{ justifyContent: "space-between" }}>
                      <SubTitleText
                        style={{ fontSize: "1.25rem", fontWeight: "normal" }}
                      >
                        รวมรายการ
                      </SubTitleText>
                      <SubTitleText
                        style={{ fontSize: "1.25rem", fontWeight: "normal" }}
                      >
                        {renderMoney(detail.price.item) + " ฿"}
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow
                      style={{
                        marginBottom: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <SubTitleText
                        style={{ fontSize: "1.25rem", fontWeight: "normal" }}
                      >
                        ค่าส่ง
                      </SubTitleText>
                      <SubTitleText
                        style={{ fontSize: "1.25rem", fontWeight: "normal" }}
                      >
                        {renderMoney(detail.price.delivery) + " ฿"}
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow
                      style={{
                        marginBottom: "1.5rem",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <SubTitleText
                        style={{ fontSize: "1.5rem", fontWeight: "600" }}
                      >
                        ยอดที่ต้องชำระ
                      </SubTitleText>
                      <SubTitleText
                        style={{ fontSize: "2rem", fontWeight: "600" }}
                      >
                        {renderMoney(
                          detail.price.item + detail.price.delivery
                        ) + " ฿"}
                      </SubTitleText>
                    </FlexRow>
                  </FlexBox>
                </FlexRow>
                <FlexBox style={{ justifyContent: "center" }}>
                  <FlexRow
                    style={{ justifyContent: "center", marginTop: "1rem" }}
                  >
                    <SubTitleText
                      style={{ fontSize: "1.25rem", fontWeight: "normal" }}
                    >
                      เลขที่บัญชี
                    </SubTitleText>
                  </FlexRow>
                  <FlexRow style={{ justifyContent: "center" }}>
                    <SubTitleText
                      style={{
                        fontSize: "2rem",
                        fontWeight: "600",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.accountId}
                    </SubTitleText>
                  </FlexRow>
                  <FlexRow style={{ justifyContent: "center" }}>
                    <SubTitleText
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "normal",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.bankName}
                    </SubTitleText>
                  </FlexRow>
                  <FlexRow style={{ justifyContent: "center" }}>
                    <SubTitleText
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "normal",
                        justifyContent: "center",
                      }}
                    >
                      ชื่อบัญชี {this.state.accountName}
                    </SubTitleText>
                  </FlexRow>
                  <FlexRow style={{ justifyContent: "center" }}>
                    <QRCode
                      id="qrCode"
                      value={this.state.qrString}
                      size={222}
                      includeMargin={true}
                    />
                  </FlexRow>
                  <FlexRow style={{ justifyContent: "center" }}>
                    <a onClick={() => this.downloadQR()} download>
                      <img src={downloadQRcode} />
                    </a>
                    <a onClick={() => this.downloadQR()} download>
                      <SubTitleText
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: "600",
                          marginLeft: ".5rem",
                        }}
                      >
                        บันทึกภาพ QR code
                      </SubTitleText>
                    </a>
                  </FlexRow>
                  <StyledDividerThin style={{ marginTop: "1rem" }} />
                </FlexBox>
                <FlexRow style={{}}>
                  <SubTitleText
                    option
                    style={{
                      fontWeight: "600",
                      fontSize: "24px",
                      marginBottom: "1rem",
                    }}
                  >
                    ส่งหลักฐานการโอน
                  </SubTitleText>
                </FlexRow>
                <FlexBox style={{ marginTop: "1rem", alignItems: "center" }}>
                  <div>
                    <Uploader
                      onUpload={this.onUpload}
                      onDelete={this.onDelete}
                    />
                  </div>
                </FlexBox>
                <StyledDividerThin />
                <SubTitleRow style={{ alignItems: "center" }}>
                  <ButtonReturn onClick={() => redirectTo("/cart")}>
                    กลับหน้าตะกร้า
                  </ButtonReturn>
                  <ButtonSubmit
                    onClick={() => this.onSubmit()}
                    disabled={this.state.disableSubmit}
                  >
                    ยืนยันการชำระเงิน
                  </ButtonSubmit>
                </SubTitleRow>
              </MediaQuery>

              <MediaQuery maxDeviceWidth={680}>
                <FlexRow style={{ marginTop: "1rem" }}>
                  <SubTitleText
                    style={{ fontSize: "1.5rem", fontWeight: "600" }}
                  >
                    จัดส่งที่
                  </SubTitleText>
                </FlexRow>
                <FlexRow>
                  <SubTitleText
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "normal",
                      color: "#B57A08",
                    }}
                  >
                    คุณ {this.state.customerName}
                  </SubTitleText>
                  <SubTitleText
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "normal",
                      color: "#B57A08",
                      marginLeft: "1rem",
                    }}
                  >
                    ({this.state.customerPhone})
                  </SubTitleText>
                </FlexRow>
                {Underscore.isEmpty(detail.address) ? (
                  <>
                    <FlexRow style={{ margin: "1rem 0 .35rem 0" }}>
                      <SubTitleText
                        style={{ fontSize: "1.1rem", fontWeight: "600" }}
                      >
                        -
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow style={{ marginBottom: "1rem" }}>
                      <SubTitleText
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "#B57A08",
                        }}
                      >
                        ถึงผู้ส่ง: -
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow style={{ marginBottom: "1rem" }}>
                      <SubTitleText
                        style={{ fontSize: "1.1rem", fontWeight: "600" }}
                      >
                        {"เวลาจัดส่ง:"}
                      </SubTitleText>
                      <SubTitleText
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "black",
                          marginLeft: ".35rem",
                        }}
                      >
                        {detail.time}
                      </SubTitleText>
                    </FlexRow>
                  </>
                ) : (
                  <>
                    <FlexRow style={{ margin: ".5rem 0rem .35rem 0rem" }}>
                      <SubTitleText
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: "600",
                          overflowWrap: "break-word",
                          width: "90%",
                        }}
                      >
                        {detail.address.name}
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow
                      style={{
                        marginBottom: ".25rem",
                      }}
                    >
                      <SubTitleText
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {detail.address.addressLine +
                          " " +
                          detail.address.district +
                          " " +
                          detail.address.subDistrict +
                          " " +
                          detail.address.province +
                          " " +
                          detail.address.postalCode}
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow style={{ marginBottom: "1rem" }}>
                      <SubTitleText
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "#B57A08",
                          width: "90%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {detail.address.comment === null
                          ? "ถึงผู้ส่ง: -"
                          : "ถึงผู้ส่ง: " + detail.address.comment}
                      </SubTitleText>
                    </FlexRow>
                    <FlexRow style={{ marginBottom: "1rem" }}>
                      <SubTitleText
                        style={{ fontSize: "1rem", fontWeight: "600" }}
                      >
                        {"เวลาจัดส่ง:"}
                      </SubTitleText>
                      <SubTitleText
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "black",
                          marginLeft: ".35rem",
                        }}
                      >
                        {detail.time}
                      </SubTitleText>
                    </FlexRow>
                  </>
                )}
                <StyledDividerThin />

                <FlexRow>
                  <SubTitleText
                    style={{ fontSize: "1.5rem", fontWeight: "600" }}
                  >
                    วิธีชำระเงิน
                  </SubTitleText>
                </FlexRow>
                <MethodBox>
                  <FlexRow
                    style={{ marginBottom: "1rem", alignItems: "center" }}
                  >
                    <Radio defaultChecked={true} />
                    <Text style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      QR Code (พร้อมเพย์)
                    </Text>
                  </FlexRow>

                  <FlexRow>
                    <FlexBox style={{ paddingRight: "1rem", width: "50%" }}>
                      <div ref={this.mobileQRRefCallback}>
                        {!!this.state.mobileQRSize ? (
                          <QRCode
                            id="qrCode"
                            value={this.state.qrString}
                            size={this.state.mobileQRSize}
                            includeMargin={false}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </FlexBox>
                    <FlexBox style={{ paddingRight: "1rem", width: "50%" }}>
                      <SubTitleText style={{ fontWeight: "normal" }}>
                        เลขที่บัญชี
                      </SubTitleText>
                      <SubTitleText style={{ fontWeight: "bold" }}>
                        {this.state.accountId}
                      </SubTitleText>
                      <SubTitleText style={{ fontWeight: "normal" }}>
                        ชื่อบัญชี
                      </SubTitleText>
                      <SubTitleText style={{ fontWeight: "bold" }}>
                        {this.state.accountName}
                      </SubTitleText>
                    </FlexBox>
                  </FlexRow>
                  <FlexRow padding={"1rem 0 1rem 0"}>
                    <ButtonQRDownload onClick={() => this.downloadQR()}>
                      <FlexRow style={{ justifyContent: "center" }}>
                        <img
                          src={downloadQRcodeYellow}
                          style={{ marginRight: "1rem" }}
                        />
                        <div>บันทึกรูป QR code</div>
                        <div></div>
                      </FlexRow>
                    </ButtonQRDownload>
                  </FlexRow>
                  <FlexRow style={{}}>
                    <SubTitleText
                      option
                      style={{
                        fontWeight: "600",
                        fontSize: "24px",
                        marginBottom: "1rem",
                      }}
                    >
                      ส่งหลักฐานการโอน : อัปโหลดสลิป
                    </SubTitleText>
                  </FlexRow>
                  <FlexRow
                    style={{
                      marginTop: "1rem",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Uploader
                        onUpload={this.onUpload}
                        onDelete={this.onDelete}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </FlexRow>
                </MethodBox>
              </MediaQuery>

              <Modal
                visible={this.state.submitModal}
                centered={true}
                closable={false}
                width={"35rem"}
                footer={null}
              >
                <ModalBox centered>
                  <FlexBox>
                    <img src={alreadyPaid} center={true} />
                    <FlexRow>
                      <SubTitleText
                        style={{
                          fontSize: "36px",
                          fontWeight: "600",
                          justifyContent: "center",
                        }}
                      >
                        ยืนยันการชำระเงินเรียบร้อย
                      </SubTitleText>
                    </FlexRow>
                    <ButtonSubmit
                      style={{
                        fontSize: "0.8em",
                        fontWeight: "bold",
                        cursor: "pointer",
                        alignItems: "center",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        localStorage.removeItem(localName.CART);
                        localStorage.removeItem(localName.CART_ADDR);
                        this.setState({ visibleModal: false });
                        redirectTo(`/my-order`);
                      }}
                    >
                      <FlexRow
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        คลิกเพื่อดูสถานะของรายการสินค้า
                      </FlexRow>
                    </ButtonSubmit>
                    <ButtonReturn
                      style={{
                        fontSize: "0.8em",
                        fontWeight: "bold",
                        cursor: "pointer",
                        alignItems: "center",
                        width: "100%",
                      }}
                      onClick={() => {
                        localStorage.removeItem(localName.CART);
                        localStorage.removeItem(localName.CART_ADDR);
                        this.setState({ visibleModal: false });
                        redirectTo("/home");
                      }}
                    >
                      กลับไปยังหน้าแรก
                    </ButtonReturn>
                  </FlexBox>
                </ModalBox>
              </Modal>
            </>
          )}
        </PaymentBox>
        <MediaQuery maxDeviceWidth={680}>
          <SummaryBar>
            {Underscore.isEmpty(detail.item) ? (
              <CenterSpin padding="0.5rem 1rem 0.5rem 1rem" />
            ) : (
              <>
                <FlexBox style={{ padding: "0.5rem", margin: 0 }}>
                  <FlexRow
                    style={{
                      "justify-content": "space-between",
                      margin: 0,
                    }}
                  >
                    <SummaryText style={{ fontWeight: 500 }}>
                      รวมรายการ
                    </SummaryText>
                    <SummaryPriceText>
                      {renderMoney(detail.price.item) + " ฿"}
                    </SummaryPriceText>
                  </FlexRow>
                  <FlexRow
                    style={{ "justify-content": "space-between", margin: 0 }}
                  >
                    <SummaryText style={{ fontWeight: 500 }}>
                      ค่าส่ง
                    </SummaryText>
                    <SummaryPriceText>
                      {renderMoney(detail.price.delivery) + " ฿"}
                    </SummaryPriceText>
                  </FlexRow>
                  {/* <FlexRow style={{ "justify-content": "space-between" }}>
                <SummaryText style={{ fontWeight: 500 }}>
                  ส่วนลดจากคูปอง
                </SummaryText>
                <SummaryPriceText>฿</SummaryPriceText>
              </FlexRow>
              <FlexRow style={{ "justify-content": "space-between" }}>
                <SummaryText style={{ fontWeight: 500 }}>
                  VAT 7%
                </SummaryText>
                <SummaryPriceText>฿</SummaryPriceText>
              </FlexRow> */}
                </FlexBox>

                <FlexBox
                  style={{
                    borderTop: "1px solid #dedede",
                    padding: "0.5rem",
                    margin: 0,
                  }}
                >
                  <FlexRow
                    style={{ "justify-content": "space-between", margin: 0 }}
                  >
                    <SummaryText style={{ fontWeight: "normal" }}>
                      ราคาสุทธิ
                    </SummaryText>
                    <FlexRow>
                      <SummaryPriceText
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "600",
                          color: "#ffb800",
                        }}
                      >
                        {renderMoney(detail.price.item + detail.price.delivery)}
                      </SummaryPriceText>
                      &nbsp;
                      <SummaryPriceText>บาท</SummaryPriceText>
                    </FlexRow>
                  </FlexRow>
                </FlexBox>
                <FlexRow style={{ margin: 0 }}>
                  <ButtonSubmit
                    onClick={() => this.onSubmit()}
                    disabled={this.state.disableSubmit}
                  >
                    ชำระเงิน
                  </ButtonSubmit>
                </FlexRow>
              </>
            )}
          </SummaryBar>
        </MediaQuery>
      </>
    );
  }
}

export default Payment;
