import React from "react";
import { Button } from "../../../customers/components/sharedComponents";
const ConfirmButton = ({ addresses, onConfirm }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "2rem",
      }}
    >
      {addresses.length > 0 ? (
        <Button
          style={{
            width: "8rem",
            height: "3rem",
          }}
          onClick={onConfirm}
        >
          ยืนยัน
        </Button>
      ) : null}
    </div>
  );
};

export default ConfirmButton;
