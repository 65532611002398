import React from "react";
import ProfileTitle from "../customers/components/profile/profileTitle";
import styled from "styled-components";
import ProfileContent from "../customers/components/profile/profileContent";
import { redirectTo, subDomain } from "../../services/redirect";
import { customer as api } from "../../services/api";
import localName from "../../values/localStorageDict";
import { message } from "antd";
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10% 2rem 10%;
  @media (max-width: 680px) {
    padding: 0 0 0 0;
  }
`;

class Profile extends React.Component {
  formRef = React.createRef();
  state = {
    input: false,
    data: {},
    imageProfile: null,
    isEmail: false,
  };
  componentWillMount() {
    api.user.getProfileData(
      ({ data, status }) => {
        this.setState({
          imageProfile: data.imageProfile[0],
          isEmail: data.isEmail,
        });
        localStorage.setItem(localName.PROFILE_DATA, JSON.stringify(data));
        localStorage.setItem(localName.PHONE_NUMBER,data.phone);
        this.formRef.current.setFieldsValue({
          firstname: data.firstname,
          lastname: data.lastname,
          email:
            data.email === undefined
              ? "Waiting data from server..."
              : data.email,
          phone: data.phone,
        });
      },
      () => {
        this.formRef.current.setFieldsValue({
          firstname: "Waiting data from server...",
          lastname: "Waiting data from server...",
          email: "Waiting data from server...",
          phone: "Waiting data from server...",
        });
      }
    );
  }
  handleEditProfile = () => {
    const input = !this.state.input;
    const data = this.state.data;
    this.setState({ data, input });
  };
  onFinishFailed = (error) => {
    // console.log("er", error);
  };

  onFinish = (val, file, isDeletePhoto) => {
    const { firstname, lastname, phone } = val;
    const data = {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
    };
    const displayName = `${firstname} ${lastname}`;

    this.postEditProfileToServer(data, file, isDeletePhoto);
    this.updateHeaderInformation(displayName, file, isDeletePhoto);
    this.enableInput(val);
  };

  updateHeaderInformation = (displayName, file) => {
    // เปลี่ยนชื่อ, รูป ตรง header
    var formData = new FormData();
    if (file != undefined) {
      formData.append("profile", file.file);
      localStorage.setItem(localName.PROFILE_IMAGE, formData);
    }
    localStorage.setItem(localName.USERNAME, displayName);
  };
  postEditProfileToServer = (payload, file, isDeletePhoto) => {
    api.user.editProfile(
      payload,
      ({ data, status }) => {
        var formData = new FormData();
        formData.append("profile", file == undefined ? "" : file.file);
        if (typeof file != "string") {
          if (file == undefined && !isDeletePhoto) {
            message.success("ดำเนินการสำเร็จ");
          } else {
            api.user.uploadProfileImg(
              formData,
              ({ data, status }) => {
                // console.log(data);
                this.props.setIsUpdateProfile(true);
                message.success("ดำเนินการสำเร็จ");
                this.setState({ input: false });
              },
              (res) => {
                console.log(res.message);
              }
            );
          }
        }
      },
      (res) => {
        this.props.setIsUpdateProfile(true);
        message.success("ดำเนินการสำเร็จ");
        redirectTo("/profile");
        // message.error("แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        // console.log(res.message);
      }
    );
  };
  enableInput = (val) => {
    const data = val;
    const input = false;
    this.setState({ data, input });
  };

  handleChangePassword = () => {
    this.props.history.push(`${subDomain}/change-password`);
  };

  validatePhoneNumber = (name, lastVal, val, prevValue) => {
    if (name === "phone") {
      // if (lastVal === "." || isNaN(parseInt(lastVal)))
      //   val = val.substring(0, val.length - 1);
      // if (isNaN(parseInt(val.substring(0, val.length)))) {
      //   this.formRef.current.setFieldsValue({
      //     phone: "0",
      //   });
      // } else
      //   this.formRef.current.setFieldsValue({
      //     phone: val.slice(0, 10),
      //   });
      let checkValue = /^[0-9]{0,10}$/.test(val);
      if (checkValue) {
        this.formRef.current.setFieldsValue({
          phone: val,
        });
      } else {
        this.formRef.current.setFieldsValue({
          phone: prevValue,
        });
      }
    }
  };
  handleChange = (e) => {
    const name = e.currentTarget.id;
    let val = e.currentTarget.value;
    const lastVal = val.slice(-1)[0];
    const prevValue = e.currentTarget.defaultValue;
    this.validatePhoneNumber(name, lastVal, val, prevValue);
  };

  render() {
    const pathItems = [];
    const { firstname, lastname, email, phone } = this.state.data;
    return (
      <React.Fragment>
        <ProfileTitle
          pathItems={pathItems}
          option={this.state.input ? null : "not-edit"}
          onEditProfile={this.handleEditProfile}
        />
        <ContentBox>
          <ProfileContent
            inputEnabled={this.state.input}
            formRef={this.formRef}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            isdisabled={this.state.input ? false : true}
            firstname={firstname}
            lastname={lastname}
            email={email}
            phone={phone}
            imageProfile={this.state.imageProfile}
            isEmail={this.state.isEmail}
            onChangePassword={this.handleChangePassword}
            onChange={this.handleChange}
          />
        </ContentBox>
      </React.Fragment>
    );
  }
}
export default Profile;
