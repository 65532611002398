import React, { Component } from "react";
import { Form, Input } from "antd";
import styled from "styled-components";
import { colors } from "../../../../values/colors";
import { redirectTo } from "../../../../services/redirect";
import Uploader from "../profile/profileUploader";
import MediaQuery from "react-responsive";

const InputSize = styled(Input)`
  width: 19rem;
  @media (max-width: 1220px) {
    width: 14rem;
  }
  @media (max-width: 875px) {
    width: 19rem;
  }
`;

const StyleButton = styled.button`
  position: ${(props) => (props.yellow ? "relative" : "null")};
  bottom: 4.1rem;
  width: 12rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  height: 3rem;
  border: 1px solid #fbb03b;
  background-color: ${(props) =>
    props.disabled ? "lightgrey" : props.yellow ? "white" : colors.buttonColor};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? "white" : props.yellow ? "#FBB03B" : "white"};
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
  font-weight: 500;

  @media (max-width: 1220px) {
    width: 10rem;
    bottom: 4.1rem;
  }
  @media (max-width: 875px) {
    width: 10rem;
    bottom: 7.75rem;
  }
  @media (max-width: 680px) {
    width: 100%;
    position: sticky;
    border-radius: 0;
    bottom: 0;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.button ? "flex-end" : "space-between")};
  padding: 1rem 0 1rem 0;

  @media (max-width: 875px) {
    flex-direction: column;
    align-items: ${(props) => (props.button ? "flex-end" : "center")};
    padding-bottom: ${(props) => (props.button ? "4.75rem" : "null")};
  }

  @media (max-width: 875px) {
    padding: 0 0 0 0;
  }
`;
const ChangePasswordBox = styled.div`
  line-height: 2rem;
  width: 19rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  height: 2.2rem;
  border: 1px solid #fbb03b;
  background-color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: #fbb03b;
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
  font-weight: 500;
  margin-top: 2.15rem;
  @media (max-width: 1220px) {
    width: 14rem;
  }
  @media (max-width: 875px) {
    width: 19rem;
  }
  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;
const NameBox = styled.div`
  color: #663c46;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 0.8rem;
  width: 19rem;
  @media (max-width: 1220px) {
    width: 14rem;
  }
  @media (max-width: 875px) {
    width: 19rem;
  }
`;

const UploadBox = styled.div`
  width: 19rem;
  margin-top: 0.2rem;

  @media (max-width: 1220px) {
    width: 14rem;
  }
  @media (max-width: 875px) {
    width: 19rem;
  }
`;

const FontText = styled(Form.Item)`
  color: #663c46;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

const FormLayout = ({ isdisabled, name, label, placeholder, onChange }) => {
  const requiredFields = ["firstname", "lastname", "phone"];
  if (name === "phone")
    return (
      <FontText
        label={label}
        name={name}
        rules={[
          {
            required: requiredFields.includes(name),
            message: "กรุณากรอกข้อมูล",
          },
          {
            type: "string",
            min: 10,
            max: 10,
            message: "โปรดระบุเบอร์โทรศัพท์ที่ถูกต้อง",
          },
        ]}
      >
        <InputSize
          disabled={isdisabled}
          placeholder={placeholder}
          onChange={onChange}
        />
      </FontText>
    );
  if (name === "email")
    return (
      <FontText
        label={label}
        name={name}
        rules={[
          {
            required: requiredFields.includes(name),
            message: "กรุณากรอกข้อมูล",
          },
        ]}
      >
        <InputSize
          disabled={isdisabled}
          placeholder={placeholder}
          onChange={onChange}
        />
      </FontText>
    );
  return (
    <FontText
      label={label}
      name={name}
      rules={[
        {
          required: requiredFields.includes(name),
          message: "กรุณากรอกข้อมูล",
        },

        {
          max: 20,
          message: "ห้ามมีตัวอักษรเกิน 20 ตัวอักษร",
        },
      ]}
    >
      <InputSize
        disabled={isdisabled}
        placeholder={placeholder}
        onChange={onChange}
      />
    </FontText>
  );
};

class ProfileContent extends Component {
  state = {
    upload: false,
    isDeletePhoto: false,
  };
  formRef = this.props.formRef;
  componentDidMount() {
    const { name, surname, email, phone } = this.props;
    this.formRef.current.setFieldsValue({
      name: name,
      surname: surname,
      email: email,
      phone: phone,
    });
  }
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  onUpload = (file) => {
    if (!!file) {
      this.setState({ file, isDeletePhoto: false });
    } else {
      this.setState({ file, isDeletePhoto: true });
    }
  };

  render() {
    const {
      inputEnabled,
      onFinish,
      isdisabled,
      firstname,
      lastname,
      email,
      phone,
      imageProfile,
      isEmail,
      onChangePassword,
      onChange,
    } = this.props;

    return (
      <React.Fragment>
        <Form
          ref={this.formRef}
          layout="vertical"
          onFinish={(val) => {
            this.props.onFinish(val, this.state.file, this.state.isDeletePhoto);
          }}
        >
          <FlexRow>
            <FormLayout
              isdisabled={isdisabled}
              name="firstname"
              label="ชื่อ"
              placeholder={firstname}
              onChange={onChange}
            ></FormLayout>
            <FormLayout
              isdisabled={isdisabled}
              name="lastname"
              label="นามสกุล"
              placeholder={lastname}
              onChange={onChange}
            ></FormLayout>
            <FormLayout
              isdisabled={true}
              name="email"
              label="อีเมล"
              placeholder={email}
              onChange={onChange}
            ></FormLayout>
          </FlexRow>
          <FlexRow>
            <FormLayout
              isdisabled={isdisabled}
              name="phone"
              label="เบอร์โทรศัพท์"
              placeholder={phone}
              onChange={onChange}
            ></FormLayout>
            {/* <UploadBox>
              <NameBox>รูปประจำตัว</NameBox>
              <ProfileUploader
                inputEnabled={this.props.inputEnabled}
                imageProfile={imageProfile}
                upload={this.state.upload}
              />
            </UploadBox> */}

            <UploadBox>
              <NameBox>รูปประจำตัว</NameBox>
              <Uploader
                onUpload={this.onUpload}
                onDelete={this.onUpload}
                value={imageProfile}
                isdisabled={isdisabled}
              />
            </UploadBox>
            {isEmail && !inputEnabled ? (
              <ChangePasswordBox yellow onClick={onChangePassword}>
                แก้ไขรหัสผ่าน
              </ChangePasswordBox>
            ) : (
              <ChangePasswordBox style={{ color: "white", border: "none" }} />
            )}
            {inputEnabled ? (
              <MediaQuery maxDeviceHeight={680}>
                <StyleButton onClick={() => this.setState({ upload: true })}>
                  บันทึก
                </StyleButton>
              </MediaQuery>
            ) : null}
          </FlexRow>
          {inputEnabled ? (
            <MediaQuery minDeviceHeight={680}>
              <FlexRow button>
                <StyleButton onClick={() => this.setState({ upload: true })}>
                  บันทึก
                </StyleButton>
              </FlexRow>
            </MediaQuery>
          ) : null}
        </Form>
        <MediaQuery minDeviceHeight={680}>
          {inputEnabled ? (
            <StyleButton
              yellow
              onClick={() => {
                redirectTo(`/profile`);
              }}
            >
              กลับ
            </StyleButton>
          ) : null}
        </MediaQuery>
        <MediaQuery maxDeviceWidth={680}>
          <></>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default ProfileContent;
