import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import { config } from "../config";
import MarkerIcon from "../assets/icons/Marker.svg";
import AutoComplete from "./AutoComplete";
import { compose, withProps, withHandlers, withState } from "recompose";
import styled from "styled-components";
Geocode.setApiKey(`${config.googleMapKey}`);
Geocode.setLanguage(`${config.googleMapLang}`);
Geocode.enableDebug();

const MapContainer = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  filter: ${(props) => props.filter};
  @media not screen and (min-device-width: 1224px) {
    height: ${(props) => "20rem"};
  }
`;

const MapWrap = styled.div`
  height: ${(props) => props.height};
  @media not screen and (min-device-width: 1224px) {
    height: ${(props) => "20rem"};
  }
`;

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address,
      city: this.props.city,
      area: this.props.area,
      state: this.props.state, //state = แขวง
      postal: this.props.postal,
      mapPosition: this.props.center,
      markerPosition: this.props.center,
      pinable: this.props.pinable,
      reset: this.props.reset,
      zoom: this.props.zoom,
    };
  }

  handleChange() {
    this.props.changeAddress(this.state.address);
    this.props.changeCity(this.state.city);
    this.props.changeArea(this.state.area);
    this.props.changeState(this.state.state);
    this.props.changePostal(this.state.postal);
    this.props.changeMapPosition(this.state.mapPosition);
    this.props.changeMarkerPosition(this.state.markerPosition);
  }

  setAddress(address) {
    this.setState({ address: address });
  }

  setArea(area) {
    this.setState({ area: area });
  }

  setCity(city) {
    this.setState({ city: city });
  }

  setSubDistrict(state) {
    this.setState({ state: state });
  }

  setPostal(postal) {
    this.setState({ postal: postal });
  }

  setMapPosition(mapPosition) {
    this.setState({ mapPosition: mapPosition });
  }

  setMarkerPosition(markerPosition) {
    this.setState({ markerPosition: markerPosition });
  }

  componentWillReceiveProps(nextProps) {
    // console.log(this.state.mapPosition, nextProps.center)
    if (this.state.mapPosition.lat == null && !this.state.pinable) {
      this.setState({ mapPosition: nextProps.center });
      this.setState({ markerPosition: nextProps.center });
    } else if (
      nextProps.center !== this.state.mapPosition &&
      this.state.pinable &&
      (this.state.reset !== nextProps.reset ||
        this.props.isMyLocation !== nextProps.isMyLocation)
    ) {
      this.setState({ mapPosition: nextProps.center });
      this.setState({ markerPosition: nextProps.center });
      this.setState({ reset: nextProps.reset });
      Geocode.fromLatLng(nextProps.center.lat, nextProps.center.lng).then(
        (response) => {
          const addressArray = response.results[0].address_components,
            address = this.getAddress(addressArray),
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray),
            postal = this.getPostal(addressArray);

          this.setState({
            address: address ? address : "",
            area: area ? area : "",
            city: city ? city : "",
            state: state ? state : "",
            postal: postal ? postal : "",
          });
          this.handleChange();
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position.coords.latitude, position.coords.longitude);
        if (this.state.mapPosition == undefined) {
          this.setState({
            mapPosition: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            markerPosition: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude
          ).then(
            (response) => {
              const addressArray = response.results[0].address_components,
                address = this.getAddress(addressArray),
                city = this.getCity(addressArray),
                area = this.getArea(addressArray),
                state = this.getState(addressArray),
                postal = this.getPostal(addressArray);

              this.setState({
                address: address ? address : "",
                area: area ? area : "",
                city: city ? city : "",
                state: state ? state : "",
                postal: postal ? postal : "",
              });
              this.handleChange();
            },
            (error) => {
              console.error(error);
            }
          );
        } else {
          Geocode.fromLatLng(
            this.state.mapPosition.lat,
            this.state.mapPosition.lng
          ).then(
            (response) => {
              const addressArray = response.results[0].address_components,
                address = this.getAddress(addressArray),
                city = this.getCity(addressArray),
                area = this.getArea(addressArray),
                state = this.getState(addressArray),
                postal = this.getPostal(addressArray);

              this.setState({
                address: address ? address : "",
                area: area ? area : "",
                city: city ? city : "",
                state: state ? state : "",
                postal: postal ? postal : "",
              });
              this.handleChange();
            },
            (error) => {
              console.error(error);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      },
      {
        maximumAge: Infinity,
      }
    );
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    if (addressArray !== undefined) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          city = addressArray[i].long_name;
          return city;
        }
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    if (addressArray !== undefined) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
          for (let j = 0; j < addressArray[i].types.length; j++) {
            if (
              addressArray[i].long_name.slice(0, 3) == "เขต" ||
              "locality" === addressArray[i].types[j]
            ) {
              area = addressArray[i].long_name;
              area = area.replace(/\s/g, "");
              return area;
            }
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    if (addressArray !== undefined) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
          for (let j = 0; j < addressArray[i].types.length; j++) {
            if (
              addressArray[i].long_name.slice(0, 4) == "แขวง" ||
              "locality" === addressArray[i].types[j]
            ) {
              state = addressArray[i].long_name;
              state = state.replace(/\s/g, "");
              return state;
            }
          }
        }
      }
    }
  };

  getPostal = (addressArray) => {
    let postal = "";
    if (addressArray !== undefined) {
      for (let i = 0; i < addressArray.length; i++) {
        for (let i = 0; i < addressArray.length; i++) {
          if (
            addressArray[i].types[0] &&
            "postal_code" === addressArray[i].types[0]
          ) {
            postal = addressArray[i].long_name;
            return postal;
          }
        }
      }
    }
  };

  getAddress = (addressArray) => {
    let Address = "";
    if (addressArray !== undefined && addressArray.length >= 2) {
      for (let i = 0; i < 2; i++) {
        if (
          "street_number" === addressArray[i].types[0] ||
          "route" === addressArray[i].types[0]
        ) {
          Address = Address + addressArray[i].long_name + " ";
        }
      }
      return Address;
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.props.setIsMyLocation(false);
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const addressArray = response.results[0].address_components,
          address = this.getAddress(addressArray),
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
        this.handleChange();
      },
      (error) => {
        console.error(error);
      }
    );
  };

  handleZoomChanged() {
    console.log(this.getZoom()); //this == GoogleMap
    // this.props.changeZoom(this.getZoom())
  }

  render() {
    if (this.state.pinable) {
      const AsyncMap = compose(
        withProps({
          googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&language=${config.googleMapLang}&libraries=places`,
          loadingElement: <div style={{ height: `100%`, width: "100%" }} />,
          containerElement: (
            <MapContainer height={this.props.height} width={this.props.width} />
          ),
          mapElement: <div style={{ height: `100%` }} />,
        }),
        withState("zoom", "onZoomChange", 15),
        withHandlers(() => {
          const refs = {
            map: undefined,
          };

          return {
            onMapMounted: () => (ref) => {
              refs.map = ref;
            },
            onZoomChanged: ({ onZoomChange }) => () => {
              onZoomChange(refs.map.getZoom());
              this.setState({ zoom: refs.map.getZoom() });
            },
          };
        }),
        withScriptjs,
        withGoogleMap
      )((props) => (
        <div>
          <GoogleMap
            // google={this.props.google}
            defaultZoom={this.state.zoom}
            defaultCenter={this.state.mapPosition}
            ref={props.onMapMounted}
            onZoomChanged={props.onZoomChanged}
            options={{ gestureHandling: "cooperative" }}
          >
            {/*Marker*/}
            <Marker
              // google={this.props.google}
              icon={MarkerIcon}
              name={"Dolores park"}
              draggable={true}
              onDragEnd={this.onMarkerDragEnd}
              position={this.state.markerPosition}
            />
            <Marker />
          </GoogleMap>
        </div>
      ));
      let map;
      if (this.props.center !== undefined) {
        map = (
          <div style={{ width: "100%" }}>
            <AutoComplete
              address={this.state.address}
              area={this.state.area}
              city={this.state.city}
              state={this.state.state}
              postal={this.state.postal}
              getAddress={this.getAddress}
              getArea={this.getArea}
              getCity={this.getCity}
              getState={this.getState}
              getPostal={this.getPostal}
              setAddress={this.setAddress.bind(this)}
              setArea={this.setArea.bind(this)}
              setCity={this.setCity.bind(this)}
              setSubDistrict={this.setSubDistrict.bind(this)}
              setPostal={this.setPostal.bind(this)}
              setMapPosition={this.setMapPosition.bind(this)}
              setMarkerPosition={this.setMarkerPosition.bind(this)}
              handleChange={this.handleChange.bind(this)}
            />

            <AsyncMap />
          </div>
        );
      }
      return map;
    } else {
      const AsyncMap = withScriptjs(
        withGoogleMap((props) => (
          <div>
            <GoogleMap
              // google={this.props.google}
              defaultZoom={this.state.zoom}
              defaultCenter={this.state.mapPosition}
              defaultOptions={{ draggable: false }}
            >
              {/*Marker*/}
              <Marker
                // google={this.props.google}
                icon={MarkerIcon}
                name={"Dolores park"}
                draggable={false}
                onDragEnd={this.onMarkerDragEnd}
                position={this.state.markerPosition}
              />
              <Marker />
            </GoogleMap>
          </div>
        ))
      );
      let map;
      if (this.props.center.lat !== undefined) {
        map = (
          <MapWrap height={this.props.height}>
            <AsyncMap
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&language=${config.googleMapLang}&libraries=places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <MapContainer
                  height={this.props.height}
                  width={this.props.width}
                  filter={this.props.filterBlur}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </MapWrap>
        );
      } else {
        map = (
          <MapContainer height={this.props.height} width={this.props.width} />
        );
      }
      return map;
    }
  }
}
export default Map;
