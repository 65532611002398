import React from "react";
import styled from "styled-components";
import { Form } from "antd";
import { colors } from "../../values/colors";

const FormItem = styled(Form.Item)`
  & .ant-input,
  .ant-input-affix-wrapper {
    font-family: Kanit;
    font-size: 1.1em;
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0.5rem 0.75rem;
    background: white;
  }

  & .ant-input:focus,
  .ant-input-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  & .ant-form-item-label > label {
    font-family: Kanit;
    font-weight: 600;
    font-size: 1em;
    color: ${colors.buttonColor};
  }

  & div.ant-col.ant-form-item-label {
    padding: 0 0 4px;
  }

  & .ant-form-item-has-error,
  .ant-form-item-explain {
    color: ${colors.errorColor};
    font-family: Kanit;
    font-weight: 300;
    font-size: 0.8em;
  }

  & .ant-form-item-has-error,
  .ant-input:not([disabled]):hover {
    border-color: ${colors.errorColor};
  }

  & .ant-btn {
    font-family: Kanit;
    font-size: 1.1em;
    line-height: 1.25rem;

    width: 100%;
    height: 2.75rem;
    border-color: transparent;
    border-radius: 5px;
    padding: 0.75rem 0.75rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-btn-primary {
    color: ${colors.textColor2};
    background-color: ${colors.buttonColor};
  }
`;

export default FormItem;
