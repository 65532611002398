import React from 'react';
import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';
import { Text } from './sharedComponents';

const ModalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.space ? "space-between" : "center")};
  margin: ${(props) => (props.padding ? "2.5rem 0 1.5rem 0" : 0)};
  padding: 0 1rem;
`;

const Modal = ({ visible, text, left, right, onLeftClick, onRightClick }) =>{
    return <AntdModal
        visible={visible}
        closable={false}
        footer={[
        <ModalBox space>
            <Text
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={onLeftClick}
            >
                {left}
            </Text>
            <Text
            yellow
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={onRightClick}
            >
                {right}
            </Text>
        </ModalBox>,
        ]}
        width={"20rem"}
    >
        <ModalBox padding>
            <Text>{text}</Text>
        </ModalBox>
    </AntdModal>
};

export default Modal;