import React from "react";
import { message } from "antd";
import { CenterSpin } from "../../pages/customers/components/sharedComponents";
import { subDomain } from "../../services/redirect";
import { Redirect } from "react-router-dom";
import localName from "../../values/localStorageDict";
import { driver as driverApi } from "../../services/api";

class matchOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.fetch = this.fetch.bind(this);
  }
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    localStorage.setItem(localName.DRIVER_ACCESS_TOKEN, token);
    this.fetch();
    let secondsToGo = 1;
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      this.setState({
        loading: false,
      });
    }, secondsToGo * 1000);
  }

  async fetch() {
    try {
      await driverApi.matchOrderWithDriver().then((data) => {
        console.log("จับคู่สำเร็จ");
      });
    } catch (err) {
      console.log("ไม่พบข้อมูล");
    }
  }

  render() {
    //return <CenterSpin height={'50rem'}/>;
    return this.state.loading ? (
      <CenterSpin height={"50rem"} />
    ) : (
      <Redirect to={`${subDomain}/driver/ordergroup`} />
    );
  }
}

export default matchOrder;
