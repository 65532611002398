import React, { useMemo } from "react";
import { Form } from "antd";
import { useField } from "react-final-form";
import styled from "styled-components";
import { colors } from "../../../values/colors";
import IconButton from "./iconButton";
import { DeleteIcon } from "../../../components/icons";

const Label = styled.span`
  font-weight: 600;
  color: ${colors.storeText};
  ${(props) => (props.smallLabel ? "font-size: 87.5%;" : "")}
`;

const SubLabel = styled.div`
  font-size: 75%;
  color: ${colors.storeText};

  margin-top: -0.2em;
`;

const Remark = styled.span`
  font-size: 75%;
  color: ${colors.storeTextDanger};

  margin-left: 0.5em;
`;

const LabelContainer = styled.div`
  font-size: 1rem;
  margin-bottom: 0.6em;
`;

const FormItem = styled(Form.Item).withConfig({
  shouldForwardProp: (prop) => !["noMinHeight"].includes(prop),
})`
  ${(props) =>
    props.noMinHeight
      ? `
        & .ant-form-item-control-input {
            min-height: unset;
        }
    `
      : ""}
`;

export const FieldLayout = ({
  label,
  smallLabel,
  subLabel,
  remark,
  noMinHeight,
  children,
  ...props
}) => {
  const hasLabel = Boolean(label);
  return (
    <FormItem {...props} noMinHeight={noMinHeight}>
      {hasLabel && (
        <LabelContainer>
          <div>
            <Label smallLabel={smallLabel}>{label}</Label>
            {Boolean(remark) && <Remark>{remark}</Remark>}
          </div>
          {Boolean(subLabel) && <SubLabel>{subLabel}</SubLabel>}
        </LabelContainer>
      )}
      {children}
    </FormItem>
  );
};

const Field = ({
  // FieldLayout props
  label,
  smallLabel,
  subLabel,
  remark,
  formItemStyle,
  noError,
  validateBorder,
  noMinHeight,
  // Field props
  name,
  rules: propRules,
  validate,
  onChange: onFieldChange,
  component: Input,
  fieldType,
  // Input props
  children,
  deletableField,
  handleDeletablaField,
  ...props
}) => {
  const {
    input: { onChange: onInputChange, ...input },
    meta,
  } = useField(name, { validate, type: fieldType });

  const rules = useMemo(() => {
    return propRules;
  }, propRules);

  // Customize onChange
  input.onChange = (...e) => {
    const currentValue =
      e[0] &&
      (e[0].nativeEvent
        ? e[0].target[fieldType === "checkbox" ? "checked" : "value"]
        : e[0]);

    if (rules) {
      for (const rule of rules) {
        if (!rule(currentValue, input.value)) {
          if (e[0] && e[0].preventDefault) {
            e[0].preventDefault();
          }
          return;
        }
      }
    }

    onInputChange(...e);
    if (onFieldChange) {
      onFieldChange(...e);
    }
  };

  const { touched, error } = meta;
  const showError = Boolean(touched && error);

  return (
    <FieldLayout
      help={noError || !showError ? undefined : error || undefined}
      validateStatus={!noError && showError ? "error" : undefined}
      style={{
        ...formItemStyle,
        ...(noError ? { marginBottom: 0 } : undefined),
      }}
      label={label}
      smallLabel={smallLabel}
      remark={remark}
      subLabel={subLabel}
      noMinHeight={noMinHeight}
    >
      <Input
        {...props}
        {...input}
        style={{
          ...props.style,
          ...(validateBorder
            ? {
                borderColor:
                  input.value != "" && input.value != "0" ? undefined : "red",
              }
            : undefined),
        }}
        suffix={
          deletableField && (
            <IconButton
              icon={DeleteIcon}
              size="1em"
              onClick={handleDeletablaField}
            />
          )
        }
      >
        {children}
      </Input>
    </FieldLayout>
  );
};

Field.Layout = FieldLayout;

export default Field;
