import React from "react";
import styled from "styled-components";
import { Modal, Form, Input, Button, message } from "antd";
import { colors } from "../../values/colors";
import FormItem from "./formItem";
import { redirectTo } from "../../services/redirect";
import { FontColorsOutlined } from "@ant-design/icons";
import { customer } from "../../services/api";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  paddingbottom: 0.1rem;
`;

const Email = styled.div`
  font-family: Kanit;
  font-weight: 600;
  font-size: 1em;
  color: #fbb03b;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const checkText = styled.div`
  position: absolute;
  left: 4.44%;
  right: 3.92%;
  top: 53.87%;
  bottom: 39.68%;

  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: 0.1px;
`;

class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.forgotPasswordModalRef = React.createRef();
    this.state = {
      text: "",
      forgotPasswordEmail: "",
    };
    this.onForgotPasswordEmailChange = this.onForgotPasswordEmailChange.bind(
      this
    );
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEmailForgotPasswordFailed = this.handleEmailForgotPasswordFailed.bind(
      this
    );
  }
  handleCancel() {
    this.props.onCloseForgotPasswordTab();
    if (this.forgotPasswordModalRef.current) {
      this.forgotPasswordModalRef.current.resetFields();
      this.setState({
        forgotPasswordEmail: "",
        text: "",
      });
    }
  }
  onForgotPasswordEmailChange(e) {
    if (this.state.text !== "") {
      this.setState({
        text: "",
      });
    }
    this.setState({
      forgotPasswordEmail: e.target.value,
    });
  }
  handleForgotPassword = () => {
    const payload = {
      email: this.state.forgotPasswordEmail.toLowerCase(),
    };
    this.props.setEmail(this.state.forgotPasswordEmail.toLowerCase());
    console.log(payload);
    customer.user.forgotPassword(
      payload,
      ({ data }) => {
        this.props.onCloseSendToEmailTab();
        console.log("ส่งอีเมลไปแล้ว");
      },
      ({ response }) => {
        this.setState({
          text: "ไม่มีอีเมลนี้ในระบบ",
        });
        console.log("ไม่มีอีเมลในระบบ");
      }
    );
  };

  handleEmailForgotPasswordFailed = (res) => {
    console.log("failed", res);
  };

  render() {
    const { forgotPasswordEmail } = this.state;
    const {
      handleClose,
      visibleForgotPasswordModal,
      onCloseForgotPasswordTab,
      onCloseSendToEmailTab,
      setEmail,
    } = this.props;
    return (
      <Modal
        footer={null}
        visible={visibleForgotPasswordModal}
        width={"24rem"}
        closable={false}
        bodyStyle={{ padding: 0 }}
        style={{
          borderRadius: "5px",
        }}
        onCancel={handleClose}
      >
        <Column>
          <Form
            onFinish={this.handleForgotPassword}
            onFinishFailed={this.handleEmailForgotPasswordFailed}
            style={{ lineHeight: 1.68, marginLeft: "1rem" }}
            layout={"vertical"}
            hideRequiredMark
            ref={this.forgotPasswordModalRef}
          >
            <FormItem
              style={{
                marginTop: "1.26rem",
                marginBottom: "0rem",
                fontStyle: "normal",
                fontFamily: "IBM Plex Sans Arabic",
                fontSize: "20px",
                fontWeight: "600",
                height: "2rem",
                padding: "0rem",
                letterSpacing: "0.1px",
              }}
              label="ลืมรหัสผ่าน"
              name="line1"
            ></FormItem>
            <FormItem
              style={{
                marginTop: "1rem",
                marginBottom: "0",
                fontStyle: "normal",
                fontFamily: "IBM Plex Sans Arabic",
                fontSize: "16px",
                fontWeight: "600",
                // height: "2rem",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              }}
              label="กรุณากรอกอีเมล"
              name="line2"
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกข้อมูล!",
                  type: "email",
                },
              ]}
            >
              <Input
                style={{
                  width: "calc(100% - 1rem)",
                  marginRight: "1rem",
                }}
                value={forgotPasswordEmail}
                onChange={this.onForgotPasswordEmailChange}
              />
            </FormItem>
            <checkText style={{ color: "red" }}>{this.state.text}</checkText>

            <Row
              style={{ justifyContent: "space-between", marginRight: "1rem" }}
            >
              <FormItem>
                <Button
                  type="primary"
                  style={{
                    justifyContent: "center",
                    marginTop: "0.9rem",
                    marginBottom: "0.5rem",
                    width: "6rem",
                    height: "3rem",
                    borderRadius: "5px",
                    backgroundColor: "#A5A5A5",
                    color: "white",
                  }}
                  onClick={this.handleCancel}
                >
                  ยกเลิก
                </Button>
              </FormItem>
              <FormItem>
                <Button
                  style={{
                    justifyContent: "center",
                    marginTop: "0.9rem",
                    marginBottom: "0.5rem",
                    width: "6rem",
                    height: "3rem",
                    borderRadius: "5px",
                    backgroundColor: "#663C46",
                    color: "white",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  ยืนยัน
                </Button>
              </FormItem>
            </Row>
          </Form>
        </Column>
      </Modal>
    );
  }
}

export default ForgotPasswordModal;
