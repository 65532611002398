import React from "react";
import Modal from "../modal";
import Stack from "../stack";
import QRCode from "react-qr-code";
import styled from "styled-components";
import Button from "../button";
import { message } from "antd";
import { config } from "../../../../config";

const StyledStack = styled(Stack)`
  margin-top: 1.5rem;

  & .or-label {
    text-align: center;
    margin: 1.1rem 0;
  }

  & button {
    margin: 0.25rem 0;
  }

  & button.cancel-button {
    margin-top: 2rem;
  }
`;

const copyToClipbord = (text) => {
  const tmpArea = document.createElement("textarea");
  tmpArea.value = text;
  document.body.appendChild(tmpArea);
  tmpArea.select();
  document.execCommand("copy");
  message.success("คัดลอก URL สำเร็จ");
  document.body.removeChild(tmpArea);
};
const QRCodeModal = ({ visible, value, onClose, ...props }) => {
  const prefSize = 300;

  return (
    <Modal
      {...props}
      title="กรุณาให้คนส่งอาหารสแกน QR Code"
      centerTitle
      visible={visible}
    >
      {({ availableWidth }) => (
        <StyledStack>
          <QRCode
            value={`${config.publicUrl}/driver/matchorder?token=${value}`}
            size={availableWidth < prefSize ? availableWidth : prefSize}
          />
          <div className="or-label">หรือ</div>
          <Button
            type="primary"
            onClick={() =>
              copyToClipbord(
                `${config.publicUrl}/driver/matchorder?token=${value}`
              )
            }
          >
            คัดลอก URL
          </Button>
          {/* <Button type="primary">ส่ง SMS</Button> */}
          <Button type="secondary" className="cancel-button" onClick={onClose}>
            {"ปิดหน้าต่าง"}
          </Button>
        </StyledStack>
      )}
    </Modal>
  );
};

export default QRCodeModal;
