import React, { useState, useEffect } from "react";
import { Title } from "../customers/components/title";
import { subDomain, redirectTo } from "../../services/redirect";
import titleIcon from "../../assets/icons/title-icon/profile.svg";
import styled from "styled-components";
import { Form, Input, message } from "antd";
import { Button } from "../customers/components/sharedComponents";
import { customer as api } from "../../services/api";
import localName from "../../values/localStorageDict";
import ChangePasswordSuccessModal from "../customers/components/changepassword/changePasswordSuccessModal";

const TitleBox = styled.div`
  padding: 0 10%;
`;

const StyledForm = styled(Form)`
  width: 500px;
  margin-bottom: 3rem;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;

  margin: 0 10% 0 10%;
`;
const ChangePassword = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (val) => {
    const profileData = JSON.parse(
      localStorage.getItem(localName.PROFILE_DATA)
    );
    const passwordData = {
      currentPassword: val.oldpassword,
      newPassword: val.newpassword,
    };
    api.user.editPassword(
      passwordData,
      ({ data, status }) => {
        setVisibleModal(true);
      },
      (res) => {
        if (res && res.response.data.message == "Password dont matching") {
          setIncorrect(true);
          handleChangeState();
        } else if (
          res &&
          res.response.data.message == "Password is already use"
        ) {
          setIsSamePassword(true);
          handleChangeState();
        }
      }
    );
  };
  const handleChangeState = () => {
    form.submit();
    setIncorrect(false);
    setIsSamePassword(false);
  };
  useEffect(() => {}, [incorrect, isSamePassword]);

  return (
    <React.Fragment>
      <TitleBox>
        <Title
          breadcrumbItems={[
            { name: "โปรไฟล์ของฉัน", path: `${subDomain}/profile` },
          ]}
          title={"แก้ไขรหัสผ่าน"}
          img={titleIcon}
        />
      </TitleBox>

      <ContentBox>
        <StyledForm
          labelAlign="left"
          labelCol={{ span: 9 }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="oldpassword"
            label="Old password : "
            validateFirst
            rules={[
              {
                required: true,
                message: "โปรดระบุรหัสผ่านเก่า",
              },

              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && !incorrect) {
                    return Promise.resolve();
                  }
                  return Promise.reject("รหัสผ่านเดิมไม่ถูกต้อง");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newpassword"
            label="New password : "
            validateFirst
            rules={[
              {
                required: true,
                message: "โปรดระบุรหัสผ่านใหม่",
              },
              { min: 6, message: "รหัสผ่านต้องมีความยาว 6 ตัวอักษรขึ้นไป" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && !isSamePassword) {
                    return Promise.resolve();
                  }
                  return Promise.reject("โปรดตั้งรหัสที่ไม่ซ้ำกับรหัสเดิม");
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmnewpassword"
            label="Confirm new password : "
            dependencies={["password"]}
            validateFirst
            hasFeedback
            rules={[
              {
                required: true,
                message: "โปรดยืนยันรหัสผ่านใหม่",
              },
              ({ getFieldValue }) => ({
                validator(rules, value) {
                  if (
                    getFieldValue("newpassword") != null ||
                    getFieldValue("newpassword") != undefined
                  ) {
                    if (
                      getFieldValue("newpassword").length >= 6 &&
                      getFieldValue("newpassword") === value
                    )
                      return Promise.resolve();
                    if (getFieldValue("newpassword").length < 6)
                      return Promise.reject("");
                  }
                  return Promise.reject("รหัสผ่านใหม่ไม่ตรงกัน");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button
            style={{
              marginTop: "1rem",
              width: "40%",
              height: "2rem",
              fontWeight: "400",
              position: "relative",
              left: "30%",
            }}
          >
            ยืนยัน
          </Button>
          <ChangePasswordSuccessModal
            visibleChangePasswordSuccessModal={visibleModal}
          />
        </StyledForm>
      </ContentBox>
    </React.Fragment>
  );
};

export default ChangePassword;
