import styled from 'styled-components';

const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    --color: ${props => (
        props.status === 'success' ? ' #00C52B' :
        props.status === 'error' ? '#FF0000' :
        props.status === 'canceled' ? '#A5A5A5' :
        'black'
    )};
    font-size: 0.75rem;
    color: var(--color);

    border: 1px solid var(--color);
    border-radius: 5px;
`;

export default Status;