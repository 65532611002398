import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { colors } from '../../../values/colors';

export const CenteredSpin = React.memo(() => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    }}>
        <LoadingOutlined
            style={{
                    fontSize: 60,
                    color: colors.storeText
                }} 
            spin 
        />
    </div>
));