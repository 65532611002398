import React from 'react';
import styled from 'styled-components';
import { OutBox, Button, OptionButton, RowDivider } from './components/sharedComponents';
import { Title } from './components/title';
import { Text } from './components/sharedComponents';
import titleIcon from '../../assets/icons/title-icon/reservation.svg'
import {
    Row, Col,
    Select as AntdSelect,
    DatePicker as AntdDatePicker,
    Divider,
    Radio
} from 'antd';
import CustomerSlidingMenu from './components/menu/customerSlidingMenu';
import { colors } from '../../values/colors';
import { TableHeader, TableBodyEditable } from './components/order/orderItemTable';
import localName from '../../values/localStorageDict';
import { redirectTo } from "../../services/redirect";

const { Option } = AntdSelect;

const Select = styled(AntdSelect)`
    & .ant-select-arrow .anticon > svg {
        color: ${colors.color1}
    }
`;

const DatePicker = styled(AntdDatePicker)`
    & .ant-picker-suffix {
        color: ${colors.color1}
    }
`;

const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0 3rem 0;
`;

const ResultRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
`;

class Reservation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chairCount: 2,
            date: '',
            dateString: '',
            time: 9,
            preOrder: false,
        };
        this.handleChairChange = this.handleChairChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleChairChange(value) {
        this.setState({
            chairCount: parseInt(value)
        })
    }

    handleDateChange(date, dateString) {
        this.setState({
            date,
            dateString
        })
    }

    handleTimeChange(value) {
        this.setState({
            time: parseInt(value)
        })
    }

    setPreOrder(value) {
        this.setState({
            preOrder: value
        });
    }

    render() {
        const { preOrder } = this.state;
        const currentCart = localStorage.getItem(localName.RESERVE_CART);
        const items = currentCart && currentCart.length > 0 ? JSON.parse(currentCart) : [];
        var totalPrice = 0;
        items.forEach(item => {
            totalPrice += (item.pricePerUnit * item.amount);
        })
        return <OutBox>
            <Title title={"จองโต๊ะอาหาร"} img={titleIcon} />
            <Text title> 1. เลือกวันและเวลาที่ต้องการจอง </Text>
            <Row style={{ marginTop: '1rem' }} >
                <Col style={{ width: '12rem' }}>
                    <Text option> จำนวนคนที่ไป </Text>
                    <Select
                        defaultValue='2'
                        style={{ width: '9rem', marginTop: '1rem' }}
                        onChange={this.handleChairChange}
                    >
                        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((i) => (
                            <Option value={i}> {i} </Option>
                        ))}
                    </Select>
                </Col>
                <Col style={{ width: '14rem' }}>
                    <Text option> เลือกวันที่จะไปใช้บริการ </Text>
                    <DatePicker
                        onChange={this.handleDateChange}
                        style={{ width: '11.5rem', marginTop: '1rem' }}
                    />
                </Col>
                <Col style={{ width: '14rem' }}>
                    <Text option> เลือกรอบเวลาจะไปใช้บริการ </Text>
                    <Select
                        defaultValue='9'
                        style={{ width: '9rem', marginTop: '1rem' }}
                        onChange={this.handleChairChange}
                    >
                        {['9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'].map((i) => (
                            <Option value={i}> {`${i}:00`} </Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Divider />
            <Text title> 2. เลือกเมนูอาหาร </Text>
            <Row style={{ margin: '1rem 0' }}>
                <Radio
                    checked={!preOrder}
                    onClick={() => this.setPreOrder(false)}
                    defaultChecked
                />
                <Text>สั่งอาหารทานที่ร้าน</Text>
            </Row>
            <Row>
                <Radio
                    checked={preOrder}
                    onClick={() => this.setPreOrder(true)}
                />
                <Text>สั่งอาหารล่วงหน้า</Text>
            </Row>
            {preOrder ? <>
                <CustomerSlidingMenu popup />
                <Text title> 3. สรุปรายการอาหาร </Text>
                <RowDivider style={{ marginTop: '1rem' }} />
                <TableHeader />
                <RowDivider />
                {items.map((item, index) => (
                    <>
                        <TableBodyEditable item={item} number={index} />
                        <RowDivider />
                    </>
                ))}
                <Text title style={{ marginTop: '5rem' }}> สรุปรายการที่ต้องชำระ </Text>
                <ResultRow>
                    <Text> จำนวนเงินที่ต้องชำระ </Text>
                    <Text title> {totalPrice} ฿ </Text>
                </ResultRow>
                <ResultRow>
                    <div />
                    <Text yellow mini>
                        คลิก “ชำระเงิน” เพื่อไปยังขั้นตอนการชำระเงิน
                    </Text>
                </ResultRow>
            </>
                :
                <>
                    <Divider />
                </>}
            <ButtonRow>
                <OptionButton
                    style={{ width: '25%', height: '2.8rem', margin: 0 }}
                    onClick={() => redirectTo('/home')}
                >
                    กลับหน้าหลัก
                </OptionButton>
                <Col span={6}>

                    <Button
                        style={{  width: '100%', height: '2.8rem' }}
                    >
                        {preOrder ? 'ชำระเงิน' : 'ดำเนินการจอง'}
                    </Button>
                </Col>
            </ButtonRow>
        </OutBox>
    }
}

export default Reservation;