import React from "react";
import styled from "styled-components";
import { colors } from "../../values/colors";
import { SendIcon } from "../../components/icons";
import { driver as driverApi } from "../../services/api";
import { message } from "antd";
import { CenterSpin } from "../customers/components/sharedComponents";
import { renderMoney } from "../../utils/money";

const OrderDetailContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const OrderInformation = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  box-shadow: 0.05rem 0.05rem 0.7rem ${colors.textColor3};
`;

const OrderMoreDetail = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  overflow-y: auto;
`;

const OrderItem = styled.div`
  width: 100%;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
`;
// OrderItem   height: 4rem;

const SummaryOrder = styled.div`
  height: 2rem;
  width: 100%;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
`;
// SummaryOrder height: 6rem;

const SummaryOrderLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const Text = styled.div`
  color: ${(props) =>
    props.BrownColorText
      ? colors.footerColor
      : props.YellowColorText
      ? colors.color1
      : colors.textColor1};
  font-size: ${(props) => (props.bigFont ? "1.3em" : "1em")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  padding: ${(props) => props.padding};
  padding-right: ${(props) => props.paddingRight};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "initial")};
  word-wrap: break-word;
`;

const Line = styled.hr`
  border-color: ${colors.lineDriverColor};
  border-style: solid;
  border-width: 0.01rem;
`;

class OrderDetail extends React.Component {
  state = {
    orderDetail: {},
    loading: true,
  };

  componentDidMount() {
    this.props.setDeliveryGroupId(this.props.match.params.id);
    this.fetch();
  }

  async fetch() {
    try {
      await driverApi
        .deliveryOrderDetail(this.props.match.params.id)
        .then((data) => {
          this.setState({
            orderDetail: data,
          });
        });
    } catch (err) {
      message.error("เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง");
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    // const order = this.props.history.location.state.item;
    // const list = this.props.history.location.state.item.orderList;
    const orderDetail = this.state.orderDetail;
    return this.state.loading ? (
      <CenterSpin height={"50rem"} />
    ) : (
      <React.Fragment>
        <OrderDetailContainer>
          <OrderInformation>
            <div
              style={{
                height: "100%",
                width: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SendIcon color={"red"} />
            </div>
            <div
              style={{
                width: "90%",
                padding: "0.5rem",
                maxHeight: "100%",
                overflowY: "auto",
              }}
            >
              <Text bold BrownColorText>
                {"จัดส่งที่ " + orderDetail.firstName}
              </Text>
              <Text
                BrownColorText
              >{`${orderDetail.addressLine} ${orderDetail.subDistrict} ${orderDetail.district} ${orderDetail.province} ${orderDetail.postalCode}`}</Text>
            </div>
          </OrderInformation>
          <OrderMoreDetail>
            <div
              style={{
                height: "1.5rem",
                margin: "0.5rem",
                paddingLeft: "0.5rem",
              }}
            >
              <Text bold>สรุปรายการสินค้า</Text>
            </div>
            <Line />
            {orderDetail.item &&
              orderDetail.item.map((item, index) => {
                let price = parseFloat(item.price);
                for (let i = 0; i < item.options.length; i++) {
                  for (let j = 0; j < item.options[i].choices.length; j++) {
                    price += parseFloat(item.options[i].choices[j].price);
                  }
                }
                return (
                  <OrderItem key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text paddingRight={"0.5rem"}>{item.name_th}</Text>
                        <Text YellowColorText>x {item.amount}</Text>
                      </div>
                      <Text bold>
                        {renderMoney(
                          parseFloat(price * item.amount).toFixed(2)
                        )}
                      </Text>
                    </div>
                    {item.options &&
                      item.options.map((option, i) => {
                        return (
                          option.choices &&
                          option.choices.map((choice, j) => {
                            return (
                              <Text
                                YellowColorText
                              >{`+ ${choice.name_th}`}</Text>
                            );
                          })
                        );
                      })}
                    {/* <Text YellowColorText>{item.addOn}</Text> */}
                  </OrderItem>
                );
              })}
            <Line />
            <SummaryOrder>
              <SummaryOrderLine>
                <Text bold> รวมค่าอาหารทั้งหมด</Text>
                <Text bold>{renderMoney(orderDetail.totalPrice)}</Text>
              </SummaryOrderLine>
              {/* <SummaryOrderLine>
                <Text> ค่าส่ง</Text>
                <Text>20</Text>
              </SummaryOrderLine>
              <SummaryOrderLine>
                <Text bold> รวมทั้งหมด</Text>
                <Text bold>999</Text>
              </SummaryOrderLine> */}
            </SummaryOrder>
            <Line />
          </OrderMoreDetail>
        </OrderDetailContainer>
      </React.Fragment>
    );
  }
}

export default OrderDetail;
