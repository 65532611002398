import React from 'react';
import { Text } from '../../../customers/components/sharedComponents';

class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          minutes: 0,
          seconds: 0,
          timeout: false
        };
    }

    componentDidMount(){
        this.setState({
            minutes: this.props.minutes || 0,
            seconds: this.props.seconds || 0,
            timeout: false
        })
        this.interval = setInterval(
            () => {
                const { minutes, seconds, timeout } = this.state;
                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                      seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        this.setState({
                            timeout: true
                        })
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                    }
                }
                if(timeout){
                    this.props.onTimeout();
                    clearInterval(this.interval);
                }
            }
            , 1000
        );
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){
        const { minutes, seconds, timeout } = this.state;
        return <Text 
            color={'#FF2828'}
            style={{ padding: '0 0.5rem'}}
        >
            {
            timeout ? '--:--' :
            `${ minutes < 10 ? `0${ minutes }` : minutes }:${ seconds < 10 ? `0${ seconds }` : seconds }`
            }
        </Text>
    }
}

export default Countdown;