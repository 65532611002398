import React from "react";
import styled from "styled-components";
import { OutBox, Label, Span, SpanBox } from "./choice-components";
import { colors } from "../values/colors";

const Icon = styled.div`
  width: 13px;
  height: 13px;
  background: ${colors.buttonColor};
  border-radius: 50%;
`;

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0.8px solid #c2c2c2;
  cursor: pointer;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const RadioContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Radio = ({ index, checked, onClick, ...props }) => (
  <RadioContainer>
    <HiddenRadio checked={checked} {...props} />
    <StyledRadio checked={checked} onClick={() => onClick(index)}>
      <Icon />
    </StyledRadio>
  </RadioContainer>
);

class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.toggleChecked = this.toggleChecked.bind(this);
  }

  toggleChecked(index) {
    var { onChange, optionIndex } = this.props;
    onChange(optionIndex, index);
  }

  render() {
    const { list, space } = this.props;
    return (
      <OutBox>
        {list.map((choice, index) => (
          <Label>
            <Radio
              index={index}
              checked={this.props.checked === index}
              onClick={this.toggleChecked}
              onChange={this.toggleChecked}
            />
            <SpanBox
              style={
                space && {
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }
              }
            >
              <Span> {choice.nameTh} </Span>
              {Number(choice.price) !== 0 ? (
                <Span price>
                  {choice.price > 0 ? "+" : choice.price < 0 ? "" : ""}
                  {choice.price} ฿{" "}
                </Span>
              ) : (
                <div />
              )}
            </SpanBox>
          </Label>
        ))}
      </OutBox>
    );
  }
}

export default RadioButtons;
