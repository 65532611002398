const localName = {
  CART: "cart",
  ACCESS_TOKEN: "access-token",
  REFRESH_TOKEN: "refresh-token",
  STORE_ACCESS_TOKEN: "store-access-token",
  STORE_REFRESH_TOKEN: "store-refresh-token",
  USERNAME: "username",
  PROFILE_IMAGE: "profile-image",
  LAST_PAGE: "last-page",
  START_AT: "startAt",
  PREVENT_TYPING_URL: "preventTypingUrl",
  ADD_OR_EDIT_ADDR: "addOrEditAddress",
  EDIT_ADDR: "edit-address",
  CART_ADDR: "cart-address",
  ADDR_WILL_BE_EDIT: "addressWillBeEdited",
  LAST_DEFAULT_ADDR: "lastDefaultAddress",
  RESERVE_CART: "reserve-cart",
  PATH_ITEMS: "path-items",
  PROFILE_DATA: "profile-data",
  DRIVER_ACCESS_TOKEN: "driver-access-token",
  PHONE_NUMBER: "phone_number"
};

export default localName;
