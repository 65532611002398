import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { Title } from "./components/title";
import {
  Text,
  OutBox as SharedOutBox,
  ColumnHalfBox,
  ColumnHalfBoxContainer,
} from "./components/sharedComponents";
import mapPinIcon from "../../assets/icons/map-pin.svg";
import phoneIcon from "../../assets/icons/phone.svg";
import fbIcon from "../../assets/image/cover/fb-icon.svg";
import igIcon from "../../assets/icons/ig.svg";
import titleIcon from "../../assets/icons/title-icon/contact.svg";
import { customer } from "../../services/api";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import MarkerIcon from "../../assets/icons/Marker.svg";
import { config } from "../../config";

const MapBox = styled.div`
  width: 100%;
  padding-top: 80%;
  background-color: lightGrey;
`;

const ContactSpan = styled(Text)`
  line-height: 24px;
`;

const IconBox = styled.div`
  width: 24px;
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem 0;
  height: auto;

  @media only screen and (max-width: 680px) {
    align-items: center;
    justify-content: start;
  }
`;

const OutBox = styled(SharedOutBox)`
  @media only screen and (max-width: 680px) {
    padding: 1rem;
  }
`;

const Contact = () => {
  const [branchInformation, setBranchInformation] = useState({});
  useEffect(() => {
    customer.store.getBranchInformation(2).then((data) => {
      setBranchInformation(data.branchInformation[0]);
    });
  }, []);

  const AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <div>
        <GoogleMap
          defaultZoom={15}
          defaultCenter={{
            lat: parseFloat(branchInformation.latitude),
            lng: parseFloat(branchInformation.longitude),
          }}
          defaultOptions={{ draggable: false }}
        >
          <Marker
            icon={MarkerIcon}
            name={"Dolores park"}
            draggable={false}
            // onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: parseFloat(branchInformation.latitude),
              lng: parseFloat(branchInformation.longitude),
            }}
          />
        </GoogleMap>
      </div>
    ))
  );

  return (
    <OutBox>
      <Title title={"ติดต่อร้านค้า"} img={titleIcon} />

      <MediaQuery minDeviceWidth={680}>
        <ColumnHalfBoxContainer>
          <ColumnHalfBox>
            {/* <MapBox> */}
            {/* <div> */}
            <AsyncMap
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&language=${config.googleMapLang}&libraries=places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{
                    height: "30rem",
                    width: "100%",
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
            {/* </div> */}
            {/* </MapBox> */}
          </ColumnHalfBox>
          <ColumnHalfBox>
            <ContactRow>
              <IconBox>
                <img src={mapPinIcon} />
              </IconBox>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ContactSpan>
                {branchInformation &&
                  `${branchInformation.address_line} ${branchInformation.sub_district} ${branchInformation.province} ${branchInformation.postal_code}`}

                {/* 449 ถนนสุทธิสาร แขวงดินแดง เขตดินแดง กรุงเทพมหานคร 10400 */}
              </ContactSpan>
            </ContactRow>

            <ContactRow>
              <IconBox>
                <img src={phoneIcon} />
              </IconBox>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <ContactSpan>
                {/* 062-981-2526, 085-344-0055 */}
                {branchInformation && branchInformation.phone}
              </ContactSpan>
            </ContactRow>

            <a href="https://www.facebook.com/im.yellow.cafe/" target="_blank">
              <ContactRow>
                <IconBox>
                  <img
                    src={fbIcon}
                    style={{
                      // paddingRight: "0.2rem",
                      height: "1.5rem ",
                      width: "1.3rem",
                    }}
                  />
                </IconBox>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ContactSpan>
                  {/* I’m Yellow Cafe & Restaurant */}
                  {branchInformation && branchInformation.facebook}
                </ContactSpan>
              </ContactRow>
            </a>
            <a href="https://www.instagram.com/im.yellow.cafe/" target="_blank">
              <ContactRow>
                <IconBox>
                  <img src={igIcon} />
                </IconBox>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ContactSpan>
                  {branchInformation && branchInformation.instagram}
                </ContactSpan>
              </ContactRow>
            </a>
          </ColumnHalfBox>
        </ColumnHalfBoxContainer>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={680}>
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&language=${config.googleMapLang}&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: "16.75rem",
                width: "100%",
                marginBottom: "1rem",
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />

        <ContactRow>
          <IconBox>
            <img src={mapPinIcon} style={{ height: "24px" }} />
          </IconBox>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ContactSpan>
            {branchInformation &&
              `${branchInformation.address_line} ${branchInformation.sub_district} ${branchInformation.province} ${branchInformation.postal_code}`}

            {/* 449 ถนนสุทธิสาร แขวงดินแดง เขตดินแดง กรุงเทพมหานคร 10400 */}
          </ContactSpan>
        </ContactRow>

        <ContactRow>
          <IconBox>
            <img src={phoneIcon} />
          </IconBox>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ContactSpan>
            {/* 062-981-2526, 085-344-0055 */}
            {branchInformation && branchInformation.phone}
          </ContactSpan>
        </ContactRow>

        <a href="https://www.facebook.com/im.yellow.cafe/" target="_blank">
          <ContactRow>
            <IconBox>
              <img
                src={fbIcon}
                style={{
                  // paddingRight: "0.2rem",
                  height: "1.5rem ",
                  width: "1.3rem",
                }}
              />
            </IconBox>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ContactSpan>
              {/* I’m Yellow Cafe & Restaurant */}
              {branchInformation && branchInformation.facebook}
            </ContactSpan>
          </ContactRow>
        </a>
        <a href="https://www.instagram.com/im.yellow.cafe/" target="_blank">
          <ContactRow>
            <IconBox>
              <img src={igIcon} />
            </IconBox>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ContactSpan>
              {branchInformation && branchInformation.instagram}
            </ContactSpan>
          </ContactRow>
        </a>
      </MediaQuery>
    </OutBox>
  );
};

export default Contact;
