import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { readFileAsDataURL } from "./readFile";
import DashBox from "../dashBox";
import { CloseIcon } from "../../../../components/icons";
import { colors } from "../../../../values/colors";

const Box = styled(DashBox)`
  background-color: ${(props) =>
    props.accepting ? "#D2FFDA" : props.rejecting ? "#FFD8D0" : "transparent"};
  border: 1px dashed
    ${(props) =>
      props.accepting ? "#009D1C" : props.rejecting ? "#BF1F00" : "#A5A5A5"};

  transition: border-color 0.3s ease, background-color 0.3s ease;
`;

const PreviewContainer = styled.div`
  margin: 12px;
  display: inline-block;
  position: relative;
`;

const ClearIconContainer = styled.div`
  display: inline-block;
  position: absolute;
  cursor: pointer;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px rgba(${colors.storeTextHightlightedRGB}, 0);

  top: -10px;
  right: -10px;

  z-index: 10;

  transition: box-shadow 0.5s ease;
  &:hover {
    box-shadow: 0px 0px 15px rgba(${colors.storeTextHightlightedRGB}, 1);
  }
`;

const Preview = styled.img`
  max-width: 100%;
  max-height: 200px;

  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

// Must be controlled
const ImageDropZone = ({ maxSize, placeholder, value, onChange }) => {
  // #region Drag & Drop Logic
  const onDropAccepted = useCallback(
    (files) => {
      if (onChange) {
        onChange(files[0]);
      }
    },
    [onChange]
  );

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ["image/jpeg", "image/png"],
    maxSize,
    multiple: false,
    onDropAccepted,
  });
  // #endregion

  // #region Preview Logic
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    if (typeof value === "string") {
      // value is URL
      setPreview(value);
    } else if (value) {
      // value is file
      readFileAsDataURL(value).then((data) => setPreview(data));
    }
  }, [value]);
  // #endregion

  const onClear = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(undefined);
    },
    [onChange]
  );

  return (
    <Box {...getRootProps()} accepting={isDragAccept} rejecting={isDragReject}>
      <input {...getInputProps()} />
      {value ? (
        <PreviewContainer>
          <Preview src={preview} />
          <ClearIconContainer onClick={onClear}>
            <CloseIcon width={"100%"} height={"100%"} />
          </ClearIconContainer>
        </PreviewContainer>
      ) : (
        placeholder
      )}
    </Box>
  );
};

export default ImageDropZone;
