import React from "react";
import { Form, Input } from "antd";
import { Button } from "../../../customers/components/sharedComponents";
import { colors } from "../../../../values/colors";
import styled from "styled-components";
const StyledButton = styled(Button)`
  background-color: ${(props) =>
    props.disabled
      ? "lightgrey"
      : props.yellow
      ? "#FBB03B"
      : props.grey
      ? "#9C9C9C"
      : colors.buttonColor};
`;
const StyledTitle = styled.p`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #663c46;
`;
const StyledText = styled.p`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 5px;
  letter-spacing: 0.1px;
  color: #333333;
`;
const SetupForm = ({ form, onFinish, onFinishFailed }) => {
  return (
    <Form
      style={{
        width: "90%",
      }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      hideRequiredMark={true}
    >
      <div style={{ width: "100%" }}>
        <StyledTitle>ตั้งค่ารหัสผ่าน</StyledTitle>
      </div>
      <Form.Item
        name="password"
        label={<StyledText>รหัสผ่าน</StyledText>}
        rules={[
          {
            required: true,
            min: 6,
            message: "รหัสผ่านต้องมีจำนวน 6 ตัวอักษรขึ้นไป",
          },
        ]}
        hasFeedback
      >
        <Input.Password autoFocus />
      </Form.Item>

      <Form.Item
        name="confirmpassword"
        label={<StyledText>ยืนยันรหัสผ่าน</StyledText>}
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "โปรดยืนยันรหัสผ่านของท่าน",
          },

          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject("รหัสผ่านไม่ตรงกัน !");
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="firstName"
        label={<StyledText>ชื่อ</StyledText>}
        rules={[
          {
            required: true,
            message: "กรุณากรอกข้อมูล!",
          },
          {
            max: 20,
            message: "ห้ามมีตัวอักษรเกิน 20 ตัวอักษร",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={<StyledText>นามสกุล</StyledText>}
        rules={[
          {
            required: true,
            message: "กรุณากรอกข้อมูล!",
          },
          {
            max: 20,
            message: "ห้ามมีตัวอักษรเกิน 20 ตัวอักษร",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <StyledButton
          yellow
          style={{ width: "50%", height: "2rem", marginTop: "1rem" }}
        >
          ยืนยัน
        </StyledButton>
      </div>
    </Form>
  );
};

export default SetupForm;
