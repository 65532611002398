import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import CustomerPage from "./pages/customers";
import Store from "./pages/stores";
import Driver from "./pages/drivers";
import "antd/dist/antd.less";
import { subDomain } from "./services/redirect";
import LogIn from "./pages/stores/login";

function App() {
  return (
    <BrowserRouter>
      <div>
        <header className="App-header">
          <div>
            <Switch>
              <Route path={`${subDomain}/driver`} component={Driver} />
              <Route path={`${subDomain}/storelogin`} component={LogIn} />
              <Route path={`${subDomain}/store`} component={Store} />
              <Route path={`${subDomain}/`} component={CustomerPage} />
              {/* <Route
                path={`${subDomain}/logout`}
                component={() => <Redirect to={`${subDomain}/`} />}
              /> */}
            </Switch>
          </div>
        </header>
      </div>
    </BrowserRouter>
  );
}

export default App;
