import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { reorder } from "./util";
import ListSelectionContext from "./context";

const List = styled.div`
  background-color: ${(props) =>
    props.isDraggingOver ? "rgba(255, 255, 22, 0.25)" : "#fff"};
  width: 100%;
  padding: 8px;

  transition: background-color 0.5s ease;
`;

/**
 * This component must be controlled to work as expected
 * In other word, just supply onOrderChange and onSelect
 *  (Wihtout onOrderChange, the order change won't be applied)
 *  (Wihtout onSelect, the selection won't be applied)
 * Read about controlled component here: https://reactjs.org/docs/forms.html#controlled-components
 *
 * @typedef {Function} selectCallback
 * @param {string} selectedId
 * @returns {void}
 *
 * @typedef {Function} reorderFunction
 * @param {Array} items
 * @returns {Array}
 *
 * @typedef {Function} orderCallback
 * @param {reorderFunction} reorder
 * @returns {void}
 *
 * @typedef {object} Props
 * @property {string} selectedId
 * @property {selectCallback} onSelect
 * @property {orderCallback} onOrderChange
 *
 * @extends {React.Component<Props>}
 */
export default class DNDList extends React.Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  getContextValue() {
    const { selectedId, onSelect, disableDND } = this.props;

    return {
      selectedId,
      select: onSelect,
      disabled: disableDND,
    };
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { onOrderChange } = this.props;
    if (onOrderChange) {
      onOrderChange(result, (items) =>
        reorder(items, result.source.index, result.destination.index)
      );
    }
  }

  render() {
    const { children, className, disableDND } = this.props;

    return (
      <ListSelectionContext.Provider value={this.getContextValue()}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="list" isDropDisabled={disableDND}>
            {(provided, snapshot) => (
              <List
                className={className}
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {children}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </ListSelectionContext.Provider>
    );
  }
}
