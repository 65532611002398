import React from 'react';
import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../values/colors';

const modalBodyPadding = 20;

const ModalTitle = styled.div`
    font-weight: 600;
    font-size: 1.25rem;
    color: ${colors.storeText};
    text-align: ${props => props.center ? 'center' : 'left'};
`;

const StyledAntdModal = styled(AntdModal)`
    font-family: "Kanit";

    & .ant-modal-content {
        border-radius: 5px;
    }

    & .ant-modal-body {
        padding: ${modalBodyPadding}px;
    }

    & input,
    & .ant-picker {
        border-radius: 5px;
    }

    
    & .ant-picker .ant-picker-suffix svg {
        fill: ${colors.storeTextHightlighted};
    }
`;

// Custom Default Modal over Antd Modal
const Modal = ({ title, centerTitle, children, width = 380, ...props }) => {
    const functionChildParams = {
        availableWidth: width - 2 * modalBodyPadding
    };
    
    return (
        <StyledAntdModal
            footer={null}
            mask
            maskClosable={false}
            closable={false}
            {...props}
            width={width}
        >
            {Boolean(title) && (
                <ModalTitle center={centerTitle}>{title}</ModalTitle>
            )}
            {typeof children === 'function' ? children(functionChildParams) : children}
        </StyledAntdModal>
    );
};

export default Modal;