import React, { useCallback } from "react";
import {
  VerticalMoreIcon,
  EditIcon,
  DeleteIcon,
} from "../../../../components/icons";
import Badge from "./badge";
import IconButton from "../iconButton";
import styled from "styled-components";
import { colors } from "../../../../values/colors";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
  & > *:not(:first-child) {
    margin-left: ${(props) => props.spacing};
  }
`;

const ItemFace = ({ name, onEdit, onDelete, badge, active }) => {
  const iconSize = "1em";

  // Wrap button event handler and call stopPropagation
  // to prevent triggering item selection from click event propagation
  const handleEdit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onEdit) {
        onEdit(e);
      }
    },
    [onEdit]
  );

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onDelete) {
        onDelete(e);
      }
    },
    [onDelete]
  );

  return (
    <Container>
      <Box spacing={"1em"}>
        <VerticalMoreIcon
          size={iconSize}
          color={colors.storeTextHightlighted}
        />
        <span >{name}</span>
      </Box>
      <Box spacing={"0.5em"}>
        <div style={{width:"70px"}}>
          {onEdit && (
            <IconButton
              icon={EditIcon}
              size={iconSize}
              onClick={handleEdit}
              isOnDarkBg={active}
            />
          )}
          <IconButton
            icon={DeleteIcon}
            size={iconSize}
            onClick={handleDelete}
            isOnDarkBg={active}
          />
        </div>
        {badge !== undefined && <Badge count={badge} />}
      </Box>
    </Container>
  );
};

export default ItemFace;
