import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { colors } from '../../../values/colors';

const LinkComponent = ({ to, onClick, children, ...props }) => {
    const history = useHistory();
    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();
        if(to) {
            history.push(to);
        } else if(onClick) {
            onClick();
        }
    };
    return (
        <a {...props} onClick={handleClick}>{children}</a>
    )
}

const Link = styled(LinkComponent)`
    font-size: 1em;
    font-weight: 600;
    color: ${colors.storeTextHightlighted};

    &:hover,
    &:focus {
        color: ${colors.storeTextHightlighted};
        text-decoration: underline;
    }
`;

export default Link;