import React, { Component } from "react";
import AddressComponent from "../customers/components/addressComponent";
import styled from "styled-components";
import { redirectTo, subDomain } from "../../services/redirect";
import { customer as api } from "../../services/api";
import titleIcon from "../../assets/icons/title-icon/my-address.svg";
import localName from "../../values/localStorageDict";
import MyAddressTitle, {
  MyAddressTitleWithOutBreadCrumb,
} from "../customers/components/myaddress/myAddressTitle";
import NotFoundAddress from "../customers/components/myaddress/notFoundAddress";
import ConfirmButton from "../customers/components/myaddress/confirmButton";
import MediaQuery from "react-responsive";
const MainAddressBox = styled.div`
  display: flex;
  flex-direction: column;
`;
class MyAddress extends Component {
  state = {
    render: false,
    signedIn:
      localStorage.getItem(localName.ACCESS_TOKEN) &&
      localStorage.getItem(localName.REFRESH_TOKEN),
    startAt: localStorage.getItem(localName.START_AT),
    addOrEditAddress: localStorage.getItem(localName.ADD_OR_EDIT_ADDR),
    addresses: [],
  };
  componentDidMount() {
    this.fetchAddresses();
    localStorage.removeItem(localName.ADDR_WILL_BE_EDIT);
    localStorage.setItem(localName.PREVENT_TYPING_URL, false);
  }
  existingAddresses = (addresses) => {
    return addresses.filter((address) => !address.isDeleted);
  };
  sortAddressesByAddressId = (addresses) => {
    return addresses.sort(function (a, b) {
      if (parseInt(a.addressId) < parseInt(b.addressId)) return -1;
      if (parseInt(a.addressId) > parseInt(b.addressId)) return 1;
      return 0;
    });
  };
  checkDefaultLocation = (addresses) => {
    let existingAddresses = [...addresses];
    const defaultAddresses = existingAddresses.filter((item) => item.isDefault);
    const countDefaultAddresses = defaultAddresses.length;
    if (countDefaultAddresses === 0) {
      existingAddresses[0].isDefault = true;
      api.address.setDefault({ addressId: existingAddresses[0].addressId });
      return existingAddresses;
    } else if (countDefaultAddresses === 1 && !existingAddresses[0].isDefault) {
      let firstAddress = existingAddresses[0];
      let defaultAddress = defaultAddresses.slice(-1)[0];
      existingAddresses[
        existingAddresses.indexOf(defaultAddress)
      ] = firstAddress;
      existingAddresses[0] = defaultAddress;
      api.address.setDefault({ addressId: existingAddresses[0].addressId });
      return existingAddresses;
    }
    return existingAddresses;
  };
  fetchAddresses = () => {
    api.address.fetch(
      (data) => {
        this.initializeData(data);
      },
      () => {
        setTimeout(() => {
          alert("Something went wrong.");
        }, 2000);
      }
    );
  };
  initializeData = (data) => {
    let cartAddress = JSON.parse(localStorage.getItem(localName.CART_ADDR));
    let existingAddresses = this.existingAddresses(data);
    let selected = false;
    if (existingAddresses.length === 0) {
      const addresses = [];
      this.setState({ addresses, render: true });
    } else if (existingAddresses.length > 0) {
      existingAddresses = this.sortAddressesByAddressId(existingAddresses);
      existingAddresses = this.checkDefaultLocation(existingAddresses);
      let addresses = existingAddresses.map((address) => {
        cartAddress = cartAddress ? cartAddress : {};
        if (address.addressId === cartAddress.addressId) {
          address.isSelectedByUser = true;
          selected = true;
        } else address.isSelectedByUser = false;
        return address;
      });
      if (!selected) addresses[0].isSelectedByUser = true;
      this.setState({ addresses, render: true });
    }
  };
  handleChecked = (address) => {
    let addresses = this.state.addresses.map((c) => {
      c.isSelectedByUser = false;
      return c;
    });
    let index = addresses.indexOf(address);
    addresses = addresses;
    addresses[index].isSelectedByUser = true;
    this.setState({ addresses });
  };
  handleConfirm = () => {
    const selectedAddress = this.state.addresses.filter(
      (address) => address.isSelectedByUser
    );
    localStorage.setItem(
      localName.CART_ADDR,
      JSON.stringify(selectedAddress[0])
    );
    redirectTo("/cart");
  };
  pathLocalStorage = (addOrEditAddress, startAtCartOrMain) => {
    localStorage.setItem(localName.ADD_OR_EDIT_ADDR, addOrEditAddress);
    localStorage.setItem(localName.START_AT, startAtCartOrMain);
    redirectTo(`/edit-address`);
  };
  // ปรับให้ที่อยู๋ตั้งต้นเดิมหายไป และเลือกที่อยู่ตั้งต้นใหม่
  handleSetDefaultLocation = (addressId) => {
    const originalState = JSON.stringify(this.state.addresses);
    const addresses = [...this.state.addresses];
    addresses.map((address) => {
      if (this.state.addresses.length !== 0 && address !== undefined) {
        if (address.isDefault) {
          address.isDefault = false;
          return address;
        } else if (address.addressId === addressId) {
          address.isDefault = true;
          return address;
        }
      }
    });
    this.initializeData(addresses);
    const payload = { addressId: addressId };
    api.address.setDefault(payload, () =>
      this.backToPrevState(JSON.parse(originalState))
    );
  };
  backToPrevState = (originalState) => {
    setTimeout(() => {
      this.initializeData(originalState);
      alert("Something went wrong.");
    }, 2000);
  };

  handleDelete = (addressId) => {
    const originalState = JSON.stringify(this.state.addresses);
    let addresses = [...this.state.addresses];
    addresses = addresses.map((address) => {
      if (address.addressId === addressId) {
        address.isDeleted = true;
        address.isDefault = false;
        if (address.isSelectedByUser)
          localStorage.removeItem(localName.CART_ADDR);
        return address;
      }
      return address;
    });
    this.initializeData(addresses);
    api.address.delete(
      addressId,
      ({ status }) => {
        if (status == 200) {
          this.fetchAddresses();
        }
      },
      () => this.backToPrevState(JSON.parse(originalState))
    );
  };
  render() {
    const { startAt, signedIn } = this.state;
    if (signedIn) {
      return (
        <MainAddressBox>
          {/* Title  */}
          <MediaQuery minDeviceWidth={680}>
            <MyAddressTitle
              startAt={startAt}
              pathLocalStorage={this.pathLocalStorage}
              titleIcon={titleIcon}
            />
          </MediaQuery>
          <MediaQuery maxDeviceWidth={680}>
            <MyAddressTitleWithOutBreadCrumb
              startAt={startAt}
              pathLocalStorage={this.pathLocalStorage}
              titleIcon={titleIcon}
            />
          </MediaQuery>
          {/* render = true ต่อเมื่อ fetch จาก server เรียบร้อยแล้ว */}
          {this.state.render ? (
            //   ถ้าไม่มีที่อยู่ ให้ render หน้าแจ้งเตือนให้เพิ่มที่อยู่
            <React.Fragment>
              {this.state.addresses.length !== 0 ? null : (
                <NotFoundAddress
                  pathLocalStorage={this.pathLocalStorage}
                  startAt={startAt}
                />
              )}
              {/* render แต่ละ address component */}
              {/* จะมีการ render 2 แบบ 1. ถูกเลือกจากหน้าหลักไปที่ "ที่อยู่ของฉัน"  2. ถูกเลือกที่อยู่จากหน้าตะกร้า */}
              {this.state.addresses.map((address) =>
                address !== undefined && this.state.addresses.length !== 0 ? (
                  <AddressComponent
                    key={address.addressId}
                    type={
                      startAt === `${subDomain}/cart` ? "have-checkbox" : null
                    }
                    address={address}
                    onSetDefaultLocation={this.handleSetDefaultLocation}
                    onChecked={this.handleChecked}
                    onDelete={this.handleDelete}
                  />
                ) : null
              )}

              {startAt === `${subDomain}/cart` ? (
                <ConfirmButton
                  addresses={this.state.addresses}
                  onConfirm={this.handleConfirm}
                />
              ) : null}
            </React.Fragment>
          ) : null}
        </MainAddressBox>
      );
    }
    // ถ้าไม่ล็อกอินจะเข้าเงื่อนไขนี้
    else {
      //  ถ้าถูกเลือกจากหน้าตะกร้า จะ Redirect ไปหน้า editAddress.
      if (
        startAt === `${subDomain}/cart` &&
        localStorage.getItem(localName.PREVENT_TYPING_URL)
      )
        return this.pathLocalStorage("add", `${subDomain}/cart`);
      // ถ้าถูกเลือกจากที่อื่นโดยยังไม่ล็อกอิน จะขึ้น Modal บังคับให้ login ด้วย. (ยังไม่ได้ทำ)
      return <p>Please Login</p>;
    }
  }
}
export default MyAddress;
