import React from "react";
import { Form } from "react-final-form";
import { Input, Radio } from "antd";
import styled from "styled-components";
import Field from "../field";
import Modal from "../modal";
import Button from "../button";
import { phoneNumberRule } from "../../util/form/rules";
import {
  validateRequiredInput,
  validateRequiredRadioGroup,
} from "../../util/form/validators";

const ButtonRow = styled.div`
  padding-top: 2em;

  display: flex;
  justify-content: space-between;
`;

export default class DriverFormModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      initialValues: undefined,
      resolve: undefined,
    };

    this.getData = this.getData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.initialValuesEqual = this.initialValuesEqual.bind(this);
  }

  async getData(initialValues, hasNext = false) {
    let modalResolve = undefined;
    const modalPromise = new Promise((resolve) => {
      modalResolve = resolve;
    });

    this.setState({
      visible: true,
      isEditMode: Boolean(initialValues),
      initialValues: {
        ...initialValues,
        name: !!initialValues
          ? `${initialValues.firstname} ${initialValues.lastname}`
          : "",
      },
      resolve: modalResolve,
      hasNext,
    });

    const result = await modalPromise;

    this.setState({
      visible: false,
      resolve: undefined,
    });

    return result;
  }

  handleSubmit(form) {
    if (this.state.resolve) {
      this.state.resolve([form, true]);
    }
  }

  handleCancel() {
    if (this.state.resolve) {
      this.state.resolve([null, false]);
    }
  }

  initialValuesEqual(a, b) {
    return a === b;
  }

  render() {
    const props = this.props;
    const { visible, initialValues, isEditMode, hasNext } = this.state;

    return (
      <Modal
        {...props}
        title={isEditMode ? "แก้ไขข้อมูลคนส่งอาหาร" : "เพิ่มคนส่งอาหาร"}
        visible={visible}
        destroyOnClose
      >
        <Form
          initialValues={initialValues}
          initialValuesEqual={this.initialValuesEqual}
          onSubmit={this.handleSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ marginTop: "0.5em" }}>
              <Field
                name="name"
                component={Input}
                validate={validateRequiredInput}
                label="ชื่อคนส่งอาหาร*"
                placeholder="ชื่อ-นามสกุล"
              />
              <Field
                name="phone"
                rules={[phoneNumberRule]}
                validate={validateRequiredInput}
                component={Input}
                label="เบอร์โทรศัพท์*"
                placeholder="เบอร์โทรศัพท์"
              />
              <Field
                name="note"
                component={Input}
                label="Note"
                placeholder="Note"
              />
              <Field
                name="isTemp"
                component={Radio.Group}
                validate={validateRequiredRadioGroup}
              >
                <Radio value={true}>{"พนักงานชั่วคราว"}</Radio>
                <Radio value={false}>{"พนักงานประจำ"}</Radio>
              </Field>

              <ButtonRow>
                <Button type="secondary" onClick={this.handleCancel}>
                  {"ยกเลิก"}
                </Button>
                <Button type="primary" buttonType="submit">
                  {hasNext ? "บันทึกและดำเนินการต่อ" : "บันทึก"}
                </Button>
              </ButtonRow>
            </form>
          )}
        </Form>
      </Modal>
    );
  }
}
