import React, { Component } from "react";
import { List, Tabs } from "antd";
import styled from "styled-components";
import { Pagination, itemRender } from "./pagination";
import { colors } from "../../../../values/colors";
import { customer as api } from "../../../../services/api";
import { CenterSpin } from "../sharedComponents";
import { redirectTo } from "../../../../services/redirect";
import logo from "../../../../assets/image/imyellow.svg";
import { renderMoney } from "../../../../utils/money";

const MenuDescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.4rem;
  height: 5rem;
`;

const MenuDescription = styled.div`
  font-family: Kanit;
  font-weight: ${(props) => (props.bold ? "500" : "normal")};
  font-size: ${(props) => (props.bold ? "18px" : "16px")};
  line-height: 20px;
  color: ${(props) => (props.bold ? colors.buttonColor : colors.textColor1)};
  display: block;
  text-align: ${(props) => (props.bold ? "right" : "left")};
  width: 100%;

  @media not screen and (min-device-width: 1224px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const InfiniteDiv = styled.div`
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;
  height: fit-content;
  padding: 0.5rem 0;
  padding-left: 15px;
  -webkit-overflow-scrolling: touch;
`;

const Card = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    box-shadow: 5px 5px 15px #dadada;
  }

  @media not screen and (min-device-width: 1224px) {
    width: 150px;
    margin-right: 10px;
    display: inline-block;
  }
`;

const ImgBox = styled.img`
  width: 100%;
  height: 9rem;
  border-radius: 5px 5px 0px 0px;
  object-fit: cover;
  object-position: 50% 50%;
`;

const CustomerSlidingMenuBox = styled.div`
  height: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;

  @media not screen and (min-device-width: 1224px) {
    margin-top: 0rem;
    height: fit-content;
  }
`;

const PaginationBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const MenuText = styled.p`
  margin-top: 4.5rem;
  width: 123px;
  height: 18px;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 18px;
  /* identical to box height, or 64% */

  letter-spacing: 0.1px;

  color: #000000;

  @media not screen and (min-device-width: 1224px) {
    margin-top: 2rem;
    height: 2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
`;

class RecommentSlidingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      pageSize: 6,
      total: 0,
      isLoading: true,
      loadTimeout: 0,
      isScrollLoading: false,
    };
    this.scrollChanged = this.scrollChanged.bind(this);
    this.loadPageData = this.loadPageData.bind(this);
    this.loadScrollPageData = this.loadScrollPageData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const pageSize = this.props.recommendSize;
    this.loadPageData(1, pageSize);
  }

  loadPageData(page, pageSize) {
    api.product.getRecommended(page, pageSize, (status, data) => {
      if (status === 200) {
        const { products, total } = data;
        this.setState({
          data: products,
          pageSize,
          page,
          total,
          isLoading: false,
        });
      }
    });
  }

  loadScrollPageData(page, pageSize) {
    api.product.getRecommended(page, pageSize, (status, data) => {
      if (status === 200) {
        const { products, total } = data;
        this.setState({
          data: [...this.state.data, ...products],
          pageSize,
          page,
          total,
          isScrollLoading: false,
          loadTimeout: 0,
        });
      }
    });
  }

  handlePageChange(page) {
    this.setState({
      isLoading: true,
    });
    this.loadPageData(page, this.state.pageSize);
  }

  scrollChanged(event) {
    const scrollPosition = event.target.scrollLeft;
    const maxPosition = event.target.scrollWidth - event.target.clientWidth;
    if (scrollPosition > maxPosition * 0.8) {
      const { page, pageSize, total } = this.state;
      if (page * pageSize < total) {
        if (this.state.loadTimeout === 0) {
          this.setState({
            loadTimeout: setTimeout(() => {
              this.loadScrollPageData(page + 1, pageSize);
            }, 300),
            isScrollLoading: true,
          });
        }
      }
    }
  }

  render() {
    const { data, page, pageSize, total, isLoading } = this.state;
    const length = data.length;
    if (length < pageSize) {
      for (var i = length; i < pageSize; i++) {
        data[i] = "empty";
      }
    }
    return length > 0 ? (
      <React.Fragment>
        <MenuText>เมนูแนะนำ</MenuText>
        <CustomerSlidingMenuBox>
          {isLoading ? (
            <CenterSpin padding={0} size={50} />
          ) : this.props.isDesktop ? (
            <List
              grid={{ gutter: 12, column: pageSize }}
              dataSource={data}
              renderItem={(item, itemIndex) => (
                <List.Item>
                  {itemIndex >= length || item === "empty" ? (
                    <Card style={{ visibility: "hidden" }}>
                      <ImgBox src={logo} />
                      <MenuDescriptionBox full>
                        <MenuDescription>-</MenuDescription>
                        <MenuDescription bold>- ฿ </MenuDescription>
                      </MenuDescriptionBox>
                    </Card>
                  ) : (
                    <Card
                      hoverable
                      onClick={() => {
                        redirectTo(`/menu/${item.productId}`);
                      }}
                    >
                      <ImgBox src={item.image || logo} />
                      <MenuDescriptionBox full>
                        <MenuDescription>{item.nameTh}</MenuDescription>
                        <MenuDescription bold>
                          {item.price ? renderMoney(item.price) : "0.00"} ฿{" "}
                        </MenuDescription>
                      </MenuDescriptionBox>
                    </Card>
                  )}
                </List.Item>
              )}
            />
          ) : (
            <InfiniteDiv onScroll={this.scrollChanged}>
              {data.map((item, itemIndex) => {
                if (item !== "empty") {
                  return (
                    <Card
                      hoverable
                      onClick={() => {
                        redirectTo(`/menu/${item.productId}`);
                      }}
                    >
                      <ImgBox src={item.image || logo} />
                      <MenuDescriptionBox full>
                        <MenuDescription>{item.nameTh}</MenuDescription>
                        <MenuDescription bold>
                          {item.price ? renderMoney(item.price) : "0.00"} ฿{" "}
                        </MenuDescription>
                      </MenuDescriptionBox>
                    </Card>
                  );
                }
              })}
              {this.state.isScrollLoading && (
                <Card style={{ height: "14rem", verticalAlign: "top" }}>
                  <CenterSpin padding={0} size={50} />
                </Card>
              )}
            </InfiniteDiv>
          )}
          {this.props.isDesktop && (
            <PaginationBox>
              <Pagination
                itemRender={itemRender}
                defaultCurrent={1}
                current={page}
                total={total}
                onChange={this.handlePageChange}
                pageSize={pageSize}
              />
            </PaginationBox>
          )}
        </CustomerSlidingMenuBox>
      </React.Fragment>
    ) : (
      <></>
    );
  }
}

export default RecommentSlidingMenu;
