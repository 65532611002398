import React, { useCallback } from "react";
import { Checkbox } from "antd";
import { EditIcon, DeleteIcon } from "../../../../components/icons";
import IconButton from "../iconButton";
import styled from "styled-components";
import { colors } from "../../../../values/colors";

const MainText = styled.div`
  font-size: 100%;
  font-weight: 600;
  color: ${colors.storeText};
`;

const SubText = styled.div`
  font-size: 75%;
  color: #a5a5a5;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: ${(props) => props.spacing};
  }
`;

const OptionFace = ({
  mainText,
  subText,
  onEdit,
  onDelete,
  onSelect,
  selected,
  active,
  showCheckbox = true,
  showEdit = true,
  checkboxDisable = false,
}) => {
  const iconSize = "1em";

  // Wrap button event handler and call stopPropagation
  // to prevent triggering item selection from click event propagation
  const handleEdit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onEdit) {
        onEdit(e);
      }
    },
    [onEdit]
  );

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onDelete) {
        onDelete(e);
      }
    },
    [onDelete]
  );

  const handleCheckboxChange = useCallback(
    (e) => {
      e.stopPropagation();
      if (onSelect) {
        onSelect(e.target.checked);
      }
    },
    [onSelect]
  );

  return (
    <Container>
      <Box spacing={"1em"} style={{ width: "80%" }}>
        {showCheckbox && (
          <Checkbox
            checked={selected}
            onChange={handleCheckboxChange}
            disabled={checkboxDisable}
          />
        )}
        <div>
          <MainText>{mainText}</MainText>
          <SubText>{subText}</SubText>
        </div>
      </Box>
      <Box spacing={"0.5em"}>
        <div>
          {showEdit && (
            <IconButton
              icon={EditIcon}
              size={iconSize}
              onClick={handleEdit}
              isOnDarkBg={active}
            />
          )}
          <IconButton
            icon={DeleteIcon}
            size={iconSize}
            onClick={handleDelete}
            isOnDarkBg={active}
          />
        </div>
      </Box>
    </Container>
  );
};

export default OptionFace;
