import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import useDataState from "../../hooks/useDataState";
import styled from "styled-components";
import StoreDrawer from "./components/drawer";
import Order from "./order";
import Menu from "./menu";
import MenuForm from "./menuForm";
import Driver from "./driver";
import Report from "./report";
import Profile from "./profile";
import Settings from "./settings";
import { subDomain } from "../../services/redirect";
import StoreHeader from "./components/header";
import { colors } from "../../values/colors";
import { store as storeApi } from "../../services/api";
import { checked } from "../../services/api";
import { adaptOrders, mergeUpdate } from "./adapter/order";
import OrderStatusPoller from "./components/order/orderStatusPoller";
import { message } from "antd";
import ConfirmModal from "./components/modals/confirmModal";
import ImageModal from "./components/modals/imageModal";
import errorPage from "../../components/errorMessage/errorPage";
import NotFoundPage from "../../components/errorMessage/notFoundPage";

const headerHeight = "4rem";
const drawerWidth = 256;

const PageContainer = styled.div`
  height: 100vh;
  padding-top: ${headerHeight};
  padding-left: ${drawerWidth}px;

  & input,
  & .ant-picker {
    border-radius: 5px;
  }

  & .ant-picker .ant-picker-suffix svg {
    fill: ${colors.storeTextHightlighted};
  }
`;

const PageContent = styled.div`
  font-family: "Kanit";

  height: 100%;
  padding: 2em 3em;
  overflow-y: auto;
`;

export const ModalContext = React.createContext();

export default function Store() {
  // #region Orders data & tabs logic
  const [tab, _setTab] = useState("1");
  const orders = useDataState();

  const setTab = useCallback(
    (targetTab) => {
      unstable_batchedUpdates(() => {
        _setTab(targetTab);
        if (!orders.loading) {
          orders.setData({
            ...orders.data,
            newUpdates:
              orders.data.newUpdates &&
              orders.data.newUpdates.map((count, index) => {
                return index === targetTab - 1 ? 0 : count;
              }),
          });
        }
      });
    },
    [_setTab, orders]
  );
  const poller = useRef();

  // useEffect(() => {
  //     orders.load(storeApi.order.getAllStage().then(adaptOrders));
  // }, []);

  const onUpdateFetched = useCallback(
    (updateObj) => {
      const result = mergeUpdate(orders.data, updateObj, tab, () => {
        orders.load(storeApi.order.getAllStage().then(adaptOrders));
      });

      if (result.updated) {
        orders.setData(result.data);
      }
    },
    [orders, tab]
  );

  const onUpdateFailed = useCallback((error) => {
    message.error("Failed to update");
    // console.error(error);
  }, []);
  // #endregion

  // #region Modals Context
  const confirmModal = useRef();
  const imageModal = useRef();

  const getConfirmation = useCallback(
    (...params) => {
      return confirmModal.current.getConfirmation(...params);
    },
    [confirmModal]
  );

  const showImage = useCallback(
    (imageUrl) => {
      imageModal.current.showImage(imageUrl);
    },
    [imageModal]
  );

  const modalContextValue = useMemo(() => {
    return {
      getConfirmation,
      showImage,
    };
  }, [getConfirmation, showImage]);
  // #endregion

  const path = useLocation().pathname;

  const [error, setError] = React.useState(false);

  // catch error when backend fail
  useEffect(() => {
    checked
      .Checkedstatus()
      .then(() => {
        orders.load(storeApi.order.getAllStage().then(adaptOrders));
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  if (error) {
    return (
      <Switch>
        <Route path={path} exact component={errorPage} />
      </Switch>
    );
  } else {
    return (
      <ModalContext.Provider value={modalContextValue}>
        {!orders.loading && (
          <OrderStatusPoller
            ref={poller}
            lastUpdateTime={orders.data.now}
            interval={6000}
            onFetched={onUpdateFetched}
            onFailed={onUpdateFailed}
          />
        )}
        <StoreHeader height={headerHeight} drawerWidth={`${drawerWidth}px`} />
        <StoreDrawer width={drawerWidth} orders={orders} />
        <PageContainer>
          <PageContent>
            <Switch>
              <Route
                path={`${subDomain}/store/order`}
                exact
                render={(props) => (
                  <Order
                    {...props}
                    poller={poller}
                    orders={orders}
                    tab={tab}
                    setTab={setTab}
                  />
                )}
              />
              <Route path={`${subDomain}/store/menu`} exact component={Menu} />
              <Route
                path={`${subDomain}/store/menu/add`}
                exact
                component={MenuForm}
              />
              <Route
                path={`${subDomain}/store/menu/edit/:id`}
                exact
                component={MenuForm}
              />
              <Route
                path={`${subDomain}/store/driver`}
                exact
                component={Driver}
              />
              {/* <Route
                path={`${subDomain}/store/report`}
                exact
                component={Report}
              /> */}
              {/* <Route
                path={`${subDomain}/store/profile`}
                exact
                component={Profile}
              /> */}
              {/* <Route
                path={`${subDomain}/store/settings`}
                exact
                component={Settings}
              /> */}
              <Route
                exact
                path={`${subDomain}/store/`}
                component={() => <Redirect to={`${subDomain}/store/order`} />}
              />
              <Route path={`${subDomain}/store/`} component={NotFoundPage} />
            </Switch>
          </PageContent>
        </PageContainer>

        <ConfirmModal ref={confirmModal} />
        <ImageModal ref={imageModal} />
      </ModalContext.Provider>
    );
  }
}
