import React from "react";
import styled from "styled-components";
import { Modal, Upload } from "antd";
import { Text } from "../../components/sharedComponents";
import { LoadingOutlined } from "@ant-design/icons";
import { subDomain, redirectTo } from "../../../../services/redirect";
import deleteQRcode from "../../../../assets/image/deleteQRcode.svg";

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.padding || "0rem 0rem 0rem 0rem"};
`;

const SubTitleText = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
`;

const ModalBox = styled.div`
  display: flex;
  align-items: center;
  font-family: "Kanit";
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5em;
  justify-content: ${(props) => (props.space ? "space-between" : "center")};
  margin: ${(props) => (props.padding ? "2.5rem 0 1.5rem 0" : 0)};
  padding: 1rem 10% 1rem 10%;
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default class Uploader extends React.Component {
  state = {
    file: null,
    isImage: true,
    imageUrl: null,
    loading: false,
  };

  handleChange = (info, onUpload) => {
    if (info.file.type === "image/jpeg" || info.file.type === "image/png") {
      onUpload(info);
      getBase64(info.fileList[0].originFileObj, (imageUrl) =>
        this.setState({ file: info, isImage: true, imageUrl })
      );
    }
  };

  render() {
    const { file, imageUrl, loading } = this.state;
    const { onUpload, onDelete, width, height } = this.props;
    return (
      <>
        {file ? (
          <img
            src={imageUrl}
            alt="avatar"
            width={!!width ? width : "373px"}
            height={!!height ? height : "297px"}
            onMouseOver={(e) => (e.currentTarget.src = deleteQRcode)}
            onMouseOut={(e) => (e.currentTarget.src = imageUrl)}
            onClick={() => {
              onDelete();
              this.setState({ file: null, imageUrl: null });
            }}
            style={{ marginBottom: "1rem", cursor: "pointer" }}
          />
        ) : (
          <Upload
            name="slip"
            listType="picture-card"
            accept="image/png, image/jpeg"
            showUploadList={false}
            beforeUpload={() => false}
            onChange={(info) => this.handleChange(info, onUpload)}
          >
            <FlexRow>
              <SubTitleText
                style={{
                  fontWeight: "400",
                  color: "#FFB800",
                  width: !!width ? width : "373px",
                  height: !!height ? height : "297px",
                }}
              >
                {file ? (
                  <></>
                ) : loading ? (
                  <LoadingOutlined />
                ) : (
                  <FlexRow
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    กดเลือกรูปสลิปที่นี่
                  </FlexRow>
                )}
              </SubTitleText>
            </FlexRow>
          </Upload>
        )}
        {/* modal เมื่ออัพโหลดไฟล์อื่นที่ไม่ใช่รูป */}
        <Modal
          visible={!this.state.isImage}
          centered={true}
          closable={false}
          footer={[
            <ModalBox centered>
              <Text
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => {
                  this.setState({ isImage: true });
                }}
              >
                ปิด
              </Text>
            </ModalBox>,
          ]}
          width={"40rem"}
        >
          <ModalBox>
            <Text style={{ color: "black" }}>
              ภาพจะต้องเป็นไฟล์นามสกุล JPG/PNG เท่านั้น
            </Text>
          </ModalBox>
        </Modal>
      </>
    );
  }
}
