import React, { Component } from "react";
import styled from "styled-components";
import { Checkbox } from "../../../components/checkboxes";
import { Link, Redirect } from "react-router-dom";
import { redirectTo, subDomain } from "../../../services/redirect";
import { Button } from "./sharedComponents";
import { DeleteAddress, test } from "./pictures/pictures";
import localName from "../../../values/localStorageDict";
import deleteAddress from "../../../assets/icons/delete-address.svg";
import editAddress from "../../../assets/icons/edit-address.svg";
import defaultLocation from "../../../assets/icons/defaultlocation.svg";

import { Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MediaQuery from "react-responsive";
const InlineBlock = styled.div`
  display: inline-block;
  position: relative;
  right: ${(props) => (props.type === "have-checkbox" ? "1rem" : "0")};
  margin-left: 1rem;
  color: ${(props) => (props.type === "have-checkbox" ? "#000000" : "#27ae60")};
`;
const EditText = styled.div`
  font-family: "Kanit";
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
`;
const BoxDefaultLocation = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Kanit";
  font-style: bold;
  font-weight: 600;
  font-size: 15px;
`;
const StyledButton = styled(Button)`
  font-weight: ${(props) => (props.mobile ? "600" : "550")};
  font-size: ${(props) => (props.mobile ? "2rem" : "15px")};
  height: ${(props) => (props.mobile ? "45px" : "2rem")};
  width: ${(props) => (props.mobile ? "100%" : "9rem")};
  position: ${(props) => !props.mobile && "relative"};
  top: ${(props) => !props.mobile && "0.4rem"};
  @media (max-width: 780px) {
    width: ${(props) => (props.mobile ? "100%" : "7rem")};
    font-size: ${(props) => (props.mobile ? "18px" : "12px")};
  }
`;
const TextBox = styled.p`
  position: relative;
  left: 0.5rem;
  top: ${(props) => (props.defaultLocation ? "1.3em" : "0.3rem")};
  margin-bottom: ${(props) => props.mobile && "0px"};
`;
const StyledDestinationText = styled.p`
  font-family: "Kanit";
  font-style: bold;
  font-weight: 600;
  font-size: 25px;
  margin-top: 0.7rem;
  margin-bottom: ${(props) => props.mobile && "0px"};
`;
const StyledLocationText = styled.p`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  @media (max-width: 780px) {
    font-size: 18px;
  }
`;
const StyledLocationComment = styled.p`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #b57a08;
`;
const ListBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.marginTop || "0.5rem"};
  margin-left: ${(props) =>
    props.marginLeft || props.mobile ? "15px" : "10%"};
  margin-right: ${(props) =>
    props.marginRight || props.mobile ? "15px" : "10%"};
  margin-bottom: ${(props) =>
    props.marginBottom || props.mobile ? "2rem" : "3rem"};
  min-height: 10rem;
  padding-bottom: 1rem;
  padding: ${(props) => props.mobile && "1rem 1rem"};
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
  align-items: stretch;
`;
const CheckBoxArea = styled.div`
  width: ${(props) => props.CheckBoxAreaWidth || "10%"};
  padding-left: 4%;
  padding: ${(props) => props.mobile && "0.5rem 0.5rem"};
  padding-top: ${(props) => (props.mobile ? "1.5rem" : "3rem")};
  padding-right: ${(props) => props.mobile && "1rem"};
`;
const RightBoxMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.type === "have-checkbox" ? "95%" : "100%")};
  margin-left: ${(props) => props.type === "have-checkbox" && "1rem"};
  padding: 0px 10px;
`;
const InformationArea = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.mobile ? "100%" : props.type === "have-checkbox" ? "65%" : "62.5%"};
  margin-left: ${(props) => !props.mobile && "1rem"};
`;
const RightBox = styled.div`
  width: ${(props) => (props.type === "have-checkbox" ? "30%" : "32.5%")};
  position: relative;
`;
const DestinationBox = styled.div`
  word-wrap: break-word;
  padding-top: ${(props) => !props.mobile && "1rem"};
  display: ${(props) => props.mobile && "flex"};
  flex-direction: ${(props) => props.mobile && "row"};
  justify-content: ${(props) => props.mobile && "flex-start"};
`;
const LocationBox = styled.div`
  word-wrap: break-word;
`;
const CommentBox = styled.div`
  word-wrap: break-word;
`;
const DefaultLocationBox = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 2rem;
  top: 2rem;
  white-space: nowrap;
`;
const EditAddressBox = styled.div`
  display: flex;
  margin-bottom: ${(props) => !props.mobile && "1rem"};
  flex-direction: row;
  position: ${(props) => !props.mobile && "absolute"};
  right: ${(props) => !props.mobile && "3rem"};
  bottom: ${(props) => !props.mobile && "1rem"};
  color: #b57a08;
  white-space: nowrap;
`;
const DeleteAddressBox = styled.div`
  display: flex;
  flex-direction: row;
  position: ${(props) => !props.mobile && "absolute"};
  right: ${(props) => !props.mobile && "3rem"};
  bottom: ${(props) => !props.mobile && "0.3rem"};
  margin: ${(props) => props.mobile && "0 1rem"};
  color: #ff0101;
  white-space: nowrap;
`;
class AddressComponent extends Component {
  pathLocalStorage = (addOrEditAddress, address, startAtCartOrMain) => {
    localStorage.setItem(localName.ADD_OR_EDIT_ADDR, addOrEditAddress);
    localStorage.setItem(localName.ADDR_WILL_BE_EDIT, JSON.stringify(address));
    localStorage.setItem(localName.START_AT, startAtCartOrMain);
    redirectTo("/edit-address");
  };
  defaultLocationComponent = () => {
    if (this.props.address.isDefault)
      if (this.props.type === "have-checkbox")
        return (
          <InlineBlock type="have-checkbox">
            <BoxDefaultLocation>
              <img
                style={{
                  position: "relative",
                  top: "0.7rem",
                }}
                src={defaultLocation}
              />
              <TextBox defaultLocation>ที่อยู่ตั้งต้น</TextBox>
            </BoxDefaultLocation>
          </InlineBlock>
        );
      else
        return (
          <InlineBlock>
            <BoxDefaultLocation>
              <img
                style={{
                  position: "relative",
                  top: "0.7rem",
                }}
                src={defaultLocation}
              />
              <TextBox defaultLocation>ที่อยู่ตั้งต้น</TextBox>
            </BoxDefaultLocation>
          </InlineBlock>
        );
    return null;
  };
  render() {
    const {
      addressId,
      name,
      addressLine,
      district,
      subDistrict,
      province,
      postalCode,
      comment,
      isDefault,
      isSelectedByUser,
    } = this.props.address;
    const noEdit = this.props.noEdit || false;
    const param = this.props.noEdit ? this.props.index : this.props.address;
    const checked = this.props.noEdit ? this.props.checked : isSelectedByUser;
    return (
      <>
        <MediaQuery minDeviceWidth={680}>
          <ListBox
            marginLeft={this.props.marginLeft}
            marginRight={this.props.marginRight}
            marginBottom={this.props.marginBottom}
          >
            <CheckBoxArea CheckBoxAreaWidth={this.props.CheckBoxAreaWidth}>
              {this.props.type === "have-checkbox" ? (
                <Checkbox
                  checked={checked}
                  index={addressId}
                  onClick={() => this.props.onChecked(param)}
                ></Checkbox>
              ) : null}
            </CheckBoxArea>

            <InformationArea type={this.props.type}>
              <DestinationBox>
                <StyledDestinationText>
                  {name}
                  {this.props.type === "have-checkbox"
                    ? null
                    : this.defaultLocationComponent()}
                </StyledDestinationText>
              </DestinationBox>
              <LocationBox>
                <StyledLocationText>
                  {addressLine}
                  {" " + district}
                  {" " + subDistrict}
                  {" " + province}
                  {" " + postalCode}
                </StyledLocationText>
              </LocationBox>
              <CommentBox>
                {comment !== "" ? (
                  <StyledLocationComment>
                    ถึงผู้ส่ง: {comment}
                  </StyledLocationComment>
                ) : (
                  <StyledLocationComment>ถึงผู้ส่ง: -</StyledLocationComment>
                )}
              </CommentBox>
            </InformationArea>

            <RightBox type={this.props.type}>
              {this.props.type === "have-checkbox" ? (
                <DefaultLocationBox>
                  {this.defaultLocationComponent()}
                </DefaultLocationBox>
              ) : (
                <DefaultLocationBox>
                  <StyledButton
                    onClick={() => this.props.onSetDefaultLocation(addressId)}
                    disabled={isDefault}
                  >
                    ตั้งค่าเป็นที่อยู่ตั้งต้น
                  </StyledButton>
                </DefaultLocationBox>
              )}

              {noEdit ? (
                <div />
              ) : (
                <React.Fragment>
                  <EditAddressBox>
                    <img src={editAddress} />
                    <TextBox
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.pathLocalStorage(
                          "edit",
                          this.props.address,
                          this.props.type === "have-checkbox"
                            ? `${subDomain}/cart` // startAt cart
                            : `${subDomain}` // startAt main
                        )
                      }
                    >
                      แก้ไขที่อยู่
                    </TextBox>
                  </EditAddressBox>

                  <DeleteAddressBox>
                    <TextBox>
                      <Popconfirm
                        title="คุณต้องการลบที่อยู่นี้ใช่หรือไม่?"
                        placement="bottomLeft"
                        onConfirm={() => this.props.onDelete(addressId)}
                        okText="ใช่"
                        cancelText="ไม่ใช่"
                      >
                        <EditText style={{ color: "#FF0000" }}>
                          <DeleteOutlined /> ลบที่อยู่
                        </EditText>
                      </Popconfirm>
                    </TextBox>
                  </DeleteAddressBox>
                </React.Fragment>
              )}
            </RightBox>
          </ListBox>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={680}>
          <ListBox mobile>
            {this.props.type === "have-checkbox" && (
              <CheckBoxArea
                mobile
                CheckBoxAreaWidth={this.props.CheckBoxAreaWidth}
              >
                <Checkbox
                  checked={checked}
                  index={addressId}
                  onClick={() => this.props.onChecked(param)}
                ></Checkbox>
              </CheckBoxArea>
            )}
            <RightBoxMobile type={this.props.type}>
              <InformationArea type={this.props.type} mobile>
                <DestinationBox mobile>
                  <StyledDestinationText mobile style={{ marginRight: "5px" }}>
                    {name}
                  </StyledDestinationText>
                  <div
                    style={
                      this.props.type === "have-checkbox"
                        ? { color: "#000000" }
                        : { color: "#27ae60" }
                    }
                  >
                    {this.defaultLocationComponent()}
                  </div>
                </DestinationBox>
                <LocationBox style={{ marginBottom: "0px" }}>
                  <StyledLocationText>
                    {addressLine}
                    {" " + district}
                    {" " + subDistrict}
                    {" " + province}
                    {" " + postalCode}
                  </StyledLocationText>
                </LocationBox>
                <CommentBox>
                  {comment !== "" ? (
                    <StyledLocationComment>
                      ถึงผู้ส่ง: {comment}
                    </StyledLocationComment>
                  ) : (
                    <StyledLocationComment>ถึงผู้ส่ง: -</StyledLocationComment>
                  )}
                </CommentBox>
              </InformationArea>
              <div style={{ padding: "0.5rem 0.5rem" }}>
                <StyledButton
                  mobile
                  onClick={() => this.props.onSetDefaultLocation(addressId)}
                  disabled={isDefault}
                >
                  ตั้งค่าเป็นที่อยู่ตั้งต้น
                </StyledButton>
              </div>
              {noEdit ? (
                <div />
              ) : (
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "flex-end",
                      marginTop: "5px",
                    }}
                  >
                    <EditAddressBox mobile>
                      <img src={editAddress} />
                      <TextBox
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.pathLocalStorage(
                            "edit",
                            this.props.address,
                            this.props.type === "have-checkbox"
                              ? `${subDomain}/cart` // startAt cart
                              : `${subDomain}` // startAt main
                          )
                        }
                      >
                        แก้ไขที่อยู่
                      </TextBox>
                    </EditAddressBox>
                    <DeleteAddressBox mobile>
                      <Popconfirm
                        title="คุณต้องการลบที่อยู่นี้ใช่หรือไม่?"
                        placement="bottomLeft"
                        onConfirm={() => this.props.onDelete(addressId)}
                        okText="ใช่"
                        cancelText="ไม่ใช่"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <DeleteOutlined />
                          <TextBox style={{ color: "#FF0000" }}>
                            ลบที่อยู่
                          </TextBox>
                        </div>
                      </Popconfirm>
                    </DeleteAddressBox>
                  </div>
                </React.Fragment>
              )}
            </RightBoxMobile>
          </ListBox>
        </MediaQuery>
      </>
    );
  }
}
export default AddressComponent;
