// Read https://final-form.org/docs/react-final-form/types/FieldProps#validate

const combineValidators = (...validators) => (value, allValues, fieldState) => {
  for (const validate of validators) {
    const error = validate(value, allValues, fieldState);
    if (error) {
      return error;
    }
  }

  return undefined;
};

export const validateRequiredInput = (value) => {
  return value || value === 0 ? undefined : "กรุณากรอกข้อมูล";
};

export const validateNotZeroInput = (value) => {
  return value && value != 0 ? undefined : "กรุณากรอกข้อมูลที่ไม่ใช่0";
};

export const validateRequiredRadioGroup = (value) => {
  return value !== undefined && value !== null ? undefined : "กรุณาเลือก";
};

export const validateRequiredCheckboxGroup = (value) => {
  return value !== undefined && value !== null && value.length !== 0
    ? undefined
    : "กรุณาเลือกอย่างน้อย1";
};

export const validateEmail = (value) => {
  const valid = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
    value
  );
  if (!valid) {
    return "อีเมลไม่ถูกต้อง";
  }

  return undefined;
};

export const validatePin = (value) => {
  const valid = /^([0-9]{4})$/.test(value);
  if (!valid) {
    return "กรุณาระบุตัวเลข 4 หลัก";
  }

  return undefined;
};
