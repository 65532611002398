import { Table as AntdTable } from 'antd';
import styled from 'styled-components';
import { colors } from '../../../values/colors';

const Table = styled(AntdTable)`
    & tr th {
        font-weight: 600;
        color: ${colors.storeText};
        background-color: transparent;
    }

    & tr td {
        color: ${colors.storeText};
        padding-top: 4px;
        padding-bottom: 4px;
    }
`;

export default Table;