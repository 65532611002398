import React, { Component } from "react";
import { EditIcon, DeleteIcon } from "../../../../components/icons";
import Table from "../table";
import Pagination from "../pagination";
import Link from "../link";
import IconButton from "../iconButton";
import { formatPhoneNumber } from "../../util/format";
import { Row } from "antd";

export default class DriverTable extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        width: "12%",
        key: "no",
        dataIndex: "driverId",
        title: "รหัสคนส่งอาหาร",
      },
      {
        width: "32%",
        key: "name",
        dataIndex: "name",
        title: "ชื่อคนส่งอาหาร",
        render: this.renderName.bind(this),
      },
      {
        width: "19%",
        key: "phone",
        dataIndex: "phone",
        title: "เบอร์โทรศัพท์",
        render: formatPhoneNumber,
      },
      {
        width: "21%",
        key: "isTemp",
        dataIndex: "isTemp",
        title: "ประเภทของพนักงาน",
        render: (value) => (value ? "พนักงานชั่วคราว" : "พนักงานประจำ"),
      },
      {
        width: "16%",
        key: "controls",
        title: "",
        render: this.renderControls.bind(this),
      },
    ];
  }

  renderName(value, row) {
    const { onClickName } = this.props;
    const onClick = () => {
      onClickName(row);
    };

    return (
      <Link onClick={onClick}>
        {row.firstname} {row.lastname}
      </Link>
    );
  }

  renderControls(_, row) {
    const { onEdit, onDelete } = this.props;
    const handleEdit = () => {
      onEdit(row);
    };
    const handleDelete = () => {
      onDelete(row);
    };

    return (
      <Row type="flex" justify="end">
        <IconButton icon={EditIcon} size={"1em"} onClick={handleEdit} />
        <IconButton icon={DeleteIcon} size={"1em"} onClick={handleDelete} />
      </Row>
    );
  }

  render() {
    const { onEdit, onDelete, onClickName, pagination, ...props } = this.props;

    return (
      <React.Fragment>
        <Table
          {...props}
          columns={this.columns}
          pagination={false}
          rowKey={({ driverId }) => driverId}
        />

        <Row type="flex" justify="end" style={{ marginTop: "1.5em" }}>
          <Pagination {...pagination} />
        </Row>
      </React.Fragment>
    );
  }
}
