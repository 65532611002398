import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../values/colors';

const Wrapper = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(${props => props.iconSize} * 2);
    height: calc(${props => props.iconSize} * 2);
    border-radius: 50%;

    cursor: pointer;
    background-color: rgba(${props => props.isOnDarkBg ? '255,255,22' : '102,60,70'},0);

    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(${props => props.isOnDarkBg ? '255,255,22,0.25' : '102,60,70,1'});
    }
`;

const IconButton = props => {
    const {icon: Icon, size, isOnDarkBg, onClick} = props;

    const fgColor = colors.storeTextHightlighted;
    
    return (
        <Wrapper onClick={onClick} iconSize={size} isOnDarkBg={isOnDarkBg}>
            <Icon width={size} height={size} color={fgColor} />
        </Wrapper>
    );
};

export default IconButton;
