import { useState, useCallback, useMemo } from 'react';

/**
 * @typedef {Object} ModalHookObject
 * @property {boolean} visible Whether modal is visible
 * @property {function} open Open modal, i.e. set visible to 'true'
 * @property {function} close Close modal, i.e. set visible to 'false'
 */

/**
 * @param {boolean} [initialVisible=false]
 * @returns {ModalHookObject}
 */
export default function useModalState(initialVisible = false) {
    const [visible, setVisible] = useState(initialVisible);

    const open = useCallback(() => setVisible(true), [setVisible]);
    const close = useCallback(() => setVisible(false), [setVisible]);

    const modal = useMemo(() => ({
        visible,
        open,
        close
    }), [visible, open, close]);

    return modal;
}