import React from "react";
import cartConfirm from "../../../../assets/image/cartConfirm.svg";
import styled from "styled-components";
import { subDomain } from "../../../../services/redirect";
import { Button } from "../../../customers/components/sharedComponents";
import { colors } from "../../../../values/colors";
const WarningBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;
const StyledText = styled.p`
  font-family: "Kanit";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #663d45;
  margin-top: 2rem;
`;
const StyledButton = styled(Button)`
  width: 15%;
  height: 2rem;
  background-color: ${(props) =>
    props.disabled
      ? "lightgrey"
      : props.yellow
      ? "#FFB800"
      : colors.buttonColor};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? "white" : props.yellow ? colors.buttonColor : "white"};
  border-radius: 5px;
  text-align: center;
  font-family: "Kanit";
  font-size: 1.2em;
  font-weight: 500;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  @media (max-width: 680px) {
    width: 100px;
  }
`;
const NotFoundAddress = ({ pathLocalStorage, startAt }) => {
  return (
    <WarningBox>
      <img src={cartConfirm} />
      <StyledText>ไม่พบข้อมูลที่อยู่</StyledText>
      <StyledButton
        onClick={() =>
          pathLocalStorage(
            "add",
            startAt === `${subDomain}/cart`
              ? `${subDomain}/cart`
              : `${subDomain}`
          )
        }
      >
        เพิ่มที่อยู่
      </StyledButton>
    </WarningBox>
  );
};

export default NotFoundAddress;
