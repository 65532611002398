import React from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { Divider, Modal } from "antd";
import {
  Text,
  OutBox,
  SpinIcon,
  CenterSpin,
  RowDivider,
  Button,
  OptionButton,
} from "./components/sharedComponents";
import { Title, TitleBar } from "./components/title";
import { TableHeader, TableBody } from "./components/order/orderItemTable";
import iconOrderSteps from "../../assets/icons/order-steps/iconOrderStep";
import iconMore from "../../assets/icons/view-more.svg";
import downIcon from "../../assets/icons/down.svg";
import { customer } from "../../services/api";
import statusList from "../../values/statusList";
import { subDomain, redirectTo } from "../../services/redirect";
import noOrderImg from "../../assets/image/online-order.svg";
import Countdown from "./components/order/countdown";
import myOrderTitleIcon from "../../assets/icons/title-icon/my-order.svg";
import historyTitleIcon from "../../assets/icons/title-icon/history.svg";
import { colors } from "../../values/colors";
import { isMobileOnly } from "react-device-detect";
import localName from "../../values/localStorageDict";
import addToCart from "../../utils/addTOCart";
import { renderMoney } from "../../utils/money";
import MediaQuery, { useMediaQuery } from "react-responsive";

const Box = styled.div`
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 1rem;
  @media not screen and (min-device-width: 1224px) {
    margin-bottom: 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.space ? "space-between" : props.end ? "flex-end" : "flex-start"};
  padding: ${(props) => (props.out ? "0.8rem 1rem 0.8rem 1rem" : 0)};
  ${(props) =>
    props.topic &&
    `
    width: 100%;
  @media only screen and (orientation: portrait){
    flex-direction: column;
  }
  `}
  ${(props) =>
    props.orderDetailWrap &&
    `
  @media only screen and (orientation: portrait){
    flex-wrap: wrap;
    align-self: start;
  }


  `}
  ${(props) =>
    props.mobile &&
    `
  @media only screen and (orientation: portrait){
    align-self: start;
    width: 100%;
  }

  @media only screen and (orientation: landscape) and (max-device-width: 680px){
    flex-direction: ${props.row ? "row" : "column"};
    align-items: start;
  }
  `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.start ? "flex-start" : "center")};
  justify-content: center;
  border: 5px 
  padding: 0rem
  @media only screen and (min-width: 768px){
    width: 10rem;
    font-size: 2vh;
  }
`;

const ColumnStep1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.start ? "flex-start" : "flex-end")};
  justify-content: center;
  @media not screen and (min-device-width: 768px) {
    align-items: center;
  }
`;

const DownIcon = styled.img`
  margin: 0 0.5rem 0 0.7rem;
  transform: ${(props) => (props.rotate ? "rotate(180deg)" : "none")};
`;

const stepNames = [
  "",
  ["ร้านค้ากำลัง", "ยืนยันการชำระเงิน"],
  ["ร้านค้ากำลัง", "จัดเตรียมอาหาร"],
  ["อาหารกำลังไปหาคุณ!"],
  ["เสร็จสิ้น!"],
];

const stepDetails = [
  "",
  "อดใจรอสักครู่ร้านค้ากำลังตรวจสอบออเดอร์ของคุณค่ะ!",
  "ร้านค้ากำลังจัดเตรียมอาหารของท่าน กรุณารออาหารประมาณ 10-15 นาที",
  "คนขับกำลังจัดส่งอาหารของคุณ กรุณารอประมาณ 10-15 นาที",
  "ร้านค้ากำลังจัดเตรียมอาหารของท่าน กรุณารออาหารประมาณ 10-15 นาที",
  "คนขับกำลังจัดส่งอาหารของคุณ กรุณารอสักครู่",
  "การจัดส่งเสร็จสิ้น",
  "ออเดอร์ถูกยกเลิก",
  "ชำระเงินไม่ทันเวลาที่กำหนด",
];

const StatusNameBox = styled.div`
  height: 1.8rem;
  border: ${(props) =>
    props.isActive ? `1px solid ${colors.color1}` : "1px solid #a5a5a5"};
  box-sizing: border-box;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  margin-right: 1.5rem;
  padding: 0 1rem;

  @media only screen and (orientation: landscape) {
    width: fit-content;
  }
  @media (min-width: 671px) and (max-width: 720px) {
    height: 100%;
  }
`;

const MarginSet = styled.div`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0")};
  @media only screen and (orientation: portrait) {
    margin: 0;
  }
`;

const OrderHeader = ({
  orderId,
  status,
  createdTime,
  totalPrice,
  showDetail,
  isTracking,
  toggleShowDetail,
}) => {
  const isMobileLandScape = useMediaQuery({
    query: "(max-device-width: 768px) and (orientation: landscape)",
  });
  return (
    <Row out onClick={toggleShowDetail} style={{ cursor: "pointer" }}>
      <Row space topic>
        <Row orderDetailWrap>
          <Text subtitle>รายการเลขที่&nbsp;</Text>
          <Text
            subtitle
            yellow
            style={{ width: isMobileLandScape ? "5rem" : "7rem" }}
          >
            {orderId.toString().padStart(7, "0")}&nbsp;&nbsp;
          </Text>
          <StatusNameBox isActive={isTracking}>
            <Text
              size={"0.75em"}
              color={isTracking ? colors.color1 : "#a5a5a5"}
            >
              {isTracking
                ? status.statusId + "." + status.nameCustomerTh
                : status.nameCustomerTh}
            </Text>
          </StatusNameBox>
          {isTracking ? (
            <></>
          ) : (
            <React.Fragment>
              <MediaQuery minDeviceWidth={768}>
                <MarginSet marginLeft="0.5rem">
                  <Text mini> วัน/เวลาสั่ง: {createdTime}</Text>
                </MarginSet>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={767} orientation={"portrait"}>
                <MarginSet marginLeft="0.5rem">
                  <Text mini> วัน/เวลาสั่ง: {createdTime}</Text>
                </MarginSet>
              </MediaQuery>
            </React.Fragment>
          )}
        </Row>
        <Row mobile>
          <Text
            size={isMobileLandScape ? "1em" : "1.25em"}
            weight={400}
            style={{
              width: isMobileLandScape ? "fit-content" : "13.5rem",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ paddingRight: "1rem" }}>ราคาอาหาร:</div>
            <div>
              {totalPrice ? renderMoney(totalPrice.toFixed(2)) : "0.00"} บาท
            </div>
          </Text>
          {!isTracking && (
            <MediaQuery maxDeviceWidth={767} orientation={"landscape"}>
              <MarginSet
                style={{ display: "flex", alignSelf: "start" }}
                marginLeft="0"
              >
                <Text mini> วัน/เวลาสั่ง: {createdTime}</Text>
              </MarginSet>
            </MediaQuery>
          )}
        </Row>
      </Row>
      <Divider type={"vertical"} />
      <DownIcon src={downIcon} rotate={showDetail} />
    </Row>
  );
};

const BetweenOrderSteps = ({ isActive, isMobile }) => {
  return (
    <img
      src={
        isActive ? iconOrderSteps.betweenStep : iconOrderSteps.betweenStepGrey
      }
      style={{
        margin: `${isActive ? "0" : "0 1rem"}`,
        width: `${isActive ? "7rem" : "2.5rem"}`,
        height: `${isActive ? (isMobile ? "3rem" : "5rem") : "2rem"}`,
        objectFit: "scale-down",
      }}
    />
  );
};

const OrderStep = ({ step, isCurrentStep }) => {
  return (
    <Column style={{}}>
      <img
        src={
          isCurrentStep
            ? iconOrderSteps.colorIcons[step]
            : iconOrderSteps.greyIcons[step]
        }
        style={{ margin: "0.7rem" }}
      />
      <Column style={{ height: "2rem" }}>
        {stepNames[step].map((text) => (
          <Text
            color={isCurrentStep ? null : "#E1E1E1"}
            height={"1.2em"}
            weight={isCurrentStep ? 600 : null}
          >
            {text}
          </Text>
        ))}
      </Column>
    </Column>
  );
};

const OrderSteps = ({
  currentStep,
  onPaymentTimeout,
  mobileCall,
  showContactModal,
  ...props
}) => {
  var onCall = isMobileOnly ? mobileCall : showContactModal;
  return (
    <Column style={{ padding: "1.75rem 1.25rem 1rem 1.25rem" }}>
      {!props.isMobile && !props.isPortrait ? (
        <Row>
          <OrderStep step={1} isCurrentStep={currentStep === 1} />
          <BetweenOrderSteps isActive={currentStep === 1} />
          <OrderStep step={2} isCurrentStep={currentStep === 2} />
          <BetweenOrderSteps isActive={currentStep === 2} />
          <OrderStep step={3} isCurrentStep={currentStep === 3} />
          <BetweenOrderSteps isActive={currentStep === 3} />
          <OrderStep step={4} />
        </Row>
      ) : (
        [
          <Row>
            <OrderStep step={currentStep} isCurrentStep={true} />
          </Row>,
          currentStep !== 4 && (
            <BetweenOrderSteps isMobile={props.isMobile} isActive={true} />
          ),
        ]
      )}
      <Box
        style={{
          width: "100%",
          margin: props.isMobile ? "1rem" : "1.75rem",
          padding: props.isMobile ? "1rem 1.3rem" : "0 1.3rem",
        }}
      >
        <Row
          space={!props.isMobile}
          topic={props.isMobile}
          style={{
            width: "100%",
            height: props.isMobile ? "fit-content" : "5.2rem",
          }}
        >
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: props.isMobile ? "1rem" : "",
            }}
          >
            รายละเอียด:&nbsp;{stepDetails[currentStep]}
          </Text>
          <Row mobile={props.isMobile} row style={{ justifyContent: "center" }}>
            {currentStep === 3 ? (
              <OptionButton
                style={{
                  marginLeft: props.isMobile ? "0" : "",
                }}
                typeA
                onClick={() => onCall(false)}
              >
                <img
                  src={iconOrderSteps.phoneWhite}
                  style={{ marginRight: "0.2rem" }}
                />
                ติดต่อคนขับ
              </OptionButton>
            ) : (
              <></>
            )}
            {currentStep === 1 ? (
              <ColumnStep1 start={props.isMobile}>
                <OptionButton
                  style={{ marginLeft: props.isMobile ? "0" : "" }}
                  onClick={() => onCall(true)}
                >
                  <img
                    src={iconOrderSteps.phoneYellow}
                    style={{ marginRight: "0.2rem" }}
                  />
                  ติดต่อร้านค้า
                </OptionButton>
                <Text
                  style={{
                    color: "#EB5757",
                    fontSize: "0.875rem",
                    marginTop: "0.3rem",
                    fontSize: props.isMobile ? "0.85em" : "",
                  }}
                >
                  หากต้องการยกเลิกรายการอาหารกรุณาติดต่อร้านค้า
                </Text>
              </ColumnStep1>
            ) : (
              <OptionButton
                style={{
                  marginLeft: props.isMobile ? "0" : "",
                }}
                onClick={() => onCall(true)}
              >
                <img
                  src={iconOrderSteps.phoneYellow}
                  style={{ marginRight: "0.2rem" }}
                />
                ติดต่อร้านค้า
              </OptionButton>
            )}
          </Row>
        </Row>
      </Box>
    </Column>
  );
};

const MoreHistory = ({
  isMoreHistories,
  isLoadingMoreHistories,
  loadMoreHistories,
}) => {
  return isMoreHistories ? (
    <Column>
      {isLoadingMoreHistories ? (
        <Divider>
          <div style={{ margin: "0 2.25rem" }}>
            <SpinIcon />
          </div>
        </Divider>
      ) : (
        <Divider>
          <Text
            yellow
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={loadMoreHistories}
          >
            ดูเพิ่มเติม&nbsp;
            <img src={iconMore} />
          </Text>
        </Divider>
      )}
    </Column>
  ) : (
    <></>
  );
};

const NoActiveOrder = () => {
  return (
    <Column style={{ padding: "4rem 0" }}>
      <img src={noOrderImg} />
      <Text style={{ padding: "1rem 0" }}>ขณะนี้ไม่มีออเดอร์ปัจจุบัน</Text>
      <Button
        onClick={() => redirectTo("/home")}
        style={{ width: "12.5rem", height: "2.15rem" }}
      >
        สั่งอาหารสักหน่อย!
      </Button>
    </Column>
  );
};

const ContactModal = ({ visible, isSeller, handleClose, phoneNumber }) => {
  return (
    <Modal
      title={null}
      footer={null}
      visible={visible}
      width={322}
      closable={false}
      bodyStyle={{ padding: "20px" }}
      onCancel={handleClose}
    >
      <Column>
        <Row style={{ width: 260, paddingBottom: "20px" }}>
          <img
            src={iconOrderSteps.phoneModal}
            style={{ paddingRight: "20px" }}
          />
          <Column start>
            <Text> {isSeller ? "ติดต่อร้านค้า" : "ติดต่อคนขับ"} </Text>
            <Text yellow weight={600} size={"1.5em"}>
              {phoneNumber}
            </Text>
          </Column>
        </Row>
        <OptionButton full onClick={handleClose}>
          ปิดหน้าต่าง
        </OptionButton>
      </Column>
    </Modal>
  );
};

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: null,
      showDetail: false,
      totalPrice: null,
      showContact: false,
      isSellerContact: true,
      phoneNumber: "",
      driverNumber: "",
    };
    this.toggleShowDetail = this.toggleShowDetail.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.mobileCall = this.mobileCall.bind(this);
    this.showContactModal = this.showContactModal.bind(this);
    this.reOrder = this.reOrder.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { order } = nextProps;
    if (
      order.driver != undefined &&
      order.status.statusId == "3" &&
      nextProps.haveChangeOrder
    ) {
      const tmp = [
        order.driver[0].phone.slice(0, -7),
        order.driver[0].phone.slice(-7, -4),
        order.driver[0].phone.slice(-4),
      ].filter((x) => !!x);
      this.setState({
        driverNumber: tmp.join("-"),
      });
    }
  }

  componentDidMount() {
    const { order } = this.props;
    if (order.driver != undefined && order.status.statusId == "3") {
      const tmp = [
        order.driver[0].phone.slice(0, -7),
        order.driver[0].phone.slice(-7, -4),
        order.driver[0].phone.slice(-4),
      ].filter((x) => !!x);
      this.setState({
        driverNumber: tmp.join("-"),
      });
    }
    const { items } = order;
    var totalPrice = 0;
    for (var i = 0; i < items.length; i++) {
      const { amount } = items[i];
      items[i].pricePerUnit = items[i].price;
      totalPrice += items[i].price * amount;
      for (var j = 0; j < items[i].choices.length; j++) {
        items[i].pricePerUnit += items[i].choices[j].price;
        totalPrice += items[i].choices[j].price * amount;
      }
    }
    this.setState({
      totalPrice,
      showDetail: this.props.isTracking,
    });
  }

  updateStatus(nextStatusId) {
    customer.order.changeOrderStatus(this.props.order.orderId, nextStatusId);
    if (
      nextStatusId === statusList.COMPLETE ||
      nextStatusId === statusList.CANCELED
    ) {
      this.props.reload();
    }
  }

  toggleShowDetail() {
    this.setState({
      showDetail: !this.state.showDetail,
    });
  }

  mobileCall(toSeller) {
    // TODO: fetch phone number
    var phoneNumber;
    if (toSeller) {
      phoneNumber = "062-981-2526";
    } else {
      phoneNumber = this.state.driverNumber;
    }
    window.location = `tel:${phoneNumber}`;
  }

  showContactModal(toSeller) {
    // TODO: fetch phone number
    if (toSeller) {
      this.setState({
        showContact: true,
        isSellerContact: toSeller,
        phoneNumber: "062-981-2526",
      });
    } else {
      this.setState({
        showContact: true,
        isSellerContact: toSeller,
        phoneNumber: this.state.driverNumber,
      });
    }
  }

  reOrder() {
    const { items } = this.props.order;
    for (var i = 0; i < items.length; i++) {
      addToCart(items[i], false, 0, false);
    }
    redirectTo("/cart");
  }

  render() {
    const { isTracking, order } = this.props;
    const {
      showDetail,
      totalPrice,
      showContact,
      isSellerContact,
      phoneNumber,
    } = this.state;

    return (
      <Box>
        <OrderHeader
          orderId={order.orderId}
          status={order.status}
          createdTime={order.createTime}
          totalPrice={totalPrice}
          showDetail={showDetail}
          isTracking={isTracking}
          toggleShowDetail={this.toggleShowDetail}
        />
        {showDetail ? (
          <div>
            {isTracking ? <RowDivider /> : <></>}
            {isTracking ? (
              <OrderSteps
                currentStep={parseInt(order.status.statusId)}
                onPaymentTimeout={this.props.reload}
                mobileCall={this.mobileCall}
                showContactModal={this.showContactModal}
                isDesktop={this.props.isDesktop}
                isMobile={this.props.isMobile}
                isPortrait={this.props.isPortrait}
              />
            ) : (
              <></>
            )}
            <RowDivider />
            <TableHeader />
            <RowDivider />
            {order.items.map((item, index) => (
              <>
                <TableBody item={item} number={index} />
                <RowDivider />
              </>
            ))}
            <Row end style={{ padding: "0.5rem 1.75rem" }}>
              <Text size={"0.9em"} color={"#A5A5A5"}>
                {" "}
                ค่าส่ง:&nbsp;&nbsp;{" "}
              </Text>
              <Text subtitle size={"0.9em"} yellow>
                {" "}
                {order.deliveryCost
                  ? renderMoney(order.deliveryCost) //.toFixed(2)
                  : "0.00"}{" "}
                บาท
              </Text>
              <Text size={"0.9em"} color={"#A5A5A5"}>
                &nbsp;&nbsp; |&nbsp;&nbsp;{" "}
              </Text>
              <Text size={"0.9em"} color={"#A5A5A5"}>
                {" "}
                ราคาทั้งหมด:&nbsp;&nbsp;{" "}
              </Text>
              <Text subtitle size={"0.9em"} yellow>
                {" "}
                {totalPrice
                  ? renderMoney(
                      (
                        parseFloat(totalPrice) + parseFloat(order.deliveryCost)
                      ).toFixed(2)
                    )
                  : "0.00"}{" "}
                บาท
              </Text>
            </Row>
            {isTracking ? (
              <></>
            ) : (
              <>
                <RowDivider />
                <Row end style={{ padding: "1rem" }}>
                  <Button
                    onClick={this.reOrder}
                    style={{ width: "12.5rem", height: "2.8rem" }}
                  >
                    สั่งอาหารอีกครั้ง
                  </Button>
                </Row>
              </>
            )}
          </div>
        ) : (
          <></>
        )}
        <ContactModal
          visible={showContact}
          isSeller={isSellerContact}
          handleClose={() => this.setState({ showContact: false })}
          phoneNumber={phoneNumber}
        />
      </Box>
    );
  }
}

class MyOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingActiveOrder: true,
      isLoadingHistoryOrder: true,
      trackingOrders: [],
      historyOrders: [],
      isMoreHistories: false,
      isLoadingMoreHistories: false,
      currentHistoryPage: 1,
      lastUpdateTime: "",
      haveChangeOrder: false,
    };
    this.loadMoreHistories = this.loadMoreHistories.bind(this);
    this.reloadHistories = this.reloadHistories.bind(this);
    this.updateActiveStatus = this.updateActiveStatus.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    this.setState({
      isLoadingActiveOrder: true,
      isLoadingHistoryOrder: true,
    });
    customer.order.getActiveOrder(
      ({ data }) => {
        this.setState({
          trackingOrders: data.orders,
          isLoadingActiveOrder: false,
          lastUpdateTime: data.now,
        });
      },
      (response) => {
        // console.log("getActiveOrder", response);
      }
    );
    this.reloadHistories();
    this.interval = setInterval(() => {
      this.updateActiveStatus();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadMoreHistories() {
    this.setState({
      isLoadingMoreHistories: true,
    });
    const currentHistoryPage = this.state.currentHistoryPage + 1;
    customer.order.getOrderHistory(
      currentHistoryPage,
      ({ data }) => {
        this.setState({
          historyOrders: [...this.state.historyOrders, ...data.orders],
          isMoreHistories: data.amount > currentHistoryPage * 5,
          isLoadingMoreHistories: false,
          currentHistoryPage,
        });
      },
      (response) => {
        // console.log("getOrderHistory", response);
      }
    );
  }

  reloadHistories() {
    this.setState({
      isLoadingHistoryOrder: true,
    });
    customer.order.getOrderHistory(
      1,
      ({ data }) => {
        this.setState({
          historyOrders: data.orders,
          isMoreHistories: data.amount > 5,
          isLoadingHistoryOrder: false,
        });
      },
      (response) => {
        // console.log("getOrderHistory", response);
      }
    );
  }

  updateActiveStatus() {
    customer.order.getChangedActiveOrder(this.state.lastUpdateTime, (data) => {
      var trackingOrders = this.state.trackingOrders;
      const { newCurrentOrders, newOrders, now } = data;
      let change = false;
      // console.log(newCurrentOrders)
      for (var i = 0; i < newCurrentOrders.length; i++) {
        for (var j = trackingOrders.length - 1; j >= 0; j--) {
          if (newCurrentOrders[i].orderId === trackingOrders[j].orderId) {
            const statusId = parseInt(newCurrentOrders[i].status.statusId);
            change = true;
            if (
              statusId === statusList.COMPLETE ||
              statusId === statusList.CANCELED
            ) {
              trackingOrders.splice(j, 1);
              this.reloadHistories();
            } else {
              trackingOrders[j].status = newCurrentOrders[i].status;
              trackingOrders[j].driver = newCurrentOrders[i].driver;
            }
          }
        }
      }
      this.setState({
        trackingOrders: [...newOrders, ...trackingOrders],
        lastUpdateTime: now,
        haveChangeOrder: change,
      });
      // console.log(this.state.trackingOrders)
    });
  }

  reload() {
    // console.log("reload()");
    this.updateActiveStatus();
    this.reloadHistories();
  }

  render() {
    const {
      isLoadingActiveOrder,
      isLoadingHistoryOrder,
      trackingOrders,
      historyOrders,
      isMoreHistories,
      isLoadingMoreHistories,
    } = this.state;
    const isLoading = isLoadingActiveOrder && isLoadingHistoryOrder;

    return isLoading ? (
      <CenterSpin />
    ) : (
      <OutBox style={{ paddingBottom: "2rem" }}>
        <Title title={"ออเดอร์ของฉัน"} img={myOrderTitleIcon} />
        {isLoadingActiveOrder ? (
          <CenterSpin />
        ) : trackingOrders.length === 0 ? (
          <NoActiveOrder />
        ) : (
          trackingOrders.map((order, index) => (
            <Order
              isTracking
              order={order}
              reload={() => this.reload()}
              isDesktop={this.props.isDesktop}
              isMobile={this.props.isMobile}
              isPortrait={this.props.isPortrait}
              haveChangeOrder={this.state.haveChangeOrder}
            />
          ))
        )}
        {historyOrders.length === 0 ? (
          <></>
        ) : (
          <TitleBar title={"ประวัติการสั่งซื้อ"} img={historyTitleIcon} />
        )}
        {isLoadingHistoryOrder ? (
          <CenterSpin />
        ) : (
          historyOrders.map((order, index) => <Order order={order} />)
        )}
        <MoreHistory
          isMoreHistories={isMoreHistories && !isLoadingHistoryOrder}
          isLoadingMoreHistories={isLoadingMoreHistories}
          loadMoreHistories={this.loadMoreHistories}
        />
      </OutBox>
    );
  }
}

export default MyOrder;
