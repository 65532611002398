import React from "react";
import { useMediaQuery } from "react-responsive";
import "antd/dist/antd.css";
import { List, Avatar, Input, Modal } from "antd";
import Tabs from "./tabs";
import { Pagination, itemRender } from "./pagination";
import { FileImageOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { colors } from "../../../../values/colors";
import { redirectTo } from "../../../../services/redirect";
import { customer as api } from "../../../../services/api";
import { CenterSpin } from "../sharedComponents";
import Search from "../../../../assets/icons/search.svg";
import NotFound from "../../../../assets/image/notFound.svg";
import Underscore, { values } from "underscore";
import logo from "../../../../assets/image/imyellow.svg";
import debounce from 'lodash.debounce';
import MenuDetail from "../../menuDetail";
import { Text } from "../sharedComponents";
import { renderMoney } from "../../../../utils/money";
const { TabPane } = Tabs;

const MenuDescriptionBox = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0.5rem;
`;

const MenuDescription = styled.div`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "500" : "normal")};
  font-size: ${(props) => (props.bold ? "20px" : "18px")};
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${(props) => (props.bold ? colors.buttonColor : colors.textColor1)};
`;

const CustomerSlidingMenuBox = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 3rem 0;
  border-radius: 5px;
  border: 1px solid #f6f6f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
  
  @media not screen and (min-device-width: 1224px) {
    display: block;
    width: 100vw;
    align-self: center;
    margin: 0rem 0 0rem 0;
    height: ${props => `calc( 100vh - ${props.headerHeight} - ${props.footerHeight})`};
  }
`;

const CategoryLabel = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 600;
  display: block;
  witdh: 100%;
  text-align: center;
  color: #FFB800;
  margin-top: 0.5rem;
  height: 2rem;
  line-height: 2rem;
`

const TabLabel = styled.label`
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`

const CategoryName = styled.div`
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.1rem;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.1px;
  padding: 1.5rem 0rem 3rem 1.5rem;
  color: ${colors.buttonColor};
`;

const MenuBox = styled.div`
  font-family: "Kanit";
  border-radius: 0.3rem;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(196, 196, 196, 0.53);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.2rem;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 3px 0px #d9d9d9;
  }
`;

const PaginationBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.space ? "space-between" : props.center ? "center" : "flex-start"};
`;

const TextRow = styled.div`
  font-family: "Kanit";
  font-size: 1.25em;
  line-height: 1.875em;
  display: flex;
  flex-direction: row;
  align-item: space-between;
  justify-content: center;
  margin-top: 3rem;
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 4rem;
  background: ${colors.color1};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuList = (props) => {
  var { isLoading, data, page, handlePageChange, total, togglePopup } = props;
  var isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
  var isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  var isMobile = useMediaQuery({ query: "(max-device-width: 680px)" });
  var columnAmount = isDesktop ? 3 : isMobile && isPortrait ? 1 : 2;
  var pageSize = isDesktop ? columnAmount * 3 : 12;
  var menuList;
  if (data.length !== pageSize && isDesktop) {
    menuList = data.length > 0 ? data.slice(0, pageSize) : [];
  } else {
    menuList = data;
  }

  if (props.isScrollLoad && data.length > 0) {
    menuList = [...menuList, { isLoading: true }]
  }
  const handleScorll = (event) => {
    const maxHeight = event.target.scrollHeight
    const scrollPostition = event.target.scrollTop
    const scrollHeight = event.target.clientHeight
    if (scrollHeight + scrollPostition > maxHeight * 0.7) {
      if (props.page * props.pageSize < total) props.scrollLoad(props.tab, props.page + 1, props.pageSize);
    }
  }

  return (
    <div>
      {isLoading ? (
        <CenterSpin height={"26rem"} />
      ) : (
          <List
            grid={{ gutter: 16, column: columnAmount }}
            dataSource={menuList}
            style={{
              height: !isDesktop ?
                `calc( 100vh - ${props.headerHeight} - ${props.footerHeight} - ${props.tabHeight})` :
                "26rem",
              overflowY: isMobile ? "auto" : null,
              overflowX: "hidden"
            }}
            onScroll={!isDesktop ? handleScorll : null}
            renderItem={(item, itemIndex) => (
              <List.Item>
                {!item.isLoading ?
                  <MenuBox onClick={() => togglePopup(item.productId)}>
                    <Avatar
                      shape="square"
                      size={100}
                      src={item.image || logo}
                      icon={<FileImageOutlined />}
                      style={{ margin: "0.4rem" }}
                    />
                    <MenuDescriptionBox>
                      <MenuDescription>{item.productNameTh}</MenuDescription>
                      <MenuDescription bold>
                        {item.productPrice ? renderMoney(item.productPrice) : "0.00"}{" "}
                    ฿
                  </MenuDescription>
                    </MenuDescriptionBox>
                  </MenuBox>
                  :
                  <MenuBox >
                    <Avatar
                      shape="square"
                      size={100}
                      src={item.image || logo}
                      icon={<FileImageOutlined />}
                      style={{ margin: "0.4rem", width: 0 }}
                    />
                    <CenterSpin padding={0} height={100} size={50}/>
                  </MenuBox>
                }
              </List.Item>
            )}
          />
        )}
      {props.isDesktop && <PaginationBox>
        <Pagination
          itemRender={itemRender}
          defaultCurrent={1}
          current={page}
          total={total}
          onChange={handlePageChange}
          pageSize={pageSize}
        />
      </PaginationBox>}
    </div>
  );
};

class CustomerSlidingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 0,
      categories: null,
      products: null,
      tab: 0,
      page: 1,
      pageSize: null,
      isLoading: true,
      searchInput: null,
      visiblePopup: false,
      popupId: null,
      total: null,
      categoryIndex: 0,
      tmpCaIndex: 0,
      slideTimeout: 0,
      isScrollLoad: false,
    };
    this.loadPageData = this.loadPageData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.updateTap = this.updateTap.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.scrollLoad = this.scrollLoad.bind(this);
  }

  componentDidMount() {
    const { isMobile, isDesktop } = this.props;
    const pageSize = isDesktop ? 9 : 12;
    api.product.getProductList(pageSize, (status, data) => {
      if (status === 200) {
        const { category, productFirstPage } = data;
        this.setState({
          categories: category,
          products: productFirstPage,
          isLoading: false,
          pageSize,
        });
      }
    });
  }

  loadPageData(tab, page, pageSize, search) {
    const { categories } = this.state;
    if (search === null || search === "") {
      api.product.getProductListPage(
        categories[tab].categoryId,
        page,
        pageSize,
        (status, data) => {
          if (status === 200) {
            this.setState({
              tab,
              page,
              pageSize,
              products: data,
              isLoading: false,
              total: null,
            });
          }
        }
      );
    } else {
      api.product.searchProductInCategory(
        search,
        categories[tab].categoryId,
        "th",
        page,
        pageSize,
        (status, data) => {
          if (status === 200) {
            this.setState({
              tab,
              page,
              pageSize,
              products: data.products,
              isLoading: false,
              total: data.amount,
            });
          }
        }
      );
    }
  }

  handlePageChange(page) {
    this.setState({
      isLoading: true,
    });
    this.loadPageData(
      this.state.tab,
      page,
      this.state.pageSize,
      this.state.searchInput
    );
  }

  handleTabChange(activeKey) {
    this.setState({
      isLoading: true,
      searchInput: "",
      categoryIndex: +activeKey,
    });
    this.loadPageData(activeKey, 1, this.state.pageSize, null);
  }

  updateTap(val) {
    this.setState(state => {
      let tmpCaIndex = state.tmpCaIndex
      if(tmpCaIndex + val < 0) tmpCaIndex = 0
      else if(tmpCaIndex + val > this.state.categories.length - 1 ) tmpCaIndex = this.state.categories.length -1
      return {
        tmpCaIndex: state.tmpCaIndex + val < 0 ? 0 : state.tmpCaIndex + val,
      }
    })
    this.debounceTabChanged();
  }

  debounceTabChanged = debounce(() => {
    this.handleTabChange(this.state.tmpCaIndex.toString())
  }, 500, {
    'trailing': true
  })

  scrollLoad(tab, page, pageSize) {
    this.setState({
      isScrollLoad: true,
    })
    this.debounceScrollLoad(tab,page, pageSize)
  }

  debounceScrollLoad = debounce((tab, page, pageSize) => {
    const { categories } = this.state;
    api.product.getProductListPage(
      categories[tab].categoryId,
      page,
      pageSize,
      (status, data) => {
        if (status === 200) {
          this.setState({
            tab,
            page,
            pageSize,
            products: [...this.state.products, ...data],
            isScrollLoad: false,
            total: null,
          });
        }
      }
    );
  }, 300, {
    'trailing': true
  })


  onSearch(e) {
    const { value } = e.target;
    this.setState({ searchInput: value });
    if (this.state.timeout) clearTimeout(this.state.timeout);
    this.setState({
      timeout: setTimeout(() => {
        this.loadPageData(this.state.tab, 1, this.state.pageSize, value);
      }, 300),
    });
  }

  togglePopup(productId) {
    if (this.props.popup) {
      this.setState({
        popupId: productId,
        visiblePopup: !this.state.visiblePopup,
      });
    } else {
      redirectTo(`/menu/${productId}`);
    }
  }

  closePopup() {
    this.setState({
      visiblePopup: !this.state.visiblePopup,
    });
  }

  render() {
    const { popup } = this.props;
    const {
      categories,
      products,
      tab,
      page,
      pageSize,
      isLoading,
      total,
      visiblePopup,
      popupId,
      categoryIndex,
      isScrollLoad,
    } = this.state;
    const headerHeight = "4rem";
    const footerHeight = "5.2rem";
    const tabHeight = "6rem";
    return !categories || !products ? (
      <CenterSpin />
    ) : (
        <CustomerSlidingMenuBox headerHeight={headerHeight} footerHeight={footerHeight}>

          <Tabs
            renderTabBar={(props, Default) => {
              return (
                <div >
                  {!this.props.isDesktop && <CategoryLabel>หมวดหมู่</CategoryLabel>}
                  <Default {...props} ></Default>
                </div>
              )
            }}
            tabPosition={"top"}
            style={{
              height: "100%",
              width: "100%",
              fontFamily: "Kanit",
              padding: this.props.isDesktop ? "0 1rem 1rem 1rem" : "0 15px",
            }}
            onChange={(activeKey) => this.handleTabChange(activeKey)}
            onPrevClick={() => { if (!this.props.isDesktop ) this.updateTap(-1) }}
            onNextClick={() => { if (!this.props.isDesktop ) this.updateTap(1) }}
            activeKey={categoryIndex.toString()}
          >
            {categories.map((category, catIndex) => (
              <TabPane tab={<TabLabel>{category.nameTh}</TabLabel>} key={catIndex}>
                {this.props.isDesktop && <FlexRow space>
                  <CategoryName> {category.nameTh} </CategoryName>
                  <CategoryName style={{ width: "35%" }}>
                    <Input
                      placeholder="ค้นหาอาหาร"
                      value={this.state.searchInput}
                      size="large"
                      suffix={<img src={Search} />}
                      onChange={(value) => this.onSearch(value)}
                      style={{ borderRadius: "5px" }}
                    />
                  </CategoryName>
                </FlexRow>}
                {Underscore.isEmpty(products) ? (
                  <div style={{ margin: "5rem 3rem 5rem 3rem" }}>
                    <img
                      src={NotFound}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "12.5%",
                      }}
                    />
                    <TextRow>ขออภัยไม่พบรายการอาหาร</TextRow>
                  </div>
                ) : (
                    <MenuList
                      isDesktop={this.props.isDesktop}
                      popup={popup}
                      togglePopup={this.togglePopup}
                      isLoading={isLoading}
                      isScrollLoad={isScrollLoad}
                      data={products}
                      page={page}
                      pageSize={pageSize}
                      tab={tab}
                      handlePageChange={this.handlePageChange}
                      total={total ? total : categories[tab].totalProduct || 10}
                      headerHeight={headerHeight}
                      footerHeight={footerHeight}
                      tabHeight={tabHeight}
                      scrollLoad={this.scrollLoad}
                    />
                  )}
              </TabPane>
            ))}
          </Tabs>
          {/* <Modal
            visible={popup && visiblePopup}
            title={null}
            footer={null}
            width={"70rem"}
            closable={false}
            bodyStyle={{ padding: 0 }}
            style={{ borderRadius: "5px" }}
            onCancel={this.togglePopup}
          >
            <ModalHeader>
              <Text size={"1.8em"} color={colors.textColor1}>
                {" "}
              เมนูอาหาร{" "}
              </Text>
            </ModalHeader>
            <MenuDetail popup productId={popupId} closePopup={this.closePopup} />
          </Modal> */}
        </CustomerSlidingMenuBox >
      );
  }
}

export default CustomerSlidingMenu;
