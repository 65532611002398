import React from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { Drawer } from "antd";
import styled from "styled-components";
import {
  FlagIcon,
  ListIcon,
  UserIcon,
  FolderIcon,
  LogoutIcon,
  HomeIcon,
  SettingsIcon,
} from "../../../components/icons";
import Badge from "./badge";
import { subDomain } from "../../../services/redirect";
import { colors } from "../../../values/colors";
import LogoImage from "../../../assets/image/logo_small.png";
import { sum } from "../util/array";
import localName from "../../../values/localStorageDict";

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0px;
  }
`;

const Logo = styled.img`
  width: 34px;
  height: 34px;
  margin-right: 4px;
`;

const DrawerBlock = styled.div`
  padding: 20px;

  & + & {
    border-top: 1px solid ${colors.storeDrawerDividerBg};
  }
`;

const DrawerHead = styled.div`
  display: flex;
  align-items: center;

  line-height: 30px;
  font-family: Kanit;
  font-size: 20px;
  font-weight: bold;
  color: ${colors.storeText};
`;

const DrawerItem = styled.div`
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 10px 18px;
  background-color: ${({ active }) =>
    active ? colors.storeDrawerActiveBg : colors.storeDrawerInactiveBg};
  transition: all 0.5s ease;
  cursor: pointer;

  line-height: 30px;
  font-family: Kanit;
  font-size: 16px;
  color: ${({ danger }) =>
    danger ? colors.storeTextDanger : colors.storeText};

  & + & {
    margin-top: 12px;
  }

  & > *:first-child {
    margin-right: 12px;
  }
`;

export default function StoreDrawer({ orders, ...props }) {
  const sections = [
    [
      {
        icon: FlagIcon,
        label: "รายการอาหารวันนี้",
        url: "/order",
        badge: orders.loading
          ? 0
          : orders.data.newUpdates
          ? sum(orders.data.newUpdates)
          : 0,
      },
      { icon: ListIcon, label: "จัดการเมนูอาหาร", url: "/menu" },
      { icon: UserIcon, label: "คนส่งอาหาร", url: "/driver" },
      // { icon: FolderIcon, label: "รายงาน", url: "/report" },
    ],
    // [
    //   { icon: HomeIcon, label: "โปรไฟล์ร้าน", url: "/profile" },
    //   { icon: SettingsIcon, label: "การตั้งค่า", url: "/settings" },
    // ],
  ];

  const history = useHistory();
  const currentPath = useLocation().pathname;

  const logout = () => {
    localStorage.removeItem(localName.STORE_ACCESS_TOKEN);
    localStorage.removeItem(localName.STORE_REFRESH_TOKEN);
    history.push(`${subDomain}/storelogin`);
  };

  return (
    <StyledDrawer
      {...props}
      placement={"left"}
      visible
      closable={false}
      mask={false}
    >
      <DrawerBlock>
        <DrawerHead>
          <Logo src={LogoImage} />
          {"ไอแอมเยลโล่วคาเฟ่"}
        </DrawerHead>
      </DrawerBlock>
      {sections.map((section, index) => (
        <DrawerBlock key={index}>
          {section.map(({ icon: Icon, label, url: shortenedUrl, badge }) => {
            const url = `${subDomain}/store${shortenedUrl}`;
            const active =
              matchPath(currentPath, {
                path: url,
              }) !== null;
            const onClick = active ? undefined : () => history.push(url);

            return (
              <DrawerItem key={shortenedUrl} active={active} onClick={onClick}>
                {<Icon color={colors.storeText} width={20} height={20} />}
                {label}
                {!!badge && <Badge>{badge}</Badge>}
              </DrawerItem>
            );
          })}
        </DrawerBlock>
      ))}
      <DrawerBlock>
        <DrawerItem danger onClick={logout}>
          <LogoutIcon color={colors.storeTextDanger} width={20} height={20} />
          {"ออกจากระบบ"}
        </DrawerItem>
      </DrawerBlock>
    </StyledDrawer>
  );
}
