import React from 'react';
import { Empty } from 'antd';
import Collapse from '../collapse';
import OrderDetail from '../order/detail';
import styled from 'styled-components';
import { colors } from '../../../../values/colors';
import Status from '../status';

const Header = styled.div`
    display: flex;
    font-size: 1.125rem;
    color: ${colors.storeText};

    & > * {
        margin: 0px 0.25rem;
    }
`;

const Title = styled.span`
    font-weight: 600;
`;

const Highlighted = styled.span`
    color: ${colors.storeTextHightlighted};
`;

const Column = styled.div`
    width: ${props => props.width};
`;

const OrderCollapse = React.memo(({ orders }) => {
    if(!orders || orders.length === 0) {
        return (
            <Empty
                style={{ marginTop: '12px' }}
                description="ไม่มีออเดอร์"
            />
        );
    }

    return (
        <React.Fragment>
            <Collapse expandIconPosition={'right'}>
                {orders && orders.map(order => {
                    let {
                        orderId,
                        customer,
                        createTime,
                        totalPrice
                    } = order;

                    customer = customer || {};

                    // TODO determine if order is paid
                    const paid = false;

                    const header = (
                        <Header>
                            <Column width={'20%'}>
                                <Title>
                                    {'รายการเลขที่ '}
                                    <Highlighted>
                                        {orderId.padStart(7, '0')}
                                    </Highlighted>
                                </Title>
                            </Column>
                            <Column width={'10%'}>
                                <Status status={['error', 'success', 'canceled'][1]}>
                                    {['ยกเลิกออเดอร์', 'เสร็จสิ้น', 'ไม่ได้ชำระเงิน'][1]}
                                </Status>
                            </Column>
                            <Column width={'30%'} />
                            <Column width={'20%'}>
                                {`เวลาสั่ง : ${createTime.split(' ')[1]}`}
                            </Column>
                            <Column width={'20%'}>
                                {`ราคาอาหาร : ${totalPrice} บาท`}
                            </Column>
                        </Header>
                    );

                    return (
                        <Collapse.Panel header={header} key={orderId}>
                            <OrderDetail
                                order={order}
                                paid={paid}
                                stage={5}
                            />
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </React.Fragment>
    );
});

export default OrderCollapse;
