import React from "react";
import styled from "styled-components";
import { Modal, Form, Input, Button, message } from "antd";
import { colors } from "../../values/colors";
import FormItem from "./formItem";
import { redirectTo } from "../../services/redirect";
import { FontColorsOutlined } from "@ant-design/icons";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  paddingbottom: 0.1rem;
`;

const Email = styled.div`
  font-family: Kanit;
  font-weight: 600;
  font-size: 1em;
  color: #fbb03b;
`;

class SendToEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleCancel() {
    this.props.backToLoginTab();
  }

  render() {
    const { visibleSendToEmailModal, email, backToLoginTab } = this.props;
    return (
      <Modal
        footer={null}
        visible={visibleSendToEmailModal}
        width={"24rem"}
        closable={false}
        bodyStyle={{ padding: 0 }}
        style={{ borderRadius: "5px" }}
      >
        <Column>
          <Form
            style={{
              lineHeight: 1.68,
              marginLeft: "1.26rem",
              marginRight: "1.26rem",
            }}
            layout={"vertical"}
            hideRequiredMark
          >
            <FormItem
              style={{
                marginTop: "1.26rem",
                marginBottom: "0.42rem",
                fontSize: "1.6rem",
                height: "2rem",
                padding: "0rem",
              }}
              label="ส่งอีเมลเรียบร้อย"
              name="line1"
            ></FormItem>
            <FormItem
              style={{
                margin: 0,
                fontSize: "16px",
                padding: "0rem",
                height: "1.2rem",
                width: "30%",
              }}
              label="ส่งอีเมลไปที่"
            >
              <Email
                style={{
                  width: "300%",
                  marginBottom: "0.4rem",
                  marginLeft: " 0.6rem",
                }}
              >
                {this.props.email}
              </Email>
            </FormItem>

            <FormItem
              style={{
                margin: 0,
                fontSize: "16px",
                padding: "0rem",
                height: "1.4rem",
              }}
              label="กรุณาตรวจสอบอีเมลของท่านเพื่อตั้งรหัสผ่านใหม่"
              name="line3"
            ></FormItem>

            <FormItem style={{ marginTop: "0rem", padding: "0rem" }}>
              <Button
                onClick={() => redirectTo("/")}
                style={{
                  marginTop: "22px",
                  width: "100%",
                }}
                type="primary"
                htmlType="submit"
                aria-label="Close"
              >
                กลับไปยังหน้าแรก
              </Button>
            </FormItem>
          </Form>
        </Column>
      </Modal>
    );
  }
}

export default SendToEmailModal;
