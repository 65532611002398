import React from "react";
import styled from "styled-components";
import { colors } from "../../../values/colors";

const Marker = styled.div`
  background: ${colors.color1};
  color: white;
  border-radius: 50% 50% 50% 0;
  border: 4px solid ${colors.color1};
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  font-family: "Kanit";
  font-style: normal;
  font-size: 1em;
  font-weight: bold;
  padding-left: 0.1rem;
`;

const Me = styled.div`
  background: red;
  color: white;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
`;

export const calBound = (map, maps, orderList) => {
  console.log(map, maps);
  console.log(maps.places);
  let center = map.getCenter();
  let bounds = new maps.LatLngBounds();
  console.log("---------", bounds);
  bounds.extend(new maps.LatLng(center.lat(), center.lng()));
  for (var i = 0; i < orderList.length; i++) {
    bounds.extend(new maps.LatLng(orderList[i].lat, orderList[i].lng));
  }
  console.log("==========", bounds);
  map.fitBounds(bounds);
  console.log(map.getZoom());
  map.setZoom(map.getZoom() - 1);
  return 0;
};

export const MapMarker = ({ text }) => (
  <Marker>
    <div style={{ transform: "rotate(45deg)" }}>{text}</div>
  </Marker>
);

export const MyMarker = () => <Me />;
