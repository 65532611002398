import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Menu, Dropdown, Badge, message, Avatar } from "antd";
import { UserOutlined, ApiFilled } from "@ant-design/icons";
import { colors } from "../../values/colors";
import cartIcon from "../../assets/icons/shopping-cart.svg";
import downIcon from "../../assets/icons/down.svg";
import { redirectTo } from "../../services/redirect";
import LoginModal from "../login/loginModal";
import logo from "../../assets/image/header/logo.svg";
import line from "../../assets/image/header/line.svg";
import localName from "../../values/localStorageDict";
import handleLogout from "../../utils/handleLogout";
import { customer } from "../../services/api";
import ForgotPasswordModal from "../login/forgotPasswordModal";
import SendToEmailModal from "../login/sendToEmailModal";
import SVGIcon from "../SVGIcon";

const Bar = styled.div`
  top: 0;
  z-index: 99;
  width: 100%;
  height: ${(props) => props.headerHeight};
  background: ${colors.color1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;

  @media not screen and (min-device-width: 1224px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const Title = styled.div`
  font-family: "Kanit";
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  font-size: 1em;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${colors.textColor1};
  cursor: pointer;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.space ? "space-between" : "flex-end")};
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const LittleSpace = styled.div`
  width: ${(props) => (props.size ? props.size : "2rem")};
`;

const DropdownMenu = styled(Menu)`
  border-radius: 5px;
`;

const MenuItem = styled(Menu.Item)`
  height: 2.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: "flex-start";
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: "Kanit";
`;

const MenuSpace = styled.div`
  width: 1rem;
`;

const SideBar = styled.div`
  position: absolute;
  opacity: ${(props) => (props.show ? "1" : "0")};
  z-index: ${(props) => (props.show ? "200" : "-100")};
  transition: all 0.2s ease-out;
`;

const SideBarMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  height: 100vh;
  width: 70%;
  background-color: white;
  transition: all 0.2s ease-out;
  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(-100%)")};
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
  width: 100%;
  height: 100%;
`;

const DrawerItem = styled.div`
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 10px 18px;
  background-color: ${({ active }) =>
    active ? colors.storeDrawerActiveBg : colors.storeDrawerInactiveBg};
  transition: all 0.5s ease;
  cursor: pointer;

  line-height: 30px;
  font-family: Kanit;
  font-size: 16px;
  color: ${({ danger, active }) =>
    active
      ? colors.activeManu
      : danger
      ? colors.storeTextDanger
      : colors.inActiveMenu};

  & + & {
    margin-top: 12px;
  }

  & > *:first-child {
    margin-right: 12px;
  }
`;

const DrawerBlock = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;

  & + & {
    border-top: 1px solid ${colors.storeDrawerDividerBg};
  }
`;

const MenuList = (props) => {
  return (
    <DropdownMenu onClick={props.handleDropdownClick}>
      <MenuItem key="0">
        <SVGIcon type="user" style={{ height: "1rem", width: "1rem" }} />
        <MenuSpace />
        โปรไฟล์ของฉัน
      </MenuItem>
      <Menu.Divider />
      <MenuItem key="1">
        <SVGIcon type="home" style={{ height: "1rem", width: "1rem" }} />
        <MenuSpace />
        ที่อยู่ของฉัน
      </MenuItem>
      <Menu.Divider />
      <MenuItem key="2">
        <SVGIcon type="phone" style={{ height: "1rem", width: "1rem" }} />
        <MenuSpace />
        ติดต่อร้านค้า
      </MenuItem>
      <Menu.Divider />
      <MenuItem key="3">
        <SVGIcon
          type="logout"
          stroke="black"
          style={{ height: "1rem", width: "1rem" }}
        />
        <MenuSpace />
        ออกจากระบบ
      </MenuItem>
    </DropdownMenu>
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartCount: 0,
      visibleLoginModal: false,
      visibleSendToEmailModal: false,
      visibleForgotPasswordModal: false,
      isLoginTab: true,
      selected: null,
      displayImage: null,
      displayName: "",
      registerOrForgotPasswordEmail: "",
      isOpenSideBar: false,
    };
    this.handleRedirect = this.handleRedirect.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
    this.handleDropdownClick = this.handleDropdownClick.bind(this);
    this.setLoginTab = this.setLoginTab.bind(this);
    this.loadProfileImg = this.loadProfileImg.bind(this);
    this.loadProfileName = this.loadProfileName.bind(this);
    this.loadProfileName();
  }

  componentDidMount() {
    const pathname = window.location.pathname.split("/");
    const selected = pathname ? pathname[pathname.length - 1] : "";
    const currentCart = localStorage.getItem(localName.CART);
    const cart =
      currentCart && currentCart.length > 0 ? JSON.parse(currentCart) : [];
    const displayImage = localStorage.getItem(localName.PROFILE_IMAGE);
    var count = 0;
    for (var i = 0; i < cart.length; i++) {
      count += cart[i].amount;
    }
    this.setState({ cartCount: count, selected, displayImage });
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.isUpdateProfile != nextProps.isUpdateProfile) {
      if (nextProps.isUpdateProfile) {
        this.loadProfileName();
        this.props.setIsUpdateProfile(false);
      }
    }
  }

  toggleLoginModal(tab) {
    this.setState({
      isLoginTab: tab === "register" ? false : true,
      visibleLoginModal: !this.state.visibleLoginModal,
    });
  }

  loadProfileName() {
    const signedIn =
      localStorage.getItem(localName.ACCESS_TOKEN) &&
      localStorage.getItem(localName.REFRESH_TOKEN);
    if (signedIn)
      customer.user.getProfileData(
        ({ data }) => {
          var displayName = `${data.firstname} ${data.lastname}`;
          if (data.lastname === null || data.lastname === null) {
            displayName = `${data.firstname}`;
          }
          this.setState({ displayName, displayImage: data.imageProfile[0] });
        },
        () => {
          // const displayName = "Waiting data from server...";
          // this.setState({ displayName });
        }
      );
  }
  handleDropdownClick({ key }) {
    switch (key) {
      case "0":
        this.handleRedirect("/profile");
        break;
      case "1":
        localStorage.setItem(localName.START_AT, "/");
        this.handleRedirect("/my-address");
        break;
      case "2":
        this.handleRedirect("/contact");
        break;
      case "3":
        this.handleRedirect("/logout");
        break;
      case "98":
        this.toggleLoginModal("register");
        break;
      case "99":
        this.toggleLoginModal();
        break;
      default:
        this.toggleLoginModal();
    }
  }

  handleRedirect(path) {
    localStorage.removeItem(localName.PHONE_NUMBER);
    if (path == "/logout") {
      handleLogout();
    } else {
      redirectTo(path);
    }
  }

  setEmail = (email) => {
    this.setState({
      registerOrForgotPasswordEmail: email,
    });
  };
  setLoginTab(isLoginTab) {
    this.setState({
      isLoginTab,
    });
  }

  loadProfileImg() {
    customer.user.getProfileData((data) => {
      localStorage.setItem(localName.PROFILE_IMAGE, data.imageProfile); // ต้องแก้เพราะ imageProfile คือ form data ไม่ใช่ string
    });
  }
  handleSendToEmailTab = () => {
    this.setState({
      visibleLoginModal: false,
      visibleSendToEmailModal: true,
      visibleForgotPasswordModal: false,
    });
  };
  handleForgotPasswordTab = () => {
    this.setState({
      visibleLoginModal: !this.state.visibleLoginModal,
      visibleForgotPasswordModal: !this.state.visibleForgotPasswordModal,
    });
  };
  backToLoginTab = () => {
    this.setState({
      visibleLoginModal: !this.state.visibleLoginModal,
      visibleForgotPasswordModal: !this.state.visibleForgotPasswordModal,
    });
  };
  render() {
    const {
      cartCount,
      visibleLoginModal,
      visibleForgotPasswordModal,
      visibleSendToEmailModal,
      isLoginTab,
      selected,
      displayImage,
      registerOrForgotPasswordEmail,
    } = this.state;

    const { headerHeight } = this.props;
    const signedIn =
      localStorage.getItem(localName.ACCESS_TOKEN) &&
      localStorage.getItem(localName.REFRESH_TOKEN);

    if (displayImage === localName.PROFILE_IMAGE) {
      this.loadProfileImg();
    }

    return (
      <div>
        <Bar headerHeight={headerHeight}>
          <MediaQuery maxDeviceWidth={680}>
            <div
              onClick={() => {
                this.setState({
                  isOpenSideBar: true,
                });
              }}
            >
              <SVGIcon type="menu" stroke={colors.buttonColor} />
            </div>
          </MediaQuery>
          <img
            src={logo}
            onClick={() => this.handleRedirect("/home")}
            style={{ cursor: "pointer", height: "80%" }}
          />
          <MediaQuery minDeviceWidth={680}>
            <FlexRow>
              <FlexRow>
                <Title
                  onClick={() => this.handleRedirect("/home")}
                  selected={selected === "home" || selected === ""}
                >
                  หน้าหลัก
                </Title>
                <LittleSpace />
                {/* <Title
                onClick={() => this.handleRedirect("/make-a-reservation")}
                selected={selected === "make-a-reservation"}
              >
                จองโต๊ะอาหาร
              </Title>
              <LittleSpace /> */}
                {signedIn ? (
                  <>
                    <Title
                      onClick={() => this.handleRedirect("/my-order")}
                      selected={selected === "my-order"}
                    >
                      ออเดอร์ของฉัน
                    </Title>
                    <LittleSpace />
                  </>
                ) : (
                  <></>
                )}
              </FlexRow>
              <Badge
                count={cartCount}
                overflowCount={10}
                style={{
                  backgroundColor: "#663C46",
                  marginTop: "22px",
                  marginRight: "22px",
                }}
              >
                <img
                  src={cartIcon}
                  onClick={() => this.handleRedirect("/cart")}
                  style={{ cursor: "pointer" }}
                />
              </Badge>
              <LittleSpace size={"1.2rem"} />
              <img src={line} />
              <LittleSpace size={"1.2rem"} />
              {signedIn ? (
                <Dropdown
                  overlay={
                    <MenuList
                      handleDropdownClick={this.handleDropdownClick}
                      toggleLoginModal={this.toggleLoginModal}
                    />
                  }
                  trigger={["hover"]}
                  placement="bottomRight"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <FlexRow>
                      <Avatar icon={<UserOutlined />} src={displayImage} />
                      <LittleSpace size={"0.6rem"} />
                      <Title>{this.state.displayName}</Title>
                      <LittleSpace size={"0.6rem"} />
                      <img src={downIcon} />
                    </FlexRow>
                  </a>
                </Dropdown>
              ) : (
                <Title onClick={() => this.toggleLoginModal()} selected={true}>
                  <SVGIcon type="user" />
                  &nbsp;เข้าสู่ระบบ
                </Title>
              )}
            </FlexRow>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={680}>
            <Badge
              count={cartCount}
              overflowCount={10}
              style={{
                backgroundColor: "#663C46",
                marginTop: "22px",
                marginRight: "22px",
              }}
            >
              <img
                src={cartIcon}
                onClick={() => this.handleRedirect("/cart")}
                style={{ cursor: "pointer" }}
              />
            </Badge>
          </MediaQuery>
          <LoginModal
            handleClose={this.toggleLoginModal}
            visible={visibleLoginModal}
            onCloseSendToEmailTab={this.handleSendToEmailTab}
            onCloseForgotPasswordTab={this.handleForgotPasswordTab}
            backToLoginTab={this.backToLoginTab}
            isLoginTab={isLoginTab}
            setLoginTab={this.setLoginTab}
            registerEmail={registerOrForgotPasswordEmail}
            setEmail={this.setEmail}
          />
          <ForgotPasswordModal
            visibleForgotPasswordModal={visibleForgotPasswordModal}
            onCloseForgotPasswordTab={this.handleForgotPasswordTab}
            onCloseSendToEmailTab={this.handleSendToEmailTab}
            backToLoginTab={this.backToLoginTab}
            ForgotPassWordEmail={registerOrForgotPasswordEmail}
            setEmail={this.setEmail}
          ></ForgotPasswordModal>
          <SendToEmailModal
            email={registerOrForgotPasswordEmail}
            visibleSendToEmailModal={visibleSendToEmailModal}
          ></SendToEmailModal>
        </Bar>
        <SideBar show={this.state.isOpenSideBar}>
          <Backdrop
            onClick={() => {
              this.setState({
                isOpenSideBar: false,
              });
            }}
          ></Backdrop>
          <SideBarMenu show={this.state.isOpenSideBar}>
            <Bar
              headerHeight="4rem"
              style={{
                // width: "70%",
                paddingLeft: "1%",
                paddingRight: "1%",
                justifyContent: "initial",
              }}
            >
              <div
                onClick={() => {
                  this.setState({
                    isOpenSideBar: false,
                  });
                }}
              >
                <SVGIcon type="xIcon" stroke={colors.buttonColor} />
              </div>
              <img
                src={logo}
                style={{ height: "60%" }}
                onClick={() => this.handleRedirect("/home")}
              />
            </Bar>
            <div
              style={{
                padding: "1rem",
                overflowY: "auto",
                height: "calc(100vh - 4rem)",
              }}
            >
              <DrawerBlock>
                <DrawerItem
                  onClick={() => this.handleRedirect("/home")}
                  active={selected === "home" || selected === ""}
                >
                  <SVGIcon
                    type="home"
                    stroke={
                      selected === "home" || selected === ""
                        ? colors.activeManu
                        : colors.inActiveMenu
                    }
                    style={{ height: "1rem", width: "1rem" }}
                  />
                  &nbsp;หน้าหลัก
                </DrawerItem>
                {signedIn && (
                  <DrawerItem
                    onClick={() => this.handleRedirect("/my-order")}
                    active={selected === "my-order"}
                  >
                    <SVGIcon
                      type="myOrder"
                      stroke={
                        selected === "my-order" || selected === ""
                          ? colors.activeManu
                          : colors.inActiveMenu
                      }
                      style={{ height: "1rem", width: "1rem" }}
                    />
                    &nbsp;ออเดอร์ของฉัน
                  </DrawerItem>
                )}
              </DrawerBlock>
              {signedIn ? (
                <React.Fragment>
                  <DrawerBlock>
                    <DrawerItem
                      onClick={() => this.handleRedirect("/profile")}
                      active={selected === "profile"}
                    >
                      <SVGIcon
                        type="user"
                        stroke={
                          selected === "profile"
                            ? colors.activeManu
                            : colors.inActiveMenu
                        }
                        style={{ height: "1rem", width: "1rem" }}
                      />
                      &nbsp;โปรไฟล์ของฉัน
                    </DrawerItem>
                    <DrawerItem
                      onClick={() => {
                        localStorage.setItem(localName.START_AT, "/");
                        this.handleRedirect("/my-address");
                      }}
                      active={selected === "my-address"}
                    >
                      <SVGIcon
                        type="home"
                        stroke={
                          selected === "my-address"
                            ? colors.activeManu
                            : colors.inActiveMenu
                        }
                        style={{ height: "1rem", width: "1rem" }}
                      />
                      &nbsp;ที่อยู่ของฉัน
                    </DrawerItem>
                    <DrawerItem
                      onClick={() => {
                        this.handleRedirect("/contact");
                      }}
                      active={selected === "contact"}
                    >
                      <SVGIcon
                        type="phone"
                        stroke={
                          selected === "contact"
                            ? colors.activeManu
                            : colors.inActiveMenu
                        }
                        style={{ height: "1rem", width: "1rem" }}
                      />
                      &nbsp;ติดต่อร้านค้า
                    </DrawerItem>
                  </DrawerBlock>
                  <DrawerBlock>
                    <DrawerItem
                      onClick={() => this.handleRedirect("/logout")}
                      danger={true}
                    >
                      <SVGIcon
                        type="logout"
                        stroke={colors.storeTextDanger}
                        style={{ height: "1rem", width: "1rem" }}
                      />
                      &nbsp;ออกจากระบบ
                    </DrawerItem>
                  </DrawerBlock>
                </React.Fragment>
              ) : (
                <DrawerBlock>
                  <DrawerItem
                    onClick={() => {
                      this.toggleLoginModal();
                      this.setState({
                        isOpenSideBar: false,
                      });
                    }}
                  >
                    <SVGIcon
                      type="user"
                      stroke={colors.inActiveMenu}
                      style={{ height: "1rem", width: "1rem" }}
                    />
                    &nbsp;เข้าสู่ระบบ
                  </DrawerItem>
                </DrawerBlock>
              )}
            </div>
          </SideBarMenu>
        </SideBar>
      </div>
    );
  }
}

export default Header;
