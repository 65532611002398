import React, { useState, useContext } from "react";
import styled from "styled-components";
import Step1 from "../../../../assets/icons/editaddress-steps/step1.svg";
import Step2 from "../../../../assets/icons/editaddress-steps/step2.svg";
import pinIcon from "../../../../assets/icons/pin.svg";
import DisabledStep2 from "../../../../assets/icons/editaddress-steps/disabledstep2.svg";
import { Button } from "../../../customers/components/sharedComponents";
import localName from "../../../../values/localStorageDict";
import { redirectTo, subDomain } from "../../../../services/redirect";

const StatusBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10% 0.5rem 5%;
  @media not screen and (min-device-width: 1224px) {
    display: none;
  }
`;

const StatusText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.125rem;
  text-align: center;
  letter-spacing: 0.1px;
  color: #663c46;
  padding-right: 10%;
  margin-bottom: 2rem;
  margin-left: 7rem;
  @media not screen and (min-device-width: 1224px) {
    display: none;
  }
`;

const LineColor = styled.div`
  position: relative;
  width: 38%;
  height: 0;
  border: ${(props) =>
    props.disabled ? "solid 0.5px #979797" : "solid 0.5px #663C46"};
`;

const StyledButton = styled(Button)`
  position: relative;
  width: 37.5%;
  height: 3rem;
  margin-left: 15%;
  margin-right: 10%;
  @media (max-width: 1075px) {
    font-size: 18px;
    width: 40%;
  }
  @media not screen and (min-device-width: 1224px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PinStatus = ({ ...props }) => {
  return (
    <Container>
      <StatusBox>
        <img src={Step1}></img>
        <LineColor disabled={!props.enable} />
        {props.enable ? (
          <img src={Step2}></img>
        ) : (
          <img src={DisabledStep2}></img>
        )}
      </StatusBox>
      <StatusText>
        <text style={{ marginRight: "32%" }}>กรุณาปักหมุด</text>
        <text>กรอกรายละเอียดเพิ่มเติม</text>
      </StatusText>
      <div>
        {!props.enable ? (
          <StyledButton
            onClick={() =>
              props.history.push(
                `${subDomain}/pin`,
                props.attributeSet.mapPosition
              )
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={pinIcon} style={{ marginRight: "0.5rem" }} />
              <text> กดตรงนี้เพื่อปักหมุด</text>
            </div>
          </StyledButton>
        ) : (
          <StyledButton
            onClick={() =>
              props.history.push(
                `${subDomain}/pin`,
                props.attributeSet.mapPosition
              )
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={pinIcon} style={{ marginRight: "0.5rem" }} />
              <text> ย้ายจุดปักหมุด</text>
            </div>
          </StyledButton>
        )}
      </div>
    </Container>
  );
};

export default PinStatus;
